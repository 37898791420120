import React, { Component } from "react";
import { BrowserRouter as Router, Route, withRouter, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import Sidebar from './components/sidebar/Sidebar';
import Navbar from './components/navbar/Navbar';
import Content from './components/content/Content';
import { getSidebarItems } from './components/sidebar/SidebarUtil';
import Login from "./components/login/Login";
import { getLoggedUser } from './redux/auth/actions';
import { UserRole, hasAnyRole } from "./utils/Security";
import Unsubscribe from "./components/common/Unsubscribe";
import UserConfirmation from "./components/common/UserConfirmation";
import { withTranslation } from "react-i18next";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      loginErrorMessage: ''
    }
  }

  componentDidMount() {
    if (window.location.href.includes("/oauth2/redirect?error")) {
      let message = new URLSearchParams(this.props.location.search).get("error")
      window.history.replaceState(null, null, "/")
      this.setState({ loginErrorMessage: message })
    }
    if (!window.location.href.includes("/unsubscribe") && !this.props.authReducer.user) {
      this.props.getLoggedUser();
    }
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  };

  render() {
    return (
      <div className="root">
        <Router getUserConfirmation={(message, callback) =>
          UserConfirmation(message, callback, this.props)}>
          {!this.props.authReducer.userLoading && <>
            <CssBaseline />
            {this.props.authReducer.user ?
              <React.Fragment>
                {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Navbar handleDrawerToggle={this.handleDrawerToggle} />}
                {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Sidebar
                  items={getSidebarItems()}
                  handleDrawerToggle={this.handleDrawerToggle}
                  mobileOpen={this.state.mobileOpen} />}
                <Content />
              </React.Fragment> : <Switch>
                <Route exact path="/oauth2/redirect" render={(props) => <Login user={this.props.authReducer.user} loginError={this.state.loginErrorMessage} {...props} />}></Route>
                <Route exact path="/" render={(props) => <Login user={this.props.authReducer.user} {...props} />}></Route>
                <Route exact path="/unsubscribe/:formId" component={Unsubscribe}></Route>
              </Switch>
            }
          </>}
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authReducer: state.authReducer
});

export default connect(mapStateToProps, { getLoggedUser })(withRouter(withTranslation()(App)));