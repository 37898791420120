import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Zoom,
    Tooltip,
    Stack,
    Box,
    Divider
} from '@mui/material';
import { FileUpload } from '@mui/icons-material'
import Base58Table from "../../components/common/Base58Table";
import { getServiceGroupsList } from '../../redux/serviceGroup/actions'
import { getServices, deleteService, importServices, setServicesPage, setServicesSize, setServicesParams, changeServiceStatus } from '../../redux/service/actions'
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { NumericFormat } from 'react-number-format';
import ImportServicesModal from './ImportServicesModal';
import { UserRole, hasAnyRole } from '../../utils/Security';
import store from '../../redux/store';
import types from '../../redux/service/types';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';

class Services extends React.Component {

    emptyService = {
        id: null,
        name: '',
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            serviceToDelete: this.emptyService,
            openDeleteDialog: false,
            importDialog: false,
            fileName: '',
            fileData: ''
        };
    }

    componentDidMount() {
        this.props.getServices()
        this.props.getServiceGroupsList()
    }

    componentWillUnmount() {
        this.props.setServicesSize(10, true)
        store.dispatch({
            type: types.RESET_SERVICE_DATA
        })
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, serviceToDelete: null });
    }

    closeImportDialog = () => {
        this.setState({ importDialog: false, fileName: null, fileData: null });
    }

    handleChangeFile = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        const data = new FormData()
        data.append('file', file)
        this.setState({ fileName: file.name, fileData: data })
    }

    importFile = () => {
        this.props.importServices(this.state.fileData).then((response) => {
            if (response.status !== 400) {
                this.setState({ importDialog: false })
                this.props.getServices()
            }
        });
    }

    render() {
        const { serviceToDelete, importDialog } = this.state;
        const { isLoading, services, servicesParams, servicesTotalElements } = this.props.serviceReducer;
        const { serviceGroupsList } = this.props.serviceGroupReducer

        let serviceList = services && services.map((service, index) => {
            return <TableRow key={index}>
                <TableCell>{service.serviceGroup && service.serviceGroup.length > 50 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.serviceGroup} style={{ cursor: 'pointer' }}>
                    <span>{service.serviceGroup && service.serviceGroup.length > 50 ? service.serviceGroup.substring(0, 50) + "..." : service.serviceGroup}</span>
                </Tooltip> :
                    service.serviceGroup}</TableCell>

                <TableCell>{service.code && service.code.length > 15 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.code} style={{ cursor: 'pointer' }}>
                    <span>{service.code && service.code.length > 15 ? service.code.substring(0, 15) + "..." : service.code}</span>
                </Tooltip> :
                    service.code}</TableCell>
                <TableCell>{service.name && service.name.length > 60 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.name} style={{ cursor: 'pointer' }}>
                    <span>{service.name && service.name.length > 60 ? service.name.substring(0, 60) + "..." : service.name}</span>
                </Tooltip> :
                    service.name}</TableCell>
                <TableCell>{service.comment && service.comment.length > 40 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={service.comment} style={{ cursor: 'pointer' }}>
                    <span>{service.comment && service.comment.length > 40 ? service.comment.substring(0, 40) + "..." : service.comment}</span>
                </Tooltip> :
                    service.comment}</TableCell>
                <TableCell style={{ textAlign: 'right', fontWeight: 'bold' }}><NumericFormat displayType="text" decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={service.price} suffix=" EUR" /></TableCell>
                {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <TableCell className='row-cell' align='center'>{
                    <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                        <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push("/services/" + service.id)}>
                            {this.props.t("edit")}
                        </Button>
                        <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ serviceToDelete: service, openDeleteDialog: true })}>
                            {this.props.t("delete")}
                        </Button>
                    </Stack>
                }</TableCell>}
            </TableRow>
        })

        let columns = [
            {
                name: "name_service_group",
                dbName: "service_group",
                width: '15%',
                type: "auto-complete",
                items: serviceGroupsList.map(sg => { return sg.name }),
                filter: true,
            },
            {
                name: "code",
                dbName: 'code',
                width: '10%',
                sort: true,
                filter: true
            },
            {
                name: "name_service",
                dbName: 'name',
                width: '27%',
                sort: true,
                filter: true
            },
            {
                name: "comment",
                width: '16%',
            },
            {
                name: "price",
                dbName: 'price',
                width: '12%',
                type: 'number',
                thousandSeparator: ',',
                decimalSeparator: '.',
                decimalScale: 2,
                sort: true,
                filter: true
            }
        ]

        const actions = { name: "", width: '17%' };

        if (hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM])) {
            columns.push(actions);
        }

        return (<Box className='custom-box'>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack direction="row" spacing={2}>
                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                            {this.props.t("services")}
                        </Typography>
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <>
                            <Button
                                className="create-btn"
                                startIcon={<AddIcon />}
                                onClick={() => this.props.history.push("/new-service")}>
                                {this.props.t("new_service")}
                            </Button>
                            <Button
                                className="create-btn"
                                startIcon={<FileUpload />}
                                onClick={() => this.setState({ importDialog: true })}>
                                {this.props.t("import_services")}
                            </Button>
                        </>}
                    </Stack>
                    <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={isLoading}
                        columns={columns}
                        data={serviceList}
                        count={servicesTotalElements}
                        rowsPerPage={servicesParams.size}
                        page={servicesParams.page}
                        onPageChange={(e, page) => this.props.setServicesPage(page)}
                        onRowsPerPageChange={(e) => this.props.setServicesSize(e.target.value)}
                        onFilterChange={(params) => this.props.setServicesParams(params)}
                    />
                </Grid>
            </Grid>
            <ConfirmationDialog
                isOpen={this.state.openDeleteDialog}
                title={this.props.t("delete_service")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_service_message", { name: serviceToDelete && serviceToDelete.name }) }} />}
                onClose={() => this.setState({ openDeleteDialog: false })}
                onConfirm={() => this.props.changeServiceStatus(serviceToDelete.id, "false").then(() => {
                    this.setState({ openDeleteDialog: false }, () => this.props.getServices(), this.props.setServicesPage(0));
                })}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
            <ImportServicesModal
                isOpen={importDialog}
                onClose={this.closeImportDialog}
                fileName={this.state.fileName}
                importFile={this.importFile}
                handleChangeFile={this.handleChangeFile}
            />
        </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer,
    serviceGroupReducer: state.serviceGroupReducer
})

const mapActionsToProps = { getServices, getServiceGroupsList, deleteService, importServices, setServicesPage, setServicesSize, setServicesParams, changeServiceStatus }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Services))
