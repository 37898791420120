import types from '../normative/types';
import api from '../../api/normativeApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getNormatives = (warehouseId) => async (dispatch) => {
    dispatch({
        type: types.SET_NORMATIVES_LOADING
    });
    const params = store.getState().normativeReducer.normativesParams;
    await api.getNormatives(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setNormativesPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_NORMATIVES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_NORMATIVES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_normatives_error")));
    });
    return Promise.resolve();
}

export const getListOfNorms = (warehouseId) => async (dispatch) => {
    await api.getNormatives(warehouseId, { params: { page: 0, size: 1000/* , sort: "id,desc" */ } }).then(response => {
        dispatch({
            type: types.SET_LIST_OF_NORMS,
            payload: response.data.content
        });
    }).catch(error => {
        dispatch({
            type: types.SET_LIST_OF_NORMS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_normatives_error")));
    });
    return Promise.resolve();
}

export const setNormativesPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_NORMATIVES_PAGE,
        payload: Number(value)
    });
    dispatch(getNormatives(warehouseId));
    return Promise.resolve();
};

export const setNormativesSize = (warehouseId, value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_NORMATIVES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getNormatives(warehouseId));
    }
    return Promise.resolve();
};

export const setNormativesParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_NORMATIVES_PARAMS,
        payload: Object.assign(store.getState().normativeReducer.normativesParams, params)
    });
    dispatch(getNormatives(warehouseId));
    return Promise.resolve();
};

export const createNormative = (data) => async (dispatch) => {
    return await api.createNormative(data).then((response) => {
        dispatch(snackActions.success(i18next.t("normative_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const updateNormative = (serviceId, data) => async (dispatch) => {
    return await api.updateNormative(serviceId, data).then(response => {
        dispatch(snackActions.success(i18next.t("normative_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response.data
    })
}

export const getNormativeById = (warehouseId, id, config = {}) => async (dispatch) => {
    return await api.getNormativeById(warehouseId, id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_normative_by_id_error")));
    });
}

export const deleteNormative = (warehouseId, id) => async (dispatch) => {
    return await api.deleteNormative(warehouseId, id).then((response) => {
        dispatch(snackActions.success(i18next.t("normative_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    })
}