export const DocumentTypes = {
    INVENTORY: 'INVENTORY',
    MANUAL: 'MANUAL'
}

export const Types = {
    IN: 'IN',
    OUT: 'OUT'
}

const actions = {
    DocumentTypes,
    Types
}

export default actions;