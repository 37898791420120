import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Grid,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
import Base58Table from '../../../components/common/Base58Table';
import { getUnitOfMeasurements, setMeasurementsSize, setMeasurementsParams, setMeasurementsPage, deleteMeasurement } from '../../../redux/warehouse/actions'
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import store from '../../../redux/store';
import types from '../../../redux/warehouse/types';

class UnitOfMeasurements extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            measurementToDelete: null
        };
    }

    componentDidMount() {
        this.props.getUnitOfMeasurements(this.props.match.params.warehouseId);
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.RESET_MEASUREMENTS_DATA
        })
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, measurementToDelete: null });
    }

    deleteMeasurement = () => {
        this.props.deleteMeasurement(this.state.measurementToDelete.id).then((response) => {
            if (response.status === 200) {
                this.setState({ openDeleteDialog: false }, () => this.props.setMeasurementsPage(this.props.match.params.warehouseId, 0));
            }
        })
    }

    render() {
        const { measurementToDelete } = this.state
        const { measurementsLoading, measurements, measurementsParams, measurementsTotalElements } = this.props.warehouseReducer;

        let measurementList = measurements && measurements.map((unit, index) => {
            return <TableRow key={index}>
                <TableCell>{unit.unitOfMeasurement}</TableCell>
                <TableCell>{unit.symbol}</TableCell>
                <TableCell className='row-cell' align='center'>{
                    <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                        <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/measurement-units/${unit.id}`)}>
                            {this.props.t("edit")}
                        </Button>
                        <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ measurementToDelete: unit, openDeleteDialog: true })}>
                            {this.props.t("delete")}
                        </Button>
                    </Stack>
                }</TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "unit_of_measurement",
                dbName: 'unitOfMeasurement',
                width: '40%',
                sort: true
            },
            {
                name: "symbol",
                dbName: 'symbol',
                width: '40%',
                sort: true
            },
            {
                name: "",
                width: '20%'
            }
        ]

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            className="create-btn"
                            sx={{ marginLeft: 'auto' }}
                            startIcon={<AddIcon />}
                            onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/measurement-units/new`)}
                        >
                            {this.props.t("new_unit")}
                        </Button>
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={measurementsLoading}
                        columns={columns}
                        data={measurementList}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        count={measurementsTotalElements}
                        rowsPerPage={measurementsParams.size}
                        page={measurementsParams.page}
                        onPageChange={(e, page) => this.props.setMeasurementsPage(this.props.match.params.warehouseId, page)}
                        onRowsPerPageChange={(e) => this.props.setMeasurementsSize(this.props.match.params.warehouseId, e.target.value)}
                        onFilterChange={(params) => this.props.setMeasurementsParams(this.props.match.params.warehouseId, params, { ...params, page: 0 })}
                    />
                </Grid>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_measurement")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_measurement_message", { name: measurementToDelete && measurementToDelete.unitOfMeasurement }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.deleteMeasurement()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

export default connect(mapStateToProps, { getUnitOfMeasurements, setMeasurementsSize, setMeasurementsParams, setMeasurementsPage, deleteMeasurement })(withRouter(withTranslation()(UnitOfMeasurements)))
