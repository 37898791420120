import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Zoom,
    Tooltip
} from '@mui/material';
import 'moment/locale/hr';

class CustomEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false
        };
    }

    render() {

        let { event, selectedBtn } = this.props

        return (
            <Grid item container direction="row">
                <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top" title={<p style={{ fontSize: '14px' }}><b>{event.patient.firstName + " " + event.patient.lastName + " (" + event.patient.phone + ") " + event.comment}</b></p>} style={{ cursor: 'pointer' }}>
                    <span style={{
                        width: "100%",
                        maxWidth: "230px",
                        overflow: "hidden",
                        display: "inline-block",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}>
                        {selectedBtn && selectedBtn === "month" ? `${event.patient.firstName} ${event.patient.lastName}` : `${event.patient.firstName} ${event.patient.lastName} (${event.patient.phone}) ${event.comment}`}
                    </span>
                </Tooltip>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {})(withTranslation()(CustomEvent));
