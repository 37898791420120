import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { getServicesByDoctorId } from '../../redux/service/actions'
import DashboardStatisticsDoctor from './DashboardStatisticsDoctor';
import DashboardStatisticsInvoice from './DashboardStatisticsInvoice';
import DashboardStatisticsForm from './DashboardStatisticsForm';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';
import DashboardStatisticsProfit from './DashboardStatisticsProfit';
import DashboardStatisticsProfitPerServices from './DashboardStatisticsProfitPerServices';
import { ReactComponent as DoctorsActiveIcon } from '../../assets/icons/statistics/doctors_active.svg';
import { ReactComponent as DoctorsInactiveIcon } from '../../assets/icons/statistics/doctors_inactive.svg';
import { ReactComponent as OffersActiveIcon } from '../../assets/icons/statistics/offers_active.svg';
import { ReactComponent as OffersInactiveIcon } from '../../assets/icons/statistics/offers_inactive.svg';
import { ReactComponent as ServicesActiveIcon } from '../../assets/icons/statistics/services_active.svg';
import { ReactComponent as ServicesInactiveIcon } from '../../assets/icons/statistics/services_inactive.svg';
import { ReactComponent as QuestionnaireActiveIcon } from '../../assets/icons/statistics/questionnaire_active.svg';
import { ReactComponent as QuestionnaireInactiveIcon } from '../../assets/icons/statistics/questionnaire_inactive.svg';
import { ReactComponent as ProfitActiveIcon } from '../../assets/icons/statistics/profit_active.svg';
import { ReactComponent as ProfitInactiveIcon } from '../../assets/icons/statistics/profit_inactive.svg';

class DashboardStatistic extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        const { selectedTab } = this.state

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={3}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography gutterBottom sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                            {this.props.t("statistics")}
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ borderBottom: 1, border: 'none' }}>
                            <Tabs value={selectedTab} onChange={this.handleChangeTab}
                                TabIndicatorProps={{
                                    sx: { display: 'none' }
                                }}>
                                <Tab icon={selectedTab === 0 ? <DoctorsInactiveIcon /> : <DoctorsActiveIcon />} iconPosition="start" label={this.props.t("doctors")} {...a11yProps(0)} wrapped />
                                <Tab icon={selectedTab === 1 ? <OffersInactiveIcon /> : <OffersActiveIcon />} iconPosition="start" label={this.props.t("offers")}  {...a11yProps(1)} wrapped />
                                <Tab icon={selectedTab === 2 ? <ServicesInactiveIcon /> : <ServicesActiveIcon />} iconPosition="start" label={this.props.t("services")}  {...a11yProps(2)} wrapped />
                                <Tab icon={selectedTab === 3 ? <QuestionnaireInactiveIcon /> : <QuestionnaireActiveIcon />} iconPosition="start" label={this.props.t("patient_satisfaction_questionnaire")}  {...a11yProps(3)} wrapped />
                                <Tab icon={selectedTab === 4 ? <ProfitInactiveIcon /> : <ProfitActiveIcon />} iconPosition="start" label={this.props.t("profit")}  {...a11yProps(4)} wrapped />
                            </Tabs>
                        </Box>
                    </Grid>
                </Grid>
                <CustomTabPanel value={selectedTab} index={0}>
                    <DashboardStatisticsDoctor />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={1}>
                    <DashboardStatisticsInvoice />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={2}>
                    <DashboardStatisticsProfitPerServices />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={3}>
                    <DashboardStatisticsForm />
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={4}>
                    <DashboardStatisticsProfit />
                </CustomTabPanel>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { getServicesByDoctorId })(withTranslation()(DashboardStatistic))