import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    CircularProgress,
    Button,
    TableRow,
    Box
} from '@mui/material'
import { getGoogleCredentials, setGoogleCredentialsPage, setGoogleCredentialsSize, setGoogleCredentialsParams } from '../../../redux/setting/actions'
import Base58Table from '../../../components/common/Base58Table';
import { Check, Clear } from '@mui/icons-material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import CreateCredentialsDialog from './CreateCredentialsDialog';
import moment from 'moment';
import JsonView from 'react18-json-view';

class Credentials extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            dialogOpen: false,
            isOpen: false,
            selectedParams: null
        }
    }

    componentDidMount() {
        Promise.all([
            this.props.getGoogleCredentials()])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    render() {
        const { mainLoading, dialogOpen } = this.state;
        const { googleCredentials, googleCredentialsTotalElement, googleCredentialsParams, googleCredentialsLoading } = this.props.settingReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }
        let credentials = googleCredentials && googleCredentials.map((credential, index) => {
            let jsonObject = {
                type: credential.type,
                project_id: credential.project_id,
                private_key_id: credential.project_id,
                private_key: credential.project_id,
                client_email: credential.project_id,
                client_id: credential.client_id,
                auth_uri: credential.auth_uri,
                token_uri: credential.token_uri,
                auth_provider_x509_cert_url: credential.auth_provider_x509_cert_url,
                client_x509_cert_url: credential.client_x509_cert_url,
                universe_domain: credential.universe_domain,
                gmail_username: credential.gmail_username,
                gmail_password: credential.gmail_password
            }
            return <TableRow key={index}>
                <TableCell>{credential.created_by}</TableCell>
                <TableCell>{moment.unix(credential.created_date).format("DD.MM.YYYY. HH:mm")}</TableCell>
                <TableCell align='center'>{credential.is_active ? <Check style={{ color: '#22AE73' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                <TableCell>
                    <JsonView collapsed={true} src={jsonObject} />
                </TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "created_by",
                width: '15%'
            },
            {
                name: "created_date",
                width: '10%'
            },
            {
                name: "is_active",
                width: '5%'
            },
            {
                name: "details",
                width: '65%'
            }
        ]

        return (<Box className='custom-box'>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button startIcon={<AddIcon />} className="create-btn" style={{ height: "40px", width: "200px", marginLeft: 'auto' }} onClick={() => this.setState({ dialogOpen: true })} >
                        {this.props.t("new_credentials")}
                    </Button>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={googleCredentialsLoading}
                        columns={columns}
                        data={credentials}
                        count={googleCredentialsTotalElement}
                        rowsPerPage={googleCredentialsParams.size}
                        page={googleCredentialsParams.page}
                        onPageChange={(e, page) => this.props.setGoogleCredentialsPage(page)}
                        onRowsPerPageChange={(e) => this.props.setGoogleCredentialsSize(e.target.value)}
                        onFilterChange={(params) => this.props.setGoogleCredentialsParams(params)}
                    />
                </Grid>
            </Grid>
            <CreateCredentialsDialog
                isOpen={dialogOpen}
                onClose={() => this.setState({ dialogOpen: false })}
                onSave={() => this.setState({ dialogOpen: false }, () => this.props.getGoogleCredentials())} />
        </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = { getGoogleCredentials, setGoogleCredentialsPage, setGoogleCredentialsSize, setGoogleCredentialsParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Credentials))
