import React from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
    FormControl,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import {
    createWarehouse,
    updateWarehouse,
    getWarehouseById
} from '../../redux/warehouse/actions';
import { NAME_REGEX } from '../../components/common/regex';
import { getUserList } from '../../redux/user/actions'
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

class ManageWarehouse extends React.Component {

    emptyWarehouse = {
        name: '',
        description: '',
        users: []
    };
    constructor(props) {
        super(props);
        this.state = {
            mainLoading: false,
            initialWarehouse: JSON.parse(JSON.stringify(this.emptyWarehouse)),
            warehouse: JSON.parse(JSON.stringify(this.emptyWarehouse)),
            validation: {
                nameError: false,
                nameErrorText: "",
                descriptionError: false,
                descriptionErrorText: "",
                usersError: false,
                usersErrorText: ""
            }
        };
    }

    componentDidMount() {
        this.props.getUserList();
        if (this.props.match.params.id) {
            this.props.getWarehouseById(this.props.match.params.id).then(response => {
                this.setState({
                    warehouse: JSON.parse(JSON.stringify(response)),
                    initialWarehouse: JSON.parse(JSON.stringify(response))
                }, () => {
                    this.validate("name", this.state.warehouse.name);
                    this.validate("description", this.state.warehouse.description);
                    this.validate("users", this.state.warehouse.users);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("name", this.state.warehouse.name);
            this.validate("description", this.state.warehouse.description);
            this.validate("users", this.state.warehouse.users);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { warehouse } = this.state;
        warehouse[target.name] = target.value.trimStart()
        this.setState({ warehouse: warehouse })
        this.validate(target.name, target.value);
    };

    handleChangeUsers = (e, users) => {
        this.setState({ warehouse: { ...this.state.warehouse, users: users } });
        this.validate("users", users);
    }

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "description":
                if (value.length === 0) {
                    validation.descriptionError = true;
                    validation.descriptionErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.descriptionError = true;
                    validation.descriptionErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.descriptionError = false;
                    validation.descriptionErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "users":
                if (value.length === 0) {
                    validation.usersError = true;
                    validation.usersErrorText = this.props.t("required_field_message");
                } else {
                    validation.usersError = false;
                    validation.usersErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageWarehouse = () => {
        const { warehouse, initialWarehouse } = this.state;
        if (this.props.match.params.id) {
            warehouse.addUserIds = warehouse.users.filter(u => !initialWarehouse.users.some(iu => iu.id === u.id)).map(u => u.id);
            warehouse.removeUserIds = initialWarehouse.users.filter(iu => !warehouse.users.some(u => u.id === iu.id)).map(u => u.id);
        }
        const action = this.props.match.params.id ?
            this.props.updateWarehouse(this.props.match.params.id, warehouse) :
            this.props.createWarehouse(warehouse);
        action.then(status => {
            if (status === 200 || status === 201) {
                this.setState({
                    initialWarehouse: JSON.parse(JSON.stringify(this.emptyWarehouse)),
                    warehouse: JSON.parse(JSON.stringify(this.emptyWarehouse))
                }, () => this.props.history.push(`/warehouses`));
            }
        })
    };

    disable() {
        let { validation, warehouse, initialWarehouse } = this.state;
        if (!warehouse.name || !warehouse.description || warehouse.users.length === 0 ||
            JSON.stringify(warehouse) === JSON.stringify(initialWarehouse) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {

        let { mainLoading, initialWarehouse, warehouse, validation } = this.state;
        let { userList } = this.props.userReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<Grid container direction="row">
            <Prompt when={JSON.stringify(initialWarehouse) !== JSON.stringify(warehouse)} message={this.props.t("prompt_message")} />
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={7} xl={7}>
                <Box className="custom-box">
                    <Grid container direction="row" spacing={3}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                    {this.props.match.params.id ? this.props.t("edit_warehouse") : this.props.t("new_warehouse")}
                                </Typography>
                                <Button className="cancel-btn"
                                    onClick={() => this.props.history.push(`/warehouses`)}>
                                    {this.props.t("cancel")}
                                </Button>
                                <Button className="create-btn" onClick={this.manageWarehouse} disabled={this.disable()}>
                                    {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("name")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <TextField
                                                    autoFocus
                                                    id="name"
                                                    name="name"
                                                    value={warehouse.name}
                                                    onChange={this.handleChange}
                                                    placeholder={this.props.t("enter", { text: this.props.t("name").toLowerCase() })}
                                                    error={validation.nameError}
                                                    helperText={validation.nameErrorText} />
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("description")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <TextField
                                                    id="description"
                                                    name="description"
                                                    value={warehouse.description}
                                                    onChange={this.handleChange}
                                                    placeholder={this.props.t("enter", { text: this.props.t("description").toLowerCase() })}
                                                    error={validation.descriptionError}
                                                    helperText={validation.descriptionErrorText}
                                                />
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("users")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <FormControl>
                                                    <WrappedAutocomplete
                                                        id="users"
                                                        name="users"
                                                        multiple={true}
                                                        value={warehouse.users}
                                                        placeholder={this.props.t("select")}
                                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.phone})`}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        options={userList}
                                                        onChange={(event, value) => {
                                                            this.handleChangeUsers(event, value)
                                                        }}
                                                        error={validation.usersError}
                                                        helperText={validation.usersErrorText}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        )
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
})

const mapActionsToProps = { createWarehouse, updateWarehouse, getWarehouseById, getUserList }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageWarehouse)))