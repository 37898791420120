import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Box,
    Button,
    DialogContent,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { createGoogleCredentials } from '../../../redux/setting/actions'
import { BootstrapDialog, Transition } from '../../../components/common/Dialog';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';

class CreateCredentialsDialog extends Component {

    emptyCredential = {
        type: '',
        project_id: '',
        private_key_id: '',
        private_key: '',
        client_email: '',
        client_id: '',
        auth_uri: '',
        token_uri: '',
        auth_provider_x509_cert_url: '',
        client_x509_cert_url: '',
        universe_domain: '',
        gmail_username: '',
        gmail_password: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            initialCredential: JSON.parse(JSON.stringify(this.emptyCredential)),
            credential: JSON.parse(JSON.stringify(this.emptyCredential)),
            promptDialogOpen: false
        };
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({ credential: JSON.parse(JSON.stringify(this.emptyCredential)) });
    }

    handleChange = (event) => {
        const target = event.target;
        let { credential } = this.state;

        credential[target.name] = target.value;
        this.setState({ credential: credential })
    };


    createGoogleCredential = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { credential } = this.state;
        const requestData = {
            ...credential,
            private_key: credential.private_key.replaceAll("\\n", "\n")
        }
        this.props.createGoogleCredentials(requestData).then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.setState({ credential: JSON.parse(JSON.stringify(this.emptyCredential)) });
                this.props.onSave();
            }
        })
    };

    validateForm() {
        let { credential } = this.state;
        let valid = true;
        if (!credential.type || !credential.project_id || !credential.private_key || !credential.private_key_id ||
            !credential.client_email || !credential.client_id || !credential.auth_uri || !credential.token_uri || !credential.auth_provider_x509_cert_url ||
            !credential.client_x509_cert_url || !credential.universe_domain
        ) {
            valid = false;
        }
        return valid;
    }

    render() {
        let { initialCredential, credential, promptDialogOpen } = this.state

        return (
            <BootstrapDialog
                onClose={() => JSON.stringify(initialCredential) !== JSON.stringify(credential) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="md">
                <DialogContent sx={{ padding: "0px !important" }}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.t("new_credentials")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => JSON.stringify(initialCredential) !== JSON.stringify(credential) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.createGoogleCredential} disabled={!this.validateForm()}>
                                        {this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("type")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="type"
                                    name="type"
                                    value={credential.type || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("type").toLowerCase() })}
                                    error={!credential.type}
                                    helperText={!credential.type && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("project_id")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="project_id"
                                    name="project_id"
                                    value={credential.project_id || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("project_id").toLowerCase() })}
                                    error={!credential.project_id}
                                    helperText={!credential.project_id && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("private_key_id")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="private_key_id"
                                    name="private_key_id"
                                    value={credential.private_key_id || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("private_key_id").toLowerCase() })}
                                    error={!credential.private_key_id}
                                    helperText={!credential.private_key_id && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("private_key")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="private_key"
                                    name="private_key"
                                    value={credential.private_key || ''}
                                    multiline
                                    rows={8}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("private_key").toLowerCase() })}
                                    error={!credential.private_key}
                                    helperText={!credential.private_key && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("client_email")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="client_email"
                                    name="client_email"
                                    value={credential.client_email || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("client_email").toLowerCase() })}
                                    error={!credential.client_email}
                                    helperText={!credential.client_email && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("client_id")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="client_id"
                                    name="client_id"
                                    value={credential.client_id || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("client_id").toLowerCase() })}
                                    error={!credential.client_id}
                                    helperText={!credential.client_id && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("auth_uri")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="auth_uri"
                                    name="auth_uri"
                                    value={credential.auth_uri || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("auth_uri").toLowerCase() })}
                                    error={!credential.auth_uri}
                                    helperText={!credential.auth_uri && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("token_uri")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="token_uri"
                                    name="token_uri"
                                    value={credential.token_uri || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("token_uri").toLowerCase() })}
                                    error={!credential.token_uri}
                                    helperText={!credential.token_uri && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("auth_provider_x509_cert_url")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="auth_provider_x509_cert_url"
                                    name="auth_provider_x509_cert_url"
                                    value={credential.auth_provider_x509_cert_url || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("auth_provider_x509_cert_url").toLowerCase() })}
                                    error={!credential.auth_provider_x509_cert_url}
                                    helperText={!credential.auth_provider_x509_cert_url && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("client_x509_cert_url")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="client_x509_cert_url"
                                    name="client_x509_cert_url"
                                    value={credential.client_x509_cert_url || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("client_x509_cert_url").toLowerCase() })}
                                    error={!credential.client_x509_cert_url}
                                    helperText={!credential.client_x509_cert_url && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("universe_domain")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="universe_domain"
                                    name="universe_domain"
                                    value={credential.universe_domain || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("universe_domain").toLowerCase() })}
                                    error={!credential.universe_domain}
                                    helperText={!credential.universe_domain && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("gmail_username")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="gmail_username"
                                    name="gmail_username"
                                    value={credential.gmail_username || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("gmail_username").toLowerCase() })}
                                    error={!credential.gmail_username}
                                    helperText={!credential.gmail_username && this.props.t("required_field_message")}
                                />
                            </Grid>
                            <Grid item container>
                                <InputLabel className="form-label">
                                    {this.props.t("gmail_password")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <TextField
                                    id="gmail_password"
                                    name="gmail_password"
                                    value={credential.gmail_password || ''}
                                    onChange={this.handleChange}
                                    placeholder={this.props.t("enter", { text: this.props.t("gmail_password").toLowerCase() })}
                                    error={!credential.gmail_password}
                                    helperText={!credential.gmail_password && this.props.t("required_field_message")}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <ConfirmationDialog
                    isOpen={promptDialogOpen}
                    title={this.props.t("prompt_title")}
                    message={this.props.t("prompt_message_2")}
                    onClose={() => this.setState({ promptDialogOpen: false })}
                    onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.closeDialog())}
                    closeButtonTitle={this.props.t("continue")}
                    confirmButtonTitle={this.props.t("discard")}
                />
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = { createGoogleCredentials }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateCredentialsDialog)))
