import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down.svg';
import Base58Table from '../../../components/common/Base58Table';
import { getItems, setItemsSize, setItemsParams, setItemsPage, deleteItem, getListOfCategories, getListOfMeasurements } from '../../../redux/warehouse/actions'
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import store from '../../../redux/store';
import types from '../../../redux/warehouse/types';

class Items extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            itemToDelete: null,
            collapseIndex: -1
        };
    }

    componentDidMount() {
        this.props.getItems(this.props.match.params.warehouseId)
        this.props.getListOfCategories(this.props.match.params.warehouseId)
        this.props.getListOfMeasurements(this.props.match.params.warehouseId)
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.RESET_ITEMS_DATA
        })
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, itemToDelete: null });
    }

    deleteItem = () => {
        this.props.deleteItem(this.state.itemToDelete.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteDialog: false }, () => this.props.setItemsPage(this.props.match.params.warehouseId, 0, true));
            }
        })
    }

    render() {
        const { itemToDelete, collapseIndex } = this.state
        const { itemsLoading, items, itemsParams, itemsTotalElements } = this.props.warehouseReducer;

        let itemsList = items && items.map((item, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell align='center'>
                        <IconButton size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                            {index === collapseIndex ? <ArrowUp /> : <ArrowDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.categoryName}</TableCell>
                    <TableCell>{`${item.unitOfMeasure} (${item.unitOfMeasureSymbol})`}</TableCell>
                    <TableCell>{item.productNo}</TableCell>
                    <TableCell className='row-cell' align='center'>{
                        <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                            <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/items/${item.id}`)}>
                                {this.props.t("edit")}
                            </Button>
                            <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ itemToDelete: item, openDeleteDialog: true })}>
                                {this.props.t("delete")}
                            </Button>
                        </Stack>
                    }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ border: "none", paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={7}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell colSpan={2} className='table-cell-light'>{this.props.t("description")}</TableCell>
                                                <TableCell colSpan={2} className='table-cell-light'>{this.props.t("barcode")}</TableCell>
                                                <TableCell colSpan={2} className='table-cell-light'>{this.props.t("manufacturer")}</TableCell>
                                                <TableCell colSpan={1} className='table-cell-light'>{this.props.t("reorder_point")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2}>{item.description}</TableCell>
                                                <TableCell colSpan={2}>{item.barcode}</TableCell>
                                                <TableCell colSpan={2}>{item.manufacturer}</TableCell>
                                                <TableCell colSpan={1}>{item.reorderPoint}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                name: "",
                dbName: '',
                width: '3%'
            },
            {
                name: "code",
                dbName: 'code',
                width: '12%',
                sort: true
            },
            {
                name: "name",
                dbName: 'name',
                width: '22%',
                filter: true,
                sort: true
            },
            {
                name: "category",
                dbName: 'categoryName',
                width: '16%',
                sort: true,
                type: "auto-complete",
                filter: true,
                items: this.props.warehouseReducer.categoriesList.map(category => { return category.name }),

            },
            {
                name: "unit",
                dbName: 'unit',
                width: '16%'
            },
            {
                name: "product_no",
                dbName: 'productNo',
                width: '16%',
                sort: true
            },
            {
                name: "",
                width: '15%'
            }
        ]


        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            className="create-btn"
                            sx={{ marginLeft: 'auto' }}
                            startIcon={<AddIcon />}
                            onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/items/new`)}
                        >
                            {this.props.t("new_item")}
                        </Button>
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={itemsLoading}
                        columns={columns}
                        data={itemsList}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        count={itemsTotalElements}
                        rowsPerPage={itemsParams.size}
                        page={itemsParams.page}
                        onPageChange={(e, page) => this.props.setItemsPage(this.props.match.params.warehouseId, page)}
                        onRowsPerPageChange={(e) => this.props.setItemsSize(this.props.match.params.warehouseId, e.target.value)}
                        onFilterChange={(params) => this.props.setItemsParams(this.props.match.params.warehouseId, { ...params, page: 0 })}
                    />
                </Grid>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_item")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_item_message", { name: itemToDelete && itemToDelete.name }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.deleteItem()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Grid>

        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

export default connect(mapStateToProps, { getItems, setItemsSize, setItemsParams, setItemsPage, deleteItem, getListOfCategories, getListOfMeasurements })(withRouter(withTranslation()(Items)))
