import React, { Component } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import moment from 'moment';

class PatientInfo extends Component {

    render() {
        const { currentPatient } = this.props.patientReducer
        return (
            <Grid container direction="row" spacing={2} sx={{padding: "8px"}}>
                <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{ minHeight: "144px !important", padding: "12px !important", borderRadius: "8px", boxShadow: "0px 4px 23.5px 0px rgba(0, 0, 0, 0.08)" }}>
                        <CardContent sx={{ padding: "0px !important" }}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("first_name")}</span>: {currentPatient.firstName || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("last_name")}</span>: {currentPatient.lastName || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("date_of_birth")}</span>: {moment(currentPatient.dateOfBirth).format("DD.MM.YYYY.") || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("gender")}</span>: {this.props.t(currentPatient.gender) || "-"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{ minHeight: "144px !important", padding: "12px !important", borderRadius: "8px", boxShadow: "0px 4px 23.5px 0px rgba(0, 0, 0, 0.08)" }}>
                        <CardContent sx={{ padding: "0px !important" }}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("oib_full")}</span>: {currentPatient.personalIdentificationNumber || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("mbo")}</span>: {currentPatient.registrationNumber || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("phone")}</span>: {currentPatient.phone || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("email")}</span>: {currentPatient.email || "-"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{ minHeight: "144px !important", padding: "12px !important", borderRadius: "8px", boxShadow: "0px 4px 23.5px 0px rgba(0, 0, 0, 0.08)" }}>
                        <CardContent sx={{ padding: "0px !important" }}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("address")}</span>: {currentPatient.address || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("city")}</span>: {currentPatient.city || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("country")}</span>: {currentPatient.country || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("county")}</span>: {currentPatient.county || "-"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card sx={{ minHeight: "144px !important", padding: "12px !important", borderRadius: "8px", boxShadow: "0px 4px 23.5px 0px rgba(0, 0, 0, 0.08)" }}>
                        <CardContent sx={{ padding: "0px !important" }}>
                            <Grid container direction="row" spacing={1}>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("source")}</span>: {this.props.t(currentPatient.source) || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <span style={{ fontWeight: 900, fontStyle: "italic" }}>{this.props.t("recommended_by")}</span>: {currentPatient.recommendedBy || "-"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

export default connect(mapStateToProps, {})(withTranslation()(PatientInfo))