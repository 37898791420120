import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import './App.css';
import './i18n/i18n';
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/themes/theme.js';
import Snackbar from './components/common/Snackbar';
import 'react-slideshow-image/dist/styles.css'

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Snackbar />
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </Provider>
);