import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import store from '../../redux/store'
import types, { POPOVER_OPEN, SET_SIGNER_EVENT } from '../../redux/patient/types';
import {
    Grid,
    TableCell,
    TableRow,
    Button,
    Typography,
    Zoom,
    Tooltip,
    Fab,
    Table,
    Collapse,
    Box,
    TableContainer,
    Paper,
    TableHead,
    TableBody,
    IconButton,
    Popover,
    FormControl,
    Select,
    MenuItem,
    Divider,
    InputLabel,
    Stack
} from '@mui/material';
import { DriveFileRenameOutline, FileDownload, ArrowDownward, ArrowUpward, Check, Clear, Close } from '@mui/icons-material'
import { getPatients, deletePatient, getGenders, getSources, setPatientsPage, setPatientsSize, setPatientsParams, sendSignerEvent, downloadGDPRDocument, getCounties, closeDocument } from '../../redux/patient/actions';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import Base58Table from "../../components/common/Base58Table";
import { UserRole, hasAnyRole, hasRole } from '../../utils/Security';
import GDPRmodal from './GDPRmodal';
import './rotate.css'
/*import { Zoom as ZoomSlide } from 'react-slideshow-image';*/
import logo from '../../assets/icon/logo_login.svg'
import moment from 'moment';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as ExaminationIcon } from '../../assets/icons/examination_icon.svg';
import { ReactComponent as SignatureIcon } from '../../assets/icons/signature_icon.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';

class Patients extends React.Component {

    emptyPatient = {
        id: null,
        firstName: '',
        lastName: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            patientToDelete: this.emptyPatient,
            openDeleteDialog: false,
            collapseIndex: -1,
            anchorEl: null,
            selectedPatient: null,
            selectedGDPR: false
        }
    }

    componentDidMount() {
        if (hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST])) {
            this.props.getPatients()
            this.props.getGenders()
            this.props.getSources()
            this.props.getCounties()
        }
        if (hasRole(UserRole.DOCTOR)) {
            this.props.getPatients()
        }
    }

    componentWillUnmount() {
        this.props.setPatientsSize(10, true)
        store.dispatch({
            type: types.RESET_PATIENT_DATA
        })
    }

    openPatient = (data) => {
        store.dispatch({
            type: types.SET_CURRENT_PATIENT,
            payload: data
        })
    }

    sendSignerEvent = (patientId) => {
        let params = {}
        params.patientId = patientId
        params.promo = this.state.selectedGDPR
        store.dispatch({
            type: SET_SIGNER_EVENT,
            payload: patientId
        })
        this.props.sendSignerEvent({ params: params })
    }

    handleClick = (e, patient) => {
        store.dispatch({
            type: POPOVER_OPEN,
            payload: true
        })
        this.setState({ anchorEl: e.currentTarget, selectedPatient: patient })
    };

    handleClose = () => {
        store.dispatch({
            type: POPOVER_OPEN,
            payload: false
        })
        this.setState({ selectedGDPR: false })
    }

    handleChangeGDPR = (event) => {
        const target = event.target;
        let { selectedGDPR } = this.state;
        selectedGDPR = target.value;
        this.setState({ selectedGDPR: selectedGDPR })
    }

    closeDocument = (patientId) => {
        let params = {}
        params.patientId = patientId
        this.props.closeDocument({ params: params })
    }

    render() {
        const { t } = this.props;
        const { collapseIndex, selectedPatient, selectedGDPR } = this.state;
        const { patientsLoading, patients, patientsParams, patientsTotalElements, popoverOpen } = this.props.patientReducer;

        let gdprModal = <>
            <Popover
                id={'simple-popover'}
                open={popoverOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <Box sx={{ p: 2, border: '2px solid #24A36C', borderRadius: '8px' }}>
                    {selectedPatient && selectedPatient.signedGDPR ? <>
                        <Typography variant="body2" gutterBottom>
                            <strong>{t("download_document")}</strong>:
                        </Typography>
                        <Fab className="table-fab" onClick={() => this.props.downloadGDPRDocument(selectedPatient && selectedPatient.id)} color="success"><FileDownload className="table-fab-icon" /> </Fab>
                    </> :
                        <>
                            <Typography variant="body2" gutterBottom>
                                <strong>{t("signing_document")}</strong>:
                            </Typography>
                            <FormControl>
                                <InputLabel>{t("GDPR")}</InputLabel>
                                <Select
                                    value={selectedGDPR}
                                    label={t("GDPR")}
                                    name="GDPR"
                                    onChange={this.handleChangeGDPR}>
                                    <MenuItem value={false}>{t("gdpr_without_promo")}</MenuItem>
                                    <MenuItem value={true}>{t("gdpr_with_promo")}</MenuItem>
                                </Select>
                            </FormControl>
                            <Button title={t("gdpr_modal_message")} className="table-fab" onClick={() => this.sendSignerEvent(selectedPatient && selectedPatient.id)} color="error"><DriveFileRenameOutline className="table-fab-icon" /> </Button>
                        </>}
                    <Divider sx={{ marginTop: '5px' }} />
                    <Typography variant="body2" gutterBottom>
                        <strong>{t("close_document")}</strong>:
                    </Typography>
                    <Fab className="table-fab" disabled={this.props.patientReducer.patientId === null} onClick={() => this.closeDocument(selectedPatient && selectedPatient.id)} color="primary"><Close className="table-fab-icon" /> </Fab>
                </Box>
            </Popover>
        </>

        let patientsList = patients && patients.map((patient, index) => {
            return <React.Fragment key={index}>
                <TableRow className={index === this.state.collapseIndex ? 'table-custom-body-background' : null}>
                    <TableCell>
                        <IconButton size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                            {index === collapseIndex ? <ArrowUpward /> : <ArrowDownward />}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        {patient.firstName && patient.firstName.length > 20 ? <Tooltip
                            disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.firstName} style={{ cursor: 'pointer' }}>
                            <span>{patient.firwhatstName && patient.firstName.length > 20 ? patient.firstName.substring(0, 20) + "..." : patient.firstName}</span>
                        </Tooltip> :
                            patient.firstName}
                    </TableCell>
                    <TableCell>{patient.lastName && patient.lastName.length > 20 ? <Tooltip
                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.lastName} style={{ cursor: 'pointer' }}>
                        <span>{patient.lastName && patient.lastName.length > 20 ? patient.lastName.substring(0, 20) + "..." : patient.lastName}</span>
                    </Tooltip> :
                        patient.lastName}</TableCell>
                    <TableCell>{patient.personalIdentificationNumber}</TableCell>
                    <TableCell>{moment(patient.dateOfBirth).format("DD.MM.YYYY.")}</TableCell>
                    <TableCell>{patient.phone}</TableCell>
                    <TableCell>{patient.email}</TableCell>
                    <TableCell>{t(patient.gender)}</TableCell>
                    {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <TableCell align='center'>
                        <IconButton
                            title="gdpr"
                            aria-label="gdpr"
                            onClick={(e) => this.handleClick(e, patient)}
                            sx={{
                                "& svg path": {
                                    stroke: patient.signedGDPR ? '#24A36C' : '#F53105'
                                }
                            }}
                        >
                            <SignatureIcon />
                        </IconButton>
                        {gdprModal}
                    </TableCell>}
                    <TableCell className='row-cell' align='center'>
                        <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/patients/${patient.id}`)}>
                                {this.props.t("edit")}
                            </Button>}
                            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM]) && <Button title={this.props.t("examination")} className="default-button-examination" endIcon={<ExaminationIcon />} onClick={() => this.props.history.push("/examination/patients/" + patient.id)}>
                                {this.props.t("examination")}
                            </Button>}
                            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ patientToDelete: patient, openDeleteDialog: true })}>
                                {this.props.t("delete")}
                            </Button>}
                        </Stack>
                    </TableCell>
                </TableRow>
                <TableRow className={index === this.state.collapseIndex ? 'table-custom-body-background' : null}>
                    <TableCell style={{ border: "none" }} colSpan={11}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" scope="row" width="22%" className={"base58-table-header-cell"}>{t("address")}</TableCell>
                                                <TableCell component="th" scope="row" width="21%" className={"base58-table-header-cell"}>{t("city")}</TableCell>
                                                <TableCell component="th" scope="row" width="21%" className={"base58-table-header-cell"}>{t("county")}</TableCell>
                                                <TableCell component="th" scope="row" width="21%" className={"base58-table-header-cell"}>{t("country")}</TableCell>
                                                <TableCell component="th" scope="row" width="15%" className={"base58-table-header-cell"}>{t("mbo")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{patient.address && patient.address.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.address} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.address && patient.address.length > 40 ? patient.address.substring(0, 40) + "..." : patient.address}</span>
                                                </Tooltip> :
                                                    patient.address}</TableCell>
                                                <TableCell>{patient.city && patient.city.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.city} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.city && patient.city.length > 40 ? patient.city.substring(0, 40) + "..." : patient.city}</span>
                                                </Tooltip> :
                                                    patient.city}</TableCell>
                                                <TableCell>{patient.county && patient.county.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.county} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.county && patient.county.length > 40 ? patient.county.substring(0, 40) + "..." : patient.county}</span>
                                                </Tooltip> :
                                                    patient.county}</TableCell>
                                                <TableCell>{patient.country && patient.country.length > 40 ? <Tooltip
                                                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={patient.country} style={{ cursor: 'pointer' }}>
                                                    <span>{patient.country && patient.country.length > 40 ? patient.country.substring(0, 40) + "..." : patient.country}</span>
                                                </Tooltip> :
                                                    patient.country}</TableCell>
                                                <TableCell>{patient.registrationNumber}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" scope="row" width="24%" className={"base58-table-header-cell"}>{t("source")}</TableCell>
                                                <TableCell component="th" scope="row" width="24%" className={"base58-table-header-cell"}>{t("recommended_by")}</TableCell>
                                                <TableCell component="th" scope="row" width="14%" align='center' className={"base58-table-header-cell"}>{t("send_sms")}</TableCell>
                                                <TableCell component="th" scope="row" width="14%" align='center' className={"base58-table-header-cell"}>{t("send_email")}</TableCell>
                                                <TableCell component="th" scope="row" width="24%" className={"base58-table-header-cell"}>{t("created_date")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{t(patient.source)}</TableCell>
                                                <TableCell>{patient.recommendedBy}</TableCell>
                                                <TableCell align='center'>{patient.sendSms ? <Check style={{ color: '#24A36C' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                                                <TableCell align='center'>{patient.sendEmail ? <Check style={{ color: '#24A36C' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                                                <TableCell>{moment(patient.createdDate).format("DD.MM.YYYY. HH:mm")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                width: '1%',
            },
            {
                name: "first_name",
                dbName: 'first_name',
                width: '12%',
                sort: true,
                filter: true
            },
            {
                name: "last_name",
                dbName: 'last_name',
                width: '12%',
                sort: true,
                filter: true
            },
            {
                name: "oib_short",
                width: '10%',
                dbName: 'personal_identification_number',
                filter: true
            },
            {
                name: "date_of_birth_short",
                dbName: 'date_of_birth',
                width: '8%',
                sort: true
            },
            {
                name: "phone",
                width: '11%',
                dbName: 'phone',
                filter: true
            },
            {
                name: "email",
                width: '14%',
                dbName: 'email',
                filter: true
            },
            {
                name: "gender",
                width: '7%'
            }/* ,
            {
                name: "created_date",
                dbName: "created_date",
                width: '10%',
                sort: true
            } */
        ]

        const gdpr = { name: "GDPR", width: '3%', dbName: 'signedGDPR', filter: true, type: 'boolean-select' };
        const actions = { name: "", width: hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) ? '22%' : '12%' }
        if (hasRole(UserRole.DOCTOR)) {
            columns.push(actions)
        } else {
            columns.push(gdpr);
            columns.push(actions)
        }

        /*const images = [
            image1,
            image2
        ];*/

        /*const zoomOutProperties = {
            duration: 5000,
            transitionDuration: 500,
            infinite: true,
            indicators: false,
            scale: 0.4,
            arrows: false
        };*/

        return (<>
            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST, UserRole.DOCTOR]) &&
                <Box className='custom-box'>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                {t("patients")}
                                {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) &&
                                    <Button
                                        className="create-btn"
                                        startIcon={<AddIcon />}
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => this.props.history.push("/new-patient")}>
                                        {this.props.t("new_patient")}
                                    </Button>}
                            </Typography>
                            <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Base58Table
                                isLoading={patientsLoading}
                                columns={columns}
                                data={patientsList}
                                count={patientsTotalElements}
                                rowsPerPage={patientsParams.size}
                                page={patientsParams.page}
                                onPageChange={(e, page) => { this.props.setPatientsPage(page); this.setState({ collapseIndex: -1 }); }}
                                onRowsPerPageChange={(e) => { this.props.setPatientsSize(e.target.value); this.setState({ collapseIndex: -1 }); }}
                                onFilterChange={(params) => { this.props.setPatientsParams(params); this.setState({ collapseIndex: -1 }); }}
                            />
                        </Grid>
                        <ConfirmationDialog
                            isOpen={this.state.openDeleteDialog}
                            title={t("delete_patient")}
                            message={<span dangerouslySetInnerHTML={{ __html: t("delete_patient_message", { firstName: this.state.patientToDelete.firstName, lastName: this.state.patientToDelete.lastName }) }} />}
                            onClose={() => this.setState({ openDeleteDialog: false })}
                            onConfirm={() => this.props.deletePatient(this.state.patientToDelete.id).then(() => {
                                this.setState({ openDeleteDialog: false }, () => this.props.getPatients(), this.props.setPatientsPage(0));
                            })}
                            closeButtonTitle={t("close")}
                            confirmButtonTitle={t("delete")}
                        />
                    </Grid>
                </Box>
            }
            {hasRole(UserRole.SIGNER) && <>
                <section id="slideshow">
                    <div className="entire-content">
                        <div className="content-carrousel">
                            <img src={logo} alt="" width={400} height={300}></img>
                        </div>
                    </div>
                </section>
                {/*<div>
                    <ZoomSlide {...zoomOutProperties} >
                        {
                            images.map((data, i) => {
                                return <img key={i} style={{ width: "110%" }} src={data} alt="" />
                            })
                        }
                    </ZoomSlide>
                </div>*/}
                <GDPRmodal />
            </>
            }
        </>

        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

const mapActionsToProps = { getPatients, deletePatient, getGenders, getSources, setPatientsPage, setPatientsSize, setPatientsParams, sendSignerEvent, downloadGDPRDocument, getCounties, closeDocument }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Patients))
