import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import {
    createServiceGroup,
    updateServiceGroup,
    getServiceGroupById
} from '../../redux/serviceGroup/actions';
import { getDoctors } from '../../redux/user/actions'
import { NAME_REGEX, COMMENT_REGEX } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

class ManageServiceGroup extends Component {

    emptyServiceGroup = {
        name: '',
        comment: '',
        userIds: []
    };

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: false,
            initialServiceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)),
            serviceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)),
            selectedDoctors: [],
            validation: {
                nameError: false,
                nameErrorText: "",
                commentError: false,
                commentErrorText: "",
            }
        };
    }

    componentDidMount() {
        this.props.getDoctors();
        if (this.props.match.params.id) {
            this.props.getServiceGroupById(this.props.match.params.id).then(response => {
                const selectedDoctors = this.props.userReducer.doctors.filter((el) => {
                    return response.data.userIds.some((f) => {
                        return f === el.id
                    });
                });
                this.setState({
                    serviceGroup: JSON.parse(JSON.stringify(response.data)),
                    initialServiceGroup: JSON.parse(JSON.stringify(response.data)),
                    selectedDoctors: selectedDoctors
                }, () => {
                    this.validate("name", this.state.serviceGroup.name);
                    this.validate("comment", this.state.serviceGroup.comment);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("name", this.state.serviceGroup.name);
            this.validate("comment", this.state.serviceGroup.comment);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { serviceGroup } = this.state;
        serviceGroup[target.name] = target.value.trimStart()
        this.setState({ serviceGroup: serviceGroup })
        this.validate(target.name, target.value);
    };

    handleChangeDoctors = (e, doctors) => {
        let { serviceGroup } = this.state
        let selectedDoctors = { ...this.state.selectedDoctors }
        selectedDoctors = doctors;
        serviceGroup.userIds = []
        selectedDoctors.forEach(data => {
            serviceGroup.userIds.push(data.id)
        })
        this.setState({ selectedDoctors: selectedDoctors, serviceGroup: serviceGroup });
    }

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "comment":
                if (value.length === 0) {
                    validation.commentError = true;
                    validation.commentErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !COMMENT_REGEX.test(value)) {
                    validation.commentError = true;
                    validation.commentErrorText = this.props.t("name_regex_message", { min: 1, max: 500 });
                } else {
                    validation.commentError = false;
                    validation.commentErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageServiceGroup = () => {
        const { serviceGroup } = this.state;
        let serviceGroupObject = {
            ...serviceGroup,
            name: serviceGroup.name && serviceGroup.name.trim(),
            comment: serviceGroup.comment && serviceGroup.comment.trim().toUpperCase()
        }
        const action = this.props.match.params.id ?
            this.props.updateServiceGroup(this.props.match.params.id, serviceGroupObject) :
            this.props.createServiceGroup(serviceGroupObject);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialServiceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup)),
                    serviceGroup: JSON.parse(JSON.stringify(this.emptyServiceGroup))
                }, () => this.props.history.push("/service-groups"));
            }
        })
    };

    disable() {
        let { validation, serviceGroup, initialServiceGroup } = this.state;
        if (!serviceGroup.name ||
            JSON.stringify(serviceGroup) === JSON.stringify(initialServiceGroup) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {

        let { mainLoading, initialServiceGroup, serviceGroup, selectedDoctors, validation } = this.state
        const { doctors } = this.props.userReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialServiceGroup) !== JSON.stringify(serviceGroup)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_service_group") : this.props.t("new_service_group")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push("/service-groups")}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageServiceGroup} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("service_group_name")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        autoFocus
                                                        id="name"
                                                        name="name"
                                                        value={serviceGroup.name}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("service_group_name").toLowerCase() })}
                                                        error={validation.nameError}
                                                        helperText={validation.nameErrorText}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("doctors")}
                                                    </InputLabel>
                                                    <FormControl>
                                                        <WrappedAutocomplete
                                                            id="selectedDoctors"
                                                            name="selectedDoctors"
                                                            multiple={true}
                                                            value={selectedDoctors}
                                                            placeholder={this.props.t("select")}
                                                            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.phone})`}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            options={doctors}
                                                            onChange={(event, value) => {
                                                                this.handleChangeDoctors(event, value)
                                                            }}
                                                            getOptionDisabled={(option) => serviceGroup.userIds.some((u) => u === option.id)}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("comment")}<span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        id="comment"
                                                        name="comment"
                                                        value={serviceGroup.comment}
                                                        placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                                                        multiline
                                                        rows={5}
                                                        onChange={this.handleChange}
                                                        error={validation.commentError}
                                                        helperText={validation.commentErrorText} />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    serviceGroupReducer: state.serviceGroupReducer,
    userReducer: state.userReducer
})

const mapActionsToProps = { createServiceGroup, updateServiceGroup, getServiceGroupById, getDoctors }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageServiceGroup)))