import axios from '../axiosConfig';

export function getNormatives(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/service_norms/${warehouseId}`, config);
}

export function createNormative(data) {
    return axios.post(`/v1/warehouse/service_norm`, data);
}

export function getNormativeById(warehouseId, id, config = {}) {
    return axios.get(`/v1/warehouse/service_norm/${warehouseId}/${id}`, config);
}

export function updateNormative(serviceId, data) {
    return axios.patch(`/v1/warehouse/service_norm/${serviceId}`, data);
}

export function deleteNormative(id) {
    return axios.delete(`/v1/warehouse/service_norm/${id}`);
}

const actions = {
    getNormatives,
    createNormative,
    getNormativeById,
    updateNormative,
    deleteNormative
}

export default actions;