import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { MuiColorInput } from 'mui-color-input'
import {
    createInfirmary,
    updateInfirmary,
    getInfirmaryById,
    getInfirmaryList
} from '../../redux/infrimary/actions';
import { getDoctors } from '../../redux/user/actions'
import { NAME_REGEX, COMMENT_REGEX } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';

class ManageInfirmary extends Component {

    emptyInfirmary = {
        name: '',
        currentOccupantId: null,
        comment: '',
        calendarHexColor: '#B2E68C'
    };

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            initialInfirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)),
            infirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)),
            selectedCurrentOccupant: null,
            validation: {
                nameError: false,
                nameErrorText: "",
                commentError: false,
                commentErrorText: "",
            }
        };
    }

    componentDidMount() {
        this.props.getDoctors();
        this.props.getInfirmaryList();
        if (this.props.match.params.id) {
            this.props.getInfirmaryById(this.props.match.params.id).then(response => {
                this.setState({
                    infirmary: JSON.parse(JSON.stringify(response.data)),
                    initialInfirmary: JSON.parse(JSON.stringify(response.data)),
                    selectedCurrentOccupant: this.props.userReducer.doctors.find(d => d.id === response.data.currentOccupantId)
                }, () => {
                    this.validate("name", this.state.infirmary.name);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("name", this.state.infirmary.name);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { infirmary } = this.state;
        infirmary[target.name] = target.value;
        this.setState({ infirmary: infirmary })
        this.validate(target.name, target.value);
    };

    handleChangeCurrentOccupant = (e, doctor) => {
        let { infirmary } = this.state;
        if (doctor !== null) {
            infirmary.currentOccupantId = doctor.id
            this.setState({ selectedCurrentOccupant: doctor, infirmary: infirmary })
        } else {
            infirmary.currentOccupantId = null
            this.setState({ selectedCurrentOccupant: null, infirmary: infirmary })
        }
    }

    handleColorChange = (value) => {
        let infirmary = { ...this.state.infirmary };
        infirmary['calendarHexColor'] = value;
        this.setState({ infirmary: infirmary });
    };

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "comment":
                if (value.length > 0 && !COMMENT_REGEX.test(value)) {
                    validation.commentError = true;
                    validation.commentErrorText = this.props.t("name_regex_message", { min: 1, max: 500 });
                } else {
                    validation.commentError = false;
                    validation.commentErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageInfirmary = () => {
        const { infirmary } = this.state;
        const action = this.props.match.params.id ? this.props.updateInfirmary(this.props.match.params.id, infirmary) : this.props.createInfirmary(infirmary);
        action.then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialInfirmary: JSON.parse(JSON.stringify(this.emptyInfirmary)),
                    infirmary: JSON.parse(JSON.stringify(this.emptyInfirmary))
                }, () => this.props.history.push("/infirmaries"));
            }
        })
    };

    disable() {
        let { validation, infirmary, initialInfirmary } = this.state;
        if (!infirmary.name ||
            JSON.stringify(infirmary) === JSON.stringify(initialInfirmary) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {

        let { mainLoading, initialInfirmary, infirmary, selectedCurrentOccupant, validation } = this.state
        const { doctors } = this.props.userReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialInfirmary) !== JSON.stringify(infirmary)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_infirmary") : this.props.t("new_infirmary")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push("/infirmaries")}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageInfirmary} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("infirmary_name")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        autoFocus
                                                        id="name"
                                                        name="name"
                                                        value={infirmary.name}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("infirmary_name").toLowerCase() })}
                                                        error={validation.nameError}
                                                        helperText={validation.nameErrorText}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("current_occupant")}
                                                    </InputLabel>
                                                    <FormControl>
                                                        <WrappedAutocomplete
                                                            id="selectedCurrentOccupant"
                                                            name="selectedCurrentOccupant"
                                                            value={selectedCurrentOccupant}
                                                            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.phone})`}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            options={doctors}
                                                            placeholder={this.props.t("select")}
                                                            getOptionDisabled={(option) => this.props.infirmaryReducer.infirmaryList.some(i => i.currentOccupant && i.currentOccupant.id === option.id)}
                                                            onChange={(event, doctor) => {
                                                                this.handleChangeCurrentOccupant(event, doctor)
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("comment")}
                                                    </InputLabel>
                                                    <TextField
                                                        id="comment"
                                                        name="comment"
                                                        value={infirmary.comment}
                                                        placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                                                        multiline
                                                        rows={5}
                                                        onChange={this.handleChange}
                                                        error={validation.commentError}
                                                        helperText={validation.commentErrorText} />
                                                </Grid>
                                                <Grid item container>
                                                    <Stack direction='row' spacing={2} sx={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}>
                                                        <Typography variant="subtitle1" width={250} >{this.props.t("background_color")}</Typography>
                                                        <MuiColorInput
                                                            sx={{
                                                                '&.MuiColorInput-Button': {
                                                                    border: 'none'
                                                                }
                                                            }}
                                                            isAlphaHidden
                                                            format="hex"
                                                            value={infirmary.calendarHexColor}
                                                            onChange={this.handleColorChange}
                                                        />
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    infirmaryReducer: state.infirmaryReducer,
    userReducer: state.userReducer
})

const mapActionsToProps = {
    createInfirmary,
    updateInfirmary,
    getInfirmaryById,
    getDoctors,
    getInfirmaryList
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageInfirmary)))
