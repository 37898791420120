import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    Box,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Stack,
    Typography,
    FormHelperText
} from '@mui/material';
import {
    createPatient,
    updatePatient,
    getPatientById,
    getGenders, getSources, getCounties
} from '../../redux/patient/actions';
import { withTranslation } from 'react-i18next';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import { UserRole, hasAnyRole } from '../../utils/Security';
import { EMAIL_REGEX, FIRST_NAME_REGEX, LAST_NAME_REGEX, MBO_REGEX, NAME_REGEX, isOibValid, isPhoneValid } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { List } from "react-virtualized";
import cities from './cities.json'
import { MuiPhone } from '../../components/common/MuiPhone';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

class ManagePatient extends Component {

    emptyPatient = {
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: null,
        phone: '+385',
        personalIdentificationNumber: '',
        registrationNumber: null,
        gender: null,
        address: null,
        country: 'Hrvatska',
        county: null,
        city: null,
        source: null,
        recommendedBy: null,
        sendSms: true,
        sendEmail: true
    };

    constructor(props) {
        super(props);
        this.state = {
            patientId: this.props.match.params.id || this.props.patientId,
            initialPatient: JSON.parse(JSON.stringify(this.emptyPatient)),
            patient: JSON.parse(JSON.stringify(this.emptyPatient)),
            selectedDateOfBirth: null,
            selectedCounty: null,
            selectedCity: null,
            gdprModel: false,
            validation: {
                firstNameError: false,
                firstNameErrorText: "",
                lastNameError: false,
                lastNameErrorText: "",
                dateOfBirthError: false,
                dateOfBirthErrorText: "",
                genderError: false,
                genderErrorText: "",
                personalIdentificationNumberError: false,
                personalIdentificationNumberErrorText: "",
                registrationNumberError: false,
                registrationNumberErrorText: "",
                emailError: false,
                emailErrorText: "",
                phoneError: false,
                phoneErrorText: "",
                countryError: false,
                countryErrorText: "",
                cityError: false,
                cityErrorText: "",
                addressError: false,
                addressErrorText: "",
                recommendedByError: false,
                recommendedByErrorText: ""
            },
            promptDialogOpen: false,
        };
    }

    componentDidMount() {
        this.props.getGenders();
        this.props.getSources();
        this.props.getCounties();

        if (this.state.patientId) {
            hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getPatientById(this.state.patientId).then(response => {
                this.setState({
                    patient: JSON.parse(JSON.stringify(response.data)),
                    initialPatient: JSON.parse(JSON.stringify(response.data)),
                    selectedCounty: response.data.county,
                    selectedCity: (response.data.country === "Hrvatska" || response.data.country === "HRVATSKA") && response.data.city ? { name: response.data.city, zipCode: cities.find(c => c.name.toUpperCase() === response.data.city.toUpperCase()) && cities.find(c => c.name.toUpperCase() === response.data.city.toUpperCase()).zipCode } : null,
                    selectedDateOfBirth: response.data.dateOfBirth
                }, () => {
                    this.validate("firstName", this.state.patient.firstName);
                    this.validate("lastName", this.state.patient.lastName);
                    this.validate("dateOfBirth", moment(this.state.patient.dateOfBirth));
                    this.validate("gender", this.state.patient.gender);
                    this.validate("personalIdentificationNumber", this.state.patient.personalIdentificationNumber);
                    this.validate("phone", this.state.patient.phone);
                    this.validate("registrationNumber", this.state.patient.registrationNumber);
                });
            })
        } else {
            this.validate("firstName", this.state.patient.firstName);
            this.validate("lastName", this.state.patient.lastName);
            this.validate("dateOfBirth", this.state.patient.dateOfBirth);
            this.validate("gender", this.state.patient.gender);
            this.validate("personalIdentificationNumber", this.state.patient.personalIdentificationNumber);
            this.validate("phone", this.state.patient.phone);
            this.validate("registrationNumber", this.state.patient.registrationNumber);
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { patient } = this.state;
        if (target.name === "country" && (target.value === "Hrvatska" || patient.country === "HRVATSKA")) {
            patient.city = null
            patient.county = null
            patient.address = null
            this.setState({ selectedCounty: null, selectedCity: null })
        }
        patient[target.name] = target.value = 0 || target.value === " " || target.value === "" ? null : target.value ? target.value.trimStart() : null;
        if (target.type === 'checkbox') {
            patient[target.name] = target.checked
        }
        this.setState({ patient: patient })
        this.validate(target.name, target.value);
    };

    handleChangeDate = (date) => {
        let { patient } = this.state
        patient.dateOfBirth = date;
        this.setState({ patient: patient, selectedDateOfBirth: date })
        this.validate("dateOfBirth", date);
    }

    handleChangeCounty = (e, county) => {
        let { patient } = this.state
        if (county !== null) {
            patient.county = county
            this.setState({ selectedCounty: county, patient: patient })
        } else {
            patient.county = null
            this.setState({ selectedCounty: null, patient: patient })
        }
    }

    handleChangeCity = (e, city) => {
        let { patient, selectedCounty } = this.state
        if (city !== null) {
            patient.city = city.name
            patient.county = city.county
            selectedCounty = city.county
            this.setState({ selectedCity: city, patient: patient, selectedCounty: selectedCounty })
        } else {
            patient.city = null
            patient.county = null
            selectedCounty = null
            this.setState({ selectedCity: null, patient: patient, selectedCounty: selectedCounty })
        }
        this.validate("city", city);
    }

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "firstName":
                if (value.length === 0) {
                    validation.firstNameError = true;
                    validation.firstNameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !FIRST_NAME_REGEX.test(value)) {
                    validation.firstNameError = true;
                    validation.firstNameErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.firstNameError = false;
                    validation.firstNameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "lastName":
                if (value.length === 0) {
                    validation.lastNameError = true;
                    validation.lastNameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !LAST_NAME_REGEX.test(value)) {
                    validation.lastNameError = true;
                    validation.lastNameErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.lastNameError = false;
                    validation.lastNameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "dateOfBirth":
                if (!value) {
                    validation.dateOfBirthError = true;
                    validation.dateOfBirthErrorText = this.props.t("required_field_message");
                } else if (!value._isValid || moment(value).isAfter(new Date()) || moment(value).isBefore('1900-01-01', 'year')) {
                    validation.dateOfBirthError = true;
                    validation.dateOfBirthErrorText = this.props.t("invalid_date");
                } else {
                    validation.dateOfBirthError = false;
                    validation.dateOfBirthErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "gender":
                if (!value) {
                    validation.genderError = true;
                    validation.genderErrorText = this.props.t("required_field_message");
                } else {
                    validation.genderError = false;
                    validation.genderErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "personalIdentificationNumber":
                if (!value) {
                    validation.personalIdentificationNumberError = true;
                    validation.personalIdentificationNumberErrorText = this.props.t("required_field_message");
                } else if (!isOibValid(value)) {
                    validation.personalIdentificationNumberError = true;
                    validation.personalIdentificationNumberErrorText = this.props.t("oib_regex_message");
                } else {
                    validation.personalIdentificationNumberError = false;
                    validation.personalIdentificationNumberErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "registrationNumber":
                if (!value) {
                    validation.registrationNumberError = true;
                    validation.registrationNumberErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !MBO_REGEX.test(value)) {
                    validation.registrationNumberError = true;
                    validation.registrationNumberErrorText = this.props.t("mbo_regex_message");
                } else {
                    validation.registrationNumberError = false;
                    validation.registrationNumberErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "email":
                if (value.length > 0 && !EMAIL_REGEX.test(value)) {
                    validation.emailError = true;
                    validation.emailErrorText = this.props.t("email_regex_message");
                } else {
                    validation.emailError = false;
                    validation.emailErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "phone":
                if (!value || (value && value.length < 5)) {
                    validation.phoneError = true;
                    validation.phoneErrorText = this.props.t("required_field_message");
                } else if (!isPhoneValid(value)) {
                    validation.phoneError = true;
                    validation.phoneErrorText = this.props.t("phone_regex_message");
                } else {
                    validation.phoneError = false;
                    validation.phoneErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "country":
                if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.countryError = true;
                    validation.countryErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validation.countryError = false;
                    validation.countryErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "city":
                if (value && typeof (value) === "object" && !NAME_REGEX.test(value.name)) {
                    validation.cityError = true;
                    validation.cityErrorText = this.props.t("number_regex_message", { max: 255 });
                } else if (value && value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.cityError = true;
                    validation.cityErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validation.cityError = false;
                    validation.cityErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "address":
                if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.addressError = true;
                    validation.addressErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validation.addressError = false;
                    validation.addressErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "recommendedBy":
                if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.recommendedByError = true;
                    validation.recommendedByErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validation.recommendedByError = false;
                    validation.recommendedByErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    managePatient = () => {
        const { patient } = this.state;
        let dataPatient = {
            ...patient,
            firstName: patient.firstName && patient.firstName.trim(),
            lastName: patient.lastName && patient.lastName.trim(),
            dateOfBirth: moment(patient.dateOfBirth).format("yyyy-MM-DD"),
            address: patient.address && patient.address.length > 0 && patient.address.trim(),
            city: patient.city && patient.city.length > 0 && patient.city.trim(),
            country: patient.country && patient.country.length > 0 && patient.country.trim(),
            recommendedBy: patient.recommendedBy && patient.recommendedBy.length > 0 && patient.recommendedBy.trim(),
            phone: patient.phone
        }
        const action = this.state.patientId ? this.props.updatePatient(this.state.patientId, dataPatient) : this.props.createPatient(dataPatient);
        action.then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialPatient: JSON.parse(JSON.stringify(this.emptyPatient)),
                    patient: JSON.parse(JSON.stringify(this.emptyPatient))
                }, () => this.props.location.pathname === "/calendar" ? this.props.onSave(this.props.patientId ? response.data.id : response.data) : this.props.history.push("/patients"));
            }
        })
    };

    closeDialog = () => {
        this.props.closeDialog()
        this.setState({
            patient: JSON.parse(JSON.stringify(this.emptyPatient))
        });
    }

    disable() {
        let { validation, patient, initialPatient } = this.state;

        if (!patient.firstName ||
            !patient.lastName ||
            !patient.dateOfBirth ||
            !patient.gender ||
            !patient.personalIdentificationNumber ||
            !patient.phone ||
            JSON.stringify(patient) === JSON.stringify(initialPatient) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }

        return false;
    }

    render() {

        let { initialPatient, patient, selectedDateOfBirth, selectedCounty, selectedCity, validation, promptDialogOpen } = this.state
        const { genders, sources, counties } = this.props.patientReducer;

        const ListboxComponent = React.forwardRef(function ListboxComponent(
            props,
            ref
        ) {
            const { children, role, ...other } = props;
            const itemCount = Array.isArray(children) ? children.length : 0;
            const itemSize = 45;

            return (
                <div ref={ref}>
                    <div {...other}>
                        <List
                            height={400}
                            width={555}
                            rowHeight={itemSize}
                            overscanCount={5}
                            rowCount={itemCount}
                            rowRenderer={props => {
                                return React.cloneElement(children[props.index], {
                                    style: props.style
                                });
                            }}
                            role={role}
                        />
                    </div>
                </div>
            );
        });

        return (<Box className="custom-box">
            <Prompt when={JSON.stringify(initialPatient) !== JSON.stringify(patient)} message={this.props.t("prompt_message")} />
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                            {this.state.patientId ? this.props.t("edit_patient") : this.props.t("new_patient")}
                        </Typography>
                        <Button className="cancel-btn" onClick={() => this.props.location.pathname === "/calendar" ?
                            (JSON.stringify(initialPatient) !== JSON.stringify(patient) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()) :
                            this.props.history.push("/patients")}>
                            {this.props.t("cancel")}
                        </Button>
                        <Button className="create-btn" onClick={this.managePatient} disabled={this.disable()}>
                            {this.state.patientId ? this.props.t("update") : this.props.t("create")}
                        </Button>
                    </Stack>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", color: "#0F0F0F", marginBottom: "4px" }}>
                                            {this.props.t("basic_information")}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel className="form-label">
                                            {this.props.t("first_name")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            autoFocus
                                            id="firstName"
                                            name="firstName"
                                            value={(patient.firstName && patient.firstName.charAt(0).toUpperCase() + patient.firstName.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            placeholder={this.props.t("enter", { text: this.props.t("first_name").toLowerCase() })}
                                            error={validation.firstNameError}
                                            helperText={validation.firstNameErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel className="form-label">
                                            {this.props.t("last_name")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            value={(patient.lastName && patient.lastName.charAt(0).toUpperCase() + patient.lastName.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            placeholder={this.props.t("enter", { text: this.props.t("last_name").toLowerCase() })}
                                            error={validation.lastNameError}
                                            helperText={validation.lastNameErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel className="form-label">
                                            {this.props.t("date_of_birth")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <WrappedDatePicker
                                            disableFuture
                                            id="dateOfBirth"
                                            name="dateOfBirth"
                                            value={selectedDateOfBirth}
                                            onChange={this.handleChangeDate}
                                            error={validation.dateOfBirthError}
                                            helperText={validation.dateOfBirthErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel className="form-label">
                                            {this.props.t("gender")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <FormControl error={validation.genderError}>
                                            <Select
                                                displayEmpty
                                                labelId="select-label"
                                                id="gender"
                                                name="gender"
                                                value={patient.gender || ''}
                                                onChange={this.handleChange}
                                                renderValue={(selected) => {
                                                    return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                                }}>
                                                {genders.map(gender => { return <MenuItem key={gender} value={gender}>{this.props.t(gender)}</MenuItem> })}
                                            </Select>
                                            <FormHelperText>{validation.genderErrorText}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel className="form-label">
                                            {this.props.t("OIB")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <TextField
                                            type="tel"
                                            id="personalIdentificationNumber"
                                            name="personalIdentificationNumber"
                                            onChange={this.handleChange}
                                            value={patient.personalIdentificationNumber || ''}
                                            inputProps={{ maxLength: 11 }}
                                            placeholder={this.props.t("enter", { text: this.props.t("OIB").toLowerCase() })}
                                            error={validation.personalIdentificationNumberError}
                                            helperText={validation.personalIdentificationNumberErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <InputLabel className="form-label">
                                            {this.props.t("MBO")}
                                        </InputLabel>
                                        <TextField
                                            type="tel"
                                            id="registrationNumber"
                                            name="registrationNumber"
                                            onChange={this.handleChange}
                                            value={patient.registrationNumber || ''}
                                            inputProps={{ maxLength: 9 }}
                                            placeholder={this.props.t("enter", { text: this.props.t("MBO").toLowerCase() })}
                                            error={validation.registrationNumberError}
                                            helperText={validation.registrationNumberErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("email_address")}
                                        </InputLabel>
                                        <TextField
                                            id="email"
                                            name="email"
                                            value={patient.email || ''}
                                            onChange={this.handleChange}
                                            placeholder={this.props.t("enter", { text: this.props.t("email_address").toLowerCase() })}
                                            error={validation.emailError}
                                            helperText={validation.emailErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("phone")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <MuiPhone
                                            value={patient.phone || ''}
                                            onChange={(phone) => this.setState({ patient: { ...this.state.patient, phone: phone } }, () => this.validate("phone", phone))}
                                            placeholder={this.props.t("enter", { text: this.props.t("phone_number").toLowerCase() })}
                                            helperText={validation.phoneErrorText}
                                            error={validation.phoneError}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "18px", color: "#0F0F0F", marginBottom: "4px" }}>
                                            {this.props.t("additional_information")}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("country")}
                                        </InputLabel>
                                        <TextField
                                            id="country"
                                            name="country"
                                            value={(patient.country && patient.country.charAt(0).toUpperCase() + patient.country.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            placeholder={this.props.t("enter", { text: this.props.t("country").toLowerCase() })}
                                            error={validation.countryError}
                                            helperText={validation.countryErrorText}
                                        />
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {(patient.country === "Hrvatska" || patient.country === "HRVATSKA") ?
                                            <>
                                                <InputLabel className="form-label">
                                                    {this.props.t("city")}
                                                </InputLabel>
                                                <FormControl>
                                                    <Autocomplete
                                                        id="selectedCity"
                                                        name="selectedCity"
                                                        disableListWrap
                                                        value={selectedCity}
                                                        options={selectedCounty !== null ? cities.filter(c => selectedCounty === c.county) : cities}
                                                        ListboxComponent={ListboxComponent}
                                                        getOptionLabel={(option) => option ? option.name + " (" + option.zipCode + ")" : ""}
                                                        isOptionEqualToValue={(option, value) => value && option.name === value.name}
                                                        onChange={(event, city) => {
                                                            this.handleChangeCity(event, city)
                                                        }}
                                                        clearText={this.props.t("clear")}
                                                        closeText={this.props.t("close")}
                                                        openText={this.props.t("open")}
                                                        renderInput={(params) => <TextField {...params} placeholder={this.props.t("select")} />
                                                        }
                                                    />
                                                </FormControl>
                                            </>
                                            :
                                            <>
                                                <InputLabel className="form-label">
                                                    {this.props.t("city")}
                                                </InputLabel>
                                                <TextField
                                                    id="city"
                                                    name="city"
                                                    value={patient.city || ''}
                                                    onChange={this.handleChange}
                                                    placeholder={this.props.t("enter", { text: this.props.t("city").toLowerCase() })}
                                                    error={validation.cityError}
                                                    helperText={validation.cityErrorText}
                                                />
                                            </>
                                        }
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("address")}
                                        </InputLabel>
                                        <TextField
                                            id="address"
                                            name="address"
                                            value={(patient.address && patient.address.charAt(0).toUpperCase() + patient.address.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            placeholder={this.props.t("enter", { text: this.props.t("home_address").toLowerCase() })}
                                            error={validation.addressError}
                                            helperText={validation.addressErrorText}
                                        />
                                    </Grid>
                                    {(patient.country === "Hrvatska" || patient.country === "HRVATSKA") && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("county")}
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedAutocomplete
                                                id="selectedCounty"
                                                name="selectedCounty"
                                                value={selectedCounty}
                                                options={counties}
                                                onChange={(event, county) => {
                                                    this.handleChangeCounty(event, county)
                                                }}
                                                placeholder={this.props.t("select")}
                                            />
                                        </FormControl>
                                    </Grid>}
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("source")}
                                        </InputLabel>
                                        <FormControl>
                                            <Select
                                                displayEmpty
                                                value={patient.source || ''}
                                                id="source"
                                                name="source"
                                                onChange={this.handleChange}
                                                renderValue={(selected) => {
                                                    return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                                }}>
                                                {sources.map(data => { return <MenuItem key={data} value={data}>{this.props.t(data)}</MenuItem> })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {patient.source === "IN_PERSON" && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("recommended_by")}
                                        </InputLabel>
                                        <TextField
                                            id="recommendedBy"
                                            name="recommendedBy"
                                            value={(patient.recommendedBy && patient.recommendedBy.charAt(0).toUpperCase() + patient.recommendedBy.slice(1)) || ''}
                                            onChange={this.handleChange}
                                            placeholder={this.props.t("enter", { text: this.props.t("recommended_by").toLowerCase() })}
                                            error={validation.recommendedByError}
                                            helperText={validation.recommendedByErrorText}
                                        />
                                    </Grid>}
                                    <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormControl>
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    style={{ marginLeft: '0px' }}
                                                    id="sendSms"
                                                    name="sendSms"
                                                    control={<Checkbox
                                                        onChange={this.handleChange}
                                                        checked={patient.sendSms || false} />
                                                    }
                                                    label={this.props.t("send_sms")}
                                                    labelPlacement="start"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    {this.state.patientId && <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <FormControl>
                                            <FormGroup aria-label="position" row>
                                                <FormControlLabel
                                                    style={{ marginLeft: '0px' }}
                                                    id="sendEmail"
                                                    name="sendEmail"
                                                    control={<Checkbox
                                                        onChange={this.handleChange}
                                                        checked={patient.sendEmail || false} />
                                                    }
                                                    label={this.props.t("send_email")}
                                                    labelPlacement="start"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>}
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmationDialog
                isOpen={promptDialogOpen}
                title={this.props.t("prompt_title")}
                message={this.props.t("prompt_message_2")}
                onClose={() => this.setState({ promptDialogOpen: false })}
                onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.props.closeDialog())}
                closeButtonTitle={this.props.t("continue")}
                confirmButtonTitle={this.props.t("discard")}
            />
        </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer
})

const mapActionsToProps = { createPatient, updatePatient, getPatientById, getGenders, getSources, getCounties }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManagePatient)))
