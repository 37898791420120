import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { withRouter } from "react-router-dom";
import {
    DialogContent,
    Button,
    Grid,
    Box,
    Stack,
    Typography
} from '@mui/material';
import {
    getListOfItems,
    getSuppliersByWarehouseId,
    getItemDocumentItemsStateById
} from "../../../redux/warehouse/actions";
import { BootstrapDialog, Transition } from '../../../components/common/Dialog';
import WrappedAutocomplete from '../../../components/common/WrappedAutocomplete';
import DocumentItems from './DocumentItems';
import Enum from "./DocumentEnums";

class AddItemDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectedItem: null,
            selectedItems: []
        };
    }

    componentDidMount() {
        this.props.getListOfItems(this.props.match.params.warehouseId);
    }

    handleChangeItem = (name, item) => {
        if (item) {
            this.props.getItemDocumentItemsStateById(item.id).then(response => {
                this.setState({ selectedItem: item, items: response.data });
            });
        } else {
            this.setState({ selectedItem: item, items: [] });
        }
    }

    render() {

        let { selectedItem, items, selectedItems } = this.state
        const { itemList } = this.props.warehouseReducer;

        return (
            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isAddItemDialogOpen}
                TransitionComponent={Transition}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "calc(100vw - 500px)"
                        },
                    },
                }}
            /* maxWidth={"xl"} */
            >
                <DialogContent sx={{ padding: "0px !important" }}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.t("add_item")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => { this.setState({ selectedItem: null }); this.props.onClose() }}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button disabled={selectedItems.length === 0} className="create-btn" onClick={() => { this.props.addSelectedItems(selectedItems); this.setState({ selectedItem: null }) }}>
                                        {this.props.t("add")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <WrappedAutocomplete
                                    id="selectedItem"
                                    name="selectedItem"
                                    value={selectedItem}
                                    getOptionLabel={(option) => `${option.name}`}
                                    options={itemList}
                                    onChange={(name, value) => this.handleChangeItem(name, value)}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.name}
                                        </Box>
                                    )}
                                    placeholder={this.props.t("select")}
                                />
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <DocumentItems
                                        isAddItemDialogOpen={this.props.isAddItemDialogOpen}
                                        documentType={Enum.DocumentTypes.MANUAL}
                                        type={Enum.Types.OUT}
                                        data={items}
                                        onChange={(items) => this.setState({ items: items })}
                                        onValidationChange={(validation) => this.setState({ documentItemsValidation: validation })}
                                        onItemsSelection={(items) => this.setState({ selectedItems: items })}
                                        selectedItems={this.props.selectedItems}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

const mapActionsToProps = { getListOfItems, getSuppliersByWarehouseId, getItemDocumentItemsStateById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(AddItemDialog)))
