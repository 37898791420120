import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { getBalance, getHistory, setHistoryPage, setHistorySize, setHistoryParams, getNotificationType } from '../../../redux/setting/actions'
import { syncCalendarEntry } from '../../../redux/calendar/actions';
import moment from 'moment';
import { Sync } from '@mui/icons-material'
import { UserRole, hasRole } from '../../../utils/Security';
import HistoryMessagesTab from '../sms/HistoryMessagesTab';
import CustomMessageTab from '../sms/CustomMessageTab';
import ConfigurationMessageTab from '../sms/ConfigurationMessageTab';
import types from '../../../redux/setting/types'
import store from '../../../redux/store';
import { CustomTabPanel, a11yProps } from '../../../components/common/tabs';
import { ReactComponent as MessageConfigurationActiveIcon } from '../../../assets/icons/settings/message_configuration_active.svg';
import { ReactComponent as MessageConfigurationInactiveIcon } from '../../../assets/icons/settings/message_configuration_inactive.svg';
import { ReactComponent as HistoryActiveIcon } from '../../../assets/icons/settings/history_active.svg';
import { ReactComponent as HistoryInactiveIcon } from '../../../assets/icons/settings/history_inactive.svg';
import { ReactComponent as CustomMessageActiveIcon } from '../../../assets/icons/settings/custom_message_active.svg';
import { ReactComponent as CustomMessageInactiveIcon } from '../../../assets/icons/settings/custom_message_inactive.svg';

class SmsProvider extends Component {

    emptyData = {
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            selectedStartDate: null,
            selectedEndDate: null,
            data: this.emptyData,
            displayData: false,
            selectedTab: 0
        };
    }

    componentDidMount() {
        Promise.all([
            this.props.getNotificationType(),
            this.props.getBalance()])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    syncCalendarEntry = () => {
        let params = {}
        params.startTime = moment(new Date())
        this.props.syncCalendarEntry(params)
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.SET_HISTORY,
            payload: []
        });
        store.dispatch({
            type: types.SET_HISTORY_PARAMS,
            payload: { page: 0, size: 10 }
        });
    }

    handleChangeTab = (event, value) => {
        this.props.calendarReducer.calendarRecords = []
        this.setState({ selectedTab: value, selectedEndDate: null, selectedStartDate: null, data: this.emptyData, displayData: false });
    }

    render() {
        const { mainLoading, selectedTab } = this.state
        const { balance } = this.props.settingReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<Grid item container direction="row" spacing={2}>
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className='custom-box'>
                    <Typography sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "27.5px", alignContent: "center", textAlign: "center" }}>
                        {`${this.props.t("balance")}: `} <span style={{ color: "#24A36C" }}>{`${balance} $`}</span>
                    </Typography>
                </Box>
            </Grid>
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className='custom-box'>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ borderBottom: 1, border: 'none' }}>
                                <Tabs value={selectedTab} onChange={this.handleChangeTab} TabIndicatorProps={{
                                    sx: { display: 'none' }
                                }}>
                                    <Tab icon={selectedTab === 0 ? <MessageConfigurationInactiveIcon /> : <MessageConfigurationActiveIcon />} iconPosition="start" label={this.props.t("configuration_message")} {...a11yProps(0)} wrapped />
                                    <Tab icon={selectedTab === 1 ? <HistoryInactiveIcon /> : <HistoryActiveIcon />} iconPosition="start" label={this.props.t("history")} {...a11yProps(1)} wrapped />
                                    <Tab icon={selectedTab === 2 ? <CustomMessageInactiveIcon /> : <CustomMessageActiveIcon />} iconPosition="start" label={this.props.t("custom_message")} {...a11yProps(1)} wrapped />
                                </Tabs>
                                {hasRole(UserRole.SUPERADMIN) &&
                                    <Button sx={{ float: "right" }} startIcon={<Sync />} title={this.props.t("sync_data")} className="create-btn" onClick={() => this.syncCalendarEntry()}>
                                        {this.props.t("sync_calendar_entry")}
                                    </Button>}
                            </Box>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <CustomTabPanel value={selectedTab} index={0} sx={{ width: "100%" }}>
                                <ConfigurationMessageTab />
                            </CustomTabPanel>
                            <CustomTabPanel value={selectedTab} index={1} sx={{ width: "100%" }}>
                                <HistoryMessagesTab />
                            </CustomTabPanel>
                            <CustomTabPanel value={selectedTab} index={2} sx={{ width: "100%" }}>
                                <CustomMessageTab />
                            </CustomTabPanel>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    calendarReducer: state.calendarReducer
})

const mapActionsToProps = { getBalance, getHistory, setHistoryPage, setHistorySize, setHistoryParams, syncCalendarEntry, getNotificationType }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(SmsProvider))
