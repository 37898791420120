import React from 'react';
import { Close } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton, Slide } from "@mui/material";
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({}));
export const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 15,
                    top: 15,
                    color: '#fff',
                }}>
                <Close /></IconButton> : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const actions = {
    BootstrapDialog,
    BootstrapDialogTitle,
    Transition
}

export default actions;
