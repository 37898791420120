import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Grid, DialogContent, Fab, Stack } from '@mui/material';
import { AddPhotoAlternate, Clear } from '@mui/icons-material'
import { importDoctorSignature, getDoctorSignature, deleteDoctorSignature } from '../../redux/user/actions'
import { BootstrapDialog, BootstrapDialogTitle, Transition } from '../../components/common/Dialog';

class UploadSignatureModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            imagePreviewUrl: '',
            file: null,
            exists: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
            if (this.props.userId && prevProps.userId !== this.props.userId) {
                this.props.getDoctorSignature(this.props.userId.toString()).then(response => {
                    if (response) {
                        this.setState({
                            imagePreviewUrl: "data:image/png;base64," + response.data,
                            exists: true
                        });
                    }
                })
            }
        }
    }

    handleImageChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (!file) {
            return;
        }
        reader.onloadend = () => {

            let image = { ...this.state.image };
            image = reader.result;

            this.setState({
                imagePreviewUrl: reader.result,
                image: image,
                file: file,
                exists: false
            });
        }
        reader.readAsDataURL(file)
    }

    handleChangeFile = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        reader.onloadend = () => {
            let image = { ...this.state.image };
            image = reader.result;

            this.setState({
                imagePreviewUrl: reader.result,
                image: image
            });
        }
        reader.readAsDataURL(file)

        this.setState({ file: file, exists: false })
    }

    clearLogo = () => {
        this.setState({
            file: null,
            imagePreviewUrl: "",
            image: null,
            initialFile: null,
            exists: false
        });
    }

    uploadImage = () => {
        const formData = new FormData();
        formData.append("file", this.state.file)
        formData.append("description", "doctor_signature")
        this.props.importDoctorSignature(this.props.userId, formData).then((response) => {
            if (response && response.status === 200) {
                this.clearLogo();
                this.props.onClose();
            }
        })
    }

    deleteSignature = () => {
        this.props.deleteDoctorSignature(this.props.userId).then((response) => {
            if (response && response.status === 200) {
                this.clearLogo();
                this.props.onClose();
            }
        })
    }

    render() {
        let { imagePreviewUrl, file, exists } = this.state

        return (
            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={() => { this.props.onClose(); this.clearLogo(); }}>
                    {this.props.t("signature")}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4} style={{ paddingTop: imagePreviewUrl ? '0px' : '16px' }}>
                        {imagePreviewUrl && <Clear style={{ position: 'relative', marginLeft: 'auto', top: '20px', cursor: 'pointer' }} onClick={this.clearLogo} />}
                        <input
                            style={{ display: 'none' }}
                            accept="image/png, image/jpeg"
                            id="contained-button-file"
                            type="file"
                            onChange={this.handleImageChange}
                        />
                        <label htmlFor="contained-button-file">
                            {imagePreviewUrl ?
                                <img
                                    style={{ width: "350px", height: "auto", cursor: 'pointer' }}
                                    src={imagePreviewUrl} alt="logo" />
                                :
                                <Fab
                                    style={{ width: "110px", height: "50px" }}
                                    component="span"
                                    color="primary">
                                    <AddPhotoAlternate style={{ fontSize: 30 }} />
                                </Fab>
                            }
                        </label>
                    </Grid>
                    <Grid item container direction="column" xs={6} sm={6} md={5} lg={4} xl={3}>
                        <Stack direction='row' spacing={2}>
                            {file && <Fab style={{ width: '131px', height: '40px' }} color="primary" className="default-button" disabled={!file} onClick={() => this.uploadImage()}>
                                {this.props.t("save")}
                            </Fab>}
                            {exists && <Fab style={{ width: '131px', height: '40px' }} color="error" className="default-button" onClick={() => this.deleteSignature()}>
                                {this.props.t("delete")}
                            </Fab>}
                        </Stack>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    diagnosisReducer: state.diagnosisReducer
})

const mapActionsToProps = { importDoctorSignature, getDoctorSignature, deleteDoctorSignature }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(UploadSignatureModal))