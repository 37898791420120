import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    FormControl,
    Grid,
    Typography,
    Button,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Table,
    InputLabel,
    Box,
    Stack
} from '@mui/material';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import { getInvoiceStatistics, exportStatisticsToExcel, exportStatisticsToPdf } from '../../redux/invoice/actions'
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    LineController,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import './Statistics.css'
import { Line, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { NumericFormat } from 'react-number-format';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import StatisticsIcon from '../../assets/icons/statistics.svg';
import ExportXLSIcon from '../../assets/icons/exportXLS.svg';
import ExportPDFIcon from '../../assets/icons/exportPDF.svg';
import { optionsLineChart, optionsPieChart, randomColor } from '../../utils/ChartUtil';

ChartJS.register(
    CategoryScale,
    PieController,
    LineController,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

class DashboardStatisticsInvoice extends Component {

    emptyData = {
        invoiceTypes: null,
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedInvoiceType: [],
            selectedStartDate: null,
            selectedEndDate: null,
            data: this.emptyData
        };
    }

    componentWillUnmount() {
        this.props.invoiceReducer.invoiceStats = null
    }

    handleChangeStartDate = (date) => {
        let { data } = this.state
        data.startDate = moment(date)
        this.props.invoiceReducer.invoiceStats = null
        this.setState({ data: data, selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        data.endDate = moment(date).add(24, 'hours')
        this.props.invoiceReducer.invoiceStats = null
        this.setState({ data: data, selectedEndDate: date });
    }

    handleChangeInvoiceType = (e, types) => {
        let { data } = this.state
        let selectedInvoiceType = { ...this.state.selectedInvoiceType }
        if (types.length === 0) {
            data.invoiceTypes = null
            this.props.invoiceReducer.invoiceStats = null
            this.setState({ data: data, selectedInvoiceType: [] });
        } else {
            selectedInvoiceType = types;
            data.invoiceTypes = []
            selectedInvoiceType.forEach(t => {
                data.invoiceTypes.push(t)
            })
            this.props.invoiceReducer.invoiceStats = null
            this.setState({ selectedInvoiceType: selectedInvoiceType, data: data });
        }
    }

    getStatistics = () => {
        this.props.getInvoiceStatistics(this.state.data)
    }

    render() {
        const { selectedStartDate, selectedEndDate, selectedInvoiceType } = this.state
        const { invoiceTypes, invoiceStats } = this.props.invoiceReducer

        let pieChartData = []
        invoiceStats && invoiceStats.valueItemList.map(item => {
            item.valueItems.sort((x, y) => x.date - y.date)
            return pieChartData.push(item)
        })
        let lineChart = []
        if (pieChartData.length === 1) {
            pieChartData.map(data => {
                return lineChart.push(data.valueItems)
            })
        }

        return (
            <Grid container direction="row" spacing={3}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ color: "#5C5C5C", fontSize: "16px", fontWeight: 500 }}>{this.props.t("statistics_for_invoices")}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box boxShadow={3} className="custom-box">
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("payment_method")}
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedAutocomplete
                                                id="selectedInvoiceType"
                                                name="selectedInvoiceType"
                                                value={selectedInvoiceType}
                                                getOptionLabel={(option) => option ? this.props.t(option) : ""}
                                                options={invoiceTypes}
                                                multiple={true}
                                                onChange={(event, value) => {
                                                    this.handleChangeInvoiceType(event, value)
                                                }}
                                                placeholder={this.props.t("select")}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <InputLabel className="form-label">
                                            {this.props.t("start_date")}
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedDatePicker
                                                id="startDate"
                                                name="startDate"
                                                onChange={this.handleChangeStartDate}
                                                maxDate={moment(selectedEndDate).add(-1, 'day')}
                                                value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <InputLabel className="form-label">
                                            {this.props.t("end_date")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedDatePicker
                                                id="endDate"
                                                name="endDate"
                                                minDate={moment(selectedStartDate).add(1, 'day')}
                                                maxDate={moment(new Date()).add(1, 'year')}
                                                onChange={this.handleChangeEndDate}
                                                value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={2} lg={2} xl={2} mt={4}>
                                        <Button
                                            className="create-btn"
                                            style={{ width: '100%' }}
                                            startIcon={<img src={StatisticsIcon} width={24} height={24} alt="statistics-icon" />}
                                            title={this.props.t("get_statistics")}
                                            onClick={() => this.getStatistics()}
                                            disabled={!selectedEndDate}>
                                            {this.props.t("statistics")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {invoiceStats && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box boxShadow={3} className="custom-box">
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("payment_method")}</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }} align="center">{this.props.t("date")}</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }} align="right">{this.props.t("total")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {invoiceStats.valueItemList.map((data, index) => {
                                                        return <TableRow key={index}>
                                                            <TableCell style={{ padding: '0px', paddingLeft: '15px' }}>
                                                                <p style={{ height: data.valueItems.length > 1 ? 20 * data.valueItems.length : 20 }}>
                                                                    {this.props.t(data.invoiceType)}
                                                                </p>
                                                                <p className='border-top-total'>
                                                                    &nbsp;
                                                                </p>
                                                            </TableCell>
                                                            <TableCell align='center' style={{ padding: '0px' }}>
                                                                {data.valueItems.sort((x, y) => x.date - y.date).map((item1, index1) => {
                                                                    return <Fragment key={index1}>
                                                                        <p style={{ height: '20px', }}>
                                                                            {moment.unix(item1.date).format("MM.YYYY.")}
                                                                        </p>
                                                                        {index1 + 1 === data.valueItems.length && <p className='border-top-total' style={{ backgroundColor: '#E7E8E7', paddingRight: '15px', textAlign: 'left' }}>
                                                                            {this.props.t("Total") + " " + this.props.t(data.invoiceType)}
                                                                        </p>}
                                                                    </Fragment>
                                                                })}
                                                            </TableCell>
                                                            <TableCell align='right' style={{ padding: '0px' }}>
                                                                {data.valueItems.sort((x, y) => x.date - y.date).map((item1, index1) => {
                                                                    return <Fragment key={index1}>
                                                                        <p style={{ height: '20px', paddingRight: '10px' }}>
                                                                            <NumericFormat value={item1.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                                        </p>
                                                                        {index1 + 1 === data.valueItems.length && <p className='border-top-total' style={{ backgroundColor: '#E7E8E7', paddingRight: '10px' }}>
                                                                            <NumericFormat value={data.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                                        </p>}
                                                                    </Fragment>
                                                                })}
                                                            </TableCell>
                                                        </TableRow>
                                                    })}
                                                    <TableRow style={{ borderTop: '1px solid black', backgroundColor: 'lightgray' }}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("Total")}</TableCell>
                                                        <TableCell colSpan={1} />
                                                        <TableCell className="total-cell" align="right"><NumericFormat value={invoiceStats.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item container>
                                        <Stack direction="row" spacing={1.5} sx={{ marginLeft: "auto" }}>
                                            <Button
                                                sx={{ height: "42px", width: "100px" }}
                                                title={this.props.t("export_to_xls")}
                                                color="primary"
                                                onClick={() => this.props.exportStatisticsToExcel(this.state.data)}
                                                startIcon={<img src={ExportXLSIcon} alt="export-icon" />}
                                            >
                                                {this.props.t("export_to_xls")}
                                            </Button>
                                            <Button
                                                sx={{ height: "42px", width: "100px" }}
                                                title={this.props.t("export_to_pdf")}
                                                color="error"
                                                onClick={() => this.props.exportStatisticsToPdf(this.state.data)}
                                                startIcon={<img src={ExportPDFIcon} alt="export-icon" />}
                                            >
                                                {this.props.t("export_to_pdf")}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>}
                    </Grid>
                </Grid>
                {invoiceStats && invoiceStats.valueItemList && <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box boxShadow={3} className="custom-box" sx={{ width: "100%" }} justifyItems="center" alignItems="center">
                        {invoiceStats.valueItemList.length > 1 && <div style={{ width: "700px", height: "700px" }}>
                            <Pie
                                data={{
                                    labels: pieChartData.map(d => { return this.props.t(d.invoiceType) }),
                                    datasets: [{
                                        data: pieChartData.map(data => { return data.total }),
                                        backgroundColor: pieChartData.map(data => { return randomColor() }),
                                        hoverOffset: 4
                                    }]
                                }}
                                options={optionsPieChart}
                            />
                        </div>}
                        {invoiceStats.valueItemList.length === 1 && lineChart[0].length > 1 && <div style={{ width: "100%", height: "auto" }}>
                            <Line
                                data={{
                                    labels: lineChart[0].map(l => { return moment.unix(l.date).format("MM.YYYY.") }),
                                    datasets: [{
                                        label: this.props.t(pieChartData[0].invoiceType),
                                        data: lineChart[0].map(l => { return l.amount }),
                                        backgroundColor: '#C0DCD2',
                                        borderColor: '#C0DCD2',
                                        hoverOffset: 4
                                    }
                                    ]
                                }}
                                options={optionsLineChart}
                                plugins={[ChartDataLabels]}
                            />
                        </div>}
                    </Box>
                </Grid>}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    invoiceReducer: state.invoiceReducer
})

export default connect(mapStateToProps, { getInvoiceStatistics, exportStatisticsToExcel, exportStatisticsToPdf })(withTranslation()(DashboardStatisticsInvoice))
