import DashboardIcon from "../../assets/icons/dashboard_icon.svg";
import StatisticsIcon from "../../assets/icons/statistics_icon.svg";
import PatientsIcon from "../../assets/icons/patients_icon.svg";
import CalendarIcon from "../../assets/icons/calendar_icon.svg";
import LoungeIcon from "../../assets/icons/lounge_icon.svg";
import InfirmaryIcon from "../../assets/icons/infirmaries_icon.svg";
import UsersIcon from "../../assets/icons/users_icon.svg";
import ServiceGroupsIcon from "../../assets/icons/service_groups_icon.svg";
import ServicesIcon from "../../assets/icons/services_icon.svg";
import DiagnosesIcon from "../../assets/icons/diagnoses_icon.svg";
import ScheduleDoctorsIcon from "../../assets/icons/schedule_doctors_icon.svg";
import OffersIcon from "../../assets/icons/offers_icon.svg";
import SettingsIcon from "../../assets/icons/settings_icon.svg";
import AuditLogsIcon from "../../assets/icons/audit_logs_icon.svg";
import WarehouseIcon from "../../assets/icons/warehouse_icon.svg";
import { UserRole } from "../../utils/Security";

export const getSidebarItems = () => {
  return [
    {
      title: "statistics",
      icon: StatisticsIcon,
      link: "/dashboard",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]
    },
    {
      title: "dashboard",
      icon: DashboardIcon,
      link: "/dashboard",
      roles: [UserRole.RECEPTIONIST]
    },
    {
      title: "calendar",
      icon: CalendarIcon,
      link: "/calendar",
      roles: [UserRole.RECEPTIONIST]
    },
    {
      title: "lounge",
      icon: LoungeIcon,
      link: "/dashboard",
      roles: [UserRole.DOCTOR]
    },
    {
      title: "patients",
      icon: PatientsIcon,
      link: "/patients",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]
    },
    {
      title: "infirmaries",
      icon: InfirmaryIcon,
      link: "/infirmaries",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]
    },
    {
      title: "users",
      icon: UsersIcon,
      link: "/users",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]
    },
    {
      title: "service_groups",
      icon: ServiceGroupsIcon,
      link: "/service-groups",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]
    },
    {
      title: "services",
      icon: ServicesIcon,
      link: "/services",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]
    },
    {
      title: "calendar",
      icon: CalendarIcon,
      link: "/calendar",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM]
    },
    {
      title: "diagnoses",
      icon: DiagnosesIcon,
      link: "/diagnoses",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM]
    },
    {
      title: "offers",
      icon: OffersIcon,
      link: "/offers",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]
    },
    {
      title: "schedule",
      icon: ScheduleDoctorsIcon,
      link: "/schedule",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]
    },
    {
      title: "warehouses",
      icon: WarehouseIcon,
      link: "/warehouses",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]
    },
    {
      title: "change_logs",
      icon: AuditLogsIcon,
      link: "/change-logs",
      roles: [UserRole.SUPERADMIN]
    },
    {
      title: "settings",
      icon: SettingsIcon,
      link: "/settings",
      roles: [UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]
    }
  ];
}