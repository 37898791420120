import axios from '../axiosConfig';

export function getExaminations(config = {}) {
    return axios.get(`/v1/examination`, config);
}

export function createExamination(data) {
    return axios.post(`/v1/examination`, data);
}

export function updateExamination(id, data) {
    return axios.patch(`/v1/examination/${id}`, data);
}

export function getExaminationById(id, config = {}) {
    return axios.get(`/v1/examination/${id}`, config);
}

export function searchExaminations(config = {}) {
    return axios.get(`/v1/examination/search`, config);
}

export function exportExaminationByIdToPdf(id, config = {}) {
    return axios.get(`/v1/examination/${id}/pdf`, config)
}

export function exportExaminationByIdToDocx(id, config = {}) {
    return axios.get(`/v1/examination/${id}/docx`, config)
}

export function getTodayExaminations(config = {}) {
    return axios.get(`/v1/examination/active-draft`, config)
}

export function exportExaminationsToDocx(config = {}) {
    return axios.get(`/v1/examination/patient/docx`, config)
}

export function exportExaminationsToPdf(config = {}) {
    return axios.get(`/v1/examination/patient/pdf`, config)
}

export function getExaminationStatistics(data) {
    return axios.post(`/v1/examination/stats`, data);
}

export function exportStatisticsToExcel(data, config = {}) {
    return axios.post(`/v1/examination/stats/xlsx`, data, config);
}

export function exportStatisticsToPdf(data, config = {}) {
    return axios.post(`/v1/examination/stats/pdf`, data, config);
}

export function deleteExamination(id, config = {}) {
    return axios.patch(`/v1/examination/change-active-status/${id}`, {}, config);
}

export function getExaminationCountPerDoctor(config = {}) {
    return axios.get(`/v1/examination/per-doctor-for-patient`, config)
}

export function getServicesStatistics(data) {
    return axios.post(`/v1/examination/stats/service-data-total`, data)
}

export function exportStatisticsPerServiceToExcel(data, config = {}) {
    return axios.post(`/v1/examination/stats/service-data-total/xlsx`, data, config);
}

export function exportStatisticsPerServiceToPdf(data, config = {}) {
    return axios.post(`/v1/examination/stats/service-data-total/pdf`, data, config);
}

const actions = {
    getExaminations,
    createExamination,
    updateExamination,
    getExaminationById,
    searchExaminations,
    exportExaminationByIdToPdf,
    exportExaminationByIdToDocx,
    getTodayExaminations,
    exportExaminationsToDocx,
    exportExaminationsToPdf,
    getExaminationStatistics,
    exportStatisticsToExcel,
    exportStatisticsToPdf,
    deleteExamination,
    getExaminationCountPerDoctor,
    getServicesStatistics,
    exportStatisticsPerServiceToExcel,
    exportStatisticsPerServiceToPdf
}

export default actions;