import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Select,
    FormControl,
    MenuItem,
    CircularProgress,
    InputLabel,
    Button,
    Card,
    CardHeader,
    Fab,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
    DialogContent,
    Link,
    Box,
    Stack,
    CardContent
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import { getTestGoogleForm, createFormConfiguration, getGoogleFormConfigs } from '../../../redux/setting/actions'
import { Prompt } from 'react-router-dom';
import { BootstrapDialog, Transition } from '../../../components/common/Dialog';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';

class CreateConfigurationDialog extends React.Component {

    emptyForm = {
        googleFormType: '',
        googleFormQuestions: []
    }

    emptyGoogleFormQuestions = {
        question: '',
        questionType: '',
        choiceQuestionType: '',
        questionAnswers: '',
        position: null,
        questionRequired: false
    }

    emptyConfiguration = {
        emailSubject: '',
        emailBody: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            questionnareForm: JSON.parse(JSON.stringify(this.emptyForm)),
            googleFormQuestion: JSON.parse(JSON.stringify(this.emptyGoogleFormQuestions)),
            configuration: JSON.parse(JSON.stringify(this.emptyConfiguration)),
            promptDialogOpen: false
        }
    }

    addNewQuestion = () => {
        let { questionnareForm } = this.state;
        questionnareForm.googleFormQuestions.push(JSON.parse(JSON.stringify(this.emptyGoogleFormQuestions)));
        this.setState({ questionnareForm: questionnareForm });
    }

    removeQuestion = (index) => {
        let { questionnareForm } = this.state;
        questionnareForm.googleFormQuestions.splice(index, 1);
        for (let i = 0; i < questionnareForm.googleFormQuestions.length; i++) {
            questionnareForm.googleFormQuestions[i].position = i
        }
        this.setState({ questionnareForm: questionnareForm });
    }

    handleChangeConfiguration = (event) => {
        const target = event.target;
        let { configuration } = this.state;
        configuration[target.name] = target.value.trimStart()
        this.setState({ configuration: configuration })
    }

    handleChange = (e, name, index) => {
        let { questionnareForm } = this.state;
        if (e.target.type === 'checkbox') {
            questionnareForm.googleFormQuestions[index][name] = e.target.checked
        }
        else if (e.target.name === "questionAnswers") {
            if (e.target.value === "") {
                questionnareForm.googleFormQuestions[index].questionAnswers = ''
            } else {
                questionnareForm.googleFormQuestions[index].questionAnswers = e.target.value.split(/\s*,\s*/)
            }
        }
        else {
            if (questionnareForm.googleFormQuestions[index].questionType === "TEXT_QUESTION") {
                questionnareForm.googleFormQuestions[index].choiceQuestionType = null
            } else {
                questionnareForm.googleFormQuestions[index].questionAnswers = null
                questionnareForm.googleFormQuestions[index].choiceQuestionType = null;

            }
            questionnareForm.googleFormQuestions[index][name] = e.target.value.trimStart();
        }
        questionnareForm.googleFormQuestions[index].position = index
        this.setState({ questionnareForm: questionnareForm })
    }

    disabledButton = () => {
        let { questionnareForm, configuration } = this.state
        if (!configuration.emailSubject ||
            !configuration.emailBody ||
            !configuration.emailBody.includes("{googleFormLink}") ||
            !configuration.emailBody.includes("{unsubscribeLink}") ||
            questionnareForm.googleFormQuestions.length === 0) {
            return true
        }
        for (let i = 0; i < questionnareForm.googleFormQuestions.length; i++) {
            if (questionnareForm.googleFormQuestions[i].question === '' || questionnareForm.googleFormQuestions[i].questionType === '') {
                return true;
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" && questionnareForm.googleFormQuestions[i].choiceQuestionType === null) {
                return true
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                questionnareForm.googleFormQuestions[i].choiceQuestionType === '' &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null &&
                questionnareForm.googleFormQuestions[i].questionAnswers === "" &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null) {
                return true
            }

            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                (questionnareForm.googleFormQuestions[i].choiceQuestionType === 'RADIO' ||
                    questionnareForm.googleFormQuestions[i].choiceQuestionType === 'CHECKBOX') &&
                (questionnareForm.googleFormQuestions[i].questionAnswers === null ||
                    questionnareForm.googleFormQuestions[i].questionAnswers === "")) {
                return true
            }
        }
        return false;
    }

    disabledButtonForTest = () => {
        let { questionnareForm } = this.state
        for (let i = 0; i < questionnareForm.googleFormQuestions.length; i++) {
            if (questionnareForm.googleFormQuestions[i].question === '' || questionnareForm.googleFormQuestions[i].questionType === '') {
                return true;
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" && questionnareForm.googleFormQuestions[i].choiceQuestionType === null) {
                return true
            }
            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                questionnareForm.googleFormQuestions[i].choiceQuestionType === '' &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null &&
                questionnareForm.googleFormQuestions[i].questionAnswers === "" &&
                questionnareForm.googleFormQuestions[i].questionAnswers === null) {
                return true
            }

            if (questionnareForm.googleFormQuestions[i].questionType === "CHOICE_QUESTION" &&
                (questionnareForm.googleFormQuestions[i].choiceQuestionType === 'RADIO' ||
                    questionnareForm.googleFormQuestions[i].choiceQuestionType === 'CHECKBOX') &&
                (questionnareForm.googleFormQuestions[i].questionAnswers === null ||
                    questionnareForm.googleFormQuestions[i].questionAnswers === "")) {
                return true
            }
        }
        return false;
    }

    createConfiguration = () => {
        let { questionnareForm, configuration } = this.state
        let requestData = {
            ...questionnareForm,
            googleFormType: 'QUESTIONNAIRE',
            emailSubject: configuration.emailSubject,
            emailBody: configuration.emailBody.replaceAll("\n", "<br>"),
        }
        this.props.createFormConfiguration(requestData).then(response => {
            if (response.status === 200) {
                this.props.getGoogleFormConfigs({ type: "QUESTIONNAIRE" });
                this.props.onClose();
            }
        })
    }

    checkForm = () => {
        let { questionnareForm } = this.state;
        questionnareForm.googleFormType = 'TEST';
        this.props.getTestGoogleForm(this.state.questionnareForm);
    }

    render() {
        const { mainLoading, questionnareForm, configuration, promptDialogOpen } = this.state;
        const { googleFromUrl, googleFormLoading } = this.props.settingReducer;

        let questions = questionnareForm.googleFormQuestions.map((qF, index) => {
            return <Grid key={index} item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                            <Typography variant="h5">
                                {this.props.t("question") + ": " + (index + 1)}
                            </Typography>
                            <FormControlLabel
                                sx={{ flexGrow: 1 }}
                                control={<Checkbox
                                    id="questionRequired"
                                    name="questionRequired"
                                    onChange={(e) => this.handleChange(e, "questionRequired", index)}
                                    checked={qF.questionRequired} />
                                }
                                label={this.props.t("required")}
                                labelPlacement="end"
                            />
                            <Fab color="error" className="default-button" onClick={() => this.removeQuestion(index)} >
                                <Delete className="table-fab-icon" />
                            </Fab>
                        </Stack>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputLabel className="form-label">
                            {this.props.t("question")} <span style={{ color: "#D32F2F" }}>*</span>
                        </InputLabel>
                        <TextField
                            id="question"
                            name="question"
                            multiline
                            rows={3}
                            value={qF.question}
                            onChange={(e) => this.handleChange(e, "question", index)}
                            placeholder={this.props.t("enter", { text: this.props.t("question").toLowerCase() })}
                        />
                    </Grid>
                    <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                        <InputLabel className="form-label">
                            {this.props.t("question_type")} <span style={{ color: "#D32F2F" }}>*</span>
                        </InputLabel>
                        <FormControl>
                            <Select
                                displayEmpty
                                labelId="select-question-type"
                                id="questionType"
                                name="questionType"
                                value={qF.questionType || ''}
                                onChange={(e) => this.handleChange(e, "questionType", index)}
                                renderValue={(selected) => {
                                    return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                }}>
                                <MenuItem value={"CHOICE_QUESTION"}>{this.props.t("CHOICE_QUESTION")}</MenuItem>
                                <MenuItem value={"TEXT_QUESTION"}>{this.props.t("TEXT_QUESTION")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {qF.questionType !== "TEXT_QUESTION" && <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                        <InputLabel className="form-label">
                            {this.props.t("choice_question_type")} <span style={{ color: "#D32F2F" }}>*</span>
                        </InputLabel>
                        <FormControl>
                            <Select
                                displayEmpty
                                labelId="select-question-choice-question-type"
                                id="choiceQuestionType"
                                name="choiceQuestionType"
                                value={qF.choiceQuestionType || ''}
                                onChange={(e) => this.handleChange(e, "choiceQuestionType", index)}
                                renderValue={(selected) => {
                                    return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                }}>
                                <MenuItem value={"RADIO"}>{this.props.t("RADIO")}</MenuItem>
                                <MenuItem value={"CHECKBOX"}>{this.props.t("CHECKBOX")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>}
                    {qF.questionType !== "TEXT_QUESTION" && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputLabel className="form-label">
                            {this.props.t("question_answers")} <span style={{ color: "#D32F2F" }}>*</span>
                        </InputLabel>
                        <TextField
                            id="questionAnswers"
                            name="questionAnswers"
                            multiline
                            rows={3}
                            value={qF.questionAnswers || ''}
                            onChange={(e) => this.handleChange(e, "questionAnswers", index)}
                            placeholder={this.props.t("enter", { text: this.props.t("question_answers").toLowerCase() })} />
                        <Typography sx={{ fontSize: '14px', fontWeight: '700' }} gutterBottom>
                            <strong>{this.props.t("info_message_google_answer")}</strong>
                        </Typography>
                    </Grid>}
                </Grid>
            </Grid>
        })

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<BootstrapDialog
            onClose={() => (questionnareForm.googleFormQuestions.length > 0 || configuration.emailSubject || configuration.emailBody) ? this.setState({ promptDialogOpen: true }) : this.props.onClose()}
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="lg"
        >
            <DialogContent sx={{ padding: "0px !important" }}>
                <Box className="custom-box">
                    <Grid container direction="row" spacing={3}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                    {this.props.t("new_configuration")}
                                </Typography>
                                <Button className="cancel-btn" onClick={() => (questionnareForm.googleFormQuestions.length > 0 || configuration.emailSubject || configuration.emailBody) ? this.setState({ promptDialogOpen: true }) : this.props.onClose()}>
                                    {this.props.t("close")}
                                </Button>
                                <Button className="create-btn" disabled={this.disabledButton()}
                                    onClick={() => this.createConfiguration()}>
                                    {this.props.t("create")}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item container direction="column" xs={8} sm={8} md={8} lg={6} xl={6}>
                            <Prompt when={this.disabledButtonForTest()} message={this.props.t("warning_message")} />
                            <Grid container direction="row" spacing={3}>
                                <Grid item container>
                                    <InputLabel className="form-label">
                                        {this.props.t("subject")} <span style={{ color: "#D32F2F" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                        id="emailSubject"
                                        name="emailSubject"
                                        value={configuration.emailSubject}
                                        onChange={this.handleChangeConfiguration}
                                        placeholder={this.props.t("enter", { text: this.props.t("subject").toLowerCase() })}
                                    />
                                </Grid>
                                <Grid item container>
                                    <InputLabel className="form-label">
                                        {this.props.t("body")} <span style={{ color: "#D32F2F" }}>*</span>
                                    </InputLabel>
                                    <TextField
                                        id="emailBody"
                                        name="emailBody"
                                        value={configuration.emailBody}
                                        multiline
                                        rows={15}
                                        onChange={this.handleChangeConfiguration}
                                        placeholder={this.props.t("enter", { text: this.props.t("body").toLowerCase() })}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Typography variant="body2" gutterBottom>
                                        <strong>{this.props.t("info_message_email")}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    {questionnareForm.googleFormQuestions.length > 0 && <Button
                                        sx={{ width: "200px !important" }}
                                        className="create-btn"
                                        disabled={this.disabledButtonForTest()}
                                        onClick={() => this.checkForm()}>
                                        {googleFormLoading ? <CircularProgress
                                            size={20}
                                            color="secondary"
                                        /> : <>
                                            <ListIcon className="table-fab-icon" style={{ marginRight: 5 }} />
                                            {this.props.t("check_form")}</>}
                                    </Button>}
                                </Grid>
                                {googleFromUrl && <Grid item container>
                                    <Typography variant="body1">
                                        {this.props.t("google_form_created")}
                                    </Typography>
                                    <Typography variant="body2">
                                        <Link href={googleFromUrl} target="_blank">{googleFromUrl}</Link>
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Card sx={{ marginTop: "24px" }}>
                                <CardHeader
                                    subheader={this.props.t("new_question")}
                                    action={<Fab
                                        title={this.props.t("new_question")}
                                        color="primary"
                                        onClick={() => this.addNewQuestion()}>
                                        <Add className="table-fab-icon" />
                                    </Fab>}>
                                </CardHeader>
                                <CardContent>
                                    <Grid container direction="row" spacing={2}>
                                        {questions}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <ConfirmationDialog
                isOpen={promptDialogOpen}
                title={this.props.t("prompt_title")}
                message={this.props.t("prompt_message_2")}
                onClose={() => this.setState({ promptDialogOpen: false })}
                onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.props.onClose())}
                closeButtonTitle={this.props.t("continue")}
                confirmButtonTitle={this.props.t("discard")}
            />
        </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = {
    getTestGoogleForm,
    createFormConfiguration,
    getGoogleFormConfigs
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(CreateConfigurationDialog))
