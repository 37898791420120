export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SET_USERS = 'SET_USERS';
export const SET_ROLES = 'SET_ROLES';
export const SET_DOCTORS = 'SET_DOCTORS'
export const SET_USERS_PAGE = 'SET_USERS_PAGE'
export const SET_USERS_SIZE = 'SET_USERS_SIZE'
export const SET_USERS_PARAMS = 'SET_USERS_PARAMS'
export const SET_USER_LIST = 'SET_USER_LIST'

const types = {
    SET_USERS_LOADING,
    SET_USERS,
    SET_ROLES,
    SET_DOCTORS,
    SET_USERS_PAGE,
    SET_USERS_SIZE,
    SET_USERS_PARAMS,
    SET_USER_LIST
};

export default types;