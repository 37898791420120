import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    TableCell,
    TableRow,
} from '@mui/material';
import { getCalendarEntriesRecords } from '../../../redux/calendar/actions'
import { sendBulkSms } from '../../../redux/setting/actions'
import WrappedDatePicker from '../../../components/common/WrappedDatePicker';
import moment from 'moment';
import Base58Table from '../../../components/common/Base58Table';
import { History, CheckBox, Send } from '@mui/icons-material';
import CustomMessagesModal from './CustomMessagesModal';

class CustomMessageTab extends Component {

    emptyData = {
        startTime: null
    }

    constructor(props) {
        super(props);
        this.state = {
            data: this.emptyData,
            selectedDate: null,
            calendarIds: [],
            phoneNumbers: [],
            openModal: false,
            displayData: false,
            filter: this.props.t("all").toUpperCase()
        };
    }

    handleChangeDate = (date) => {
        let { data } = this.state
        if (date !== null && date._isValid) {
            data.startTime = moment(date).format("YYYY-MM-DDT00:00:00.000Z")
            this.setState({ data: data, selectedDate: date, displayData: false });
        }
    }

    getCalendarEntries = () => {
        let { data } = this.state
        let params = {}
        params.startTime = data.startTime
        this.props.getCalendarEntriesRecords({ params: params }).then(() => {
            this.setState({ displayData: true })
        })
    }

    handleChange = (cr) => {
        let { calendarIds, phoneNumbers } = this.state
        if (!calendarIds.includes(cr.id)) {
            calendarIds.push(cr.id)
            phoneNumbers.push(cr.patient.phone)
        } else {
            calendarIds.splice(calendarIds.indexOf(cr.id), 1);
            phoneNumbers.splice(phoneNumbers.indexOf(cr.patient.phone), 1)
        }
        this.setState({ calendarIds: calendarIds, phoneNumbers: phoneNumbers })
    }

    handleChangeAll = () => {
        const { calendarRecords } = this.props.calendarReducer
        let { calendarIds, phoneNumbers } = this.state
        if (calendarRecords.length === calendarIds.length) {
            this.setState({ calendarIds: [], phoneNumbers: [] })
        } else {
            calendarRecords.forEach(cr => {
                if (!calendarIds.includes(cr.id)) {
                    calendarIds.push(cr.id)
                    phoneNumbers.push(cr.patient.phone)
                }
                this.setState({ calendarIds: calendarIds, phoneNumbers: phoneNumbers })
            })
        }
    }

    closeDialog = () => {
        this.setState({ openModal: false });
    }

    sendMessages = (message) => {
        let { phoneNumbers } = this.state
        let request = {
            patientsPhones: phoneNumbers,
            smsText: message
        }
        this.props.sendBulkSms(request).then(() => {
            this.setState({ openModal: false, phoneNumbers: [], calendarIds: [] });
        })
    }

    handleChangeFilter = (e) => {
        this.setState({ filter: e.target.value })
    }

    displayData = (cr, index) => {
        return <TableRow>
            <TableCell>
                <Checkbox
                    style={{ padding: '0px' }}
                    edge="end"
                    onChange={() => this.handleChange(cr)}
                    checked={this.state.calendarIds.some(item => item === cr.id)}
                    inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${index}` }}
                />
            </TableCell>
            <TableCell>{moment(cr.startTime).format("HH:mm") + " - " + moment(cr.endTime).format("HH:mm")}</TableCell>
            <TableCell>{cr.patient && cr.patient.firstName}</TableCell>
            <TableCell>{cr.patient && cr.patient.lastName}</TableCell>
            <TableCell>{cr.patient && cr.patient.phone}</TableCell>
            <TableCell>
                <span style={{ backgroundColor: cr.infirmary && cr.infirmary.calendarHexColor, padding: "4px", borderRadius: "4px" }}>{cr.infirmary && cr.infirmary.name}</span>
            </TableCell>
            <TableCell>{cr.comment}</TableCell>
        </TableRow>
    }

    render() {
        const { selectedDate, calendarIds, openModal, filter } = this.state
        const { calendarRecords, calendarRecordsLoading } = this.props.calendarReducer

        let allInfirmaries = []
        allInfirmaries = calendarRecords.filter((entry, index, self) =>
            index === self.findIndex((t) => (
                t.infirmary.id === entry.infirmary.id
            ))
        );
        allInfirmaries.push({ infirmary: { id: 999999, name: this.props.t("all").toUpperCase() } })

        let calendarRecordsList;
        if (calendarRecords && calendarRecords.length > 0) {
            calendarRecordsList = calendarRecords.map((cr, index) => {
                if (cr.infirmary.name === filter) {
                    return <React.Fragment key={index}>
                        {this.displayData(cr, index)}
                    </React.Fragment>
                }
                if (filter === this.props.t("all").toUpperCase()) {
                    return <React.Fragment key={index}>
                        {this.displayData(cr, index)}
                    </React.Fragment>
                }
                return null
            })
        }

        let columns = [
            {
                name: "",
                width: '5%'
            },
            {
                name: "time",
                width: '10%'
            },
            {
                name: "first_name",
                width: '15%'
            },
            {
                name: "last_name",
                width: '15%'
            },
            {
                name: "phone",
                width: '15%'
            },
            {
                name: "infirmary",
                dbName: "infirmary",
                width: '15%',
                type: "auto-complete",
                items: allInfirmaries.map(data => { return data.infirmary.name }),
                multiple: false,
                filter: true
            },
            {
                name: "comment",
                width: '20%'
            }
        ]


        return (
            <Grid item container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                    <InputLabel className="form-label">
                        {this.props.t("date")}
                    </InputLabel>
                    <FormControl>
                        <WrappedDatePicker
                            id="date"
                            name="date"
                            minDate={moment()}
                            maxDate={moment(new Date()).add(2, 'month')}
                            onChange={this.handleChangeDate}
                            value={selectedDate ? Date.parse(selectedDate) : null}
                        />
                    </FormControl>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={1} xl={1} mt={4}>
                    <Button
                        sx={{ marginTop: '2px', height: '40px' }}
                        title={this.props.t("get_history")}
                        color="primary"
                        onClick={() => this.getCalendarEntries()}
                        disabled={!selectedDate}>
                        <History />
                    </Button>
                </Grid>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8} />
                {calendarRecords && calendarRecords.length > 0 && this.state.displayData && <Grid item container direction="column" xs={8} sm={8} md={8} lg={2} xl={2}>
                    <Button fullWidth startIcon={<CheckBox />} title={calendarRecords.length === calendarIds.length ? this.props.t("uncheck_all") : this.props.t("check_all")} className="create-btn" color="primary" onClick={() => this.handleChangeAll()}>
                        {calendarRecords.length === calendarIds.length ? this.props.t("uncheck_all") : this.props.t("check_all")}
                    </Button>
                </Grid>}
                {calendarRecords && calendarRecords.length > 0 && this.state.displayData && <Grid item container direction="column" xs={8} sm={8} md={8} lg={2} xl={2}>
                    <Button
                        className="create-btn"
                        startIcon={<Send />}
                        title={this.props.t("send_messages")}
                        color="secondary"
                        disabled={!calendarIds.length > 0}
                        onClick={() => this.setState({ openModal: true })}>
                        {this.props.t("send_messages")}
                    </Button>
                </Grid>}
                {calendarRecords && this.state.displayData &&
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Base58Table
                            isLoading={calendarRecordsLoading}
                            columns={columns}
                            data={calendarRecordsList}
                            pagination={false}
                            onFilterChange={(params) => this.setState({ filter: params.infirmary === null ? this.props.t("all").toUpperCase() : params.infirmary })}
                        />
                    </Grid>}
                {openModal && <CustomMessagesModal
                    isOpen={openModal}
                    onClose={this.closeDialog}
                    sendMessage={(message) => this.sendMessages(message)} />}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer
})

export default connect(mapStateToProps, { getCalendarEntriesRecords, sendBulkSms })(withTranslation()(CustomMessageTab))