import types from './types';
import api from '../../api/diagnosisApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';

export const getDiagnoses = () => async (dispatch) => {
    const params = store.getState().diagnosisReducer.diagnosesParams;
    await api.getDiagnoses({ params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setDiagnosesPage(params.page - 1));
        }
        dispatch({
            type: types.SET_DIAGNOSES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_DIAGNOSES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_diagnoses_error")));
    });
    return Promise.resolve();
}

export const setDiagnosesPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_DIAGNOSES_PAGE,
        payload: Number(value)
    });
    dispatch(getDiagnoses());
    return Promise.resolve();
};

export const setDiagnosesSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_DIAGNOSES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getDiagnoses());
    }
    return Promise.resolve();
};

export const setDiagnosesParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_DIAGNOSES_PARAMS,
        payload: Object.assign(store.getState().diagnosisReducer.diagnosesParams, params)
    });
    dispatch(getDiagnoses());
    return Promise.resolve();
};

export const getDiagnosesList = (config = {}) => async (dispatch) => {
    await api.getDiagnosesList(config).then(response => {
        dispatch({
            type: types.SET_DIAGNOSES_LIST,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_DIAGNOSES_LIST,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("get_diagnoses_list_error")));
    });
    return Promise.resolve();
}

export const importDiagnoses = (data) => async (dispatch) => {
    dispatch({
        type: types.SET_LOADING_IMPORT,
        payload: true
    });
    return await api.importDiagnoses(data).then((response) => {
        dispatch({
            type: types.SET_LOADING_IMPORT,
            payload: false
        });
        dispatch(snackActions.success(i18next.t("import_successfully")));
        return response
    }).catch((error) => {
        dispatch({
            type: types.SET_LOADING_IMPORT,
            payload: false
        });
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    });
}

export const createDiagnosis = (data) => async (dispatch) => {
    return await api.createDiagnosis(data).then((response) => {
        dispatch(snackActions.success(i18next.t("diagnosis_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const updateDiagnosis = (id, data) => async (dispatch) => {
    return await api.updateDiagnosis(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("diagnosis_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const getDiagnosisById = (id, config = {}) => async (dispatch) => {
    return await api.getDiagnosisById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_diagnosis_by_id_error")));
    });
}

export const deleteDiagnosis = (id) => async (dispatch) => {
    await api.deleteDiagnosis(id).then(() => {
        dispatch(snackActions.success(i18next.t("diagnosis_deleted_successfully")));
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    })
    return Promise.resolve();
}
