import React from 'react';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux'
import store from '../../redux/store'
import types, { SET_CURRENT_PATIENT } from '../../redux/patient/types';
import {
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Grid,
    Card,
    CardContent,
    Box,
    LinearProgress,
    Typography,
    CardHeader,
    TextField,
    Autocomplete,
    FormControl,
    Zoom,
    Tooltip,
    CircularProgress,
    createFilterOptions,
    InputLabel
} from '@mui/material';
import { ExpandMore, Info } from '@mui/icons-material'
import { getPatientById, updatePatient, getPatientFiles } from '../../redux/patient/actions'
import { getServiceList } from '../../redux/service/actions'
import { getDiagnosesList } from '../../redux/diagnosis/actions'
import { getExaminations, createExamination, updateExamination, getExaminationById, searchExaminations, exportExaminationByIdToPdf, exportExaminationByIdToDocx, exportExaminationsToDocx, exportExaminationsToPdf, deleteExamination, getExaminationCountPerDoctor } from '../../redux/examination/actions'
import moment from 'moment'
import PatientInfo from './PatientInfo';
import ExaminationPatientFilesModal from './ExaminationPatientFilesModal';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { hasAnyRole, hasRole, UserRole } from '../../utils/Security';
import LeftArrowIcon from '../../assets/icons/left_arrow.svg';
import { Stack } from '@mui/system';
import FilesIcon from '../../assets/icons/files.svg';
import SaveIcon from '../../assets/icons/save.svg';
import SaveAsNewIcon from '../../assets/icons/save_as_new.svg';
import ClearFormIcon from '../../assets/icons/clear_form.svg';
import DeleteIcon from '../../assets/icons/delete_icon.svg';
import ExportXLSIcon from '../../assets/icons/exportXLS.svg';
import ExportPDFIcon from '../../assets/icons/exportPDF.svg';
import SearchIcon from '../../assets/icons/search.svg';
import EditIcon from '../../assets/icons/edit_icon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExaminationServices from './ExaminationServices';

class Examination extends React.Component {

    emptyExamination = {
        patientId: null,
        comment: '',
        diagnosisIds: [],
        internalNote: '',
        examinationServices: [],
        calendarEntryId: null
    }

    emptyExaminationService = {
        serviceId: null,
        price: null,
        quantity: null,
        discountAmount: null,
        discountPercentage: null,
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            examination: JSON.parse(JSON.stringify(this.emptyExamination)),
            initialExamination: JSON.parse(JSON.stringify(this.emptyExamination)),
            collapseIndex: -1,
            collapseExamination: 1,
            collapseService: 1,
            collapseInternalNote: -1,
            examinationId: null,
            examinationsFilter: '',
            selectedDiagnoses: [],
            mainLoading: true,
            internalNote: null,
            initialInternalNote: null,
            modalOpen: false,
            openDeleteDialog: false,
            examinationToDelete: null,
            expanded: ["panel2", "panel4"],
            examinationServicesValidation: []
        };
    }

    componentDidMount() {
        Promise.all([this.props.getServiceList(), this.props.getDiagnosesList(), this.props.getExaminations({ params: { patientId: this.props.match.params.id } },

            this.props.getExaminationCountPerDoctor({ params: { patientId: this.props.match.params.id } }),
            this.props.getPatientById(this.props.match.params.id).then(response => {
                this.setState({ internalNote: response.data.internalNote, initialInternalNote: response.data.internalNote })
                store.dispatch({
                    type: SET_CURRENT_PATIENT,
                    payload: response.data
                });
            }))])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    handleChange = (panel) => (event, isExpanded) => {
        let { expanded } = this.state;
        if (isExpanded) {
            expanded.push(panel);
        } else {
            expanded.splice(expanded.indexOf(panel), 1);
        }
        this.setState({ expanded: expanded });
    };

    handleChangeExamination = (event) => {
        const target = event.target;
        let { examination } = this.state;
        examination[target.name] = target.value.trimStart();
        this.setState({ examination: examination })
    }

    handleChangeDiagnosis = (e, diagnosis) => {
        let { selectedDiagnoses, examination } = this.state

        selectedDiagnoses = diagnosis
        examination.diagnosisIds = []

        selectedDiagnoses.forEach(data => {
            examination.diagnosisIds.push(data.id)
        })
        this.setState({ examination: examination, selectedDiagnoses: selectedDiagnoses })
    }

    saveExamination = () => {
        let { examination, examinationId } = this.state
        examination.patientId = this.props.patientReducer.currentPatient.id
        examination.calendarEntryId = Number(this.props.match.params.calendarEntryId);
        const action = examinationId ? this.props.updateExamination(examinationId, examination) : this.props.createExamination(examination);

        action.then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.props.getExaminationCountPerDoctor({ params: { patientId: this.props.match.params.id } })
                this.setState({
                    initialExamination: JSON.parse(JSON.stringify(this.emptyExamination)),
                    examination: JSON.parse(JSON.stringify(this.emptyExamination)),
                    examinationId: null, selectedDiagnoses: [], expanded: ["panel2", "panel4"]
                });
                this.props.getExaminations({ params: { patientId: this.props.match.params.id } })
            }
        })
    }

    saveAsNewExamination = () => {
        let { examination } = this.state
        examination.patientId = this.props.patientReducer.currentPatient.id
        examination.calendarEntryId = Number(this.props.match.params.calendarEntryId);
        this.props.createExamination(examination).then(() => {
            this.setState({
                initialExamination: JSON.parse(JSON.stringify(this.emptyExamination)),
                examination: JSON.parse(JSON.stringify(this.emptyExamination)),
                examinationId: null, selectedDiagnoses: [], expanded: ["panel2", "panel4"]
            });
            this.props.getExaminations({ params: { patientId: this.props.match.params.id } })
        })
    }

    getExaminationById = (id) => {
        this.props.getExaminationById(id).then((response) => {
            const selectedDiagnoses = this.props.diagnosisReducer.diagnosesList.filter((d) => {
                return response.data.diagnosisIds.some((f) => {
                    return f === d.id
                });
            })
            this.setState({
                examination: JSON.parse(JSON.stringify(response.data)),
                initialExamination: JSON.parse(JSON.stringify(response.data)),
                examinationId: id,
                selectedDiagnoses: selectedDiagnoses,
                collapseExamination: 1,
                collapseService: 1
            })
            store.dispatch({
                type: types.SET_CURRENT_PATIENT,
                payload: response.data.patient
            })
        })
    }

    handleChangeFilter = (event) => {
        this.setState({ examinationsFilter: event.target.value })
    }

    filterExaminations = () => {
        let params = {}
        params.patientId = this.props.patientReducer.currentPatient.id
        params.query = this.state.examinationsFilter
        this.props.searchExaminations({ params: params })
    }

    validateForm() {
        let { examination, initialExamination, examinationServicesValidation } = this.state;
        let valid = true;
        if (examination.comment.length > 5000) {
            valid = false;
        }
        if (examination.comment.length === 0) {
            valid = false;
        }
        if (JSON.stringify(examination) === JSON.stringify(initialExamination)) {
            valid = false
        }
        if (examination.examinationServices.length === 0) {
            valid = false
        }
        if (examinationServicesValidation.some((es) => Object.values(es).some((error) => error === true))) {
            valid = false;
        }
        return valid;
    }

    getNameHelperTextComment() {
        let { examination } = this.state;
        if (!examination.comment.trim()) {
            return this.props.t("required_field_message");
        }
        if (examination.comment && examination.comment.length > 5000) {
            return this.props.t("number_regex_message", { max: 5000 })
        }
    }

    disabledDeleteOption = (data) => {
        if (data.createdBy === this.props.authReducer.user.email && hasRole(UserRole.DOCTOR)) {
            return true
        }
        if (hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM])) {
            return true
        }
        return false
    }

    handleChangeInternalNote = (event) => {
        const target = event.target;
        let { internalNote } = this.state;
        internalNote = target.value ? target.value.trimStart() : null;
        this.setState({ internalNote: internalNote })
    }

    updateInternalNote = () => {
        let data = {
            ...this.props.patientReducer.currentPatient,
            internalNote: this.state.internalNote
        }
        this.props.updatePatient(this.props.patientReducer.currentPatient.id, data).then((response) => {
            this.setState({ internalNote: response.data.internalNote, initialInternalNote: response.data.internalNote })
        })
    }

    validationInternalNote = () => {
        let { internalNote, initialInternalNote } = this.state;
        let valid = true;
        if (internalNote && internalNote.length === 0) {
            valid = false;
        }
        if (JSON.stringify(internalNote) === JSON.stringify(initialInternalNote)) {
            valid = false
        }
        return valid;
    }

    closeDialog = () => {
        this.setState({ modalOpen: false })
    }

    deleteExamination = () => {
        let params = {}
        params.activeStatus = false
        this.props.deleteExamination(this.state.examinationToDelete.id, { params: params }).then(() => {
            this.setState({ openDeleteDialog: false, examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationId: null, selectedDiagnoses: [], collapseIndex: -1 })
            this.props.getExaminations({ params: { patientId: this.props.match.params.id } })
        })
    }

    render() {
        const { examination, collapseIndex, selectedDiagnoses, examinationsFilter, mainLoading, initialExamination, internalNote, expanded } = this.state
        const { currentPatient } = this.props.patientReducer
        const { examinations, isLoading, examinationsCount } = this.props.examinationReducer
        const { diagnosesList } = this.props.diagnosisReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        const filterOptions = createFilterOptions({
            matchFrom: 'any',
            limit: 1000,
        });

        return (<Box className="custom-box">
            <Prompt when={JSON.stringify(examination) !== JSON.stringify(initialExamination)} message={this.props.t("prompt_message")} />
            <Grid container direction="row" spacing={3}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack direction="row" spacing={3}>
                        <Button
                            variant='link'
                            startIcon={<img src={LeftArrowIcon} width={16} height={16} alt="left-arrow-icon" />}
                            onClick={() => this.props.history.goBack()}
                            title={this.props.t("back")}
                            sx={{ textTransform: "none !important" }}>
                            <Typography color="primary">
                                {this.props.t("patients")}
                            </Typography>
                        </Button>
                        <Typography sx={{ fontWeight: 500, fontSize: "24px" }}>
                            {`${this.props.t("patient")}: ${currentPatient.firstName} ${currentPatient.lastName} (${moment(currentPatient.dateOFBirth).format("DD.MM.YYYY.")}), ${this.props.t("oib_short")}: ${currentPatient.personalIdentificationNumber || "-"}`}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Card sx={{ padding: "24px !important", borderRadius: "8px", boxShadow: "0px 4px 23.5px 0px rgba(0, 0, 0, 0.08)" }}>
                        <CardHeader
                            title={this.props.t("new_examination")}
                            action={<Stack direction="row" spacing={1.5}>
                                <Button
                                    sx={{ height: "42px", width: "67px" }}
                                    title={this.props.t("files")}
                                    color="primary"
                                    onClick={() => this.setState({ modalOpen: true }, () => this.props.getPatientFiles(this.props.match.params.id))}>
                                    <img src={FilesIcon} alt="files-icon" />
                                </Button>
                                <Button
                                    sx={{ height: "42px", width: "67px" }}
                                    title={this.props.t("save")}
                                    color="primary"
                                    onClick={() => this.saveExamination()}
                                    disabled={!this.validateForm()}>
                                    <img src={SaveIcon} alt="save-icon" />
                                </Button>
                                <Button
                                    sx={{ height: "42px", width: "67px" }}
                                    title={this.props.t("save_as_new")}
                                    color="primary"
                                    onClick={() => this.saveAsNewExamination()}
                                    disabled={!this.validateForm()}>
                                    <img src={SaveAsNewIcon} alt="save-as-new-icon" />
                                </Button>
                                <Button
                                    sx={{ height: "42px", width: "67px" }}
                                    title={this.props.t("clear_form")}
                                    color="primary"
                                    onClick={() => this.setState({ examination: JSON.parse(JSON.stringify(this.emptyExamination)), examinationId: null, selectedDiagnoses: [] })}>
                                    <img src={ClearFormIcon} alt="clear-form-icon" />
                                </Button>
                            </Stack>}>
                        </CardHeader>
                        <CardContent sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Accordion onChange={this.handleChange('panel1')}>
                                        <AccordionSummary
                                            sx={{ paddingLeft: "0px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "18.34px", color: expanded.includes('panel1') ? "#1B7E53" : "unset" }}>
                                                {this.props.t("basic_information")}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <PatientInfo />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={10} xl={10}>
                                    <InputLabel className="form-label">
                                        {this.props.t("internal_note_patient")}
                                    </InputLabel>
                                    <TextField
                                        id="internalNote"
                                        name="internalNote"
                                        value={internalNote || ''}
                                        onChange={this.handleChangeInternalNote}
                                        placeholder={this.props.t("enter", { text: this.props.t("internal_note_patient").toLowerCase() })}
                                    />
                                </Grid>
                                <Grid item container direction="column" xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Button sx={{ height: "42px !important", marginTop: "26px" }} onClick={() => this.updateInternalNote()} disabled={!this.validationInternalNote()}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                                            {this.props.t("update")}
                                        </Typography>
                                    </Button>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Accordion defaultExpanded onChange={this.handleChange('panel2')}>
                                        <AccordionSummary
                                            sx={{ paddingLeft: "0px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2-content"
                                            id="panel2-header"
                                        >
                                            <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "18.34px", color: expanded.includes('panel2') ? "#1B7E53" : "unset" }}>
                                                {this.props.t("examination")}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("diagnosis")}
                                                    </InputLabel>
                                                    <FormControl>
                                                        <Autocomplete
                                                            id="selectedDiagnoses"
                                                            name="selectedDiagnoses"
                                                            disableListWrap
                                                            filterOptions={filterOptions}
                                                            value={selectedDiagnoses}
                                                            options={diagnosesList}
                                                            multiple={true}
                                                            getOptionLabel={(option) => option ? option.code + " " + option.name : ""}
                                                            onChange={(event, diagnosis) => {
                                                                this.handleChangeDiagnosis(event, diagnosis)
                                                            }}
                                                            placeholder={this.props.t("select")}
                                                            clearText={this.props.t("clear")}
                                                            closeText={this.props.t("close")}
                                                            openText={this.props.t("open")}
                                                            renderInput={(params) => <TextField {...params} placeholder={this.props.t("select")} />}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("therapy_comment")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="comment"
                                                        name="comment"
                                                        value={(examination.comment && examination.comment.charAt(0).toUpperCase() + examination.comment.slice(1))}
                                                        onChange={this.handleChangeExamination}
                                                        placeholder={this.props.t("enter", { text: this.props.t("therapy_and_or_comment").toLowerCase() })}
                                                        multiline
                                                        rows={10}
                                                        onKeyDown={(e) => {
                                                            const { value } = e.target;
                                                            if (e.key === 'Tab') {
                                                                e.preventDefault();
                                                                const cursorPosition = e.target.selectionStart;
                                                                const cursorEndPosition = e.target.selectionEnd;
                                                                const tab = '\t';
                                                                e.target.value =
                                                                    value.substring(0, cursorPosition) +
                                                                    tab +
                                                                    value.substring(cursorEndPosition);
                                                                e.target.selectionStart = cursorPosition + 1;
                                                                e.target.selectionEnd = cursorPosition + 1;
                                                            }
                                                        }}
                                                        error={examination.comment.length > 5000 || !examination.comment.trim() ? true : false}
                                                        helperText={this.getNameHelperTextComment()} />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Accordion onChange={this.handleChange('panel3')} >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel3-content"
                                                            id="panel3-header"
                                                        >
                                                            <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "18.34px", color: expanded.includes('panel3') ? "#1B7E53" : "unset" }}>
                                                                {this.props.t("internal_note")}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container direction="row" spacing={2}>
                                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    {/*  <InputLabel className="form-label">
                                                                        {this.props.t("internal_note")}
                                                                    </InputLabel> */}
                                                                    <TextField
                                                                        id="internalNote"
                                                                        name="internalNote"
                                                                        value={examination.internalNote || ''}
                                                                        onChange={this.handleChangeExamination}
                                                                        placeholder={this.props.t("enter", { text: this.props.t("internal_note").toLowerCase() })}
                                                                        multiline
                                                                        rows={3}
                                                                        onKeyDown={(e) => {
                                                                            const { value } = e.target;

                                                                            if (e.key === 'Tab') {
                                                                                e.preventDefault();
                                                                                const cursorPosition = e.target.selectionStart;
                                                                                const cursorEndPosition = e.target.selectionEnd;
                                                                                const tab = '\t';

                                                                                e.target.value =
                                                                                    value.substring(0, cursorPosition) +
                                                                                    tab +
                                                                                    value.substring(cursorEndPosition);
                                                                                e.target.selectionStart = cursorPosition + 1;
                                                                                e.target.selectionEnd = cursorPosition + 1;
                                                                            }
                                                                        }} />
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion defaultExpanded onChange={this.handleChange('panel4')}>
                                        <AccordionSummary
                                            sx={{ paddingLeft: "0px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4-content"
                                            id="panel4-header"
                                        >
                                            <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "18.34px", color: expanded.includes('panel4') ? "#1B7E53" : "unset" }}>
                                                {this.props.t("services")}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ExaminationServices
                                                data={examination.examinationServices}
                                                onChange={(examinationServices) => this.setState({ examination: { ...this.state.examination, examinationServices: examinationServices } })}
                                                onValidationChange={(validation) => this.setState({ examinationServicesValidation: validation })} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Card sx={{ padding: "24px !important", borderRadius: "8px", boxShadow: "0px 4px 23.5px 0px rgba(0, 0, 0, 0.08)" }}>
                        {isLoading ? <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box> : null}
                        <CardHeader
                            title={this.props.t("history_examination")}
                            action={<Stack direction="row" spacing={1.5}>
                                <Button
                                    sx={{ height: "42px" }}
                                    title={this.props.t("export_to_docx")}
                                    color="primary"
                                    onClick={() => this.props.exportExaminationsToDocx(currentPatient.id)}
                                    startIcon={<img src={ExportXLSIcon} alt="export-icon" />}
                                    disabled={examinations.length === 0}>
                                    {this.props.t("export_to_docx")}
                                </Button>
                                <Button
                                    sx={{ height: "42px" }}
                                    title={this.props.t("export_to_pdf")}
                                    color="error"
                                    onClick={() => this.props.exportExaminationsToPdf(currentPatient.id)}
                                    startIcon={<img src={ExportPDFIcon} alt="export-icon" />}
                                    disabled={examinations.length === 0}>
                                    {this.props.t("export_to_pdf")}
                                </Button>
                            </Stack>}>
                        </CardHeader>
                        <CardContent sx={{ paddingLeft: "0px !important", paddingRight: "0px !important" }}>
                            {examinations && <Stack direction="row" spacing={1.5} sx={{ alignItems: "center", marginBottom: "16px" }}>
                                <TextField
                                    id="search"
                                    name="search"
                                    value={examinationsFilter}
                                    onChange={this.handleChangeFilter}
                                    placeholder={this.props.t("search_for_examination_history")}
                                    onKeyUp={(event) => {
                                        if (event.key === "Enter") {
                                            this.filterExaminations()
                                        }
                                    }} />
                                <Button sx={{ height: "40px", width: "200px" }} color="primary" startIcon={<img src={SearchIcon} alt="search-icon" />} onClick={() => this.filterExaminations()}>
                                    {this.props.t("search")}
                                </Button>
                                {examinations && examinations.length > 0 &&
                                    <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="bottom-end" title={
                                        <span>
                                            <p>{this.props.t("examination_number_doctors")}:</p>
                                            {examinationsCount && examinationsCount.map((c, index) => {
                                                return <li key={index}>{c.doctorsFirstName + " " + c.doctorsLastName + ": " + c.examinationCount}</li>
                                            })}
                                        </span>}>
                                        <Info style={{ fontSize: '25px', cursor: 'pointer', color: '#6F8684' }} />
                                    </Tooltip>}
                            </Stack>}
                            {examinations.map((data, index) => {
                                return <Accordion className={index === this.state.collapseIndex ? 'accordion-custom-body-background' : null} TransitionProps={{ unmountOnExit: true }} onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })} key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls={"panel-content" + index}
                                        id={"panel-header" + index}
                                        style={{ alignItems: 'center', display: 'flex' }}>
                                        <Typography>
                                            {moment(data.createdDate).format("DD.MM.YYYY. HH:mm")}
                                        </Typography>
                                        <Typography sx={{ flexGrow: 1 }}>
                                            <span style={{ fontWeight: 900, fontStyle: "italic", marginLeft: "24px", marginRight: "8px" }}>
                                                {this.props.t("created_by")}:
                                            </span>
                                            {this.props.authReducer.user.email === data.createdBy ? this.props.t("you") : data.createdBy}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ backgroundColor: "#FFFFFF", padding: "12px" }}>
                                            <Typography sx={{ fontWeight: 900, fontStyle: "italic" }}>
                                                {this.props.t("diagnosis")}
                                            </Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>{data.diagnoses && data.diagnoses.map(data => { return data.name }).join(", ")}</Typography>
                                        </Box>
                                        <Box sx={{ backgroundColor: "#FFFFFF", padding: "12px" }}>
                                            <Typography sx={{ fontWeight: 900, fontStyle: "italic", whiteSpace: 'pre-line', alignItems: 'center', display: 'flex' }}>
                                                {this.props.t("therapy_comment")}
                                            </Typography>
                                            <Typography sx={{ whiteSpace: 'pre-line', alignItems: 'center', display: 'flex' }}>
                                                {data.comment}
                                            </Typography>
                                        </Box>
                                    </AccordionDetails>
                                    <AccordionActions sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
                                        <Stack direction="row" spacing={1.5} sx={{ alignItems: "center", marginLeft: 'auto'/* , width: "100%" */ }}>
                                            <Button
                                                sx={{ height: "42px" }}
                                                title={this.props.t("export_to_docx")}
                                                color="primary"
                                                onClick={() => this.props.exportExaminationByIdToDocx(data.patient.id)}
                                                startIcon={<img src={ExportXLSIcon} alt="export-icon" />}
                                                disabled={examinations.length === 0}>
                                                {this.props.t("export_to_docx")}
                                            </Button>
                                            <Button
                                                sx={{ height: "42px" }}
                                                title={this.props.t("export_to_pdf")}
                                                color="error"
                                                onClick={() => this.props.exportExaminationByIdToPdf(data.id)}
                                                startIcon={<img src={ExportPDFIcon} alt="export-icon" />}
                                                disabled={examinations.length === 0}>
                                                {this.props.t("export_to_pdf")}
                                            </Button>
                                            <Button
                                                sx={{ height: "42px" }}
                                                title={this.props.t("edit")}
                                                className="default-button-edit"
                                                endIcon={<img src={EditIcon} alt="edit-icon" />}
                                                onClick={() => this.getExaminationById(data.id)}>
                                                {this.props.t("edit")}
                                            </Button>
                                            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.DOCTOR]) &&
                                                <Button
                                                    sx={{ height: "42px" }}
                                                    disabled={!this.disabledDeleteOption(data)}
                                                    className="default-button-delete"
                                                    endIcon={<img src={DeleteIcon} alt="delete-icon" />}
                                                    onClick={() => this.setState({ openDeleteDialog: true, examinationToDelete: data })}>
                                                    {this.props.t("delete")}
                                                </Button>}
                                        </Stack>
                                    </AccordionActions>
                                </Accordion>
                            })}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <ExaminationPatientFilesModal
                isOpen={this.state.modalOpen}
                onClose={this.closeDialog}
                patientId={this.props.match.params.id}
            />
            <ConfirmationDialog
                isOpen={this.state.openDeleteDialog}
                title={this.props.t("delete_examination")}
                message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_examination_message") }} />}
                onClose={() => this.setState({ openDeleteDialog: false })}
                onConfirm={() => this.deleteExamination()}
                closeButtonTitle={this.props.t("close")}
                confirmButtonTitle={this.props.t("delete")}
            />
        </Box >
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer,
    serviceReducer: state.serviceReducer,
    examinationReducer: state.examinationReducer,
    diagnosisReducer: state.diagnosisReducer,
    authReducer: state.authReducer
})

const mapActionsToProps = { getServiceList, getExaminations, createExamination, updateExamination, getExaminationById, getDiagnosesList, searchExaminations, exportExaminationByIdToPdf, exportExaminationByIdToDocx, exportExaminationsToDocx, getPatientById, exportExaminationsToPdf, updatePatient, getPatientFiles, deleteExamination, getExaminationCountPerDoctor }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Examination))
