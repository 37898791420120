import React, { Component } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { withTranslation } from 'react-i18next';

class WrappedAutocomplete extends Component {

    render() {
        return (
            <Autocomplete
                freeSolo={this.props.freeSolo}
                disabled={this.props.disabled}
                multiple={this.props.multiple}
                value={this.props.value}
                label={this.props.label}
                id={this.props.id}
                name={this.props.name}
                size="small"
                options={this.props.options}
                onChange={(e, obj) => this.props.onChange(e, obj)}
                onInputChange={(e, obj) => this.props.freeSolo && this.props.onChange(this.props.name, obj)}
                clearText={this.props.t("clear")}
                closeText={this.props.t("close")}
                openText={this.props.t("open")}
                getOptionLabel={this.props.getOptionLabel}
                getOptionDisabled={this.props.getOptionDisabled}
                isOptionEqualToValue={this.props.isOptionEqualToValue}
                renderOption={this.props.renderOption}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={this.props.label}
                        fullWidth
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off', spellCheck: false // disable autocomplete and autofill
                        }}
                        placeholder={this.props.placeholder}
                        required={this.props.required}
                        error={this.props.error}
                        helperText={this.props.helperText}
                        onKeyDown={this.props.onKeyDown ? (e) => this.props.onKeyDown(e) : null}
                    />
                )}
            />
        );
    }
}

export default withTranslation()(WrappedAutocomplete);
