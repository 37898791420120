import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
    IconButton
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import {
    getListOfItems
} from '../../../redux/warehouse/actions';
import {
    createNormative, updateNormative, getNormativeById, getListOfNorms
} from '../../../redux/normative/actions';
import WrappedAutocomplete from '../../../components/common/WrappedAutocomplete';
import { NumericFormat } from 'react-number-format';
import { getServiceList } from '../../../redux/service/actions'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash_icon.svg';

class ManageNormative extends Component {

    emptyNormative = {
        serviceId: null,
        items: [],
        warehouseId: this.props.match.params.warehouseId
    }

    emptyItem = {
        itemId: null,
        quantity: null,
        comment: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            initialNormative: JSON.parse(JSON.stringify(this.emptyNormative)),
            normative: JSON.parse(JSON.stringify(this.emptyNormative)),
            selectedService: null,
            validation: {
                serviceError: false,
                serviceErrorText: ""
            }
        };
    }

    componentDidMount() {
        this.props.getListOfNorms(this.props.match.params.warehouseId);
        this.props.getServiceList();
        this.props.getListOfItems(this.props.match.params.warehouseId)
        if (this.props.match.params.id) {
            this.props.getNormativeById(this.props.match.params.warehouseId, this.props.match.params.id).then(response => {
                let data = {
                    serviceId: response.data[0].serviceId,
                    items: response.data.map(res => { return { itemId: res.itemId, quantity: res.quantity, comment: res.comment } }),
                    warehouseId: this.props.match.params.warehouseId
                }
                this.setState({
                    normative: JSON.parse(JSON.stringify(data)),
                    initialNormative: JSON.parse(JSON.stringify(data)),
                    selectedService: this.props.serviceReducer.serviceList.find(s => s.id === data.serviceId)
                }, () => {
                    this.validate("service", this.state.normative.serviceId);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("service", this.state.normative.serviceId);
            this.setState({ mainLoading: false });
        }
    }

    addNewItem = () => {
        let { normative } = this.state;
        normative.items.push(JSON.parse(JSON.stringify(this.emptyItem)));
        this.setState({ normative: normative });
    }

    handleChangeQuantity = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { normative } = this.state;
            normative.items[index][sourceInfo.event.target.name] = floatValue;
            this.setState({ normative: normative })
        }
    }

    handleChangeComment = (e, name, index) => {
        let { normative } = this.state;
        normative.items[index][name] = e.target.value.trimStart();
        this.setState({ normative: normative })
    }

    handleChangeService = (e, service) => {
        let { normative } = this.state;
        if (service !== null) {
            normative.serviceId = service.id
            this.setState({ selectedService: service, normative: normative })
        } else {
            normative.serviceId = null
            this.setState({ selectedService: null, normative: normative })
        }
        this.validate("service", normative);
    }

    handleChangItem = (e, item, index) => {
        let { normative } = this.state;
        let items = {};
        if (item !== null) {
            items.itemId = item.id
            items.quantity = 1
            items.comment = ''
            normative.items[index] = items;
        } else {
            items.itemId = null
            items.quantity = null
            items.comment = ''
            normative.items[index] = items;
        }
        this.setState({ normative: normative })
    }

    removeItem = (index) => {
        let { normative } = this.state;
        normative.items.splice(index, 1);
        this.setState({ normative: normative });
    }

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "service":
                if (value === null) {
                    validation.serviceError = true;
                    validation.serviceErrorText = this.props.t("required_field_message");
                } else {
                    validation.serviceError = false;
                    validation.serviceErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageNormative = () => {
        const { normative } = this.state;
        const action = this.props.match.params.id ?
            this.props.updateNormative(this.props.match.params.id, normative) :
            this.props.createNormative(normative);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialNormative: JSON.parse(JSON.stringify(this.emptyNormative)),
                    normative: JSON.parse(JSON.stringify(this.emptyNormative))
                }, () => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/normatives`));
            }
        })
    };

    disable() {
        let { validation, normative, initialNormative } = this.state;
        if (!normative.serviceId || normative.items.length === 0 ||
            JSON.stringify(normative) === JSON.stringify(initialNormative) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        for (let i = 0; i < normative.items.length; i++) {
            if (normative.items[i].itemId === null || !normative.items[i].quantity) {
                return true;
            }
        }
        return false;
    }

    render() {

        let { initialNormative, normative, selectedService, validation, mainLoading } = this.state
        const { serviceList } = this.props.serviceReducer
        const { itemList } = this.props.warehouseReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        let items = normative.items.map((item, index) => {
            return <React.Fragment key={index}>
                <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputLabel>
                        {this.props.t("item")}  <span style={{ color: "#D32F2F" }}>*</span>
                    </InputLabel>
                    <WrappedAutocomplete
                        id="selectedItem"
                        name="selectedItem"
                        value={itemList.find(i => i.id === item.itemId) ? itemList.find(i => i.id === item.itemId) : null}
                        getOptionLabel={(option) => `${option.name} (${option.unitOfMeasureSymbol})`}
                        options={itemList}
                        placeholder={this.props.t("select")}
                        onChange={(event, item) => {
                            this.handleChangItem(event, item, index)
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionDisabled={(option) => normative.items.some(n => n.itemId === option.id)}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                                {option.name}
                            </Box>
                        )}
                        error={item.itemId === null}
                        helperText={item.itemId === null ? this.props.t("required_field_message") : null}
                    />
                </Grid>
                <Grid item container direction="column" xs={2} sm={2} md={2} lg={2} xl={2}>
                    <InputLabel>
                        {this.props.t("quantity")}  <span style={{ color: "#D32F2F" }}>*</span>
                    </InputLabel>
                    <NumericFormat
                        id="quantity"
                        name="quantity"
                        customInput={TextField}
                        decimalScale={2}
                        value={normative.items[index].quantity || ''}
                        onValueChange={(values, sourceInfo) => this.handleChangeQuantity(values, sourceInfo, index)}
                        allowNegative={false}
                        disabled={normative.items[index].itemId === null}
                        error={!normative.items[index].quantity}
                        helperText={!normative.items[index].quantity ? this.props.t("required_field_message") : null}
                    />
                </Grid>
                <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4}>
                    <InputLabel>
                        {this.props.t("comment")}
                    </InputLabel>
                    <Stack direction="row" spacing={2} sx={{ display: "flex", alignItems: "center" }} mt={1}>
                        <TextField
                            name="comment"
                            value={normative.items[index].comment || ''}
                            onChange={(e) => this.handleChangeComment(e, "comment", index)}
                            disabled={normative.items[index].serviceId === null}
                            error={normative.items[index].comment && normative.items[index].comment.length > 255 ? true : false}
                            helperText={normative.items[index].comment && normative.items[index].comment.length > 255 && this.props.t("number_regex_message", { max: 255 })}
                            inputProps={{ autoComplete: 'off', spellCheck: false }} />
                        <IconButton
                            title="Add"
                            aria-label="add"
                            onClick={() => this.removeItem(index)}
                        >
                            <TrashIcon />
                        </IconButton>
                    </Stack>
                </Grid>
            </React.Fragment>
        })

        return (
            <Box className="custom-box">
                <Prompt when={JSON.stringify(initialNormative) !== JSON.stringify(normative)} message={this.props.t("prompt_message")} />
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={8}>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_normative") : this.props.t("new_normative")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/normatives`)}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageNormative} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("select_service")}<span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <FormControl>
                                                <WrappedAutocomplete
                                                    id="selectedService"
                                                    name="selectedService"
                                                    value={selectedService}
                                                    getOptionLabel={(option) => `${option.name}`}
                                                    options={serviceList}
                                                    onChange={(event, service) => {
                                                        this.handleChangeService(event, service)
                                                    }}
                                                    disabled={this.props.match.params.id !== undefined ? true : false}
                                                    placeholder={this.props.t("select")}
                                                    error={validation.serviceError}
                                                    helperText={validation.serviceErrorText}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    getOptionDisabled={(option) => this.props.normativeReducer.listOfNorms.some(n => n.serviceId === option.id)}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.id}>
                                                            {option.name}
                                                        </li>
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Button
                                                className="create-btn"
                                                sx={{ marginLeft: "auto" }}
                                                startIcon={<AddIcon />}
                                                onClick={() => this.addNewItem()}
                                            >
                                                {this.props.t("add_item")}
                                            </Button>
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Grid container direction="row" spacing={2}>
                                                {items}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }
}
const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer,
    warehouseReducer: state.warehouseReducer,
    normativeReducer: state.normativeReducer
})

const mapActionsToProps = { getListOfItems, createNormative, updateNormative, getNormativeById, getServiceList, getListOfNorms }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageNormative)))