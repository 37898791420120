
import store from '../redux/store';

export const hasRole = (role) => {
    const user = store.getState().authReducer.user;
    return user ? user.role.includes(role) : false;
}

export const hasAnyRole = (roles) => {
    const user = store.getState().authReducer.user;
    return user ? roles.some((r) => user.role.includes(r)) : false;
}

export const UserRole = {
    SUPERADMIN: "SUPERADMIN",
    ADMIN: "ADMIN",
    DOCTOR: "DOCTOR",
    SYSTEM: "SYSTEM",
    RECEPTIONIST: "RECEPTIONIST",
    SIGNER: "SIGNER"
}