import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../assets/themes/theme.js';
import ConfirmationDialog from "./ConfirmationDialog";

const UserConfirmation = (message, callback, props) => {
    let root = document.getElementById("root");
    let container = document.createElement("div");
    document.body.appendChild(container);
    const closeModal = (callbackState) => {
        root.unmount(container);
        document.body.removeChild(container);
        callback(callbackState);
    };
    root = createRoot(container);
    root.render(
        <ThemeProvider theme={theme}>
            <ConfirmationDialog
                isOpen={true}
                title={props.t("prompt_title")}
                message={message}
                onClose={() => closeModal(false)}
                onConfirm={() => closeModal(true)}
                closeButtonTitle={props.t("stay")}
                confirmButtonTitle={props.t("leave")}
            />
        </ThemeProvider>
    );
};
export default UserConfirmation;