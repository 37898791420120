import { PhoneNumberUtil } from 'google-libphonenumber';

export const FIRST_NAME_REGEX = /^[a-zA-Z ćĆčČđĐšŠžŽ]{1,50}$/;
export const LAST_NAME_REGEX = /^[a-zA-Z ćĆčČđĐšŠžŽ]{1,50}$/;
export const PHONE_REGEX = /^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{3,9}$/im;
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const CODE_REGEX = /^.{1,50}$/;
export const NAME_REGEX = /^.{1,255}$/;
export const COMMENT_EXAMINATION_REGEX = /^.{2,2000}$/;
export const COMMENT_REGEX = /^.{1,500}$/;
export const INVOICE_REF_NUMBER_REGEX = /^.{1,30}$/;
export const NAME_SHORT_REGEX = /^.{1,50}$/;
export const MBO_REGEX = /^[0-9]{9,9}$/;

const phoneUtil = PhoneNumberUtil.getInstance();

export const isPhoneValid = (phone) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
        return false;
    }
};

// https://github.com/domagojpa/oib-validation/blob/main/JavaScript/oib-validation.js
export const isOibValid = (input) => {
    const oib = input.toString();

    if (oib.match(/\d{11}/) === null) {
        return false;
    }

    let calculated = 10;

    for (const digit of oib.substring(0, 10)) {
        calculated += parseInt(digit);

        calculated %= 10;

        if (calculated === 0) {
            calculated = 10;
        }

        calculated *= 2;

        calculated %= 11;
    }

    let check = 11 - calculated;

    if (check === 10) {
        check = 0;
    }

    return check === parseInt(oib[10]);
}
