import axios from '../axiosConfig';

export function getUsers(config = {}) {
    return axios.get(`/v1/user`, config);
}

export function getRoles() {
    return axios.get(`/v1/user/roles`);
}

export function getUserById(id, config = {}) {
    return axios.get(`/v1/user/${id}`, config);
}

export function getUserList(config = {}) {
    return axios.get(`/v1/user/list`, config);
}

export function getDoctors(config = {}) {
    return axios.get(`/v1/user/doctors`, config);
}

export function createUser(data) {
    return axios.post(`/v1/user`, data);
}

export function updateUser(id, data) {
    return axios.patch(`/v1/user/${id}`, data);
}

export function deleteUser(id) {
    return axios.delete(`/v1/user/${id}`);
}

export function importDoctorSignature(id, data) {
    return axios.post(`/v1/users-file/doctor-signature/${id}`, data);
}

export function getDoctorSignature(id, config = {}) {
    return axios.get(`/v1/users-file/doctor-signature/${id}`, config);
}

export function deleteDoctorSignature(id, config = {}) {
    return axios.delete(`/v1/users-file/doctor-signature/${id}`, config);
}


const actions = {
    getUsers,
    getUserById,
    getRoles,
    getDoctors,
    createUser,
    updateUser,
    deleteUser,
    importDoctorSignature,
    getDoctorSignature,
    getUserList,
    deleteDoctorSignature
}

export default actions;
