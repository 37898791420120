import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down.svg';
import { getNormatives, setNormativesPage, setNormativesSize, setNormativesParams, deleteNormative, updateNormative } from '../../../redux/normative/actions'
import { getServiceList } from '../../../redux/service/actions'
import Base58Table from '../../../components/common/Base58Table';
import types from '../../../redux/normative/types';
import store from '../../../redux/store';
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';

class Normatives extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            normativeToDelete: null,
            collapseIndex: -1,
            normativeItemToDelete: false,
            openDeleteItemDialog: false
        };
    }

    componentDidMount() {
        this.props.getNormatives(this.props.match.params.warehouseId);
        this.props.getServiceList()
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.RESET_NORMATIVE_DATA
        })
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, normativeToDelete: null });
    }

    closeDeleteItemDialog = () => {
        this.setState({ openDeleteItemDialog: false, normativeItemToDelete: null });
    }

    updateNormative = () => {
        this.props.updateNormative(this.state.normativeToDelete.serviceId, { items: [], warehouseId: this.props.match.params.warehouseId }).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteDialog: false, collapseIndex: -1 }, () => this.props.setNormativesPage(this.props.match.params.warehouseId, 0));
            }
        })
    }

    deleteNormative = () => {
        this.props.deleteNormative(this.state.normativeItemToDelete.id,).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteItemDialog: false }, () => this.props.setNormativesPage(this.props.match.params.warehouseId, 0));
            }
        })
    }

    render() {
        const { collapseIndex, normativeToDelete, normativeItemToDelete } = this.state
        const { normativesLoading, normatives, normativesParams, normativesTotalElements } = this.props.normativeReducer;

        let normativeList;
        if (normatives && normatives.length > 0) {
            normativeList = normatives.map((normative, index) => {
                return <React.Fragment key={index}>
                    <TableRow>
                        <TableCell align='center'>
                            <IconButton size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                                {index !== collapseIndex ? <ArrowDown /> : <ArrowUp />}
                            </IconButton>
                        </TableCell>
                        <TableCell>{normative.serviceName}</TableCell>
                        <TableCell className='row-cell' align='center'>{
                            <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                                <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/normatives/${normative.serviceId}`)}>
                                    {this.props.t("edit")}
                                </Button>
                                <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ normativeToDelete: normative, openDeleteDialog: true })}>
                                    {this.props.t("delete")}
                                </Button>
                            </Stack>
                        }</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ border: "none", paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={7}>
                            <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit>
                                <Box margin={1}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={"base58-table-header-cell"} width='50%'>{this.props.t("item")}</TableCell>
                                                    <TableCell className={"base58-table-header-cell"} width='15%'>{this.props.t("quantity")}</TableCell>
                                                    <TableCell className={"base58-table-header-cell"} width='20%'>{this.props.t("comment")}</TableCell>
                                                    <TableCell className={"base58-table-header-cell"} width='15%'></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {normative.items.map((item, index) => {
                                                    return <TableRow key={index}>
                                                        <TableCell>{item.itemName}</TableCell>
                                                        <TableCell>{item.quantity}</TableCell>
                                                        <TableCell>{item.comment ? item.comment : '-'}</TableCell>
                                                        <TableCell className='row-cell' align='center'>{
                                                            <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                                                                <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ normativeItemToDelete: item, openDeleteItemDialog: true })}>
                                                                    {this.props.t("delete")}
                                                                </Button>
                                                            </Stack>
                                                        }</TableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            })
        }

        let columns = [
            {
                name: "",
                width: '5%'
            },
            {
                name: "service",
                dbName: 'serviceName',
                width: '75%',
            },
            {
                name: "",
                width: '20%'
            }
        ]

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }}>
                            <Button
                                className="create-btn"
                                sx={{ marginLeft: 'auto' }}
                                startIcon={<AddIcon />}
                                onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/normatives/new`)}
                            >
                                {this.props.t("new_normative")}
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Base58Table
                            isLoading={normativesLoading}
                            columns={columns}
                            data={normativeList}
                            rowsPerPageOptions={[10, 20, 30, 40]}
                            count={normativesTotalElements}
                            rowsPerPage={normativesParams.size}
                            page={normativesParams.page}
                            onPageChange={(e, page) => this.props.setNormativesPage(this.props.match.params.warehouseId, page)}
                            onRowsPerPageChange={(e) => this.props.setNormativesSize(this.props.match.params.warehouseId, e.target.value)}
                            onFilterChange={(params) => this.props.setNormativesParams(this.props.match.params.warehouseId, { ...params, page: 0 })}
                        />
                    </Grid>
                </Grid>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_normative")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_normative_message", { name: normativeToDelete && normativeToDelete.serviceName }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.updateNormative()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
                <ConfirmationDialog
                    isOpen={this.state.openDeleteItemDialog}
                    title={this.props.t("delete_service_item")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_service_item_message", { name: normativeItemToDelete && normativeItemToDelete.itemName }) }} />}
                    onClose={() => this.setState({ openDeleteItemDialog: false })}
                    onConfirm={() => this.deleteNormative()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    normativeReducer: state.normativeReducer
})

const mapActionsToProps = { getNormatives, setNormativesPage, setNormativesSize, setNormativesParams, getServiceList, deleteNormative, updateNormative }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(Normatives)))