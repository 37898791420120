import React, { Component } from 'react';
import { Box, Button, DialogContent, Grid, Stack, Typography } from '@mui/material'
import { BootstrapDialog, Transition } from './Dialog';

class ConfirmationDialog extends Component {

    render() {
        return (

            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                maxWidth="md"
            >
                <DialogContent>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.title}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.onClose()}>
                                       {this.props.closeButtonTitle}
                                    </Button>
                                    <Button className="create-btn" color="primary" onClick={() => this.props.onConfirm()}>
                                        {this.props.confirmButtonTitle}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    {this.props.message}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

export default ConfirmationDialog;