import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Grid,
    Link,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';
import ItemCategories from './category/ItemCategories';
import UnitOfMeasurements from './unit-of-measurement/UnitOfMeasurements';
import Items from './item/Items';
import Documents from './document/Documents';
import { ReactComponent as CategoryActiveIcon } from '../../assets/icons/warehouse/category_active.svg';
import { ReactComponent as CategoryInactiveIcon } from '../../assets/icons/warehouse/category_inactive.svg';
import { ReactComponent as UnitActiveIcon } from '../../assets/icons/warehouse/unit_active.svg';
import { ReactComponent as UnitInactiveIcon } from '../../assets/icons/warehouse/unit_inactive.svg';
import { ReactComponent as ItemsActiveIcon } from '../../assets/icons/warehouse/items_active.svg';
import { ReactComponent as ItemsInactiveIcon } from '../../assets/icons/warehouse/items_inactive.svg';
import { ReactComponent as DocumentsActiveIcon } from '../../assets/icons/warehouse/documents_active.svg';
import { ReactComponent as DocumentsInactiveIcon } from '../../assets/icons/warehouse/documents_inactive.svg';
import { ReactComponent as StatesInactiveIcon } from '../../assets/icons/warehouse/state_inactive.svg';
import { ReactComponent as StatesActiveIcon } from '../../assets/icons/warehouse/state_active.svg';
import { ReactComponent as NormativesInactiveIcon } from '../../assets/icons/warehouse/normatives_inactive.svg';
import { ReactComponent as NormativesActiveIcon } from '../../assets/icons/warehouse/normatives_active.svg';
import { getWarehouseById } from '../../redux/warehouse/actions';
import Normatives from './normative/Normatives';
import State from './state/State';

class WarehouseOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedWarehouse: null,
            selectedTab: -1
        };
    }

    componentDidMount() {
        if (this.props.match.params.warehouseId) {
            this.props.getWarehouseById(this.props.match.params.warehouseId).then(response => {
                this.setState({ selectedWarehouse: response });
            });
        }

        switch (this.props.match.params.option) {
            case 'categories':
                return this.handleChangeTab('categories', 0);
            case 'measurement-units':
                return this.handleChangeTab('measurement-units', 1);
            case 'items':
                return this.handleChangeTab('items', 2);
            case 'documents':
                return this.handleChangeTab('documents', 3);
            case 'state':
                return this.handleChangeTab('state', 4);
            case 'normatives':
                return this.handleChangeTab('normatives', 5);
            default:
                return this.handleChangeTab('categories', 0);
        }
    }

    handleChangeTab = (name, value) => {
        this.setState({ selectedTab: value }, () => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/${name}`));
    }

    render() {

        const { selectedTab, selectedWarehouse } = this.state;

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack direction="row" spacing={1}>
                            <Link component="button" onClick={() => this.props.history.push("/warehouses")}>
                                <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                    {this.props.t("warehouses")}
                                </Typography>
                            </Link>
                            <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                / {selectedWarehouse && selectedWarehouse.name}
                            </Typography>
                        </Stack>
                        <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ borderBottom: 1, border: 'none' }}>
                            {selectedTab > -1 && <Tabs value={selectedTab} onChange={(e, value) => this.handleChangeTab(e.target.name, value)}
                                TabIndicatorProps={{
                                    sx: { display: 'none' }
                                }}>
                                <Tab id="categories" name="categories" icon={selectedTab === 0 ? <CategoryInactiveIcon /> : <CategoryActiveIcon />} iconPosition="start" label={this.props.t("categories")} {...a11yProps(0)} wrapped />
                                <Tab id="measurement-units" name="measurement-units" icon={selectedTab === 1 ? <UnitInactiveIcon /> : <UnitActiveIcon />} iconPosition="start" label={this.props.t("measurement_units")} {...a11yProps(1)} wrapped />
                                <Tab id="items" name="items" icon={selectedTab === 2 ? <ItemsInactiveIcon /> : <ItemsActiveIcon />} iconPosition="start" label={this.props.t("items")} {...a11yProps(2)} wrapped />
                                <Tab id="documents" name="documents" icon={selectedTab === 3 ? <DocumentsInactiveIcon /> : <DocumentsActiveIcon />} iconPosition="start" label={this.props.t("documents")} {...a11yProps(3)} wrapped />
                                <Tab id="state" name="state" icon={selectedTab === 4 ? <StatesInactiveIcon /> : <StatesActiveIcon />} iconPosition="start" label={this.props.t("state")} {...a11yProps(4)} wrapped />
                                <Tab id="normatives" name="normatives" icon={selectedTab === 5 ? <NormativesInactiveIcon /> : <NormativesActiveIcon />} iconPosition="start" label={this.props.t("normatives")} {...a11yProps(5)} wrapped />
                            </Tabs>}
                        </Box>
                    </Grid>
                    {this.props.match.params.warehouseId && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomTabPanel value={selectedTab} index={0} style={{ width: "100%" }}>
                            <ItemCategories />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1} style={{ width: "100%" }}>
                            <UnitOfMeasurements />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={2} style={{ width: "100%" }}>
                            <Items />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={3} style={{ width: "100%" }}>
                            <Documents />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={4} style={{ width: "100%" }}>
                            <State />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={5} style={{ width: "100%" }}>
                            <Normatives />
                        </CustomTabPanel>
                    </Grid>}
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = { getWarehouseById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(WarehouseOptions)))
