import React from "react";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom";
import {
    Button,
    Grid,
    TextField,
    Box,
    IconButton,
    TableRow,
    TableCell,
    Checkbox,
    Stack
} from "@mui/material";
import { withTranslation } from "react-i18next";
import {
    getListOfItems,
    getSuppliersByWarehouseId,
    getWarehouseCurrentState
} from "../../../redux/warehouse/actions";
import { NAME_REGEX } from "../../../components/common/regex";
import WrappedAutocomplete from "../../../components/common/WrappedAutocomplete";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { ReactComponent as AddIcon } from "../../../assets/icons/add_icon.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash_icon.svg";
import WrappedDatePicker from "../../../components/common/WrappedDatePicker";
import Base58Table from "../../../components/common/Base58Table";
import Enum from "./DocumentEnums";
import { History } from '@mui/icons-material';

class DocumentItems extends React.Component {

    emptyDocumentItem = {
        itemId: null,
        supplier: "",
        quantity: null,
        price: null,
        expiryDate: "",
        serialNo: "",
        comment: "",
        documentationComment: "",
        serviceComment: "",
    }

    emptyValidation = {
        itemIdError: false,
        itemIdErrorText: "",
        supplierError: false,
        supplierErrorText: "",
        priceError: false,
        priceErrorText: "",
        quantityError: false,
        quantityErrorText: "",
        dateError: false,
        dateErrorText: "",
        serialNoError: false,
        serialNoErrorText: "",
        commentError: false,
        commentErrorText: "",
        documentationCommentError: false,
        documentationCommentErrorText: "",
        serviceCommentError: false,
        serviceCommentErrorText: ""
    }

    constructor(props) {
        super(props);
        this.state = {
            suppliers: [],
            initialDocumentItems: [],
            documentItems: [],
            validations: [],
            selectedItems: []
        };
    }

    componentDidMount() {
        this.props.getListOfItems(this.props.match.params.warehouseId)
        this.props.getSuppliersByWarehouseId(this.props.match.params.warehouseId).then(response => {
            this.setState({ suppliers: response.data });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                documentItems: this.props.data,
                initialDocumentItems: JSON.parse(JSON.stringify(this.props.data)),
                validations: this.props.data.map(() => JSON.parse(JSON.stringify(this.emptyValidation)))
            }, () => {
                this.state.documentItems.forEach((di, index) => {
                    Object.entries(di).forEach(([key, value]) => {
                        this.validate(key, value, index);
                    });
                });
            });
        }
    }

    getWarehouseCurrentState = () => {
        this.props.getWarehouseCurrentState(this.props.match.params.warehouseId).then(response => {
            const currentItems = response.map(item => {
                return { ...item, itemId: item.id };
            });
            this.setState({
                documentItems: currentItems,
                initialDocumentItems: JSON.parse(JSON.stringify(currentItems)),
                validations: currentItems.map(() => JSON.parse(JSON.stringify(this.emptyValidation)))
            }, () => {
                this.state.documentItems.forEach((di, index) => {
                    Object.entries(di).forEach(([key, value]) => {
                        this.validate(key, value, index);
                    });
                });
            });
        });
    }

    addNewItem = () => {
        if ((this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN) {
            let { documentItems, suppliers, validations } = this.state;
            if (documentItems.length > 0) {
                const lastEnteredSupplier = documentItems[documentItems.length - 1].supplier;
                if (!suppliers.some(s => s === lastEnteredSupplier)) {
                    suppliers.push(lastEnteredSupplier);
                }
            }
            documentItems.push(JSON.parse(JSON.stringify(this.emptyDocumentItem)));
            validations.push(JSON.parse(JSON.stringify(this.emptyValidation)));
            this.setState({ documentItems: documentItems, validations: validations }, () => {
                this.props.onChange(documentItems);
                this.props.onValidationChange(validations);
                Object.entries(documentItems[documentItems.length - 1]).forEach(([key, value]) => {
                    this.validate(key, value, documentItems.length - 1);
                });
            });
        }
        if (this.props.documentType === Enum.DocumentTypes.MANUAL && this.props.type === Enum.Types.OUT) {
            this.props.onItemsDialogOpen();
        }
    }

    removeItem = (index) => {
        let { documentItems, validations } = this.state;
        documentItems.splice(index, 1);
        validations.splice(index, 1);
        this.setState({ documentItems: documentItems, validations: validations }, () => {
            this.props.onChange(documentItems);
            this.props.onValidationChange(validations);
        });
    }

    validate = (field, value, index) => {
        let { validations } = this.state;
        switch (field) {
            case "itemId":
                if (!value) {
                    validations[index].itemIdError = true;
                    validations[index].itemIdErrorText = this.props.t("required_field_message");
                } else {
                    validations[index].itemIdError = false;
                    validations[index].itemIdErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "supplier":
                if (!value) {
                    validations[index].supplierError = true;
                    validations[index].supplierErrorText = this.props.t("required_field_message");
                } else {
                    validations[index].supplierError = false;
                    validations[index].supplierErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "quantity":
                if (!value) {
                    validations[index].quantityError = true;
                    validations[index].quantityErrorText = this.props.t("required_field_message");
                } else if (value === 0) {
                    validations[index].quantityError = true;
                    validations[index].quantityErrorText = this.props.t("quantity_regex_message");
                } else {
                    validations[index].quantityError = false;
                    validations[index].quantityErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "price":
                if (!value) {
                    validations[index].priceError = true;
                    validations[index].priceErrorText = this.props.t("required_field_message");
                } else {
                    validations[index].priceError = false;
                    validations[index].priceErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "expiryDate":
                if (!value) {
                    validations[index].expiryDateError = true;
                    validations[index].expiryDateErrorText = this.props.t("required_field_message");
                } else if (!value._isValid || moment(value).isBefore(new Date())) {
                    validations[index].expiryDateError = true;
                    validations[index].expiryDateErrorText = this.props.t("invalid_date");
                } else {
                    validations[index].expiryDateError = false;
                    validations[index].expiryDateErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "serialNo":
                if (!value) {
                    validations[index].serialNoError = true;
                    validations[index].serialNoErrorText = this.props.t("required_field_message");
                } else {
                    validations[index].serialNoError = false;
                    validations[index].serialNoErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "comment":
                if (value && value.length > 0 && !NAME_REGEX.test(value)) {
                    validations[index].commentError = true;
                    validations[index].commentErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validations[index].commentError = false;
                    validations[index].commentErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "documentationDocument":
                if (value && value.length > 0 && !NAME_REGEX.test(value)) {
                    validations[index].documentationCommentError = true;
                    validations[index].documentationCommentErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validations[index].documentationCommentError = false;
                    validations[index].documentationCommentErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "serviceComment":
                if (value && value.length > 0 && !NAME_REGEX.test(value)) {
                    validations[index].serviceCommentError = true;
                    validations[index].serviceCommentErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validations[index].serviceCommentError = false;
                    validations[index].serviceCommentErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            default:
                return;
        }
    }

    handleChangeItem = (name, item, index) => {
        let { documentItems } = this.state;
        if (item) {
            documentItems[index].itemId = item.id;
            documentItems[index].supplier = "";
            documentItems[index].quantity = 1;
            documentItems[index].price = null;
            documentItems[index].expiryDate = "";
            documentItems[index].serialNo = "";
            documentItems[index].comment = "";
            documentItems[index].documentationComment = "";
            documentItems[index].serviceComment = "";
        } else {
            documentItems[index] = JSON.parse(JSON.stringify(this.emptyDocumentItem));
        }
        this.setState({ documentItems: documentItems }, () => {
            this.props.onChange(documentItems);
            Object.entries(documentItems[index]).forEach(([key, value]) => {
                this.validate(key, value, index);
            });
        });
    }

    handleChangeSupplier = (name, value, index) => {
        let { documentItems } = this.state;
        documentItems[index][name] = value || "";
        this.setState({ documentItems: documentItems }, () => {
            this.props.onChange(documentItems);
            this.validate(name, documentItems[index][name], index);
        });
    }

    handleChangeQuantity = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { documentItems } = this.state;
            documentItems[index][sourceInfo.event.target.name] = floatValue;
            this.setState({ documentItems: documentItems }, () => {
                this.props.onChange(documentItems);
                this.validate(sourceInfo.event.target.name, documentItems[index][sourceInfo.event.target.name], index);
            });
        }
    }

    handleChangePrice = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { documentItems } = this.state;
            documentItems[index][sourceInfo.event.target.name] = floatValue;
            this.setState({ documentItems: documentItems }, () => {
                this.props.onChange(documentItems);
                this.validate(sourceInfo.event.target.name, documentItems[index][sourceInfo.event.target.name], index);
            });
        }
    }

    handleChangeExpiryDate = (date, index) => {
        let { documentItems } = this.state
        documentItems[index].expiryDate = date;
        this.setState({ documentItems: documentItems }, () => {
            this.props.onChange(documentItems);
            this.validate("expiryDate", documentItems[index]["expiryDate"], index);
        });
    }

    handleChange = (e, index) => {
        let { documentItems } = this.state;
        documentItems[index][e.target.name] = e.target.value.trimStart();
        this.setState({ documentItems: documentItems }, () => {
            this.props.onChange(documentItems);
            this.validate(e.target.name, documentItems[index][e.target.name], index);
        });
    }

    selectItem = (item) => {
        let { selectedItems } = this.state;
        const findIndex = selectedItems.findIndex(si => si.id === item.id);
        if (findIndex > -1) {
            selectedItems.splice(findIndex, 1);
        } else {
            selectedItems.push(item);
        }
        this.setState({ selectedItems: selectedItems }, () => this.props.onItemsSelection(selectedItems));
    }

    render() {

        let { suppliers, documentItems, validations, selectedItems } = this.state;
        const { itemList } = this.props.warehouseReducer;

        let columns = [];
        if ((this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN) {
            columns.push({
                name: "#",
                dbName: "#",
                width: "3%"
            });
        }

        if ((this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN) {
            columns.push({
                name: "item",
                dbName: "item",
                width: "11%",
                required: true
            });
        }

        if (this.props.documentType === Enum.DocumentTypes.MANUAL && this.props.type === Enum.Types.OUT) {
            columns.push({
                name: "document_reference_no",
                dbName: "item",
                width: "14%"
            });
        }

        columns = columns.concat([
            {
                name: "supplier",
                dbName: "supplier",
                width: "10%",
                required: (this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? true : false
            },
            {
                name: "quantity",
                dbName: "quantity",
                width: "7%",
                required: this.props.isAddItemDialogOpen ? false : true
            },
            {
                name: "price",
                dbName: "price",
                width: "6%",
                required: (this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? true : false
            },
            {
                name: "expiry_date",
                dbName: "expiryDate",
                width: "9%",
                required: (this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? true : false
            },
            {
                name: "serial_no",
                dbName: "serialNo",
                width: "7%",
                required: (this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? true : false
            },
            {
                name: "comment",
                dbName: "comment",
                width: "14%"
            },
            {
                name: "documentation_comment",
                dbName: "documentationComment",
                width: "14%"
            },
            {
                name: "service_comment",
                dbName: "serviceComment",
                width: "14%"
            },
            {
                name: "actions",
                dbName: "actions",
                width: "5%"
            }
        ]);

        console.log(this.props.documentType + "-" + this.props.type, columns.map(c => Number(c.width.replace("%", ""))).reduce((a, b) => a + b, 0))

        let data = [];
        if (documentItems && documentItems.length > 0) {
            data = documentItems.map((item, index) => {
                return <TableRow key={index}
                    sx={{
                        backgroundColor: item.quantity === 0 || (this.props.isAddItemDialogOpen && this.props.selectedItems && this.props.selectedItems.some(di => di.id === item.id))
                            ? "#D6D6D6" : "unset"
                    }}>
                    {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN && <TableCell>
                        {index + 1}
                    </TableCell>}
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <WrappedAutocomplete
                            id="selectedItem"
                            name="selectedItem"
                            value={itemList.find(i => i.id === item.itemId) ? itemList.find(i => i.id === item.itemId) : null}
                            getOptionLabel={(option) => `${option.name}`}
                            options={itemList}
                            onChange={(name, value) => this.handleChangeItem(name, value, index)}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionDisabled={option => documentItems.some(di => di.itemId === option.id)}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option.id}>
                                    {option.name}
                                </Box>
                            )}
                            placeholder={this.props.t("select")}
                            error={validations[index].itemIdError}
                        /> : (this.props.isAddItemDialogOpen ? (item.documentReferenceNo ? item.documentReferenceNo : "-") : (itemList.find(i => i.id === item.itemId) ? itemList.find(i => i.id === item.itemId).name : ""))}
                    </TableCell>
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <WrappedAutocomplete
                            freeSolo
                            id="supplier"
                            name="supplier"
                            value={item.supplier || ""}
                            getOptionLabel={(option) => `${option}`}
                            options={suppliers ? suppliers.filter(s => s !== null) : []}
                            onChange={(name, value) => this.handleChangeSupplier(name, value, index)}
                            placeholder={this.props.t("enter_or_select")}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={option}>
                                    {option}
                                </Box>
                            )}
                            disabled={!item.itemId}
                            error={validations[index].supplierError}
                        /> : (item.supplier ? item.supplier : "-")}
                    </TableCell>
                    <TableCell>
                        {((this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN) ||
                            (this.props.documentType === Enum.DocumentTypes.MANUAL && this.props.type === Enum.Types.OUT && !this.props.isAddItemDialogOpen) ?
                            <NumericFormat
                                id="quantity"
                                name="quantity"
                                customInput={TextField}
                                decimalScale={2}
                                value={item.quantity}
                                onValueChange={(values, sourceInfo) => this.handleChangeQuantity(values, sourceInfo, index)}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                disabled={!item.itemId}
                                error={validations[index].quantityError}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    if (floatValue) {
                                        if (this.props.documentType === Enum.DocumentTypes.MANUAL && this.props.type === Enum.Types.OUT && !this.props.isAddItemDialogOpen) {
                                            return floatValue <= this.state.initialDocumentItems[index].quantity;
                                        }
                                        return true;
                                    }
                                    if (floatValue === 0) return false;
                                    return true;
                                }}
                                placeholder={this.state.initialDocumentItems[index] && this.state.initialDocumentItems[index].quantity ? String(this.state.initialDocumentItems[index].quantity) : this.props.t("enter", { text: "" })}
                            /> : (item.quantity ? item.quantity : "-")}
                    </TableCell>
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <NumericFormat
                            id="price"
                            name="price"
                            customInput={TextField}
                            decimalScale={2}
                            suffix=" EUR"
                            decimalSeparator=","
                            value={item.price || ""}
                            onValueChange={(values, sourceInfo) => this.handleChangePrice(values, sourceInfo, index)}
                            placeholder={this.props.t("enter", { text: "" })}
                            allowNegative={false}
                            disabled={!item.itemId}
                            error={validations[index].priceError}
                        /> : (item.price ? item.price : "-")}
                    </TableCell>
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <WrappedDatePicker
                            id="expiryDate"
                            name="expiryDate"
                            required
                            minDate={moment()}
                            maxDate={moment().add(2, "year")}
                            value={item.expiryDate}
                            onChange={(date) => this.handleChangeExpiryDate(date, index)}
                            disabled={!item.itemId}
                            error={validations[index].expiryDateError}
                        /> : (item.expiryDate ? moment(item.expiryDate).format("DD.MM.YYYY.") : "-")}
                    </TableCell>
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <TextField
                            id="serialNo"
                            name="serialNo"
                            value={item.serialNo || ""}
                            onChange={(e) => this.handleChange(e, index)}
                            placeholder={this.props.t("enter", { text: "" })}
                            disabled={!item.itemId}
                            error={validations[index].serialNoError}
                        /> : (item.serialNo ? item.serialNo : "-")}
                    </TableCell>
                    <TableCell>
                        {((this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN) ||
                            (this.props.documentType === Enum.DocumentTypes.MANUAL && this.props.type === Enum.Types.OUT && !this.props.isAddItemDialogOpen) ? <TextField
                            id="comment"
                            name="comment"
                            value={item.comment || ""}
                            onChange={(e) => this.handleChange(e, index)}
                            placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                            disabled={!item.itemId}
                            error={validations[index].commentError}
                            helperText={validations[index].commentErrorText}
                        /> : (item.comment ? item.comment : "-")}
                    </TableCell>
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <TextField
                            id="documentationComment"
                            name="documentationComment"
                            value={item.documentationComment || ""}
                            onChange={(e) => this.handleChange(e, index)}
                            placeholder={this.props.t("enter", { text: this.props.t("documentation_comment").toLowerCase() })}
                            disabled={!item.itemId}
                            error={validations[index].documentationCommentError}
                            helperText={validations[index].documentationCommentErrorText}
                        /> : (item.documentationComment ? item.documentationComment : "-")}
                    </TableCell>
                    <TableCell>
                        {(this.props.documentType === Enum.DocumentTypes.INVENTORY || this.props.documentType === Enum.DocumentTypes.MANUAL) && this.props.type === Enum.Types.IN ? <TextField
                            id="serviceComment"
                            name="serviceComment"
                            value={item.serviceComment || ""}
                            onChange={(e) => this.handleChange(e, index)}
                            placeholder={this.props.t("enter", { text: this.props.t("service_comment").toLowerCase() })}
                            disabled={!item.itemId}
                            error={validations[index].serviceCommentError}
                            helperText={validations[index].serviceCommentErrorText}
                        /> : (item.serviceComment ? item.serviceComment : "-")}
                    </TableCell>
                    <TableCell align="center" sx={{ height: "55px !important" }}>
                        {this.props.isAddItemDialogOpen && this.props.selectedItems && !this.props.selectedItems.some(di => di.id === item.id) && item.quantity > 0 &&
                            <Checkbox
                                id="selectedItem"
                                name="selectedItem"
                                onChange={() => this.selectItem(item)}
                                checked={selectedItems.some(si => si.id === item.id)} />}
                        {!this.props.isAddItemDialogOpen && <IconButton
                            title="remove"
                            aria-label="remove"
                            onClick={() => this.removeItem(index)}
                        >
                            <TrashIcon />
                        </IconButton>}
                    </TableCell>
                </TableRow>
            })
        }

        return (
            <Grid container direction="row" spacing={2}>
                {!this.props.isAddItemDialogOpen && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Stack direction="row" spacing={2}>
                        <Button
                            className="create-btn"
                            sx={{ width: "200px !important" }}
                            startIcon={<AddIcon />}
                            //disabled={validations.some((v) => Object.values(v).some((error) => error === true))}
                            onClick={() => this.addNewItem()}
                        >
                            {this.props.t("add_item")}
                        </Button>
                        {this.props.documentType === Enum.DocumentTypes.INVENTORY && this.props.type === Enum.Types.IN && <Button
                            className="create-btn"
                            sx={{ width: "200px !important" }}
                            startIcon={<History />}
                            onClick={() => this.getWarehouseCurrentState()}
                        >
                            {this.props.t("load_current_state")}
                        </Button>}
                    </Stack>
                </Grid>}
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        columns={columns}
                        data={data}
                        pagination={false}
                    />
                </Grid>
            </Grid>

        )
    }
}
const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

const mapActionsToProps = { getListOfItems, getSuppliersByWarehouseId, getWarehouseCurrentState }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(DocumentItems)))