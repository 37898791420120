import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Collapse,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { getItemsDocumentItemsState, setDocumentItemsStatesPage, setDocumentItemsStatesSize, setDocumentItemsStatesParams, getListOfCategories } from '../../../redux/warehouse/actions'
import store from '../../../redux/store';
import types from '../../../redux/warehouse/types';
import Base58Table from '../../../components/common/Base58Table';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down.svg';
import moment from 'moment';

class DocumentItems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseIndex: -1
        };
    }

    componentDidMount() {
        this.props.getItemsDocumentItemsState(this.props.match.params.warehouseId);
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.RESET_DOCUMENT_ITEMS_STATES_DATA
        })
    }

    render() {
        const { collapseIndex } = this.state
        const { documentItemsStates, documentItemsStatesLoading, documentItemsStatesTotalElements, documentItemsStatesParams } = this.props.warehouseReducer

        let documentItemsStatesList = documentItemsStates && documentItemsStates.map((item, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell align='center'>
                        <IconButton size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                            {index === collapseIndex ? <ArrowUp /> : <ArrowDown />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{item.documentId}</TableCell>
                    <TableCell>{moment(item.inDocumentDate).format("DD.MM.YYYY.")}</TableCell>
                    <TableCell>{item.itemName}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ border: "none", paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={7}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="table-cell-light">{this.props.t("price")}</TableCell>
                                                <TableCell className="table-cell-light">{this.props.t("expiry_date")}</TableCell>
                                                <TableCell className="table-cell-light">{this.props.t("serial_number")}</TableCell>
                                                <TableCell className="table-cell-light">{this.props.t("comment")}</TableCell>
                                                <TableCell className="table-cell-light">{this.props.t("supplier")}</TableCell>
                                                <TableCell className="table-cell-light">{this.props.t("doc_comment")}</TableCell>
                                                <TableCell className="table-cell-light">{this.props.t("serv_comment")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>{item.price ? item.price : '-'}</TableCell>
                                                <TableCell>{item.expiryDate ? moment(item.expiryDate).format('DD.MM.YYYY.') : '-'}</TableCell>
                                                <TableCell>{item.serialNo ? item.serialNo : '-'}</TableCell>
                                                <TableCell>{item.comment ? item.comment : '-'}</TableCell>
                                                <TableCell>{item.supplier ? item.supplier : '-'}</TableCell>
                                                <TableCell>{item.documentationComment ? item.documentationComment : '-'}</TableCell>
                                                <TableCell>{item.serviceComment ? item.serviceComment : '-'}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                width: '2%'
            },
            {
                name: "document_id",
                width: '8%'
            },
            {
                name: "document_date",
                width: '10%'
            },
            {
                name: "item",
                dbName: 'name',
                width: '30%',
                sort: true,
                filter: true
            },
            {
                name: "category",
                dbName: 'category',
                width: '30%',
                sort: true,
                type: "auto-complete",
                filter: true,
                items: this.props.warehouseReducer.categoriesList.map(category => { return category.name }),
            },
            {
                name: "quantity",
                dbName: 'quantity',
                width: '20%',
                sort: true,
                filter: true
            }
        ]

        return (
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Base58Table
                    isLoading={documentItemsStatesLoading}
                    columns={columns}
                    data={documentItemsStatesList}
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    count={documentItemsStatesTotalElements}
                    rowsPerPage={documentItemsStatesParams.size}
                    page={documentItemsStatesParams.page}
                    onPageChange={(e, page) => { this.props.setDocumentItemsStatesPage(this.props.match.params.warehouseId, page); this.setState({ collapseIndex: -1 }) }}
                    onRowsPerPageChange={(e) => { this.props.setDocumentItemsStatesSize(this.props.match.params.warehouseId, e.target.value); this.setState({ collapseIndex: -1 }) }}
                    onFilterChange={(params) => { this.props.setDocumentItemsStatesParams(this.props.match.params.warehouseId, { ...params, page: 0 }); this.setState({ collapseIndex: -1 }) }}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer

})

const mapActionsToProps = { getItemsDocumentItemsState, setDocumentItemsStatesPage, setDocumentItemsStatesSize, setDocumentItemsStatesParams, getListOfCategories }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(DocumentItems)))
