import React from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import {
    createCategory,
    updateCategory,
    getCategoryById
} from '../../../redux/warehouse/actions';
import { NAME_REGEX } from '../../../components/common/regex';

class ManageCategory extends React.Component {

    emptyCategory = {
        warehouseId: Number(this.props.match.params.warehouseId),
        name: '',
        description: '',
    };
    constructor(props) {
        super(props);
        this.state = {
            mainLoading: false,
            initialCategory: JSON.parse(JSON.stringify(this.emptyCategory)),
            category: JSON.parse(JSON.stringify(this.emptyCategory)),
            validation: {
                nameError: false,
                nameErrorText: "",
                descriptionError: false,
                descriptionErrorText: ""
            }
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getCategoryById(this.props.match.params.id).then(response => {
                this.setState({
                    category: JSON.parse(JSON.stringify(response.data)),
                    initialCategory: JSON.parse(JSON.stringify(response.data))
                }, () => {
                    this.validate("name", this.state.category.name);
                    this.validate("description", this.state.category.description);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("name", this.state.category.name);
            this.validate("description", this.state.category.description);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { category } = this.state;
        category[target.name] = target.value.trimStart()
        this.setState({ category: category })
        this.validate(target.name, target.value);
    };

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "description":
                if (value.length === 0) {
                    validation.descriptionError = true;
                    validation.descriptionErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.descriptionError = true;
                    validation.descriptionErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.descriptionError = false;
                    validation.descriptionErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageCategory = () => {
        const { category } = this.state;
        const action = this.props.match.params.id ?
            this.props.updateCategory(this.props.match.params.id, category) :
            this.props.createCategory(category);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialCategory: JSON.parse(JSON.stringify(this.emptyCategory)),
                    category: JSON.parse(JSON.stringify(this.emptyCategory))
                }, () => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/categories`));
            }
        })
    };

    disable() {
        let { validation, category, initialCategory } = this.state;
        if (!category.name || !category.description ||
            JSON.stringify(category) === JSON.stringify(initialCategory) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {

        let { mainLoading, initialCategory, category, validation } = this.state

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<Grid container direction="row">
            <Prompt when={JSON.stringify(initialCategory) !== JSON.stringify(category)} message={this.props.t("prompt_message")} />
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={7} xl={7}>
                <Box className="custom-box">
                    <Grid container direction="row" spacing={3}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                    {this.props.match.params.id ? this.props.t("edit_category") : this.props.t("new_category")}
                                </Typography>
                                <Button className="cancel-btn" onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/categories`)}>
                                    {this.props.t("cancel")}
                                </Button>
                                <Button className="create-btn" onClick={this.manageCategory} disabled={this.disable()}>
                                    {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("name")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <TextField
                                                    autoFocus
                                                    id="name"
                                                    name="name"
                                                    value={category.name}
                                                    onChange={this.handleChange}
                                                    placeholder={this.props.t("enter", { text: this.props.t("name").toLowerCase() })}
                                                    error={validation.nameError}
                                                    helperText={validation.nameErrorText} />
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("description")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <TextField
                                                    id="description"
                                                    name="description"
                                                    value={category.description}
                                                    onChange={this.handleChange}
                                                    placeholder={this.props.t("enter", { text: this.props.t("description").toLowerCase() })}
                                                    error={validation.descriptionError}
                                                    helperText={validation.descriptionErrorText}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
})

const mapActionsToProps = { createCategory, updateCategory, getCategoryById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageCategory)))