import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    DialogContent,
    Button,
    Grid,
    Box,
    Stack,
    Typography
} from '@mui/material';
import { getExaminationById, updateExamination } from '../../redux/examination/actions'
import { BootstrapDialog, Transition } from '../../components/common/Dialog';
import ExaminationServices from './ExaminationServices';

class ExaminationServicesModal extends Component {

    emptyExamination = {
        patientId: null,
        comment: '',
        diagnosisIds: [],
        internalNote: '',
        examinationServices: []
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            examination: JSON.parse(JSON.stringify(this.emptyExamination)),
            initialExamination: JSON.parse(JSON.stringify(this.emptyExamination)),
            examinationServicesValidation: []
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.examinationId && prevProps.examinationId !== this.props.examinationId) {
            this.props.getExaminationById(this.props.examinationId.toString()).then(response => {
                this.setState({
                    examination: JSON.parse(JSON.stringify(response.data)),
                    initialExamination: JSON.parse(JSON.stringify(response.data))
                });
            })
        }
    }

    updateExamination = () => {
        let { examination } = this.state

        this.props.updateExamination(this.props.examinationId, examination).then(() => {
            this.setState({ examination: JSON.parse(JSON.stringify(this.emptyExamination)) });
            this.props.onSave()
        })
    }

    validateForm() {
        let { examination, initialExamination, examinationServicesValidation } = this.state;
        let valid = true;
        if (JSON.stringify(examination) === JSON.stringify(initialExamination)) {
            valid = false
        }
        if (examination.examinationServices.length === 0) {
            valid = false
        }
        if (examinationServicesValidation.some((es) => Object.values(es).some((error) => error === true))) {
            valid = false;
        }
        return valid;
    }

    render() {

        let { examination } = this.state

        return (

            <BootstrapDialog
                fullWidth
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                maxWidth={"xl"}
            >
                <DialogContent sx={{ padding: "0px !important" }}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.t("services")}
                                    </Typography>
                                    <Button className="create-btn" onClick={this.updateExamination} disabled={!this.validateForm()}>
                                        {this.props.t("update")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <ExaminationServices
                                        data={examination.examinationServices}
                                        onChange={(examinationServices) => this.setState({ examination: { ...this.state.examination, examinationServices: examinationServices } })}
                                        onValidationChange={(validation) => this.setState({ examinationServicesValidation: validation })} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    examinationReducer: state.examinationReducer,
    serviceReducer: state.serviceReducer
})

const mapActionsToProps = { getExaminationById, updateExamination }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ExaminationServicesModal))
