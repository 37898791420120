import React, { Component } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import './Sidebar.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { hasAnyRole } from '../../utils/Security';
import { ReactComponent as Logo } from '../../assets/icon/logo.svg';
import InfirmaryIcon from "../../assets/icons/infirmaries_icon.svg";
import LogoutIcon from "../../assets/icons/logout.svg";
import ConfirmationDialog from '../common/ConfirmationDialog';
import { onLogout, updateInfirmaryForLogout } from '../../redux/auth/actions';

const drawerWidth = 240;

class Sidebar extends Component {

    state = {
        items: this.props.items,
        logoutDialogOpen: false
    };

    handleDrawerToggle = () => {
        this.props.handleDrawerToggle();
    };

    handleClickItem = () => {
        if (this.props.mobileOpen) {
            this.props.handleDrawerToggle();
        }
    };

    handleClickItemWithChilds = (index) => {
        let { items } = this.state;
        items[index].opened = !items[index].opened;
        this.setState({ items: items });
    };

    render() {

        const drawer = (
            <div>
                <div className="navbar-name">
                    <Logo />
                </div>
                {this.props.user.currentInfirmary && this.props.user.currentInfirmary.name && <div>
                    <ListItem
                        className={"sidebar-infirmary"}>
                        <ListItemIcon>
                            <img loading='lazy' width={20} height={20} src={InfirmaryIcon} alt={InfirmaryIcon} />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography className='drawer-list-text' variant="subtitle2" fontSize={16} sx={{ fontWeight: '600' }}>
                                {this.props.user.currentInfirmary && this.props.user.currentInfirmary.name}
                            </Typography>} />
                    </ListItem>
                </div>}
                <List disablePadding>
                    <PerfectScrollbar style={{ height: 'calc(100vh - 70px)', marginTop: '24px' }}>
                        {this.state.items.map((item, parentIndex) => {
                            if (hasAnyRole(item.roles)) {
                                return <ListItem
                                    id={item.title + "-sidebar-item"}
                                    className={this.props.location.pathname === item.link ? "sidebar-item-selected" : "sidebar-item-not-selected"}
                                    button
                                    key={"parent-" + parentIndex}
                                    sx={{ marginTop: item.title === "settings" && '90px' }}
                                    onClick={() => this.handleClickItem()}
                                    component={Link}
                                    to={item.link}>
                                    <ListItemIcon>
                                        <img loading='lazy' width={20} height={20} src={item.icon} alt={item.title} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={<Typography variant={this.props.location.pathname === item.link ? "h4" : "subtitle2"}>
                                            {this.props.t(item.title)}
                                        </Typography>} />
                                </ListItem>
                            }
                            return null;
                        })}
                        <ListItem
                            id={"logout-sidebar-item"}
                            className={"sidebar-item-not-selected"}
                            button
                            key={"parent-logout"}
                            onClick={() => this.setState({ logoutDialogOpen: true })}
                        >
                            <ListItemIcon>
                                <img loading='lazy' width={20} height={20} src={LogoutIcon} alt={'logout-icon'} />
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography variant={"subtitle2"}>
                                    {this.props.t("logout")}
                                </Typography>} />
                        </ListItem>
                    </PerfectScrollbar>
                </List>
                <div style={{ position: 'absolute', textAlign: 'center', bottom: 20, width: '100%', color: 'black' }}>
                    v{process.env.REACT_APP_VERSION || 'NOT_SET'}
                </div>
            </div>
        );
        const container = window !== undefined ? () => window.document.body : undefined;
        return (
            <Box
                style={{ display: /* ( */this.props.location.pathname === '/choose-infirmary' ? 'none' : 'block' }}
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, boxShadow: "0px 1px 3px 0px #ADB5BD !important", zIndex: 998 }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={this.props.mobileOpen}
                    onClose={this.handleDrawerToggle}
                    classes={{
                        paper: "drawerPaper",
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{

                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: "drawerPaper",
                    }}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
                {this.state.logoutDialogOpen && <ConfirmationDialog
                    isOpen={this.state.logoutDialogOpen}
                    title={this.props.t("logout")}
                    message={this.props.t("logout_message")}
                    onClose={() => this.setState({ logoutDialogOpen: false })}
                    onConfirm={() => this.props.user.currentInfirmary !== null ? this.props.updateInfirmaryForLogout(this.props.user.currentInfirmary.id) : this.props.onLogout()}
                    closeButtonTitle={this.props.t("no")}
                    confirmButtonTitle={this.props.t("yes")}
                />}
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user
});

export default connect(mapStateToProps, { onLogout, updateInfirmaryForLogout })(withRouter(withTranslation()(Sidebar)));