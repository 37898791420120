export const SET_NORMATIVES_LOADING = 'SET_NORMATIVES_LOADING';
export const SET_NORMATIVES = 'SET_NORMATIVES';
export const SET_NORMATIVES_PAGE = 'SET_NORMATIVES_PAGE'
export const SET_NORMATIVES_SIZE = 'SET_NORMATIVES_SIZE'
export const SET_NORMATIVES_PARAMS = 'SET_NORMATIVES_PARAMS'
export const RESET_NORMATIVE_DATA = 'RESET_NORMATIVE_DATA'
export const SET_LIST_OF_NORMS = 'SET_LIST_OF_NORMS'

const types = {
    SET_NORMATIVES_LOADING,
    SET_NORMATIVES,
    SET_NORMATIVES_PAGE,
    SET_NORMATIVES_SIZE,
    SET_NORMATIVES_PARAMS,
    RESET_NORMATIVE_DATA,
    SET_LIST_OF_NORMS
};

export default types;