import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    Typography,
    FormControl,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Tooltip,
    Zoom,
    Chip,
    InputLabel,
    Box
} from '@mui/material';
import './Statistics.css'
import { getPriceEstimateStatistics } from '../../redux/calendar/actions'
import StatisticsIcon from '../../assets/icons/statistics.svg';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

class DashboardStatisticsProfit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStartDate: null,
            selectedEndDate: null
        };
    }

    componentWillUnmount() {
        this.props.calendarReducer.priceEstimateStatistics = null
    }

    handleChangeStartDate = (date) => {
        this.setState({ selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        this.setState({ selectedEndDate: date });
    }

    getStatistics = () => {
        const { selectedStartDate, selectedEndDate } = this.state;
        let params = {}
        params.startTime = selectedStartDate !== null ? moment(selectedStartDate) : null
        params.endTime = selectedEndDate !== null ? moment(selectedEndDate) : null
        this.props.getPriceEstimateStatistics({ params: params })
    }

    render() {
        const { selectedStartDate, selectedEndDate } = this.state
        const { priceEstimateStatistics } = this.props.calendarReducer

        return (
            <Grid container direction="row" spacing={3}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ color: "#5C5C5C", fontSize: "16px", fontWeight: 500 }}>{this.props.t("statistics_for_profit")}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={7} lg={7} xl={7}>
                    <Box boxShadow={3} className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                <InputLabel className="form-label">
                                    {this.props.t("start_date")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <FormControl>
                                    <WrappedDatePicker
                                        id="startDate"
                                        name="startDate"
                                        maxDate={moment(selectedEndDate).add(-1, 'day')}
                                        onChange={this.handleChangeStartDate}
                                        value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                <InputLabel className="form-label">
                                    {this.props.t("end_date")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <FormControl>
                                    <WrappedDatePicker
                                        id="endDate"
                                        name="endDate"
                                        minDate={moment(selectedStartDate).add(1, 'day')}
                                        maxDate={moment(new Date()).add(1, 'year')}
                                        onChange={this.handleChangeEndDate}
                                        value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={2} lg={2} xl={2} mt={4}>
                                <Button
                                    className="create-btn"
                                    style={{ width: '100%' }}
                                    startIcon={<img src={StatisticsIcon} width={24} height={24} alt="statistics-icon" />}
                                    title={this.props.t("get_statistics")}
                                    onClick={() => this.getStatistics()}
                                    disabled={!selectedStartDate || !selectedEndDate}>
                                    {this.props.t("statistics")}
                                </Button>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                {priceEstimateStatistics && <Grid container direction="row" spacing={3}>
                                    <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <InputLabel className="form-label">
                                            {this.props.t("profit")}
                                        </InputLabel>
                                        <NumericFormat
                                            style={{ fontWeight: 'bold', fontSize: '20px', color: "#24A36C", backgroundColor: "#F9F9F9", padding: "24px", textAlign: "center" }}
                                            value={priceEstimateStatistics.priceEstimateSum || 0}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            suffix=" €"
                                        />
                                    </Grid>
                                    {priceEstimateStatistics.priceNotSpecifiedList.length > 0 && <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <InputLabel className="form-label">
                                            {this.props.t("non_defined_prices")}
                                        </InputLabel>
                                        {priceEstimateStatistics.priceNotSpecifiedList.map((data, index) => {
                                            return <ListItem
                                                key={index}
                                                sx={{ border: '1px solid lightgrey', height: '38px' }}
                                            >
                                                <ListItemText primary={`${moment.unix(data.date).format("DD.MM.YYYY.")}`} />
                                                <ListItemAvatar>
                                                    <Tooltip
                                                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<span>
                                                            {this.props.t("number_of_non_defined_prices")}
                                                        </span>}>
                                                        <Chip
                                                            style={{ width: '45px', backgroundColor: '#BDBDBD', cursor: 'pointer' }}
                                                            key={data.priceNotSpecifiedCount}
                                                            tabIndex={-1}
                                                            label={<Typography variant="body2" sx={{ color: 'white' }}>{data.priceNotSpecifiedCount}</Typography>}
                                                            variant="filled"
                                                        />
                                                    </Tooltip>
                                                </ListItemAvatar>
                                            </ListItem>
                                        })}
                                    </Grid>}
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer
})

export default connect(mapStateToProps, { getPriceEstimateStatistics })(withTranslation()(DashboardStatisticsProfit))