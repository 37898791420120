import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Chip,
    Grid,
    LinearProgress,
    Box,
    FormControl,
    Typography,
    Divider,
    CardContent,
    Card,
    Button,
    InputLabel,
    Stack,
    List,
    ListItem
} from '@mui/material';
import "./Calendar.css"
import { Calendar as Scheduler, momentLocalizer } from 'react-big-calendar'
import PersonIcon from '../../assets/icons/person.svg';
import CalendarIcon from '../../assets/icons/calendar.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import moment from 'moment'
import 'moment/locale/hr';
import CustomEvent from './CustomEvent';
import CustomToolbar from './CustomToolbar';
import ManageEvent from './ManageEvent';
import { getInfirmaryList } from '../../redux/infrimary/actions'
import { getScheduleEvents } from '../../redux/schedule/actions'
import { getPatientList } from '../../redux/patient/actions'
import { getCalendarEvents, deleteCalendarEntry } from '../../redux/calendar/actions'
import { UserRole, hasAnyRole } from '../../utils/Security';
import store from '../../redux/store';
import { snackActions } from '../../redux/snackbar/reducer';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { Search } from '@mui/icons-material';
import SearchPatientCalendarEntryModal from './SearchPatientCalendarEntryModal';
import PerfectScrollbar from 'react-perfect-scrollbar';

class Calendar extends React.Component {

    emptyCalendarEntry = {
        infirmaryIds: []
    }

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            selectedInfirmary: [],
            selectedSlot: null,
            calendarEventToEdit: null,
            calendar: this.emptyCalendarEntry,
            selectedBtn: 'day',
            navigateBtn: 'TODAY',
            isLoadingEvents: true,
            selectedDay: new Date(),
            view: 'day',
            searchPatientDialog: false
        };
    }

    componentDidMount() {
        Promise.all([
            this.props.getInfirmaryList(),
            hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getPatientList(),
            hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.getCalendarEvents(),
            hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.getScheduleEvents()])
            .then(() => {
                hasAnyRole([UserRole.DOCTOR]) && this.setState({ selectedInfirmary: this.props.infirmaryReducer.infirmaryList.filter((d) => { return d.id === this.props.authReducer.user.currentInfirmary.id }) },
                    () => this.getCalendarEvents(this.props.authReducer.user.currentInfirmary.id))
            })
            .catch(error => {
            })
    }

    getScheduleEvents = (event) => {
        let { selectedDay } = this.state
        this.setState({ selectedBtn: event })
        if (hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST])) {
            switch (event) {
                case 'month':
                    return this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('month').add(1, 'hours'), endTime: moment(selectedDay).endOf('month').add(1, 'hours') })
                case 'work_week':
                    return this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('isoweek').add(1, 'hours'), endTime: moment(selectedDay).endOf('isoweek').add(1, 'hours') })
                default:
                    return this.props.getScheduleEvents({ startTime: moment(selectedDay).startOf('day').add(1, 'hours'), endTime: moment(selectedDay).endOf('day').add(1, 'hours') })
            }
        }
    }

    getScheduleEventsOnNavigateTabs = (event, view, action) => {
        this.setState({ selectedDay: event, selectedBtn: view })
        if (new Date().toDateString() === event.toDateString()) {
            this.setState({ navigateBtn: 'TODAY' })
        } else {
            this.setState({ navigateBtn: '' })
        }
        if (action === "DATE" && view === "month") {
            this.setState({ selectedDay: event, view: 'day', selectedBtn: 'day' })
            hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
        } else {
            if (hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST])) {
                switch (view) {
                    case 'month':
                        return this.props.getScheduleEvents({ startTime: moment(event).startOf('month').add(1, 'hours'), endTime: moment(event).endOf('month').add(1, 'hours') })
                    case 'work_week':
                        return this.props.getScheduleEvents({ startTime: moment(event).startOf('isoweek').add(1, 'hours'), endTime: moment(event).endOf('isoweek').add(1, 'hours') })
                    default:
                        return this.props.getScheduleEvents({ startTime: moment(event).startOf('day').add(1, 'hours'), endTime: moment(event).endOf('day').add(1, 'hours') })
                }
            }
        }
    }

    getCalendarEventsOnNavigateTabs = (event, view, action) => {
        let { calendar } = this.state
        let params = {}
        this.setState({ selectedDay: event, selectedBtn: view })
        if (new Date().toDateString() === event.toDateString()) {
            this.setState({ navigateBtn: 'TODAY' })
        } else {
            this.setState({ navigateBtn: '' })
        }
        if (action === "DATE" && view === "month") {
            this.setState({ selectedDay: event, view: 'day', selectedBtn: 'day' })
            params.startTime = moment(event).startOf('day').add(1, 'hours')
            params.endTime = moment(event).endOf('day').add(1, 'hours')
            hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params })
        } else {
            params.infirmaryIds = hasAnyRole([UserRole.DOCTOR]) ? this.state.selectedInfirmary[0].id : calendar.infirmaryIds.length > 0 ? calendar.infirmaryIds.join(",") : null
            switch (view) {
                case 'month':
                    params.startTime = moment(event).startOf('month').add(1, 'hours')
                    params.endTime = moment(event).endOf('month').add(1, 'hours')
                    return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
                case 'work_week':
                    params.startTime = moment(event).startOf('isoweek').add(1, 'hours')
                    params.endTime = moment(event).endOf('isoweek').add(1, 'hours')
                    return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
                case 'day':
                    params.startTime = moment(event).startOf('day').add(1, 'hours')
                    params.endTime = moment(event).endOf('day').add(1, 'hours')
                    return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST, UserRole.DOCTOR]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
                default:
                    params.startTime = moment(event).startOf('day').add(1, 'hours')
                    params.endTime = moment(event).endOf('day').add(1, 'hours')
                    return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            }
        }
    }

    getCalendarEvents = (infirmaryIds) => {
        let { calendar, selectedDay, selectedBtn } = this.state;
        let params = {}
        params.infirmaryIds = calendar.infirmaryIds.length > 0 ? calendar.infirmaryIds.join(",") : infirmaryIds && infirmaryIds.length > 0 ? infirmaryIds.join(",") : infirmaryIds;
        switch (selectedBtn) {
            case 'month':
                params.startTime = moment(selectedDay).startOf('month').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('month').add(1, 'hours')
                return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            case 'work_week':
                params.startTime = moment(selectedDay).startOf('isoweek').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('isoweek').add(1, 'hours')
                return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            case 'day':
                params.startTime = moment(selectedDay).startOf('day').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('day').add(1, 'hours')
                return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST, UserRole.DOCTOR]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
            default:
                params.startTime = moment(selectedDay).startOf('day').add(1, 'hours')
                params.endTime = moment(selectedDay).endOf('day').add(1, 'hours')
                return hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getCalendarEvents({ params: params }).then(this.setState({ isLoadingEvents: false }))
        }
    }

    closeDialog = () => {
        this.setState({ dialogOpen: false, calendarEventToEdit: null })
    }

    closeSearchPatientDialog = () => {
        this.setState({ searchPatientDialog: false })
    }

    handleChangeInfirmaries = (e, infirmary) => {
        let { calendar } = this.state
        let selectedInfirmary = { ...this.state.selectedInfirmary }
        selectedInfirmary = infirmary;
        calendar.infirmaryIds = []
        selectedInfirmary.forEach(data => {
            calendar.infirmaryIds.push(data.id)
        })
        this.setState({ selectedInfirmary: selectedInfirmary, calendar: calendar }, () => this.getCalendarEvents(calendar.infirmaryIds));
    }

    handleClickDoctorScheduleCard = (item) => {
        this.setState({ view: 'day', selectedBtn: 'day' }, () => this.getScheduleEventsOnNavigateTabs(new Date(item.startTime), "day"))
    }

    goToDay = (startTime) => {
        let calendar = this.state
        calendar.infirmaryIds = []
        this.setState({ selectedDay: new Date(startTime), selectedBtn: 'day', navigateBtn: '', searchPatientDialog: false, selectedInfirmary: [], calendar: calendar }, () => this.getCalendarEvents())
    }

    onView = (event) => {
        this.setState({ selectedBtn: event, view: event }, () => { this.getScheduleEvents(event); this.getCalendarEvents(); })
    }

    onNavigate = (event, view, action) => {
        this.getScheduleEventsOnNavigateTabs(event, view, action);
        this.getCalendarEventsOnNavigateTabs(event, view, action)
    }

    onSelectSlot = (slot) => {
        if (hasAnyRole([UserRole.DOCTOR])) return
        if (this.state.selectedBtn === "month") return
        if (moment(slot.start).isAfter(moment())) {
            if (this.state.calendar.infirmaryIds.length === 0) {
                store.dispatch(snackActions.warning(this.props.t("first_select_infirmary")));
            } else if (this.state.calendar.infirmaryIds.length > 1) {
                store.dispatch(snackActions.warning(this.props.t("select_only_one_infirmary")));
            } else {
                this.setState({ dialogOpen: true, selectedSlot: slot });
            }
        } else {
            store.dispatch(snackActions.info(this.props.t("info_message_for_calendar")));
        }
    }

    render() {
        const { selectedInfirmary, selectedSlot, isLoadingEvents, selectedBtn } = this.state
        const { isLoading, calendarEvents } = this.props.calendarReducer;
        const { infirmaryList } = this.props.infirmaryReducer
        const { scheduleEvents } = this.props.scheduleReducer;

        if (isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }

        if (isLoadingEvents) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )
        }

        const customDayPropGetter = (date) => {
            let dayOfWeek = date.getDay();
            if (dayOfWeek === 0 || dayOfWeek === 6)
                return {
                    className: "day_weekend",
                    style: {
                        border: 'solid 1px ' + (dayOfWeek === 0 || dayOfWeek === 6 ? '#fc4242' : 'none'),
                    },
                }
            else return {}
        }

        return (<Grid container direction="row" spacing={3}>
            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box className="custom-box">
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                {this.props.t("calendar")}
                            </Typography>
                            <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                            <InputLabel className="form-label">
                                {this.props.t("infirmary")}
                            </InputLabel>
                            <FormControl>
                                <WrappedAutocomplete
                                    placeholder={this.props.t("select_infirmary")}
                                    name="selectedInfirmary"
                                    value={selectedInfirmary}
                                    multiple={true}
                                    getOptionLabel={(option) => option ? option.name : ""}
                                    options={infirmaryList}
                                    onChange={(event, infirmary) => {
                                        this.handleChangeInfirmaries(event, infirmary)
                                    }}
                                    disabled={hasAnyRole([UserRole.DOCTOR])}
                                    renderOption={(props, option) => {
                                        return (
                                            <span {...props}>
                                                {option.name}
                                                <Chip style={{ marginLeft: 'auto', backgroundColor: option.calendarHexColor, width: '32px' }}></Chip>
                                            </span>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Button startIcon={<Search />} color="primary" sx={{ height: "40px", marginTop: "34px" }} onClick={() => this.setState({ searchPatientDialog: true })} >
                                {this.props.t("patient_arrival_search")}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>}
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" spacing={3}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) ? 9 : 12} xl={hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) ? 9 : 12}>
                        <Box className="custom-box">
                            {hasAnyRole([UserRole.DOCTOR]) && <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                {this.props.t("calendar")}
                            </Typography>}
                            <Scheduler
                                style={{ height: hasAnyRole([UserRole.DOCTOR]) ? 'calc(100vh - 220px)' : 'calc(100vh - 384px)' }}
                                localizer={momentLocalizer(moment)}
                                events={calendarEvents}
                                defaultDate={new Date()}
                                scrollToTime={new Date()}
                                date={this.state.selectedDay}
                                dayPropGetter={customDayPropGetter}
                                startAccessor={(event) => { return new Date(moment(event.startTime).utc()) }}
                                endAccessor={(event) => { return new Date(moment(event.endTime).utc()) }}
                                //titleAccessor={(event) => { return <> <span> {selectedBtn === "month" ? `${event.patient.firstName} ${event.patient.lastName}` : `${event.patient.firstName} ${event.patient.lastName} (${event.patient.phone}) ${event.patient.comment}`}</span></> }}
                                min={new Date(0, 0, 0, 8, 0, 0)}
                                max={new Date(0, 0, 0, 21, 30, 0)}
                                components={{
                                    toolbar: props => (
                                        <CustomToolbar {...props} selectedBtn={selectedBtn} navigateBtn={this.state.navigateBtn} view={this.state.view} selectedDay={this.state.selectedDay} />),
                                    event: props => (<CustomEvent {...props} selectedBtn={selectedBtn} />)
                                }}
                                dayLayoutAlgorithm={'no-overlap'}
                                timeslots={1}
                                step={15}
                                onSelectSlot={this.onSelectSlot}
                                onSelectEvent={hasAnyRole([UserRole.DOCTOR]) ? null : event => event.id && this.setState({ dialogOpen: true, calendarEventToEdit: event })}
                                selectable
                                defaultView='day'
                                view={this.state.view}
                                onView={this.onView}
                                onNavigate={this.onNavigate}
                                views={['month', 'work_week', 'day']}
                                culture={localStorage.getItem('i18nextLng') === 'hr' ? 'hr' : 'en'}
                                messages={localStorage.getItem('i18nextLng') === 'hr' ? {
                                    date: this.props.t("date"),
                                    time: this.props.t("time"),
                                    event: this.props.t("event"),
                                    allDay: this.props.t("all_day"),
                                    week: this.props.t("week"),
                                    work_week: this.props.t("work_week"),
                                    day: this.props.t("day"),
                                    month: this.props.t("month"),
                                    previous: this.props.t("back"),
                                    next: this.props.t("cal_next"),
                                    yesterday: this.props.t("yesterday"),
                                    tomorrow: this.props.t("tomorrow"),
                                    today: this.props.t("today"),
                                    agenda: this.props.t("agenda"),
                                    noEventsInRange: this.props.t("no_event")
                                } : {}}
                                eventPropGetter={event => {
                                    const backgroundColor = event.infirmary.calendarHexColor ? event.infirmary.calendarHexColor : "#99d996";
                                    const color = event.infirmary.calendarHexColor ? "black" : "white";
                                    return { style: { backgroundColor, color } };
                                }}
                            />
                        </Box>
                    </Grid>
                    {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) &&
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Box className="custom-box" sx={{ paddingRight: "8px !important" }}>
                                <Typography sx={{ fontWeight: 500, size: "18px", lineHeight: "20.63px" }}>
                                    {this.props.t("schedule")}
                                </Typography>
                                <PerfectScrollbar style={{ height: 'calc(100vh - 406px)' }}>
                                    <List sx={{ paddingRight: "16px" }}>
                                        {scheduleEvents && scheduleEvents.length > 0 &&
                                            scheduleEvents.map((item, index) => {
                                                return <ListItem key={index} disablePadding sx={{ marginBottom: "16px !important" }} onClick={() => this.handleClickDoctorScheduleCard(item)}>
                                                    <Card
                                                        sx={{
                                                            cursor: 'pointer',
                                                            border: `2px solid ${item.infirmary.calendarHexColor} !important`, width: '100% !important'
                                                        }} onClick={() => this.handleClickDoctorScheduleCard(item)}>
                                                        <CardContent sx={{ padding: '8px !important' }}>
                                                            <Grid container direction="row" spacing={2}>
                                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <Stack direction="row" spacing={2} sx={{ backgroundColor: item.infirmary.calendarHexColor, padding: "6px 14px 6px 14px !important", borderRadius: "4px", alignItems: "center" }}>
                                                                        <img src={PersonIcon} width={24} height={24} alt="person-icon" />
                                                                        <Typography sx={{ fontWeight: 500, size: "14px", lineHeight: "13.75px" }}>
                                                                            {`${item.user.firstName} ${item.user.lastName}`}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <Stack direction="row" spacing={1} sx={{ padding: "4px !important", alignItems: "center" }}>
                                                                        <Typography sx={{ backgroundColor: item.infirmary.calendarHexColor, fontWeight: 500, fontSize: "12px", lineHeight: "11.46px", padding: "4px", borderRadius: "4px" }}>
                                                                            {item.infirmary.name}
                                                                        </Typography>
                                                                        <img src={CalendarIcon} width={16} height={16} alt="calendar-icon" />
                                                                        <Typography sx={{ fontWeight: 500, fontSize: "12px", lineHeight: "11.46px" }}>
                                                                            {moment(item.startTime).format("DD.MM.yyyy.")}
                                                                        </Typography>
                                                                        <img src={ClockIcon} width={16} height={16} alt="clock-icon" />
                                                                        <Typography sx={{ fontWeight: 500, fontSize: "12px", lineHeight: "11.46px" }}>
                                                                            {moment(item.startTime).format("HH:mm") + " - " + moment(item.endTime).format("HH:mm")}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </ListItem>
                                            })}
                                    </List>
                                </PerfectScrollbar>
                            </Box>
                        </Grid>}
                    {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <ManageEvent
                        isOpen={this.state.dialogOpen}
                        onClose={this.closeDialog}
                        selectedSlot={selectedSlot}
                        calendarEventToEdit={this.state.calendarEventToEdit}
                        calendar={this.state.calendar}
                        infirmary={selectedInfirmary}
                        onSave={() => this.setState({
                            dialogOpen: false,
                            calendarEventToEdit: null
                        }, () => this.getCalendarEvents())}
                    />}
                    <SearchPatientCalendarEntryModal
                        isOpen={this.state.searchPatientDialog}
                        onClose={this.closeSearchPatientDialog}
                        goToDay={(startTime) => this.goToDay(startTime)} />
                </Grid>
            </Grid>
        </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer,
    infirmaryReducer: state.infirmaryReducer,
    scheduleReducer: state.scheduleReducer,
    authReducer: state.authReducer
})

const mapActionsToProps = { getInfirmaryList, getPatientList, getCalendarEvents, deleteCalendarEntry, getScheduleEvents }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Calendar))
