import React from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import {
    createMeasurement, updateMeasurement, getMeasurementById
} from '../../../redux/warehouse/actions';
import { CODE_REGEX, NAME_REGEX } from '../../../components/common/regex';

class ManageUnitOfMeasurement extends React.Component {

    emptyMeasurement = {
        warehouseId: Number(this.props.match.params.warehouseId),
        unitOfMeasurement: '',
        symbol: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: false,
            initialMeasurement: JSON.parse(JSON.stringify(this.emptyMeasurement)),
            measurement: JSON.parse(JSON.stringify(this.emptyMeasurement)),
            validation: {
                unitOfMeasurementError: false,
                unitOfMeasurementErrorText: "",
                symbolError: false,
                symbolErrorText: ""
            }
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getMeasurementById(this.props.match.params.id).then(response => {
                this.setState({
                    measurement: JSON.parse(JSON.stringify(response.data)),
                    initialMeasurement: JSON.parse(JSON.stringify(response.data))
                }, () => {
                    this.validate("unitOfMeasurement", this.state.measurement.unitOfMeasurement);
                    this.validate("symbol", this.state.measurement.symbol);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("unitOfMeasurement", this.state.measurement.unitOfMeasurement);
            this.validate("symbol", this.state.measurement.symbol);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { measurement } = this.state;
        measurement[target.name] = target.value.trimStart()
        this.setState({ measurement: measurement })
        this.validate(target.name, target.value);
    };

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "unitOfMeasurement":
                if (value.length === 0) {
                    validation.unitOfMeasurementError = true;
                    validation.unitOfMeasurementErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.unitOfMeasurementError = true;
                    validation.unitOfMeasurementErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.unitOfMeasurementError = false;
                    validation.unitOfMeasurementErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "symbol":
                if (value.length === 0) {
                    validation.symbolError = true;
                    validation.symbolErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !CODE_REGEX.test(value)) {
                    validation.symbolError = true;
                    validation.symbolErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.symbolError = false;
                    validation.symbolErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageUnitOfMeasurement = () => {
        const { measurement } = this.state;
        const action = this.props.match.params.id ?
            this.props.updateMeasurement(this.props.match.params.id, measurement) :
            this.props.createMeasurement(measurement);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialMeasurement: JSON.parse(JSON.stringify(this.emptyMeasurement)),
                    measurement: JSON.parse(JSON.stringify(this.emptyMeasurement))
                }, () => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/measurement-units`));
            }
        })
    };

    disable() {
        let { validation, measurement, initialMeasurement } = this.state;
        if (!measurement.unitOfMeasurement || !measurement.symbol ||
            JSON.stringify(measurement) === JSON.stringify(initialMeasurement) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {
        let { mainLoading, initialMeasurement, measurement, validation } = this.state

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialMeasurement) !== JSON.stringify(measurement)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_measurement") : this.props.t("new_measurement")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/measurement-units`)}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageUnitOfMeasurement} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("unit_of_measurement")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        autoFocus
                                                        id="unitOfMeasurement"
                                                        name="unitOfMeasurement"
                                                        value={measurement.unitOfMeasurement}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("unit_of_measurement").toLowerCase() })}
                                                        error={validation.unitOfMeasurementError}
                                                        helperText={validation.unitOfMeasurementErrorText} />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("symbol")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        id="symbol"
                                                        name="symbol"
                                                        value={measurement.symbol}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("symbol").toLowerCase() })}
                                                        error={validation.symbolError}
                                                        helperText={validation.symbolErrorText}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
})

const mapActionsToProps = { createMeasurement, updateMeasurement, getMeasurementById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageUnitOfMeasurement)))