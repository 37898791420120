import i18next from "i18next";

export function randomColor() {
    const red = Math.floor((1 + Math.random()) * 256 / 2);
    const green = Math.floor((1 + Math.random()) * 256 / 2);
    const blue = Math.floor((1 + Math.random()) * 256 / 2);
    return "rgba(" + red + ", " + green + ", " + blue + ",0.4)";
}

export const optionsPieChart = {
    plugins: {
        tooltip: {
            callbacks: {
                label: function (context) {

                    const max = context.dataset.data.reduce((a, b) => (a + b), 0);

                    let label = context.label;
                    let total = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.parsed);
                    let percentage = `${(context.parsed * 100 / max).toFixed(2)}%`;

                    return ` ${label}: ${total} / ${percentage}`;
                }
            }
        },
        legend: {
            display: true,
            position: 'right',
            labels: {
                boxHeight: 8,
                boxWidth: 20,
                font: {
                    size: 14
                },
                generateLabels: function (chart) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                        const {
                            labels: {
                                pointStyle
                            }
                        } = chart.legend.options;

                        const max = data.datasets[0].data.reduce((a, b) => (a + b), 0);

                        return data.labels.map((label, i) => {
                            const meta = chart.getDatasetMeta(0);
                            const style = meta.controller.getStyle(i);

                            return {
                                text: `${label.length > 30 ? label.substring(0, 30) + "..." : label} (${(data.datasets[0].data[i] * 100 / max).toFixed(2)}%)`,
                                fillStyle: style.backgroundColor,
                                strokeStyle: style.borderColor,
                                lineWidth: style.borderWidth,
                                pointStyle: pointStyle,
                                hidden: !chart.getDataVisibility(i),

                                // Extra data used for toggling the correct item
                                index: i
                            };
                        });
                    }
                    return [];
                }
            }
        },
        datalabels: {
            color: "grey",
            font: {
                size: "14px",
                weight: 700,
                family: 'Roboto'
            },
            formatter(value, categories) {
                const max = categories.chart.data.datasets[0].data.reduce((a, b) => (a + b), 0);
                let percentage = (value * 100 / max).toFixed(2);
                if (percentage < 9) {
                    return "";
                }
                return `${percentage}%`;
            },
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        },
        responsive: true,
        maintainAspectRatio: false,
    }
}

export const optionsLineChart = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        tooltip: {
            callbacks: {
                label: function (context) {
                    let label = ' ';
                    if (context.parsed !== null) {
                        label += new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(context.raw);
                    }
                    return label;
                }
            },
        },
        legend: {
            display: true,
            position: 'bottom'
        },
        datalabels: {
            display: false
        }
    },
    interaction: {
        mode: 'point',
        intersect: false
    },
    scales: {
        x: {
            display: true,
            title: {
                display: true,
                text: i18next.t("date")
            }
        },
        y: {
            display: true,
            title: {
                display: true,
                text: i18next.t("amount")
            }
        }
    }
}