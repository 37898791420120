import React, { Component } from 'react';
import { DialogContent } from '@mui/material';
import { BootstrapDialog, Transition } from '../../components/common/Dialog';
import ManagePatient from './ManagePatient';

class ManagePatientDialog extends Component {

    constructor(props) {
        super(props);
    }

    closeDialog = () => {
        this.props.onClose();
    }

    render() {
        return (<BootstrapDialog
            onClose={this.closeDialog}
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="lg"
        >
            <DialogContent sx={{ padding: "0px !important" }}>
                <ManagePatient
                    patientId={this.props.patientId}
                    onSave={(patientId) => this.props.onSave(patientId)}
                    closeDialog={this.closeDialog} />
            </DialogContent>
        </BootstrapDialog>
        );
    }
}

export default ManagePatientDialog;