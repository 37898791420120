import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Button,
    Grid,
    TextField,
    Autocomplete,
    FormControl,
    TableRow,
    TableCell,
    InputLabel
} from '@mui/material';
import { getServiceList } from '../../redux/service/actions'
import Base58Table from '../../components/common/Base58Table';
import { NumericFormat } from "react-number-format";
import AddIcon from '../../assets/icons/add_icon.svg';
import DeleteIcon from '../../assets/icons/delete_icon.svg';
import { NAME_REGEX } from '../../components/common/regex';
import { withRouter } from 'react-router-dom';

class ExaminationServices extends React.Component {

    emptyExaminationService = {
        serviceId: null,
        price: null,
        quantity: null,
        discountAmount: null,
        discountPercentage: null,
        comment: ''
    }

    emptyValidation = {
        serviceIdError: false,
        serviceIdErrorText: "",
        priceError: false,
        priceErrorText: "",
        quantityError: false,
        quantityErrorText: "",
        discountAmountError: false,
        discountAmountErrorText: "",
        discountPercentageError: false,
        discountPercentageErrorText: "",
        commentError: false,
        commentErrorText: ""
    }

    constructor(props) {
        super(props);
        this.state = {
            examinationServices: [],
            validations: []
        };
    }

    componentDidMount() {
        this.props.getServiceList();
        /* if (this.props.data.length === 0) {
            this.addNewService();
        } */
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            this.setState({
                examinationServices: this.props.data,
                validations: this.props.data.map(() => JSON.parse(JSON.stringify(this.emptyValidation)))
            }, () => {
                this.state.examinationServices.forEach((es, index) => {
                    Object.entries(es).forEach(([key, value]) => {
                        this.validate(key, value, index);
                    });
                });
            });
        }

    }

    handleChangeService = (e, service, index) => {
        let { examinationServices } = this.state;
        if (service !== null) {
            examinationServices[index].serviceId = service.id;
            examinationServices[index].price = service.price;
            examinationServices[index].quantity = 1;
            examinationServices[index].discountAmount = null;
            examinationServices[index].discountPercentage = null;
        } else {
            examinationServices[index].serviceId = null;
            examinationServices[index].price = '';
            examinationServices[index].quantity = null;
            examinationServices[index].discountAmount = null;
            examinationServices[index].discountPercentage = null;
        }
        this.setState({ examinationServices: examinationServices }, () => {
            this.props.onChange(this.state.examinationServices);
            Object.entries(examinationServices[index]).forEach(([key, value]) => {
                this.validate(key, value, index);
            });
        });
    }

    handleChangeQuantity = (e, index) => {
        if (e.target) {
            let { examinationServices } = this.state;
            examinationServices[index][e.target.name] = Number(e.target.value);
            examinationServices[index].discountAmount = null;
            examinationServices[index].discountPercentage = null;
            this.setState({ examinationServices: examinationServices }, () => {
                this.props.onChange(this.state.examinationServices);
                this.validate(e.target.name, Number(e.target.value), index);
            });
        }
    }

    handleChangeNumber = (values, sourceInfo, index) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            let { examinationServices } = this.state;
            if (sourceInfo.event.target.name === "discountPercentage" && examinationServices[index].discountAmount) {
                examinationServices[index].discountAmount = null;
            }
            if (sourceInfo.event.target.name === "discountAmount" && examinationServices[index].discountPercentage) {
                examinationServices[index].discountPercentage = null;
            }
            examinationServices[index][sourceInfo.event.target.name] = floatValue;
            this.setState({ examinationServices: examinationServices }, () => {
                this.props.onChange(this.state.examinationServices);
                this.validate(sourceInfo.event.target.name, floatValue, index);
            });
        }
    }

    calculateDiscount = (index) => {
        let { examinationServices } = this.state;
        let result = (examinationServices[index].price * examinationServices[index].quantity) * (examinationServices[index].discountPercentage / 100);
        examinationServices[index].discountAmount = result.toFixed(2);
        this.setState({ examinationServices: examinationServices }, () => {
            this.props.onChange(this.state.examinationServices);
            this.validate("discountAmount", examinationServices[index].discountAmount, index);
        });
    }

    calculatePercentage = (index) => {
        let { examinationServices } = this.state;
        let result = (examinationServices[index].discountAmount / (examinationServices[index].price * examinationServices[index].quantity)) * 100;
        examinationServices[index].discountPercentage = result.toFixed(2);
        this.setState({ examinationServices: examinationServices }, () => {
            this.props.onChange(this.state.examinationServices)
            this.validate("discountPercentage", examinationServices[index].discountPercentage, index);
        });
    }

    handleChangeServiceComment = (e, index) => {
        let { examinationServices } = this.state;
        examinationServices[index][e.target.name] = e.target.value.trimStart();
        this.setState({ examinationServices: examinationServices }, () => {
            this.props.onChange(this.state.examinationServices);
            this.validate(e.target.name, examinationServices[index][e.target.name], index);
        });
    }

    addNewService = () => {
        let { examinationServices, validations } = this.state;
        examinationServices.push(JSON.parse(JSON.stringify(this.emptyExaminationService)));
        validations.push(JSON.parse(JSON.stringify(this.emptyValidation)));
        this.setState({ examinationServices: examinationServices, validations: validations }, () => {
            this.props.onChange(this.state.examinationServices);
            this.props.onValidationChange(this.state.validations);
            Object.entries(examinationServices[examinationServices.length - 1]).forEach(([key, value]) => {
                this.validate(key, value, examinationServices.length - 1);
            });
        });
    }

    removeService = (index) => {
        let { examinationServices, validations } = this.state;
        examinationServices.splice(index, 1);
        validations.splice(index, 1);
        this.setState({ examinationServices: examinationServices, validations: validations }, () => {
            this.props.onChange(this.state.examinationServices);
            this.props.onValidationChange(this.state.validations);
        });
    }

    validate = (field, value, index) => {
        let { validations, examinationServices } = this.state;
        switch (field) {
            case "serviceId":
                if (!value) {
                    validations[index].serviceIdError = true;
                    validations[index].serviceIdErrorText = this.props.t("required_field_message");
                } else {
                    validations[index].serviceIdError = false;
                    validations[index].serviceIdErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "quantity":
                if (!value) {
                    validations[index].quantityError = true;
                    validations[index].quantityErrorText = this.props.t("required_field_message");
                } else if (value === 0) {
                    validations[index].quantityError = true;
                    validations[index].quantityErrorText = this.props.t("quantity_regex_message");
                } else {
                    validations[index].quantityError = false;
                    validations[index].quantityErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "price":
                if (!value) {
                    validations[index].priceError = true;
                    validations[index].priceErrorText = this.props.t("required_field_message");
                } else {
                    validations[index].priceError = false;
                    validations[index].priceErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "discountAmount":
                if (value && value > (examinationServices[index].price * examinationServices[index].quantity)) {
                    validations[index].discountAmountError = true;
                    validations[index].discountAmountErrorText = this.props.t("discount_amount_regex_message");
                } else {
                    validations[index].discountAmountError = false;
                    validations[index].discountAmountErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "discountPercentage":
                if (value && value > 100) {
                    validations[index].discountPercentageError = true;
                    validations[index].discountPercentageErrorText = this.props.t("number_regex_message", { max: 3 });
                } else {
                    validations[index].discountPercentageError = false;
                    validations[index].discountPercentageErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            case "comment":
                if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validations[index].commentError = true;
                    validations[index].commentErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validations[index].commentError = false;
                    validations[index].commentErrorText = "";
                }
                this.setState({ validations: validations }, () => this.props.onValidationChange(this.state.validations));
                break;
            default:
                return;
        }
    }

    render() {
        const { serviceList } = this.props.serviceReducer;
        const { examinationServices, validations } = this.state;

        let columns = [
            {
                name: "service",
                width: '45%'
            },
            {
                name: "quantity",
                width: '10%',
            },
            {
                name: "price",
                width: '18%'
            },
            {
                name: "discount_amount",
                width: '15%',
            },
            {
                name: "discount_percentage_%",
                width: '12%',
            }
        ]

        let data = examinationServices.map((examinationService, index) => {
            return <React.Fragment key={index}>
                <TableRow >
                    <TableCell sx={{ borderBottom: "none !important" }}>
                        <FormControl>
                            <Autocomplete
                                id="serviceId"
                                name="serviceId"
                                value={serviceList.some(es => es.id === examinationService.serviceId) ? serviceList.find(es => es.id === examinationService.serviceId) : null}
                                getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                options={serviceList}
                                getOptionDisabled={option => examinationServices.some(es => es.serviceId === option.id)}
                                onChange={(event, service) => {
                                    this.handleChangeService(event, service, index)
                                }}
                                clearText={this.props.t("clear")}
                                closeText={this.props.t("close")}
                                openText={this.props.t("open")}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        placeholder={this.props.t("select_service") + " *"}
                                        error={validations[index].serviceIdError}
                                        helperText={validations[index].serviceIdErrorText}
                                    />}
                            />
                        </FormControl>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none !important" }}>
                        <TextField
                            id="quantity"
                            name="quantity"
                            type="number"
                            value={examinationService.quantity || ''}
                            onChange={(e) => this.handleChangeQuantity(e, index)}
                            disabled={examinationService.serviceId === null}
                            InputProps={{
                                inputProps: {
                                    min: 1
                                }
                            }}
                            error={validations[index].quantityError}
                            helperText={!validations[index].serviceIdError && validations[index].quantityErrorText}
                        />
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none !important" }}>
                        <NumericFormat
                            id="price"
                            name="price"
                            customInput={TextField}
                            decimalScale={2}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            value={examinationService.price}
                            onValueChange={(values, sourceInfo) => this.handleChangeNumber(values, sourceInfo, index)}
                            allowNegative={false}
                            disabled={examinationService.serviceId === null}
                            error={validations[index].priceError}
                            helperText={!validations[index].serviceIdError && validations[index].priceErrorText}
                        />
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none !important" }}>
                        <NumericFormat
                            id="discountAmount"
                            name="discountAmount"
                            title={this.props.t("press_enter_info_discount_amount")}
                            customInput={TextField}
                            decimalScale={2}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            value={examinationService.discountAmount || ''}
                            onValueChange={(values, sourceInfo) => this.handleChangeNumber(values, sourceInfo, index)}
                            disabled={examinationService.serviceId === null}
                            allowNegative={false}
                            onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    this.calculatePercentage(index)
                                }
                            }}
                            error={validations[index].discountAmountError}
                            helperText={validations[index].discountAmountErrorText}
                            isAllowed={(values) => {
                                if (!values.floatValue) return true;
                                const { floatValue } = values;
                                return floatValue >= 1 && floatValue <= examinationService.price;
                            }}
                        />
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none !important" }}>
                        <NumericFormat
                            id="discountPercentage"
                            name="discountPercentage"
                            title={this.props.t("press_enter_info_discount_price")}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            value={examinationService.discountPercentage || ''}
                            onValueChange={(values, sourceInfo) => this.handleChangeNumber(values, sourceInfo, index)}
                            allowNegative={false}
                            disabled={examinationService.serviceId === null}
                            onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                    this.calculateDiscount(index)
                                }
                            }}
                            error={validations[index].discountPercentageError}
                            helperText={validations[index].discountPercentageErrorText}
                            isAllowed={(values) => {
                                if (!values.floatValue) return true;
                                const { floatValue } = values;
                                return floatValue >= 1 && floatValue <= 100;
                            }}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan="4">
                        <InputLabel className="form-label">
                            {this.props.t("comment")}
                        </InputLabel>
                        <TextField
                            id="comment"
                            name="comment"
                            value={examinationService.comment || ''}
                            onChange={(e) => this.handleChangeServiceComment(e, index)}
                            placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                            disabled={examinationService.serviceId === null}
                            error={validations[index].commentError}
                            helperText={validations[index].commentErrorText}
                        />
                    </TableCell>
                    <TableCell align='center'>
                        {examinationServices.length > 1 && <Button sx={{ height: "40px !important", marginTop: "22px" }} title={this.props.t("delete")} className="default-button-delete" endIcon={<img src={DeleteIcon} alt="delete-icon" />} onClick={() => this.removeService(index)}>
                            {this.props.t("delete")}
                        </Button>}
                    </TableCell>
                </TableRow>
            </React.Fragment>
        })

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container sx={{ paddingLeft: this.props.location.pathname !== "/dashboard" && "0px !important" }}>
                    <Button sx={{ height: "40px", marginLeft: "auto" }} disabled={examinationServices.some(es => !es.serviceId)} startIcon={<img src={AddIcon} alt="add-icon" />} color="primary" onClick={() => this.addNewService()}>
                        {this.props.t("new_service")}
                    </Button>
                </Grid>
                <Grid item container sx={{ paddingLeft: this.props.location.pathname !== "/dashboard" && "0px !important" }}>
                    <Base58Table
                        columns={columns}
                        data={data}
                        pagination={false}
                        helperText="add_at_least_one_service"
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer
})

const mapActionsToProps = { getServiceList }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ExaminationServices)))
