import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Button,
    DialogContent,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    CircularProgress,
    InputAdornment,
    Alert,
    Stack,
    Box,
    Typography
} from '@mui/material';
import { InsertDriveFile } from '@mui/icons-material'
import { BootstrapDialog, Transition } from '../../components/common/Dialog';

class ImportServicesModal extends Component {

    render() {
        return (
            <BootstrapDialog
                onClose={() => this.props.onClose()}
                aria-labelledby="customized-dialog-title"
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth
                maxWidth={"md"}
            >
                <DialogContent sx={{ padding: "0px !important" }}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.t("import_services")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.onClose()}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    {this.props.fileName && <Button className="create-btn" color="primary" disabled={this.props.serviceReducer.isLoadingImport} onClick={() => this.props.importFile()}>
                                        {this.props.serviceReducer.isLoadingImport ? <CircularProgress
                                            size={20}
                                            color="secondary"
                                        /> : this.props.t("import")}
                                    </Button>}
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <Grid item>
                                        <Alert style={{ marginBottom: '5px' }} severity="warning">{this.props.t("import_services_info")}</Alert>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <input
                                            style={{ display: 'none' }}
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            id="servicesFile"
                                            name="importServices"
                                            type="file"
                                            onChange={(e) => { this.props.handleChangeFile(e); this.setState(() => e.target.value = null) }}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel>{this.props.fileName ? this.props.t("selected_file") : this.props.t("select_file")}</InputLabel>
                                            <OutlinedInput
                                                labelid="fileName"
                                                label={this.props.fileName ? this.props.t("selected_file") : this.props.t("select_file")}
                                                id="fileName"
                                                name="fileName"
                                                disabled={true}
                                                value={this.props.fileName || ''}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <label htmlFor="servicesFile">
                                                            <IconButton
                                                                component="span"
                                                                edge="end"
                                                            >
                                                                <InsertDriveFile />
                                                            </IconButton>
                                                        </label>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </BootstrapDialog >
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(ImportServicesModal))
