import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { getServiceGroupsList } from '../../redux/serviceGroup/actions'
import {
    createService,
    updateService,
    getServiceById
} from '../../redux/service/actions';
import { NAME_REGEX, COMMENT_REGEX, CODE_REGEX } from '../../components/common/regex';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { NumericFormat } from 'react-number-format';

class ManageService extends Component {

    emptyService = {
        serviceGroupId: null,
        code: '',
        name: '',
        comment: '',
        price: null
    };

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            initialService: JSON.parse(JSON.stringify(this.emptyService)),
            service: JSON.parse(JSON.stringify(this.emptyService)),
            selectedServiceGroup: null,
            validation: {
                serviceGroupError: false,
                serviceGroupErrorText: "",
                codeError: false,
                codeErrorText: "",
                nameError: false,
                nameErrorText: "",
                commentError: false,
                commentErrorText: "",
                priceError: false,
                priceErrorText: ""
            }
        };
    }

    componentDidMount() {
        this.props.getServiceGroupsList()
        if (this.props.match.params.id) {
            this.props.getServiceById(this.props.match.params.id).then(response => {
                this.setState({
                    service: JSON.parse(JSON.stringify(response.data)),
                    initialService: JSON.parse(JSON.stringify(response.data)),
                    selectedServiceGroup: this.props.serviceGroupReducer.serviceGroupsList.find(s => s.id === response.data.serviceGroupId)
                }, () => {
                    this.validate("serviceGroup", this.state.service.serviceGroupId);
                    this.validate("name", this.state.service.name);
                    this.validate("code", this.state.service.code);
                    this.validate("price", this.state.service.price);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("serviceGroup", this.state.service.serviceGroupId);
            this.validate("name", this.state.service.name);
            this.validate("code", this.state.service.code);
            this.validate("price", this.state.service.price);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { service } = this.state;
        service[target.name] = target.value.trimStart()
        this.setState({ service: service })
        this.validate(target.name, target.value);
    };

    handleChangePrice = (values) => {
        const { floatValue } = values;
        let { service } = this.state
        service.price = floatValue;
        this.setState({ service: service })
        this.validate("price", floatValue);
    }

    handleChangeServiceGroup = (e, group) => {
        let { service } = this.state;
        if (group !== null) {
            service.serviceGroupId = group.id
            this.setState({ selectedServiceGroup: group, service: service })

        } else {
            service.serviceGroupId = null
            this.setState({ selectedServiceGroup: null, service: service })
        }
        this.validate("serviceGroup", group);
    }

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "serviceGroup":
                if (value === null) {
                    validation.serviceGroupError = true;
                    validation.serviceGroupErrorText = this.props.t("required_field_message");
                } else {
                    validation.serviceGroupError = false;
                    validation.serviceGroupErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "code":
                if (value.length === 0) {
                    validation.codeError = true;
                    validation.codeErrorText = this.props.t("required_field_message");
                }
                else if (value.length > 0 && !CODE_REGEX.test(value)) {
                    validation.codeError = true;
                    validation.codeErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.codeError = false;
                    validation.codeErrorText = "";
                }
                this.setState({ validation: validation });
                break;

            case "comment":
                if (value.length > 0 && !COMMENT_REGEX.test(value)) {
                    validation.commentError = true;
                    validation.commentErrorText = this.props.t("name_regex_message", { min: 1, max: 500 });
                } else {
                    validation.commentError = false;
                    validation.commentErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "price":
                if (value === null) {
                    validation.priceError = true;
                    validation.priceErrorText = this.props.t("required_field_message");
                } else {
                    validation.priceError = false;
                    validation.priceErrorText = "";
                }
                this.setState({ validation: validation });
                break;

            default:
                return;
        }
    }

    manageService = () => {
        const { service } = this.state;
        const action = this.props.match.params.id ?
            this.props.updateService(this.props.match.params.id, service) :
            this.props.createService(service);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialService: JSON.parse(JSON.stringify(this.emptyService)),
                    service: JSON.parse(JSON.stringify(this.emptyService))
                }, () => this.props.history.push("/services"));
            }
        })
    };

    disable() {
        let { validation, service, initialService } = this.state;
        if (!service.name ||
            JSON.stringify(service) === JSON.stringify(initialService) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {

        let { initialService, service, selectedServiceGroup, validation, mainLoading } = this.state
        const { serviceGroupsList } = this.props.serviceGroupReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialService) !== JSON.stringify(service)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_service") : this.props.t("new_service")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push("/services")}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageService} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("service_group")}<span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <FormControl>
                                                        <WrappedAutocomplete
                                                            id="selectedServiceGroup"
                                                            name="selectedServiceGroup"
                                                            value={selectedServiceGroup}
                                                            placeholder={this.props.t("select")}
                                                            getOptionLabel={(option) => `${option.name}`}
                                                            isOptionEqualToValue={(option, value) => option.id === value.id}
                                                            options={serviceGroupsList}
                                                            onChange={(event, group) => {
                                                                this.handleChangeServiceGroup(event, group)
                                                            }}
                                                            error={validation.serviceGroupError}
                                                            helperText={validation.serviceGroupErrorText}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("code")}<span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        id="code"
                                                        name="code"
                                                        value={service.code}
                                                        placeholder={this.props.t("enter", { text: this.props.t("code").toLowerCase() })}
                                                        onChange={this.handleChange}
                                                        error={validation.codeError}
                                                        helperText={validation.codeErrorText} />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("service_name")}<span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        value={service.name}
                                                        placeholder={this.props.t("enter", { text: this.props.t("service_name").toLowerCase() })}
                                                        onChange={this.handleChange}
                                                        error={validation.nameError}
                                                        helperText={validation.nameErrorText} />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("comment")}
                                                    </InputLabel>
                                                    <TextField
                                                        id="comment"
                                                        name="comment"
                                                        value={service.comment || ""}
                                                        placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                                                        multiline
                                                        rows={5}
                                                        onChange={this.handleChange}
                                                        error={validation.commentError}
                                                        helperText={validation.commentErrorText} />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("price_eur")}<span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <NumericFormat
                                                        id="price"
                                                        customInput={TextField}
                                                        decimalScale={2}
                                                        placeholder={this.props.t("enter", { text: this.props.t("price_in_eur").toLowerCase() })}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        value={service.price || null}
                                                        onValueChange={(values) => this.handleChangePrice(values)}
                                                        allowNegative={false}
                                                        suffix={" EUR"}
                                                        error={validation.priceError}
                                                        helperText={validation.priceErrorText}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    serviceGroupReducer: state.serviceGroupReducer
})

const mapActionsToProps = { createService, updateService, getServiceById, getServiceGroupsList }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageService)))