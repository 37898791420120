import axios from '../axiosConfig';

export function getDocumentLanguages(config = {}) {
    return axios.get(`/v1/settings/document-languages`, config);
}

export function getSettings(config = {}) {
    return axios.get(`/v1/settings`, config);
}

export function updateSettings(data) {
    return axios.patch(`/v1/settings`, data);
}

export function getBalance(config = {}) {
    return axios.get(`/v1/notifications/balance`, config);
}

export function getHistory(config = {}) {
    return axios.get(`/v1/notifications`, config);
}

export function sendBulkSms(data) {
    return axios.post(`/v1/notifications/send-bulk-sms`, data);
}

export function getNotificationType(data) {
    return axios.get(`/v1/notifications/configuration/notification-type`, data);
}

export function getConfigurations(config = {}) {
    return axios.get(`/v1/notifications/configuration`, config);
}

export function createConfiguration(data) {
    return axios.post(`/v1/notifications/configuration`, data);
}

export function getTestGoogleForm(data) {
    return axios.post(`/v1/google-api/form-test`, data);
}

export function createFormConfiguration(data) {
    return axios.post(`/v1/google-api/form-config`, data);
}

export function getEmailConfigurations(config = {}) {
    return axios.get(`/v1/google-api/form-config`, config);
}

export function updateGoogleForm(data) {
    return axios.patch(`/v1/google-api/form-config`, data);
}

export function getAllGoogleForms(config = {}) {
    return axios.get(`/v1/google-api/form-list`, config);
}

export function getGoogleCredentials(config = {}) {
    return axios.get(`/v1/google-api/credentials`, config);
}

export function createGoogleCredentials(data) {
    return axios.post(`/v1/google-api/credentials`, data);
}

export function getGoogleFormConfigs(config = {}) {
    return axios.get(`/v1/google-api/form-configs`, config);
}

const actions = {
    getDocumentLanguages,
    getSettings,
    updateSettings,
    getBalance,
    getHistory,
    sendBulkSms,
    getNotificationType,
    getConfigurations,
    createConfiguration,
    getTestGoogleForm,
    createFormConfiguration,
    getEmailConfigurations,
    updateGoogleForm,
    getAllGoogleForms,
    getGoogleCredentials,
    createGoogleCredentials,
    getGoogleFormConfigs
}

export default actions;
