import types from './types';
import api from '../../api/warehouseApi';
import { snackActions } from '../snackbar/reducer';
import i18next from 'i18next';
import store from '../store';
import { getLoggedUser } from '../auth/actions';

/* WAREHOUSES */
/* -------------------------------- */
export const getWarehouses = () => async (dispatch) => {
    const params = store.getState().warehouseReducer.warehousesParams;
    let warehouses = await api.getWarehouses({ params: params });
    if (params.page > 0 && warehouses.data.content.length === 0) {
        dispatch(setWarehousesPage(params.page - 1));
    }
    const promises = warehouses.data.content.map(async wh => {
        const users = await api.getWarehouseUsers(wh.id);
        wh.users = users.data;
        return wh;
    });
    await Promise.all(promises).then(response => {
        warehouses.data.content = response;
        dispatch({
            type: types.SET_WAREHOUSES,
            payload: warehouses.data
        });
    }).catch(() => {
        dispatch({
            type: types.SET_WAREHOUSES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
}

/* export const getAllowedWarehouses = () => async (dispatch) => {
    try {
        const warehousesResponse = await api.getWarehouses({ params: { page: 0, size: 1000, sort: "id,desc" } });
        const warehouseIds = store.getState().authReducer.user.warehouseIds || [];
        return warehousesResponse.data.content.filter(w => warehouseIds.some(wid => wid === w.id));
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(snackActions.error(error.response.data.errors[0]));
        }
        return error.response.status;
    }
} */

export const getUserWarehouses = () => async (dispatch) => {
    return await api.getUserWarehouses().then(response => {
        return response.data;
    }).catch(error => {
        if (error.response && error.response.data) {
            dispatch(snackActions.error(error.response.data.errors[0]));
        }
        return error.response.status;
    });
}

export const setWarehousesPage = (value) => (dispatch) => {
    dispatch({
        type: types.SET_WAREHOUSES_PAGE,
        payload: Number(value)
    });
    dispatch(getWarehouses());
    return Promise.resolve();
};

export const setWarehousesSize = (value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_WAREHOUSES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getWarehouses());
    }
    return Promise.resolve();
};

export const setWarehousesParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_WAREHOUSES_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.warehousesParams, params)
    });
    dispatch(getWarehouses());
    return Promise.resolve();
};

export const createWarehouse = (data) => async (dispatch) => {
    try {
        const warehouseResponse = await api.createWarehouse(data);
        const userIds = data.users.map(u => u.id);
        await api.addUsersToWarehouse(warehouseResponse.data, { "userIds": userIds });
        await dispatch(getLoggedUser());
        dispatch(snackActions.success(i18next.t("warehouse_created_successfully")));
        return 201;
    } catch (error) {
        if (error.response && error.response.data) {
            if (error.response.data.code === 3100) {
                dispatch(snackActions.warning(error.response.data.errors[0]));
            } else {
                dispatch(snackActions.error(error.response.data.errors[0]));
            }
        }
        return error.response.status;
    }
}

export const updateWarehouse = (id, data) => async (dispatch) => {
    try {
        await api.updateWarehouse(id, data);
        await api.addUsersToWarehouse(id, { "userIds": data.addUserIds });
        await api.removeUsersFromWarehouse(id, { "userIds": data.removeUserIds });
        await dispatch(getLoggedUser());
        dispatch(snackActions.success(i18next.t("warehouse_updated_successfully")));
        return 200;
    } catch (error) {
        if (error.response && error.response.data) {
            if (error.response.data.code === 3100) {
                dispatch(snackActions.warning(error.response.data.errors[0]));
            } else {
                dispatch(snackActions.error(error.response.data.errors[0]));
            }
        }
        return error.response.status;
    }
}

export const deleteWarehouse = (id) => async (dispatch) => {
    await api.deleteWarehouse(id).then(() => {
        dispatch(getWarehouses());
        dispatch(snackActions.success(i18next.t("warehouse_deleted_successfully")));
    }).catch(error => {
        if (error.response && error.response.data) {
            if (error.response.data.code === 400) {
                dispatch(snackActions.warning(i18next.t("warehouse_delete_nok")));
            }
        }
    })
}

export const getWarehouseById = (id, config = {}) => async (dispatch) => {
    try {
        const warehouseResponse = await api.getWarehouseById(id, config);
        const warehouseUsersResponse = await api.getWarehouseUsers(id, config);
        let warehouse = warehouseResponse.data;
        warehouse.users = warehouseUsersResponse.data;
        return warehouse;
    } catch (error) {
        if (error.response && error.response.data) {
            if (error.response.data.code === 3100) {
                dispatch(snackActions.warning(error.response.data.errors[0]));
            } else {
                dispatch(snackActions.error(error.response.data.errors[0]));
            }
        }
        return error.response.status;
    }
}

export const getWarehouseCurrentState = (warehouseId) => async (dispatch) => {
    return await api.getWarehouseCurrentState(warehouseId).then(response => {
        return response.data;
    }).catch(error => {
        if (error.response && error.response.data) {
            dispatch(snackActions.error(error.response.data.errors[0]));
        }
        return error.response.status;
    });
}


/* ITEM CATEGORY */
/* -------------------------------- */
export const getCategories = (warehouseId) => async (dispatch) => {
    const params = store.getState().warehouseReducer.categoriesParams;
    await api.getCategories(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setCategoriesPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_ITEM_CATEGORIES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_ITEM_CATEGORIES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
    return Promise.resolve();
}

export const getListOfCategories = (warehouseId) => async (dispatch) => {
    await api.getListOfCategories(warehouseId).then(response => {
        dispatch({
            type: types.SET_LIST_OF_CATEGORIES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_LIST_OF_CATEGORIES,
            payload: []
        });
    });
    return Promise.resolve();
}

export const setCategoriesPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_CATEGORIES_PAGE,
        payload: Number(value)
    });
    dispatch(getCategories(warehouseId));
    return Promise.resolve();
};

export const setCategoriesSize = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_CATEGORIES_SIZE,
        payload: Number(value)
    });
    dispatch(getCategories(warehouseId));
    return Promise.resolve();
};

export const setCategoriesParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_CATEGORIES_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.categoriesParams, params)
    });
    dispatch(getCategories(warehouseId));
    return Promise.resolve();
};

export const createCategory = (data) => async (dispatch) => {
    return await api.createCategory(data).then((response) => {
        dispatch(snackActions.success(i18next.t("category_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const updateCategory = (id, data) => async (dispatch) => {
    return await api.updateCategory(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("category_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const deleteCategory = (id) => async (dispatch) => {
    return await api.deleteCategory(id).then((response) => {
        dispatch(snackActions.success(i18next.t("category_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response.data
    })
}

export const getCategoryById = (id, config = {}) => async (dispatch) => {
    return await api.getCategoryById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
}

/* -------------------------------- */
/* UNIT OF MEASUREMENT */

export const getUnitOfMeasurements = (warehouseId) => async (dispatch) => {
    const params = store.getState().warehouseReducer.measurementsParams;
    await api.getUnitOfMeasurements(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setMeasurementsPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_MEASUREMENTS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_MEASUREMENTS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
    return Promise.resolve();
}

export const getListOfMeasurements = (warehouseId) => async (dispatch) => {
    await api.getListOfMeasurements(warehouseId).then(response => {
        dispatch({
            type: types.SET_LIST_OF_MEASUREMENT,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_LIST_OF_MEASUREMENT,
            payload: []
        });
    });
    return Promise.resolve();
}

export const setMeasurementsPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_MEASUREMENTS_PAGE,
        payload: Number(value)
    });
    dispatch(getUnitOfMeasurements(warehouseId));
    return Promise.resolve();
};

export const setMeasurementsSize = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_MEASUREMENTS_SIZE,
        payload: Number(value)
    });
    dispatch(getUnitOfMeasurements(warehouseId));
    return Promise.resolve();
};

export const setMeasurementsParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_MEASUREMENTS_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.measurementsParams, params)
    });
    dispatch(getUnitOfMeasurements(warehouseId));
    return Promise.resolve();
};

export const createMeasurement = (data) => async (dispatch) => {
    return await api.createMeasurement(data).then((response) => {
        dispatch(snackActions.success(i18next.t("unit_of_measurement_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const updateMeasurement = (id, data) => async (dispatch) => {
    return await api.updateMeasurement(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("unit_of_measurement_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const deleteMeasurement = (id) => async (dispatch) => {
    return await api.deleteMeasurement(id).then((response) => {
        dispatch(snackActions.success(i18next.t("unit_of_measurement_deleted_successfully")));
        return response
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const getMeasurementById = (id, config = {}) => async (dispatch) => {
    return await api.getMeasurementById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("get_unit_of_measurements_error")));
    });
}

/* -------------------------------- */
/* ITEMS */
export const getItems = (warehouseId) => async (dispatch) => {
    const params = store.getState().warehouseReducer.itemsParams;
    await api.getItems(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setItemsPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_ITEMS,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_ITEMS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
    return Promise.resolve();
}

export const getListOfItems = (warehouseId) => async (dispatch) => {
    await api.getListOfItems(warehouseId).then(response => {
        dispatch({
            type: types.SET_LIST_OF_ITEM,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_LIST_OF_ITEM,
            payload: []
        });
    });
    return Promise.resolve();
}

export const setItemsPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_ITEMS_PAGE,
        payload: Number(value)
    });
    dispatch(getItems(warehouseId));
    return Promise.resolve();
};

export const setItemsSize = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_ITEMS_SIZE,
        payload: Number(value)
    });
    dispatch(getItems(warehouseId));
    return Promise.resolve();
};

export const setItemsParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_ITEMS_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.itemsParams, params)
    });
    dispatch(getItems(warehouseId));
    return Promise.resolve();
};

export const createItem = (data) => async (dispatch) => {
    return await api.createItem(data).then((response) => {
        dispatch(snackActions.success(i18next.t("item_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const updateItem = (id, data) => async (dispatch) => {
    return await api.updateItem(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("item_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const deleteItem = (id) => async (dispatch) => {
    return await api.deleteItem(id).then((response) => {
        dispatch(snackActions.success(i18next.t("item_deleted_successfully")));
        return response.data
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    })
}

export const getItemById = (id, config = {}) => async (dispatch) => {
    return await api.getItemById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
}

/* -------------------------------- */
/* DOCUMENTS */
export const getDocuments = (warehouseId) => async (dispatch) => {
    const params = store.getState().warehouseReducer.documentsParams
    return await api.getDocuments(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setDocumentsPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_DOCUMENTS,
            payload: response.data
        });
        return response
    }).catch(error => {
        dispatch({
            type: types.SET_DOCUMENTS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
        return error.response
    });
}

export const setDocumentsPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENTS_PAGE,
        payload: Number(value)
    });
    dispatch(getDocuments(warehouseId));
    return Promise.resolve();
};

export const setDocumentsSize = (warehouseId, value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENTS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getDocuments(warehouseId));
    }
    return Promise.resolve();
};

export const setDocumentsParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENTS_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.documentsParams, params)
    });
    dispatch(getDocuments(warehouseId));
    return Promise.resolve();
};

export const createDocument = (data) => async (dispatch) => {
    return await api.createDocument(data).then((response) => {
        dispatch(snackActions.success(i18next.t("document_created_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const updateDocument = (id, data) => async (dispatch) => {
    return await api.updateDocument(id, data).then(response => {
        dispatch(snackActions.success(i18next.t("document_updated_successfully")));
        return response
    }).catch((error) => {
        dispatch(snackActions.error(
            error.response && error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
        return error.response
    })
}

export const deleteDocument = (id) => async (dispatch) => {
    return await api.deleteDocument(id).then((response) => {
        dispatch(snackActions.success(i18next.t("document_deleted_successfully")));
        return response.data
    }).catch(error => {
        dispatch(snackActions.error(
            error.response.data.errors.map((item, index) => {
                return <li key={index}>{item}</li>
            })
        ));
    })
}

export const getDocumentById = (id, config = {}) => async (dispatch) => {
    return await api.getDocumentById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
}

export const getDocumentItemsbyDocumentId = (id) => async (dispatch) => {
    return await api.getDocumentItemsbyDocumentId(id, { params: store.getState().warehouseReducer.documentItemsParams }).then(response => {
        let items = [];
        if (response.data && response.data.content.length > 0) {

            items = response.data.content;
            const promises = items.map(async item => {
                const itemState = await api.getItemStateById(item.itemId);
                item.maxAllowedQuantity = item.quantity + itemState.data.quantity;
                return item;
            });

            Promise.all(promises).then(response => {
                items = response;
            })

            items = [...store.getState().warehouseReducer.documentItems, ...items]

            dispatch({
                type: types.SET_DOCUMENT_ITEMS,
                payload: {
                    content: items,
                    totalElements: response.data.totalElements
                }
            });

        } else {
            dispatch({
                type: types.SET_HAS_MORE_DOCUMENT_ITEMS,
                payload: false
            });
        }
        return items;
    }).catch(error => {
        dispatch({
            type: types.SET_DOCUMENT_ITEMS,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
        return error.response
    });
}

export const setDocumentItemsPage = (id, value) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENT_ITEMS_PAGE,
        payload: Number(value) + 1
    });
    dispatch(getDocumentItemsbyDocumentId(id));
    return Promise.resolve();
};

export const setDocumentItemsSize = (id, value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENT_ITEMS_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getDocumentItemsbyDocumentId(id));
    }
    return Promise.resolve();
};

export const setDocumentItemsParams = (params) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENT_ITEMS_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.documentItemsParams, params)
    });
    dispatch(getDocuments());
    return Promise.resolve();
};

export const getSuppliersByWarehouseId = (warehouseId, config = {}) => async (dispatch) => {
    return await api.getSuppliersByWarehouseId(warehouseId, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
}

export const getManufacturersByWarehouseId = (warehouseId, config = {}) => async (dispatch) => {
    return await api.getManufacturersByWarehouseId(warehouseId, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
}

export const getItemStates = (warehouseId) => async (dispatch) => {
    const params = store.getState().warehouseReducer.itemStatesParams;
    await api.getItemStates(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setItemStatesPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_ITEM_STATES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_ITEM_STATES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
    return Promise.resolve();
}


export const setItemStatesPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_ITEM_STATES_PAGE,
        payload: Number(value)
    });
    dispatch(getItemStates(warehouseId));
    return Promise.resolve();
};

export const setItemStatesSize = (warehouseId, value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_ITEM_STATES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getItemStates(warehouseId));
    }
    return Promise.resolve();
};

export const setItemStatesParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_ITEM_STATES_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.itemStatesParams, params)
    });
    dispatch(getItemStates(warehouseId));
    return Promise.resolve();
};

export const getItemStateById = (id, config = {}) => async (dispatch) => {
    return await api.getItemStateById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("")));
    });
}

export const getItemsDocumentItemsState = (warehouseId) => async (dispatch) => {
    const params = store.getState().warehouseReducer.documentItemsStatesParams;
    await api.getItemsDocumentItemsState(warehouseId, { params: params }).then(response => {
        if (params.page > 0 && response.data.content.length === 0) {
            dispatch(setDocumentItemsStatesPage(warehouseId, params.page - 1));
        }
        dispatch({
            type: types.SET_DOCUMENT_ITEMS_STATES,
            payload: response.data
        });
    }).catch(error => {
        dispatch({
            type: types.SET_DOCUMENT_ITEMS_STATES,
            payload: []
        });
        dispatch(snackActions.error(i18next.t("something_went_wrong")));
    });
    return Promise.resolve();
}


export const setDocumentItemsStatesPage = (warehouseId, value) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENT_ITEMS_STATES_PAGE,
        payload: Number(value)
    });
    dispatch(getItemsDocumentItemsState(warehouseId));
    return Promise.resolve();
};

export const setDocumentItemsStatesSize = (warehouseId, value, reset = false) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENT_ITEMS_STATES_SIZE,
        payload: Number(value)
    });
    if (!reset) {
        dispatch(getItemsDocumentItemsState(warehouseId));
    }
    return Promise.resolve();
};

export const setDocumentItemsStatesParams = (warehouseId, params) => (dispatch) => {
    dispatch({
        type: types.SET_DOCUMENT_ITEMS_STATES_PARAMS,
        payload: Object.assign(store.getState().warehouseReducer.documentItemsStatesParams, params)
    });
    dispatch(getItemsDocumentItemsState(warehouseId));
    return Promise.resolve();
};

export const getItemDocumentItemsStateById = (id, config = {}) => async (dispatch) => {
    return await api.getItemDocumentItemsStateById(id, config).then(response => {
        return response;
    }).catch(() => {
        dispatch(snackActions.error(i18next.t("")));
    });
}