import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { AppBar, Button, IconButton, Toolbar, Menu, MenuItem, Avatar, Typography, Chip, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import '../../components/sidebar/Sidebar.css';
import { connect } from 'react-redux';
import './Navbar.css'
import { withTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { updateInfirmaryForLogout, onLogout } from '../../redux/auth/actions';
import { ReactComponent as Logo } from '../../assets/icon/logo.svg';
import { FlagImage } from 'react-international-phone';
import ConfirmationDialog from '../common/ConfirmationDialog';

const languages = ["hr", "en"]

class Navbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false,
            openLogoutDialog: false
        }
    }

    handleMenu = (event) => {
        this.setState({ isMenuOpen: true, anchorEl: event.currentTarget })
    };

    handleLanguageMenu = (event) => {
        this.setState({ languageAnchorEl: event.currentTarget })
    }

    handleLanguageClose = () => {
        this.setState({ languageAnchorEl: null })
    };

    handleClose = () => {
        this.setState({ isMenuOpen: false })
    };

    getColor = (role) => {
        switch (role) {
            case 'SYSTEM':
                return '#D5F6E8'
            case 'ADMIN':
                return '#FFF9E0'
            case 'DOCTOR':
                return '#FEDED7'
            case 'RECEPTIONIST':
                return '#FDD8E0'
            case 'SIGNER':
                return '#CFF5FC'
            case 'SUPERADMIN':
                return '#f4fccf'
            default:
                return null;
        }
    }

    stringToColor = (string) => {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    stringAvatar = (name) => {
        return {
            sx: {
                width: "32px",
                height: "32px",
                fontSize: "16px",
                bgcolor: this.stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    render() {
        const { t } = this.props
        return (
            <AppBar
                color="primary"
                position="fixed"
                className={window.location.pathname.startsWith("/choose-infirmary") ? 'appBar-full' : 'appBar'}
                sx={{
                    boxShadow: '0px 1px 0px 0px #ADB5BD',
                    height: '60px'
                }}>
                <Toolbar>
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.props.handleDrawerToggle}
                        className="menuButton">
                        <MenuIcon />
                    </IconButton>
                    <div>
                        {window.location.pathname.startsWith("/choose-infirmary") && <Logo />}
                    </div>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center", marginLeft: "auto" }}>
                        <Typography fontSize={13} component={'span'} sx={{ color: 'black', textAlign: 'right' }}>
                            {`${this.props.authReducer.user.firstName} ${this.props.authReducer.user.lastName}`}
                            <Chip
                                sx={{ backgroundColor: this.getColor(this.props.authReducer.user.role), borderRadius: '0px', height: '21px', display: 'block', textAlign: 'center' }}
                                key={t(this.props.authReducer.user.role)}
                                tabIndex={-1}
                                label={<Typography variant="overline">{t(this.props.authReducer.user.role).toUpperCase()}</Typography>}
                                variant="filled"
                            />
                        </Typography>
                        <Avatar {...this.stringAvatar(`${this.props.authReducer.user?.firstName} ${this.props.authReducer.user?.lastName}`)} alt={`${this.props.authReducer.user?.firstName} ${this.props.authReducer.user?.lastName}`} />
                        <IconButton
                            size="large"
                            aria-label="languages"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={this.handleLanguageMenu}
                            color="inherit"
                        >
                            <FlagImage
                                iso2={this.props.i18n.language === "en" ? "gb" : this.props.i18n.language}
                                style={{ display: 'flex' }}
                                alt="flag"
                                title={t(this.props.i18n.language)}
                            />
                        </IconButton>
                        <Menu
                            style={{ marginTop: '30px', marginLeft: "4px" }}
                            id="menu-appbar"
                            anchorEl={this.state.languageAnchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(this.state.languageAnchorEl)}
                            onClose={this.handleLanguageClose}
                        >
                            {languages.filter(language => language !== this.props.i18n.language).map(language => {
                                return <MenuItem key={language} onClick={() => { this.props.i18n.changeLanguage(language); this.handleLanguageClose(); }}>
                                    <FlagImage
                                        iso2={language === "en" ? "gb" : language}
                                        style={{ display: 'flex' }}
                                        alt="flag"
                                        title={t(language)}
                                    />
                                </MenuItem>
                            })}
                        </Menu>
                        {window.location.pathname.startsWith("/choose-infirmary") && <Button className="create-btn" sx={{ height: '38px !important', marginTop: 'auto !important', marginBottom: 'auto !important', width: '40px !important' }} onClick={() => this.setState({ openLogoutDialog: true })}>
                            <LogoutIcon />
                        </Button>}
                    </Stack>
                </Toolbar>
                <ConfirmationDialog
                    isOpen={this.state.openLogoutDialog}
                    title={t("logout")}
                    message={t("logout_message")}
                    onClose={() => this.setState({ openLogoutDialog: false })}
                    onConfirm={() => this.props.user.currentInfirmary !== null ? this.props.updateInfirmaryForLogout(this.props.user.currentInfirmary.id) : this.props.onLogout()}
                    closeButtonTitle={t("no")}
                    confirmButtonTitle={t("yes")}
                />
            </AppBar >
        );
    }
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer
})

export default connect(mapStateToProps, { updateInfirmaryForLogout, onLogout })(withRouter(withTranslation()(Navbar)));
