import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    Stack
} from '@mui/material';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { UserRole, hasAnyRole } from '../../utils/Security';
import moment from 'moment';

class CustomToolbar extends Toolbar {

    constructor(props) {
        super(props);
        this.state = {
            selectedBtn: this.props.selectedBtn ? this.props.selectedBtn : 'day',
            navigateBtn: this.props.navigateBtn ? this.props.navigateBtn : "",
            view: this.props.view ? this.props.view : "day"
        };
    }

    componentDidUpdate() {
        if (this.props.view === 'day' && this.props.selectedBtn === 'day') {
            this.props.onView("day")
        } else {
            this.props.onView(this.state.selectedBtn)
        }
    }

    getDateFormatByView = () => {
        switch (this.props.view) {
            case 'month':
                return moment(this.props.date).locale(localStorage.getItem('i18nextLng')).format('MMMM, YYYY');
            case 'work_week':
                if (localStorage.getItem('i18nextLng') === "en") {
                    return `${moment(this.props.date).locale(localStorage.getItem('i18nextLng')).startOf("week").add(1, 'days').format('MMMM D')} - ${moment(this.props.date).locale(localStorage.getItem('i18nextLng')).endOf("week").subtract(1, 'days').format('D, YYYY')}`;
                } else if (localStorage.getItem('i18nextLng') === "hr") {
                    return `${moment(this.props.date).locale(localStorage.getItem('i18nextLng')).startOf("week").format('MMMM D')} - ${moment(this.props.date).locale(localStorage.getItem('i18nextLng')).endOf("week").subtract(2, 'days').format('D, YYYY')}`;
                } else {
                    return `date format is not set for '${localStorage.getItem('i18nextLng')}' language`;
                }
            case 'day':
                return moment(this.props.date).locale(localStorage.getItem('i18nextLng')).format('dddd, MMMM D, YYYY');
            default:
                return `date format is not set for '${this.props.view}' view`;
        }
    }

    render() {
        return (
            <Grid container direction="row" style={{ marginBottom: '10px' }}>
                <Grid item container direction="column" xs={12} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
                    <Stack direction="row" spacing={0.5}>
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Button onClick={() => this.setState({ selectedBtn: "month" }, () => this.props.onView("month"))} className={this.state.selectedBtn === "month" ? "calendar-nav-btn-selected" : "calendar-nav-btn-not-selected"}>{this.props.t("month")}</Button>}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Button onClick={() => this.setState({ selectedBtn: "work_week" }, () => this.props.onView("work_week"))} className={this.state.selectedBtn === "work_week" ? "calendar-nav-btn-selected" : "calendar-nav-btn-not-selected"}>{this.props.t("week")}</Button>}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Button onClick={() => this.setState({ selectedBtn: "day" }, () => this.props.onView("day"))} className={this.state.selectedBtn === "day" ? "calendar-nav-btn-selected" : "calendar-nav-btn-not-selected"}>{this.props.t("day")} </Button>}
                    </Stack>
                </Grid>
                <Grid item container direction="column" xs={12} sm={3} md={3} lg={3} xl={3} style={{ textAlign: "center", fontWeight: 500, color: "#24A36C", fontSize: "18px" }}>
                    {this.getDateFormatByView()}
                </Grid>
                <Grid container item direction="column" xs={12} sm={4.5} md={4.5} lg={4.5} xl={4.5}>
                    {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST, UserRole.DOCTOR]) && <Stack direction="row" spacing={0.5} sx={{ marginLeft: "auto" }}>
                        <Button onClick={() => this.setState({ navigateBtn: "TODAY" }, () => this.props.onNavigate("TODAY"))} className={this.state.navigateBtn === "TODAY" ? "calendar-nav-btn-selected" : "calendar-nav-btn-not-selected"}>{this.props.t("today")}</Button>
                        <Button onClick={() => this.setState({ navigateBtn: "" }, () => this.props.onNavigate('PREV'))}><ArrowBackIosIcon className="table-fab-icon" /></Button>
                        <Button onClick={() => this.setState({ navigateBtn: "" }, () => this.props.onNavigate('NEXT'))}><ArrowForwardIosIcon className="table-fab-icon" /></Button>
                    </Stack>}
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(withTranslation()(CustomToolbar));
