/* WAREHOUSES*/
export const SET_WAREHOUSES = 'SET_WAREHOUSES';
export const SET_WAREHOUSES_PAGE = 'SET_WAREHOUSES_PAGE';
export const SET_WAREHOUSES_SIZE = 'SET_WAREHOUSES_SIZE';
export const SET_WAREHOUSES_PARAMS = 'SET_WAREHOUSES_PARAMS';
export const RESET_WAREHOUSES_DATA = 'RESET_WAREHOUSES_DATA';

/* ITEM CATEGORY */
export const SET_ITEM_CATEGORIES = 'SET_ITEM_CATEGORIES';
export const SET_LIST_OF_CATEGORIES = 'SET_LIST_OF_CATEGORIES'
export const SET_CATEGORIES_PAGE = 'SET_CATEGORIES_PAGE';
export const SET_CATEGORIES_SIZE = 'SET_CATEGORIES_SIZE';
export const SET_CATEGORIES_PARAMS = 'SET_CATEGORIES_PARAMS';
export const RESET_CATEGORIES_DATA = 'RESET_CATEGORIES_DATA';
/* UNIT OF MEASUREMENT */
export const SET_MEASUREMENTS = 'SET_MEASUREMENTS';
export const SET_LIST_OF_MEASUREMENT = 'SET_LIST_OF_MEASUREMENT'
export const SET_MEASUREMENTS_PAGE = 'SET_MEASUREMENTS_PAGE';
export const SET_MEASUREMENTS_SIZE = 'SET_MEASUREMENTS_SIZE';
export const SET_MEASUREMENTS_PARAMS = 'SET_MEASUREMENTS_PARAMS';
export const RESET_MEASUREMENTS_DATA = 'RESET_MEASUREMENTS_DATA';
/* ITEMS */
export const SET_ITEMS = 'SET_ITEMS';
export const SET_LIST_OF_ITEM = 'SET_LIST_OF_ITEM'
export const SET_ITEMS_PAGE = 'SET_ITEMS_PAGE';
export const SET_ITEMS_SIZE = 'SET_ITEMS_SIZE';
export const SET_ITEMS_PARAMS = 'SET_ITEMS_PARAMS';
export const RESET_ITEMS_DATA = 'RESET_ITEMS_DATA';
/* DOCUMENTS */
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_DOCUMENTS_PAGE = 'SET_DOCUMENTS_PAGE';
export const SET_DOCUMENTS_SIZE = 'SET_DOCUMENTS_SIZE';
export const SET_DOCUMENTS_PARAMS = 'SET_DOCUMENTS_PARAMS';
export const RESET_DOCUMENTS_DATA = 'RESET_DOCUMENTS_DATA';
export const SET_DOCUMENT_ITEMS = 'SET_DOCUMENT_ITEMS';
export const SET_DOCUMENT_ITEMS_PAGE = 'SET_DOCUMENT_ITEMS_PAGE';
export const SET_DOCUMENT_ITEMS_SIZE = 'SET_DOCUMENT_ITEMS_SIZE';
export const SET_DOCUMENT_ITEMS_PARAMS = 'SET_DOCUMENT_ITEMS_PARAMS';
export const RESET_DOCUMENT_ITEMS_DATA = 'RESET_DOCUMENT_ITEMS_DATA';
export const SET_HAS_MORE_DOCUMENT_ITEMS = 'SET_HAS_MORE_DOCUMENT_ITEMS'
/* STATES */
export const SET_ITEM_STATES = 'SET_ITEM_STATES'
export const SET_ITEM_STATES_PAGE = 'SET_ITEM_STATES_PAGE';
export const SET_ITEM_STATES_SIZE = 'SET_ITEM_STATES_SIZE';
export const SET_ITEM_STATES_PARAMS = 'SET_ITEM_STATES_PARAMS';
export const RESET_ITEM_STATES_DATA = 'RESET_ITEM_STATES_DATA';

export const SET_DOCUMENT_ITEMS_STATES = 'SET_DOCUMENT_ITEMS_STATES'
export const SET_DOCUMENT_ITEMS_STATES_PAGE = 'SET_DOCUMENT_ITEMS_STATES_PAGE';
export const SET_DOCUMENT_ITEMS_STATES_SIZE = 'SET_DOCUMENT_ITEMS_STATES_SIZE';
export const SET_DOCUMENT_ITEMS_STATES_PARAMS = 'SET_DOCUMENT_ITEMS_STATES_PARAMS';
export const RESET_DOCUMENT_ITEMS_STATES_DATA = 'RESET_DOCUMENT_ITEMS_STATES_DATA';

const types = {
    SET_WAREHOUSES,
    SET_WAREHOUSES_PAGE,
    SET_WAREHOUSES_SIZE,
    SET_WAREHOUSES_PARAMS,
    RESET_WAREHOUSES_DATA,
    SET_ITEM_CATEGORIES,
    SET_LIST_OF_CATEGORIES,
    SET_CATEGORIES_PAGE,
    SET_CATEGORIES_SIZE,
    SET_CATEGORIES_PARAMS,
    RESET_CATEGORIES_DATA,
    SET_MEASUREMENTS,
    SET_LIST_OF_MEASUREMENT,
    SET_MEASUREMENTS_PAGE,
    SET_MEASUREMENTS_SIZE,
    SET_MEASUREMENTS_PARAMS,
    RESET_MEASUREMENTS_DATA,
    SET_ITEMS,
    SET_LIST_OF_ITEM,
    SET_ITEMS_PAGE,
    SET_ITEMS_SIZE,
    SET_ITEMS_PARAMS,
    RESET_ITEMS_DATA,
    SET_DOCUMENTS,
    SET_DOCUMENTS_PAGE,
    SET_DOCUMENTS_SIZE,
    SET_DOCUMENTS_PARAMS,
    RESET_DOCUMENTS_DATA,
    SET_DOCUMENT_ITEMS,
    SET_DOCUMENT_ITEMS_PAGE,
    SET_DOCUMENT_ITEMS_SIZE,
    SET_DOCUMENT_ITEMS_PARAMS,
    RESET_DOCUMENT_ITEMS_DATA,
    SET_HAS_MORE_DOCUMENT_ITEMS,
    SET_ITEM_STATES,
    SET_ITEM_STATES_PAGE,
    SET_ITEM_STATES_SIZE,
    SET_ITEM_STATES_PARAMS,
    RESET_ITEM_STATES_DATA,
    SET_DOCUMENT_ITEMS_STATES,
    SET_DOCUMENT_ITEMS_STATES_PAGE,
    SET_DOCUMENT_ITEMS_STATES_SIZE,
    SET_DOCUMENT_ITEMS_STATES_PARAMS,
    RESET_DOCUMENT_ITEMS_STATES_DATA
};

export default types;