export const SET_DOCUMENT_LANGUAGES = 'SET_DOCUMENT_LANGUAGES';
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_BALANCE = 'SET_BALANCE'
export const SET_HISTORY_LOADING = 'SET_HISTORY_LOADING'
export const SET_HISTORY = 'SET_HISTORY'
export const SET_HISTORY_PAGE = 'SET_HISTORY_PAGE'
export const SET_HISTORY_SIZE = 'SET_HISTORY_SIZE'
export const SET_HISTORY_PARAMS = 'SET_HISTORY_PARAMS'
export const SET_NOTIFICATION_TYPE = 'SET_NOTIFICATION_TYPE'
export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS'
export const SET_CONFIGURATIONS_LOADING = 'SET_CONFIGURATIONS_LOADING'
export const SET_CONFIGURATIONS_PAGE = 'SET_CONFIGURATIONS_PAGE'
export const SET_CONFIGURATIONS_SIZE = 'SET_CONFIGURATIONS_SIZE'
export const SET_CONFIGURATIONS_PARAMS = 'SET_CONFIGURATIONS_PARAMS'
export const SET_GOOGLE_FORM_TEST_URL = 'SET_GOOGLE_FORM_TEST_URL'
export const GOOGLE_FORM_LOADING = 'GOOGLE_FORM_LOADING'
export const SET_ALL_GOOGLE_FORMS = 'SET_ALL_GOOGLE_FORMS'
export const SET_GOOGLE_FORMS_PAGE = 'SET_GOOGLE_FORMS_PAGE'
export const SET_GOOGLE_FORMS_SIZE = 'SET_GOOGLE_FORMS_SIZE'
export const SET_GOOGLE_FORMS_PARAMS = 'SET_GOOGLE_FORMS_PARAMS'
export const SET_GOOGLE_CREDENTIALS_LOADING = 'SET_GOOGLE_CREDENTIALS_LOADING'
export const SET_GOOGLE_CREDENTIALS = 'SET_GOOGLE_CREDENTIALS'
export const SET_GOOGLE_CREDENTIALS_PAGE = 'SET_GOOGLE_CREDENTIALS_PAGE'
export const SET_GOOGLE_CREDENTIALS_SIZE = 'SET_GOOGLE_CREDENTIALS_SIZE'
export const SET_GOOGLE_CREDENTIALS_PARAMS = 'SET_GOOGLE_CREDENTIALS_PARAMS'

export const SET_GOOGLE_FORM_CONFIGS_LOADING = 'SET_GOOGLE_FORM_CONFIGS_LOADING';
export const SET_GOOGLE_FORM_CONFIGS = 'SET_GOOGLE_FORM_CONFIGS';
export const SET_GOOGLE_FORM_CONFIGS_PAGE = 'SET_GOOGLE_FORM_CONFIGS_PAGE';
export const SET_GOOGLE_FORM_CONFIGS_SIZE = 'SET_GOOGLE_FORM_CONFIGS_SIZE';
export const SET_GOOGLE_FORM_CONFIGS_PARAMS = 'SET_GOOGLE_FORM_CONFIGS_PARAMS';

const types = {
    SET_DOCUMENT_LANGUAGES,
    SET_SETTINGS,
    SET_BALANCE,
    SET_HISTORY_LOADING,
    SET_HISTORY,
    SET_HISTORY_PAGE,
    SET_HISTORY_SIZE,
    SET_HISTORY_PARAMS,
    SET_NOTIFICATION_TYPE,
    SET_CONFIGURATIONS,
    SET_CONFIGURATIONS_LOADING,
    SET_CONFIGURATIONS_PAGE,
    SET_CONFIGURATIONS_SIZE,
    SET_CONFIGURATIONS_PARAMS,
    SET_GOOGLE_FORM_TEST_URL,
    GOOGLE_FORM_LOADING,
    SET_ALL_GOOGLE_FORMS,
    SET_GOOGLE_FORMS_PARAMS,
    SET_GOOGLE_FORMS_PAGE,
    SET_GOOGLE_FORMS_SIZE,
    SET_GOOGLE_CREDENTIALS_LOADING,
    SET_GOOGLE_CREDENTIALS,
    SET_GOOGLE_CREDENTIALS_PAGE,
    SET_GOOGLE_CREDENTIALS_SIZE,
    SET_GOOGLE_CREDENTIALS_PARAMS,

    SET_GOOGLE_FORM_CONFIGS_LOADING,
    SET_GOOGLE_FORM_CONFIGS,
    SET_GOOGLE_FORM_CONFIGS_PAGE,
    SET_GOOGLE_FORM_CONFIGS_SIZE,
    SET_GOOGLE_FORM_CONFIGS_PARAMS
};

export default types;