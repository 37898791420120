import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    Tab,
    Tabs
} from '@mui/material';
import { ReactComponent as WarehouseActiveIcon } from '../../../assets/icons/warehouse/warehouse_active.svg';
import { ReactComponent as WarehouseInactiveIcon } from '../../../assets/icons/warehouse/warehouse_inactive.svg';
import { ReactComponent as DocumentsActiveIcon } from '../../../assets/icons/warehouse/documents_active.svg';
import { ReactComponent as DocumentsInactiveIcon } from '../../../assets/icons/warehouse/documents_inactive.svg';
import { CustomTabPanel, a11yProps } from '../../../components/common/tabs';
import WarehouseState from './WarehouseState';
import DocumentsItems from './DocumentsItems';

class States extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleChangeTab = (name, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        const { selectedTab } = this.state;

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box sx={{ borderBottom: 1, border: 'none' }}>
                            {selectedTab > -1 && <Tabs value={selectedTab} onChange={(e, value) => this.handleChangeTab(e.target.name, value)}
                                TabIndicatorProps={{
                                    sx: { display: 'none' }
                                }}>
                                <Tab id="items" name="warehouse_state" icon={selectedTab === 0 ? <WarehouseInactiveIcon /> : <WarehouseActiveIcon />} iconPosition="start" label={this.props.t("warehouse_state")} {...a11yProps(0)} wrapped />
                                <Tab id="documents" name="documents_items" icon={selectedTab === 1 ? <DocumentsInactiveIcon /> : <DocumentsActiveIcon />} iconPosition="start" label={this.props.t("document_items")} {...a11yProps(1)} wrapped />
                            </Tabs>}
                        </Box>
                    </Grid>
                    {this.props.match.params.warehouseId && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CustomTabPanel value={selectedTab} index={0} style={{ width: "100%" }}>
                            <WarehouseState />
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTab} index={1} style={{ width: "100%" }}>
                            <DocumentsItems />
                        </CustomTabPanel>
                    </Grid>}
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({

})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(States)))