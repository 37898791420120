import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    DialogContent,
    Grid,
    FormControl,
    Box,
    Stack,
    Typography,
    InputLabel
} from '@mui/material';
import {
    getAuditLogsActions,
    deleteAuditLogs
} from '../../redux/auditLog/actions';
import { withTranslation } from 'react-i18next';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import { BootstrapDialog, Transition } from '../../components/common/Dialog';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

class DeleteAuditLogsDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAction: null,
            selectedStartDate: null,
            selectedEndDate: null,
            promptDialogOpen: false
        };
    }

    componentDidMount() {
        this.props.getAuditLogsActions()
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            selectedAction: null,
            selectedStartDate: null,
            selectedEndDate: null
        });
    }

    handleChangeAction = (e, action) => {
        if (action !== null) {
            this.setState({ selectedAction: action })
        } else {
            this.setState({ selectedAction: null })
        }
    }

    handleChangeStartDate = (date) => {
        this.setState({ selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        this.setState({ selectedEndDate: date });
    }

    deleteAuditLogs = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { selectedAction, selectedStartDate, selectedEndDate } = this.state;
        let params = {}
        params.action = selectedAction
        params.startTime = selectedStartDate !== null ? moment(selectedStartDate) : null
        params.endTime = selectedEndDate !== null ? moment(selectedEndDate) : null
        this.props.deleteAuditLogs({ params: params }).then(() => {
            this.closeDialog()
            this.props.onSave()
        })
    };

    validateForm() {
        let { selectedAction } = this.state;
        let valid = true;
        if (!selectedAction) {
            valid = false;
        }
        return valid;
    }

    render() {
        let { selectedAction, selectedStartDate, selectedEndDate, promptDialogOpen } = this.state
        const { auditLogsActions } = this.props.auditReducer

        return (
            <BootstrapDialog
                onClose={() => (selectedAction || selectedStartDate || selectedEndDate) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}
                open={this.props.isOpen}
                fullWidth
                TransitionComponent={Transition}>
                <form onSubmit={this.deleteAuditLogs} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Box className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                            {this.props.t("delete_audit_logs")}
                                        </Typography>
                                        <Button className="cancel-btn" onClick={() => (selectedAction || selectedStartDate || selectedEndDate) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}>
                                            {this.props.t("cancel")}
                                        </Button>
                                        <Button className="create-btn" color="primary" disabled={!this.validateForm()} type="submit">
                                            {this.props.t("delete")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("action")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <FormControl>
                                                    <WrappedAutocomplete
                                                        id="selectedAction"
                                                        name="selectedAction"
                                                        value={selectedAction || null}
                                                        options={auditLogsActions}
                                                        onChange={(e, action) => {
                                                            this.handleChangeAction(e, action)
                                                        }}
                                                        placeholder={this.props.t("select_action")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("start_date")}
                                                </InputLabel>
                                                <FormControl>
                                                    <WrappedDatePicker
                                                        id="start_date"
                                                        name="startDate"
                                                        onChange={this.handleChangeStartDate}
                                                        value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("end_date")}
                                                </InputLabel>
                                                <FormControl>
                                                    <WrappedDatePicker
                                                        id="end_date"
                                                        name="endDate"
                                                        onChange={this.handleChangeEndDate}
                                                        value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </form>
                <ConfirmationDialog
                    isOpen={promptDialogOpen}
                    title={this.props.t("prompt_title")}
                    message={this.props.t("prompt_message_2")}
                    onClose={() => this.setState({ promptDialogOpen: false })}
                    onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.closeDialog())}
                    closeButtonTitle={this.props.t("continue")}
                    confirmButtonTitle={this.props.t("discard")}
                />
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    auditReducer: state.auditReducer
})

const mapActionsToProps = { getAuditLogsActions, deleteAuditLogs }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(DeleteAuditLogsDialog)))
