import types from './types';

const initialState = {
    normativesLoading: false,
    normatives: [],
    normativesTotalElements: 0,
    normativesParams: {
        page: 0,
        size: 10
    },
    listOfNorms: []
}

export default function normative(state = initialState, action) {
    switch (action.type) {
        case types.SET_NORMATIVES_LOADING:
            return {
                ...state,
                normativesLoading: true
            }
        case types.SET_NORMATIVES:
            return {
                ...state,
                normatives: action.payload.content,
                normativesTotalElements: action.payload ? action.payload.totalElements : 0,
                normativesLoading: false
            }
        case types.SET_LIST_OF_NORMS:
            return {
                ...state,
                listOfNorms: action.payload
            }
        case types.SET_NORMATIVES_PAGE:
            return {
                ...state, normativesParams: { ...state.normativesParams, page: action.payload }
            }
        case types.SET_NORMATIVES_SIZE:
            return {
                ...state, normativesParams: { ...state.normativesParams, size: action.payload, page: 0 }
            }
        case types.SET_NORMATIVES_PARAMS:
            return {
                ...state,
                normativesParams: action.payload
            }
        case types.RESET_NORMATIVE_DATA:
            return {
                ...initialState,
                servicesParams: {
                    size: 10,
                    page: 0
                }
            }
        default:
            return state;
    }
}