import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Chip,
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Tooltip,
    Zoom,
    Stack,
    Box,
    Divider
} from '@mui/material';
import Base58Table from "../../components/common/Base58Table";
import { getInfirmaries, deleteInfirmary, setInfirmariesPage, setInfirmariesSize, setInfirmariesParams, getInfirmaryList } from '../../redux/infrimary/actions'
import { getDoctors } from '../../redux/user/actions'
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import { UserRole, hasAnyRole } from '../../utils/Security';
import types from '../../redux/infrimary/types';
import store from '../../redux/store';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';

class Infirmaries extends React.Component {

    emptyInfirmary = {
        id: null,
        name: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            infirmaryToDelete: this.emptyInfirmary,
            openDeleteDialog: false
        };
    }

    componentDidMount() {
        hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && this.props.getInfirmaries() && this.props.getInfirmaryList()
        hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && this.props.getDoctors()
    }

    componentWillUnmount() {
        this.props.setInfirmariesSize(10, true)
        store.dispatch({
            type: types.RESET_INFIRMARY_DATA
        })
    }

    deleteInfirmary = () => {
        this.props.deleteInfirmary(this.state.infirmaryToDelete.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteDialog: false }, () => this.props.getInfirmaries(), this.props.setInfirmariesPage(0));
            }
        })
    }

    render() {
        const { infirmariesLoading, infirmaries, infirmariesParams, infirmariesTotalElements } = this.props.infirmaryReducer;

        let infirmariesList = infirmaries && infirmaries.map((infirmary, index) => {
            return <TableRow key={index}>
                <TableCell>{infirmary.name && infirmary.name.length > 55 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={infirmary.name} sx={{ cursor: 'pointer' }}>
                    <span>{infirmary.name && infirmary.name.length > 55 ? infirmary.name.substring(0, 55) + "..." : infirmary.name}</span>
                </Tooltip> :
                    infirmary.name}</TableCell>
                <TableCell>{infirmary.currentOccupant && infirmary.currentOccupant.firstName + " " + infirmary.currentOccupant.lastName}</TableCell>
                <TableCell>{infirmary.comment && infirmary.comment.length > 35 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={infirmary.comment} sx={{ cursor: 'pointer' }}>
                    <span>{infirmary.comment && infirmary.comment.length > 35 ? infirmary.comment.substring(0, 35) + "..." : infirmary.comment}</span>
                </Tooltip> :
                    infirmary.comment}</TableCell>
                <TableCell align='center'><Chip sx={{ backgroundColor: infirmary.calendarHexColor, width: '32px' }}></Chip></TableCell>
                <TableCell className='row-cell' align='center'>{
                    <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                        <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push("/infirmaries/" + infirmary.id)}>
                            {this.props.t("edit")}
                        </Button>
                        <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ infirmaryToDelete: infirmary, openDeleteDialog: true })}>
                            {this.props.t("delete")}
                        </Button>
                    </Stack>
                }</TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "name_infirmary",
                dbName: "name",
                width: '35%',
                sort: true,
                filter: true
            },
            {
                name: "current_occupant",
                dbName: "current_occupant",
                width: '20%',
                sort: true
            },
            {
                name: "comment",
                dbName: "comment",
                width: '18%',
                sort: true
            },
            {
                name: "background_color",
                width: '9%'
            },
            {
                name: '',
                width: '18%'
            }
        ]

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                            {this.props.t("infirmaries")}
                            <Button
                                className="create-btn"
                                startIcon={<AddIcon />}
                                style={{ marginLeft: 'auto' }}
                                onClick={() => this.props.history.push("/new-infirmary")}>
                                {this.props.t("new_infirmary")}
                            </Button>
                        </Typography>
                        <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Base58Table
                            isLoading={infirmariesLoading}
                            columns={columns}
                            data={infirmariesList}
                            count={infirmariesTotalElements}
                            rowsPerPage={infirmariesParams.size}
                            page={infirmariesParams.page}
                            onPageChange={(e, page) => this.props.setInfirmariesPage(page)}
                            onRowsPerPageChange={(e) => this.props.setInfirmariesSize(e.target.value)}
                            onFilterChange={(params) => this.props.setInfirmariesParams(params)}
                        />
                    </Grid>
                </Grid>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_infirmary")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_infirmary_message", { name: this.state.infirmaryToDelete.name }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.deleteInfirmary()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    infirmaryReducer: state.infirmaryReducer
})

const mapActionsToProps = { getInfirmaries, getDoctors, deleteInfirmary, setInfirmariesPage, setInfirmariesSize, setInfirmariesParams, getInfirmaryList }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Infirmaries))