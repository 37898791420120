import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
    TableHead,
    TableRow,
    TableCell,
    Typography,
    FormControl,
    Select,
    MenuItem,
    Box
} from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import SearchInput from './SearchInput';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { NumericFormat } from 'react-number-format';
import WrappedAutocomplete from './WrappedAutocomplete';

class SortingAndFiltering extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filter: null
        };
    }

    componentDidUpdate() {
        if (this.state.filter !== this.props.filter) {
            this.setState({ filter: this.props.filter });
        }
    }

    onSortChange = (column, order) => {
        this.setState({
            filter: { ...this.state.filter, sort: column + "," + order }
        }, () => this.props.onSortChange(this.state.filter))
    }

    onChange = (e) => {
        this.setState({
            filter: { ...this.state.filter, [e.target.name]: (e.target.value != null && e.target.value.length > 0) ? e.target.value : null }
        }, () => this.props.onFilterChange(e, this.state.filter));
    }

    onMultiSelectChange = (e, name, items) => {
        this.setState({
            filter: { ...this.state.filter, [name]: (items != null && items.length > 0) ? items : null }
        }, () => this.props.onFilterChange(e, this.state.filter));
    }

    onNumberChange = (values, sourceInfo) => {
        if (sourceInfo.event) {
            const { floatValue } = values;
            this.setState({
                filter: { ...this.state.filter, [sourceInfo.event.target.name]: floatValue /* ? floatValue : null */ }
            }, () => this.props.onFilterChange(sourceInfo.event, this.state.filter));
        }
    }

    onSearch = (column) => {
        if (this.props.filter[column] === null || String(this.props.filter[column]).length > 0) {
            this.props.onFilterSearch(this.state.filter);
        }
    }

    onClear = (e) => {
        this.setState({ filter: { ...this.state.filter, [e.currentTarget.id]: null } }, () => this.props.onFilterClear(this.state.filter));
    }

    render() {
        return (
            <TableHead>
                <TableRow>
                    {this.props.columns.map((column, index) => {
                        if (column.sort) {
                            return <TableCell
                                key={"sort-" + index}
                                className={index === 0 ? "base58-table-header-cell-first" : "base58-table-header-cell"}
                                component="th"
                                scope="row"
                                width={column.width}>
                                <Typography className="base58-table-header-cell-title">
                                    {this.props.t(column.name)} {column.required && "*"}
                                </Typography>
                                {this.props.filter.sort == null || this.props.filter.sort !== (column.dbName + ",desc") ?
                                    <ArrowUpward className="base58-table-header-cell-sort-icon" onClick={() => this.onSortChange(column.dbName, "desc")} /> :
                                    <ArrowDownward className="base58-table-header-cell-sort-icon" onClick={() => this.onSortChange(column.dbName, "asc")} />
                                }
                            </TableCell>
                        } else {
                            return <TableCell
                                key={"sort-" + index}
                                className={index === 0 ? "base58-table-header-cell-first" : "base58-table-header-cell"}
                                component="th"
                                scope="row"
                                width={column.width}>
                                {this.props.t(column.name)} {column.required && "*"}
                            </TableCell>
                        }
                    })}
                </TableRow>
                <TableRow>
                    {this.props.columns.map((column, index) => {
                        if (column.filter) {
                            return <TableCell key={"filter-" + index} className="base58-table-header-search-cell">
                                {!column.type &&
                                    <SearchInput
                                        id={column.dbName}
                                        name={column.dbName}
                                        type={column.type}
                                        value={this.props.filter[column.dbName]}
                                        actualValue={this.props.currentFilter && this.props.currentFilter[column.name] ? this.props.currentFilter[column.name] : null}
                                        placeholder={this.props.placeholder}
                                        onChange={this.onChange}
                                        onSearch={() => this.onSearch(column.dbName)}
                                        onClear={(e) => this.onClear(e)}
                                    />}

                                {column.type && (column.type === "number" || column.type === "big-decimal") &&
                                    <NumericFormat
                                        id={column.dbName}
                                        name={column.dbName}
                                        label={this.props.placeholder}
                                        thousandSeparator={column.thousandSeparator}
                                        decimalSeparator={column.decimalSeparator}
                                        decimalScale={column.decimalScale}
                                        fixedDecimalScale={true}
                                        onValueChange={this.onNumberChange}
                                        onSearch={() => this.onSearch(column.dbName)}
                                        onClear={(e) => this.onClear(e)}
                                        placeholder={this.props.placeholder}
                                        value={this.props.filter[column.dbName] === null ? '' : this.props.filter[column.dbName]}
                                        actualValue={this.props.currentFilter && this.props.currentFilter[column.name] ? this.props.currentFilter[column.name] : null}
                                        customInput={SearchInput}
                                    />}
                                {column.type && column.type === "select" &&
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            displayEmpty
                                            id={column.dbName}
                                            name={column.dbName}
                                            value={this.props.filter[column.dbName] || ''}
                                            onChange={this.onChange}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <span style={{ color: "#A2A2A2" }}>{this.props.t("select...")}</span>
                                                }
                                                return this.props.t(selected);
                                            }}
                                        >
                                            <MenuItem key="select" value="">-</MenuItem>
                                            {column.items.map((item, index) => {
                                                return <MenuItem key={index} value={item}>{this.props.t(item)}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>}
                                {column.type && column.type === "boolean-select" &&
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            displayEmpty
                                            id={column.dbName}
                                            name={column.dbName}
                                            value={this.props.filter[column.dbName] || ''}
                                            onChange={this.onChange}
                                            renderValue={(selected) => {
                                                if (String(selected).length === 0) {
                                                    return <span style={{ color: "#A2A2A2" }}>{this.props.t("select...")}</span>
                                                }
                                                return <Box sx={{ display: "flex", gap: 1, height: '23px' }}>
                                                    {String(selected) === "true" ? <CheckIcon style={{ color: '#22AE73' }} /> : <ClearIcon style={{ color: '#F44812' }} />}
                                                </Box>
                                            }}
                                        >
                                            <MenuItem key="select" value="">-</MenuItem>
                                            <MenuItem key="true" value="true"><CheckIcon style={{ color: '#22AE73' }} /></MenuItem>
                                            <MenuItem key="false" value="false"><ClearIcon style={{ color: '#F44812' }} /></MenuItem>
                                        </Select>
                                    </FormControl>}
                                {column.type && column.type === "auto-complete" &&
                                    <WrappedAutocomplete
                                        multiple={column.multiple}
                                        placeholder={this.props.placeholder}
                                        id={column.dbName}
                                        name={column.dbName}
                                        value={this.props.filter[column.dbName] || (column.multiple ? [] : null)}
                                        options={column.items}
                                        onChange={(e, items) => this.onMultiSelectChange(e, column.dbName, items)}
                                        getOptionLabel={option => this.props.t(option)}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                {this.props.t(option)}
                                            </Box>
                                        )}
                                    />
                                }
                            </TableCell>
                        } else {
                            return <TableCell key={"filter-" + index} className="base58-table-cell" />
                        }
                    })}
                </TableRow>
            </TableHead>
        );
    }
}

export default (withTranslation()(SortingAndFiltering));
