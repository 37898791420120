import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import {
    createDiagnosis,
    updateDiagnosis,
    getDiagnosisById
} from '../../redux/diagnosis/actions';
import { NAME_REGEX, CODE_REGEX } from '../../components/common/regex';

class ManageDiagnosis extends Component {

    emptyDiagnosis = {
        code: '',
        name: '',
        latinName: ''
    };
    constructor(props) {
        super(props);
        this.state = {
            mainLoading: false,
            diagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis)),
            initialDiagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis)),
            validation: {
                codeError: false,
                codeErrorText: "",
                nameError: false,
                nameErrorText: "",
                latinNameError: false,
                latinNameErrorText: "",
            }
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.props.getDiagnosisById(this.props.match.params.id).then(response => {
                this.setState({
                    diagnosis: JSON.parse(JSON.stringify(response.data)),
                    initialDiagnosis: JSON.parse(JSON.stringify(response.data)),
                }, () => {
                    this.validate("name", this.state.diagnosis.name);
                    this.validate("code", this.state.diagnosis.code);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("name", this.state.diagnosis.name);
            this.validate("code", this.state.diagnosis.code);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { diagnosis } = this.state;
        diagnosis[target.name] = target.value.trimStart();
        this.setState({ diagnosis: diagnosis })
        this.validate(target.name, target.value);
    };

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "code":
                if (value.length === 0) {
                    validation.codeError = true;
                    validation.codeErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !CODE_REGEX.test(value)) {
                    validation.codeError = true;
                    validation.codeErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.codeError = false;
                    validation.codeErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "latinName":
                if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.latinNameError = true;
                    validation.latinNameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.latinNameError = false;
                    validation.latinNameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageDiagnosis = () => {
        const { diagnosis, initialDiagnosis } = this.state;
        let createDiagnosisObject = {
            ...diagnosis,
            latinName: diagnosis.latinName === '' ? null : diagnosis.latinName
        }
        let diagnosisObject = {
            ...diagnosis,
            code: initialDiagnosis.code === diagnosis.code ? null : diagnosis.code,
            name: initialDiagnosis.name === diagnosis.name ? null : diagnosis.name,
            latinName: diagnosis.latinName === "" ? null : diagnosis.latinName,
        }

        const action = this.props.match.params.id ?
            this.props.updateDiagnosis(this.props.match.params.id, diagnosisObject) :
            this.props.createDiagnosis(createDiagnosisObject);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialDiagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis)),
                    diagnosis: JSON.parse(JSON.stringify(this.emptyDiagnosis))
                }, () => this.props.history.push("/diagnoses"));
            }
        })
    };

    disable() {
        let { validation, diagnosis, initialDiagnosis } = this.state;
        if (!diagnosis.name || !diagnosis.code ||
            JSON.stringify(diagnosis) === JSON.stringify(initialDiagnosis) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {

        let { mainLoading, initialDiagnosis, diagnosis, validation } = this.state

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialDiagnosis) !== JSON.stringify(diagnosis)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={7} xl={7}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={3}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_diagnosis") : this.props.t("new_diagnosis")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push("/diagnoses")}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageDiagnosis} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                            <Grid container direction="row" spacing={3}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("code")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        autoFocus
                                                        id="code"
                                                        name="code"
                                                        value={(diagnosis.code && diagnosis.code.charAt(0).toUpperCase() + diagnosis.code.slice(1)) || ''}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("code").toLowerCase() })}
                                                        error={validation.codeError}
                                                        helperText={validation.codeErrorText}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("name")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <TextField
                                                        id="name"
                                                        name="name"
                                                        value={(diagnosis.name && diagnosis.name.charAt(0).toUpperCase() + diagnosis.name.slice(1)) || ''}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("name").toLowerCase() })}
                                                        error={validation.nameError}
                                                        helperText={validation.nameErrorText}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("latin_name")}
                                                    </InputLabel>
                                                    <TextField
                                                        id="latinName"
                                                        name="latinName"
                                                        value={(diagnosis.latinName && diagnosis.latinName.charAt(0).toUpperCase() + diagnosis.latinName.slice(1)) || ''}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("latin_name").toLowerCase() })}
                                                        error={validation.latinNameError}
                                                        helperText={validation.latinNameErrorText}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
})

const mapActionsToProps = { createDiagnosis, updateDiagnosis, getDiagnosisById }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageDiagnosis)))