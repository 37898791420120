import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Stack,
    Typography,
    FormHelperText
} from '@mui/material';
import {
    createUser,
    updateUser,
    getUserById,
    getRoles
} from '../../redux/user/actions';
import { withTranslation } from 'react-i18next';
import { EMAIL_REGEX, FIRST_NAME_REGEX, LAST_NAME_REGEX, isPhoneValid } from '../../components/common/regex';
import { MuiPhone } from '../../components/common/MuiPhone';
import { UserRole } from '../../utils/Security';

class ManageUser extends Component {

    emptyUser = {
        firstName: '',
        lastName: '',
        phone: '+385',
        email: '',
        role: '',
        primaryTitle: '',
        secondaryTitle: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.match.params.id,
            initialUser: JSON.parse(JSON.stringify(this.emptyUser)),
            user: JSON.parse(JSON.stringify(this.emptyUser)),
            validation: {
                firstNameError: false,
                firstNameErrorText: "",
                lastNameError: false,
                lastNameErrorText: "",
                phoneError: false,
                phoneErrorText: "",
                emailError: false,
                emailErrorText: "",
                roleError: false,
                roleErrorText: ""
            }
        };
    }

    componentDidMount() {
        if (this.state.userId) {
            this.props.getUserById(this.state.userId.toString()).then(response => {
                this.setState({
                    user: JSON.parse(JSON.stringify(response.data)),
                    initialUser: JSON.parse(JSON.stringify(response.data)),
                }, () => {
                    this.validate("firstName", this.state.user.firstName);
                    this.validate("lastName", this.state.user.lastName);
                    this.validate("phone", this.state.user.phone);
                    this.validate("role", this.state.user.role);
                    this.validate("email", this.state.user.email);
                });
            })
        } else {
            this.validate("firstName", this.state.user.firstName);
            this.validate("lastName", this.state.user.lastName);
            this.validate("phone", this.state.user.phone);
            this.validate("role", this.state.user.role);
            this.validate("email", this.state.user.email);
        }
        this.props.getRoles();
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ user: { ...this.state.user, [name]: value.trimStart() } }, () => this.validate(name, value));
    };

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "firstName":
                if (value.length === 0) {
                    validation.firstNameError = true;
                    validation.firstNameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !FIRST_NAME_REGEX.test(value)) {
                    validation.firstNameError = true;
                    validation.firstNameErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.firstNameError = false;
                    validation.firstNameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "lastName":
                if (value.length === 0) {
                    validation.lastNameError = true;
                    validation.lastNameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !LAST_NAME_REGEX.test(value)) {
                    validation.lastNameError = true;
                    validation.lastNameErrorText = this.props.t("name_regex_message", { min: 1, max: 50 });
                } else {
                    validation.lastNameError = false;
                    validation.lastNameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "phone":
                if (!value || (value && value.length < 5)) {
                    validation.phoneError = true;
                    validation.phoneErrorText = this.props.t("required_field_message");
                } else if (!isPhoneValid(value)) {
                    validation.phoneError = true;
                    validation.phoneErrorText = this.props.t("phone_regex_message");
                } else {
                    validation.phoneError = false;
                    validation.phoneErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "email":
                if (value.length === 0) {
                    validation.emailError = true;
                    validation.emailErrorText = this.props.t("required_field_message");
                }
                else if (value.length > 0 && !EMAIL_REGEX.test(value)) {
                    validation.emailError = true;
                    validation.emailErrorText = this.props.t("email_regex_message");
                } else {
                    validation.emailError = false;
                    validation.emailErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "role":
                if (!value) {
                    validation.roleError = true;
                    validation.roleErrorText = this.props.t("required_field_message");
                } else {
                    validation.roleError = false;
                    validation.roleErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageUser = () => {
        const { user } = this.state;
        let dataUser = {
            ...user,
            firstName: user.firstName && user.firstName.trim(),
            lastName: user.lastName && user.lastName.trim(),
            primaryTitle: user.role === UserRole.DOCTOR ? user.primaryTitle : null,
            secondaryTitle: user.role === UserRole.DOCTOR ? user.secondaryTitle : null
        }
        const action = this.state.userId ?
            this.props.updateUser(this.state.userId, dataUser) :
            this.props.createUser(dataUser);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialUser: JSON.parse(JSON.stringify(this.emptyUser)),
                    user: JSON.parse(JSON.stringify(this.emptyUser))
                }, () => this.props.history.push("/users"));
            }
        })
    };

    disable() {
        let { validation, user, initialUser } = this.state;

        if (!user.firstName ||
            !user.lastName ||
            !user.email ||
            !user.phone ||
            !user.role ||
            JSON.stringify(user) === JSON.stringify(initialUser) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }

        return false;
    }

    render() {
        let { validation, initialUser, user } = this.state
        let { roles } = this.props.userReducer

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialUser) !== JSON.stringify(user)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.state.userId ? this.props.t("edit_user") : this.props.t("new_user")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push("/users")}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageUser} disabled={this.disable()}>
                                        {this.state.userId ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("first_name")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <TextField
                                                autoFocus
                                                id="firstName"
                                                name="firstName"
                                                value={(user.firstName && user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1)) || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("first_name").toLowerCase() })}
                                                error={validation.firstNameError}
                                                helperText={validation.firstNameErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("last_name")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <TextField
                                                id="lastName"
                                                name="lastName"
                                                value={(user.lastName && user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)) || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("last_name").toLowerCase() })}
                                                error={validation.lastNameError}
                                                helperText={validation.lastNameErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("phone")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <MuiPhone
                                                value={user.phone || ''}
                                                onChange={(phone) => this.setState({ user: { ...this.state.user, phone: phone } }, () => this.validate("phone", phone))}
                                                placeholder={this.props.t("enter", { text: this.props.t("phone_number").toLowerCase() })}
                                                helperText={validation.phoneErrorText}
                                                error={validation.phoneError}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("email_address")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <TextField
                                                id="email"
                                                name="email"
                                                value={user.email || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("email_address").toLowerCase() })}
                                                error={validation.emailError}
                                                helperText={validation.emailErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("role")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <FormControl error={validation.roleError}>
                                                <Select
                                                    displayEmpty
                                                    value={user.role || ''}
                                                    id="role"
                                                    name="role"
                                                    onChange={this.handleChange}
                                                    renderValue={(selected) => {
                                                        return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                                    }}>
                                                    {roles.map(role => { return <MenuItem key={role} value={role}>{this.props.t(role)}</MenuItem> })}
                                                </Select>
                                                <FormHelperText>{validation.roleErrorText}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        {user.role === UserRole.DOCTOR && <>
                                            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("primary_title")}
                                                </InputLabel>
                                                <Grid item container>
                                                    <TextField
                                                        id="primaryTitle"
                                                        name="primaryTitle"
                                                        value={user.primaryTitle || ''}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("primary_title").toLowerCase() })} />
                                                </Grid>
                                            </Grid>
                                            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("secondary_title")}
                                                </InputLabel>
                                                <Grid item container>
                                                    <TextField
                                                        id="secondaryTitle"
                                                        name="secondaryTitle"
                                                        value={user.secondaryTitle || ''}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("secondary_title").toLowerCase() })} />
                                                </Grid>
                                            </Grid></>}
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
})

const mapActionsToProps = { createUser, updateUser, getUserById, getRoles }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageUser)))
