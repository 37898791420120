import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Box,
    Divider
} from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/icons/trash_icon.svg';
import Base58Table from "../../components/common/Base58Table";
import { getAuditLogs, setAuditLogsPage, setAuditLogsSize, setAuditLogsParams } from '../../redux/auditLog/actions'
import moment from 'moment';
import DeleteAuditLogsDialog from './DeleteAuditLogsDialog';
import types from '../../redux/auditLog/types';
import store from '../../redux/store';
import JsonView from 'react18-json-view';

class AuditLog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedParams: null,
            dialogOpen: false
        };
    }

    componentDidMount() {
        this.props.getAuditLogs()
    }

    componentWillUnmount() {
        this.props.setAuditLogsSize(10, true)
        store.dispatch({
            type: types.RESET_AUDIT_LOGS_DATA
        })
    }

    getStatus = (status) => {
        switch (status) {
            case "SUCCESS": {
                return <span style={{ color: 'green' }}>{status}</span>
            }
            case "ERROR": {
                return <span style={{ color: 'red' }}>{status}</span>
            }
            default:
                return
        }
    }

    render() {
        let { dialogOpen } = this.state
        const { auditLogsLoading, auditLogs, auditLogsTotalElements, auditLogsParams, auditLogsActions } = this.props.auditReducer;

        let auditLogList = auditLogs && auditLogs.map((auditLog, index) => {
            return <TableRow key={index}>
                <TableCell>{auditLog.action}</TableCell>
                <TableCell>{moment.unix(auditLog.timestamp).format("DD.MM.YYYY. HH:mm")}</TableCell>
                <TableCell>{auditLog.initiator}</TableCell>
                <TableCell>{this.getStatus(auditLog.status)}</TableCell>
                <TableCell>
                    <JsonView collapsed={true} src={auditLog.params} />
                </TableCell>
            </TableRow >
        })

        let columns = [
            {
                name: "action",
                dbName: "action",
                type: "auto-complete",
                items: auditLogsActions,
                multiple: false,
                width: '20%',
                filter: true,
                sort: false,
            },
            {
                name: "time",
                width: '20%'
            },
            {
                name: "caller",
                dbName: "caller",
                width: '25%',
                filter: true
            },
            {
                name: "status",
                dbName: "status",
                width: '10%',
                filter: true
            },
            {
                name: '',
                width: '25%'
            }
        ]

        return (<Box className='custom-box'>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                        {this.props.t("change_logs")}
                        <Button
                            sx={{
                                "& svg path": {
                                    stroke: "#FFFFFF"
                                }
                            }}
                            className="create-btn"
                            startIcon={<DeleteIcon />}
                            style={{ marginLeft: 'auto' }}
                            onClick={() => this.setState({ dialogOpen: true })}>
                            {this.props.t("delete_audit_logs")}
                        </Button>
                    </Typography>
                    <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={auditLogsLoading}
                        columns={columns}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        data={auditLogList}
                        count={auditLogsTotalElements}
                        rowsPerPage={auditLogsParams.size}
                        page={auditLogsParams.page}
                        onPageChange={(e, page) => this.props.setAuditLogsPage(page)}
                        onRowsPerPageChange={(e) => this.props.setAuditLogsSize(e.target.value)}
                        onFilterChange={(params) => this.props.setAuditLogsParams(params)}
                    />
                </Grid>
            </Grid>
            <DeleteAuditLogsDialog
                isOpen={dialogOpen}
                onClose={() => this.setState({ dialogOpen: false })}
                onSave={() => this.setState({ dialogOpen: false }, () => this.props.getAuditLogs())} />
        </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    auditReducer: state.auditReducer
})

const mapActionsToProps = { getAuditLogs, setAuditLogsPage, setAuditLogsSize, setAuditLogsParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(AuditLog))
