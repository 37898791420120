import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    Box,
    Button,
    Grid,
    InputLabel,
    Stack,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { getEmailConfigurations, updateGoogleForm } from '../../../redux/setting/actions'

import 'react18-json-view/src/style.css';
import Base58Table from '../../../components/common/Base58Table';
import { Check, Clear } from '@mui/icons-material';
import { getGoogleFormConfigs } from '../../../redux/setting/actions';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import JSONViewerDialog from './JSONViewerDialog';
import CreateConfigurationDialog from './CreateConfigurationDialog';

class GoogleQuestionnaire extends React.Component {

    emptyGoogleFormConfig = {
        emailSubject: '',
        emailBody: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            googleFormConfig: JSON.parse(JSON.stringify(this.emptyGoogleFormConfig)),
            initialGoogleFormConfig: JSON.parse(JSON.stringify(this.emptyGoogleFormConfig)),
            jsonViewerDialogOpen: false,
            newConfigurationDialogOpen: false
        };
    }


    componentDidMount() {
        this.props.getGoogleFormConfigs({ type: "QUESTIONNAIRE" });
    }

    handleChange = (e) => {
        this.setState({ googleFormConfig: { ...this.state.googleFormConfig, [e.target.name]: e.target.value } });
    };

    disabledButton = () => {
        let { googleFormConfig, initialGoogleFormConfig } = this.state;

        if (JSON.stringify(googleFormConfig) === JSON.stringify(initialGoogleFormConfig)) {
            return true;
        }
        if (!googleFormConfig.emailSubject || !googleFormConfig.emailBody.includes("{googleFormLink}") || !googleFormConfig.emailBody.includes("{unsubscribeLink}")) {
            return true;
        }
        return false;
    }

    updateForm = () => {
        let { googleFormConfig } = this.state;
        googleFormConfig.googleFormType = 'QUESTIONNAIRE';
        googleFormConfig.emailBody = googleFormConfig.emailBody.replaceAll("\n", "<br>");
        this.props.updateGoogleForm(googleFormConfig).then((response) => {
            if (response.status === 200) {
                this.props.getGoogleFormConfigs({ type: "QUESTIONNAIRE" });
                this.setState({
                    googleFormConfig: JSON.parse(JSON.stringify(this.emptyGoogleFormConfig)),
                    initialGoogleFormConfig: JSON.parse(JSON.stringify(this.emptyGoogleFormConfig))
                });
            }
        });
    }

    render() {
        const { initialGoogleFormConfig, googleFormConfig, jsonViewerDialogOpen, newConfigurationDialogOpen } = this.state;
        const { googleFormConfigsLoading, googleFormConfigs, googleFormConfigsTotalElements, googleFormConfigsParams } = this.props.settingReducer;

        let data;
        if (googleFormConfigs && googleFormConfigs.length > 0) {
            data = googleFormConfigs.map((gfc, index) => {
                return <TableRow key={index}>
                    <TableCell>{gfc.configVersion}</TableCell>
                    <TableCell>{gfc.emailSubject}</TableCell>
                    <TableCell><div dangerouslySetInnerHTML={{ __html: gfc.emailBody }} /></TableCell>
                    <TableCell>{gfc.googleFormActive ? <Check style={{ color: 'green', fontSize: '26px' }} /> : <Clear style={{ color: 'red', fontSize: '26px' }} />}</TableCell>
                    <TableCell>
                        <Stack direction="row" spacing={1}>
                            <Button title={this.props.t("preview")} className="default-button-examination"
                                onClick={() => this.setState({ googleFormConfig: gfc, initialGoogleFormConfig: JSON.parse(JSON.stringify(gfc)), jsonViewerDialogOpen: true })}>
                                {this.props.t("preview")}
                            </Button>
                            {gfc.googleFormActive && <Button
                                title={this.props.t("edit")}
                                className="default-button-edit"
                                endIcon={<EditIcon />}
                                onClick={() => this.setState({
                                    googleFormConfig: { emailSubject: gfc.emailSubject, emailBody: gfc.emailBody.replaceAll("<br>", "\n") },
                                    initialGoogleFormConfig: JSON.parse(JSON.stringify({ emailSubject: gfc.emailSubject, emailBody: gfc.emailBody.replaceAll("<br>", "\n") })),
                                    jsonViewerDialogOpen: false
                                })}>
                                {this.props.t("edit")}
                            </Button>}
                        </Stack>
                    </TableCell>
                </TableRow>
            })
        } else {
            data = <TableRow>
                <TableCell align="center" colSpan={5}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "version",
                width: '5%'
            },
            {
                name: "email_subject",
                width: '20%'
            },
            {
                name: "email_body",
                width: '60%'
            },
            {
                name: "active",
                width: '5%'
            },
            {
                name: "actions",
                width: '10%'
            }
        ]

        return (<Box className='custom-box'>
            <Prompt when={JSON.stringify(initialGoogleFormConfig) !== JSON.stringify(googleFormConfig)} message={this.props.t("prompt_message")} />
            <Grid container direction="row" spacing={3}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container>
                            <InputLabel className="form-label">
                                {this.props.t("subject")} <span style={{ color: "#D32F2F" }}>*</span>
                            </InputLabel>
                            <TextField
                                disabled={!googleFormConfig.emailSubject}
                                id="emailSubject"
                                name="emailSubject"
                                value={googleFormConfig.emailSubject || ""}
                                onChange={this.handleChange}
                                placeholder={this.props.t("enter", { text: this.props.t("subject").toLowerCase() })}
                            />
                        </Grid>
                        <Grid item container>
                            <InputLabel className="form-label">
                                {this.props.t("body")} <span style={{ color: "#D32F2F" }}>*</span>
                            </InputLabel>
                            <TextField
                                disabled={!googleFormConfig.emailBody}
                                id="emailBody"
                                name="emailBody"
                                value={googleFormConfig.emailBody || ""}
                                multiline
                                rows={15}
                                onChange={this.handleChange}
                                placeholder={this.props.t("enter", { text: this.props.t("body").toLowerCase() })}
                            />
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={2}>
                                <Typography variant="body2" gutterBottom sx={{ flexGrow: 1 }}>
                                    <strong>{this.props.t("info_message_email")}</strong>
                                </Typography>
                                <Button
                                    className="cancel-btn"
                                    title={this.props.t("cancel")}
                                    color="primary"
                                    onClick={() => this.setState({ googleFormConfig: JSON.parse(JSON.stringify(this.emptyGoogleFormConfig)) })}>
                                    {this.props.t("cancel")}
                                </Button>
                                <Button
                                    className="create-btn"
                                    title={this.props.t("save")}
                                    disabled={this.disabledButton()}
                                    onClick={() => this.updateForm()}>
                                    {this.props.t("save")}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={2}>
                                <InputLabel className="form-label" sx={{ flexGrow: 1 }}>
                                    {this.props.t("list_of_configurations")}
                                </InputLabel>
                                <Button
                                    sx={{ width: "200px !important" }}
                                    className="create-btn"
                                    title={this.props.t("create")}
                                    onClick={() => this.setState({ newConfigurationDialogOpen: true })}>
                                    {this.props.t("create_configuration")}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Base58Table
                                isLoading={googleFormConfigsLoading}
                                columns={columns}
                                data={data}
                                count={googleFormConfigsTotalElements}
                                rowsPerPage={googleFormConfigsParams.size}
                                page={googleFormConfigsParams.page}
                                onPageChange={(e, page) => this.props.setGoogleFormConfigsPage(page)}
                                onRowsPerPageChange={(e) => this.props.setGoogleFormConfigsSize(e.target.value)}
                                onFilterChange={(params) => this.props.setGoogleFormConfigsParams(params)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                jsonViewerDialogOpen && googleFormConfig && <JSONViewerDialog
                    isOpen={jsonViewerDialogOpen}
                    onClose={() => this.setState({ jsonViewerDialogOpen: false })}
                    data={googleFormConfig}
                />
            }
            {
                newConfigurationDialogOpen && googleFormConfig && <CreateConfigurationDialog
                    isOpen={newConfigurationDialogOpen}
                    onClose={() => this.setState({ newConfigurationDialogOpen: false })}
                />
            }
        </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = {
    getEmailConfigurations,
    updateGoogleForm,
    getGoogleFormConfigs
}

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(GoogleQuestionnaire)))

