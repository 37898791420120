import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    TableCell,
    TableRow,
    Tooltip,
    Zoom
} from '@mui/material';
import { getHistory, setHistoryPage, setHistorySize, setHistoryParams } from '../../../redux/setting/actions'
import WrappedDatePicker from '../../../components/common/WrappedDatePicker';
import moment from 'moment';
import Base58Table from '../../../components/common/Base58Table';
import { Check, Clear, History } from '@mui/icons-material'


class HistoryMessagesTab extends Component {

    emptyData = {
        startDate: null,
        endDate: null
    }


    constructor(props) {
        super(props);
        this.state = {
            data: this.emptyData,
            selectedEndDate: null,
            selectedStartDate: null
        };
    }


    handleChangeStartDate = (date) => {
        let { data } = this.state
        if (date !== null && date._isValid) {
            data.startDate = moment(date)
            this.setState({ data: data, selectedStartDate: date });
        }
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        if (date !== null && date._isValid) {
            data.endDate = moment(date).add(24, 'hours')
            this.setState({ data: data, selectedEndDate: date });
        }
    }

    getHistory = () => {
        let { data } = this.state
        let params = {}
        params.timeFrom = data.startDate ? data.startDate : null
        params.timeTo = data.endDate ? data.endDate : null
        this.props.setHistoryParams(params).then(() => {
            this.setState({ displayData: true })
        })
    }

    render() {
        const { selectedEndDate, selectedStartDate } = this.state
        const { history, historyTotalElements, historyParams, historyLoading } = this.props.settingReducer;

        let historyList;
        if (history && history.length > 0) {
            historyList = history.map((history, index) => {
                return <React.Fragment key={index}>
                    <TableRow>
                        <TableCell>{history.dateToSend !== null ? moment.unix(history.dateToSend).format("DD.MM.YYYY. HH:mm") : null}</TableCell>
                        <TableCell>{history.patientFirstName}</TableCell>
                        <TableCell>{history.patientLastName}</TableCell>
                        <TableCell>{history.mobileNumber}</TableCell>
                        <TableCell>{history.sent ? <Check style={{ color: 'green', fontSize: '26px' }} /> : <Clear style={{ color: 'red', fontSize: '26px' }} />}</TableCell>
                        <TableCell>{history.notificationSid && history.notificationSid.length > 25 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={history.notificationSid} style={{ cursor: 'pointer' }}>
                            <span>{history.notificationSid && history.notificationSid.length > 25 ? history.notificationSid.substring(0, 25) + "..." : history.notificationSid}</span>
                        </Tooltip> :
                            history.notificationSid}</TableCell>
                        <TableCell>{history.dateOfEvent !== null ? moment.unix(history.dateOfEvent).format("DD.MM.YYYY. HH:mm") : null}</TableCell>
                        <TableCell>{history.statusDescription && history.statusDescription.length > 30 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={history.statusDescription} style={{ cursor: 'pointer' }}>
                            <span>{history.statusDescription && history.statusDescription.length > 30 ? history.statusDescription.substring(0, 30) + "..." : history.statusDescription}</span>
                        </Tooltip> :
                            history.statusDescription}</TableCell>
                        <TableCell>{history.content && history.content.length > 60 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={history.content} style={{ cursor: 'pointer' }}>
                            <span>{history.content && history.content.length > 60 ? history.content.substring(0, 60) + "..." : history.content}</span>
                        </Tooltip> :
                            history.content}</TableCell>
                    </TableRow>
                </React.Fragment>
            })
        } else {
            historyList = <TableRow>
                <TableCell align="center" colSpan={9}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "date_to_send",
                dbName: "dateToSend",
                width: '10%',
                sort: true
            },
            {
                name: "first_name",
                dbName: "firstName",
                width: '10%',
                filter: true
            },
            {
                name: "last_name",
                dbName: "lastName",
                width: '10%',
                filter: true
            },
            {
                name: "phone",
                dbName: "phoneNumber",
                width: '10%',
                filter: true
            },
            {
                name: "sent",
                dbName: "notificationSent",
                width: '5%',
                type: 'boolean-select',
                filter: true
            },
            {
                name: "id",
                dbName: "smsId",
                width: '10%',
                filter: true
            },
            {
                name: "date_of_event",
                dbName: "dateOfEvent",
                width: '10%',
                sort: true
            },
            {
                name: "status",
                width: '10%'
            },
            {
                name: "content",
                width: '25%'
            }
        ]


        return (
            <Grid item container direction="row" spacing={2}>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={3} xl={3}>
                    <InputLabel className="form-label">
                        {this.props.t("start_date")}
                    </InputLabel>
                    <FormControl>
                        <WrappedDatePicker
                            id="startDate"
                            name="startDate"
                            maxDate={selectedEndDate || moment(new Date()).add(1, 'month')}
                            onChange={this.handleChangeStartDate}
                            value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                            placeholder={this.props.t("select")}
                        />
                    </FormControl>
                </Grid>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={3} xl={3}>
                    <InputLabel className="form-label">
                        {this.props.t("end_date")} <span style={{ color: "#D32F2F" }}>*</span>
                    </InputLabel>
                    <FormControl>
                        <WrappedDatePicker
                            id="endDate"
                            name="endDate"
                            minDate={selectedStartDate || null}
                            maxDate={moment(new Date()).add(2, 'month')}
                            onChange={this.handleChangeEndDate}
                            value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                            placeholder={this.props.t("select")}
                        />
                    </FormControl>
                </Grid>
                <Grid item container direction="column" xs={8} sm={8} md={8} lg={1} xl={1}>
                    <Button
                        style={{ marginTop: '35px', height: '40px' }}
                        title={this.props.t("get_history")}
                        color="primary"
                        onClick={() => this.getHistory()}
                        disabled={!selectedEndDate}>
                        <History />
                    </Button>
                </Grid>
                {history && this.state.displayData && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={historyLoading}
                        columns={columns}
                        data={historyList}
                        rowsPerPageOptions={[5, 10, 15]}
                        count={historyTotalElements}
                        rowsPerPage={historyParams.size}
                        page={historyParams.page}
                        onPageChange={(e, page) => this.props.setHistoryPage(page)}
                        onRowsPerPageChange={(e) => this.props.setHistorySize(e.target.value)}
                        onFilterChange={(params) => this.props.setHistoryParams(params)}
                    />
                </Grid>}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

export default connect(mapStateToProps, { getHistory, setHistoryPage, setHistorySize, setHistoryParams })(withTranslation()(HistoryMessagesTab))
