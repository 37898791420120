import axios from 'axios';
import configApp from './config';
import qs from 'query-string';
import moment from 'moment';

axios.defaults.baseURL = configApp.baseURL;
axios.defaults.withCredentials = true


axios.defaults.paramsSerializer = (params) => {
  if (Object.values(params).some(param => param === null || param instanceof moment)) {
    Object.keys(params).forEach(key => {
      if (params[key] === null) {
        delete params[key];
      }
      if (params[key] instanceof moment) {
        params[key] = moment(params[key]).toISOString();
      }
    });
  }
  return qs.stringify(params, { indices: false });
};

axios.interceptors.request.use(
  config => {
    config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  if (response.request.responseURL.includes('oauth2/')) {
    window.location.href = response.request.responseURL
  }
  return response;
}, function (error) {
  if (error.response && window.location.pathname !== "/") {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

export default axios; 