import React from "react";
import { connect } from "react-redux"
import { withRouter, Prompt } from "react-router-dom";
import {
    Button,
    Grid,
    TextField,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
    FormControl,
    Select,
    MenuItem,
    FormHelperText
} from "@mui/material";
import { withTranslation } from "react-i18next";
import {
    getListOfItems,
    createDocument,
    updateDocument,
    getDocumentById,
    getSuppliersByWarehouseId
} from "../../../redux/warehouse/actions";
import { getInfirmaryList } from "../../../redux/infrimary/actions"
import { getUserList } from "../../../redux/user/actions"
import { NAME_REGEX } from "../../../components/common/regex";
import WrappedAutocomplete from "../../../components/common/WrappedAutocomplete";
import Enum from "./DocumentEnums";
import moment from "moment";
import WrappedDatePicker from "../../../components/common/WrappedDatePicker";
import DocumentItems from "./DocumentItems";
import AddItemDialog from "./AddItemDialog";

class ManageDocument extends React.Component {

    emptyDocument = {
        date: moment(),
        referenceNo: "",
        type: "",
        documentType: "",
        description: "",
        infirmaryId: null,
        userId: null,
        documentItems: [],
        warehouseId: this.props.match.params.warehouseId
    };

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: true,
            initialDocument: JSON.parse(JSON.stringify(this.emptyDocument)),
            document: JSON.parse(JSON.stringify(this.emptyDocument)),
            selectedInfirmary: null,
            selectedUser: null,
            validation: {
                referenceNoError: false,
                referenceNoErrorText: "",
                dateError: false,
                dateErrorText: "",
                documentTypeError: false,
                documentTypeErrorText: "",
                typeError: false,
                typeErrorText: "",
                itemIdError: false,
                itemIdErrorText: ""
            },
            documentItemsValidation: [],
            dialogOpen: false
        };
    }

    componentDidMount() {
        this.props.getInfirmaryList();
        this.props.getUserList();
        if (this.props.match.params.id) {
            this.props.getDocumentById(this.props.match.params.id).then(response => {
                let data = {
                    ...response.data,
                    date: response.data.date,
                    documentItems: response.data.documentItems.map(res => {
                        return {
                            itemId: res.itemId,
                            quantity: res.quantity,
                            price: res.price,
                            comment: res.comment,
                            expiryDate: res.expiryDate,
                            serialNo: res.serialNo
                        }
                    })
                }
                this.setState({
                    document: JSON.parse(JSON.stringify(data)),
                    initialDocument: JSON.parse(JSON.stringify(data)),
                    selectedInfirmary: this.props.infirmaryReducer.infirmaryList.find(i => i.id === response.data.infirmaryId),
                    selectedUser: this.props.userReducer.userList.find(u => u.id === response.data.userId),
                }, () => {
                    this.validate("documentType", this.state.document.documentType);
                    this.validate("type", this.state.document.type);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("documentType", this.state.document.documentType);
            this.validate("type", this.state.document.type);
            this.setState({ mainLoading: false });
        }
    }

    handleChangeDate = (date) => {
        this.setState({ document: { ...this.state.document, date: date } });
    }

    handleChange = (event) => {
        const target = event.target;
        let { document } = this.state;
        if (target.name === "documentType") {
            if (target.value === Enum.DocumentTypes.INVENTORY) {
                document[target.name] = target.value.trimStart()
                document.type = Enum.Types.IN
                this.setState({ document: document })
                this.validate("type", "");
            } else {
                document[target.name] = target.value.trimStart()
                this.setState({ document: document })
            }
        } else {
            document[target.name] = target.value.trimStart()
            this.setState({ document: document })
        }
        this.validate(target.name, target.value);
    };

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "referenceNo":
                if (value && value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.referenceNoError = true;
                    validation.referenceNoErrorText = this.props.t("number_regex_message", { max: 255 });
                } else {
                    validation.referenceNoError = false;
                    validation.referenceNoErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "documentType":
                if (value.length === 0) {
                    validation.documentTypeError = true;
                    validation.documentTypeErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.documentTypeError = true;
                    validation.documentTypeErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.documentTypeError = false;
                    validation.documentTypeErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "type":
                if (this.state.document.documentType === "INVENTORY") {
                    validation.typeError = false;
                    validation.typeErrorText = "";
                }
                else if (value.length === 0) {
                    validation.typeError = true;
                    validation.typeErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.typeError = true;
                    validation.typeErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.typeError = false;
                    validation.typeErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    handleChangInfirmary = (e, infirmary) => {
        const { document } = this.state
        if (infirmary) {
            document.infirmaryId = infirmary.id
            this.setState({ selectedInfirmary: infirmary, document: document })
        } else {
            document.infirmaryId = null
            this.setState({ selectedInfirmary: null, document: document })
        }
    }

    handleChangeUser = (e, user) => {
        const { document } = this.state
        if (user) {
            document.userId = user.id
            this.setState({ selectedUser: user, document: document })
        } else {
            document.userId = null
            this.setState({ selectedUser: null, document: document })
        }
    }

    manageDocument = () => {

        let { document } = this.state;

        document.date = moment(document.date).format("yyyy-MM-DD");

        if (document.documentType === Enum.DocumentTypes.MANUAL && document.type === Enum.Types.OUT) {
            document.documentItems = document.documentItems.map(item => {
                return { ...item, inDocumentItemId: item.id };
            });
        }

        const action = this.props.match.params.id ?
            this.props.updateDocument(this.props.match.params.id, document) :
            this.props.createDocument(document);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialDocument: JSON.parse(JSON.stringify(this.emptyDocument)),
                    document: JSON.parse(JSON.stringify(this.emptyDocument))
                }, () => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/documents`));
            }
        })
    };

    disable() {
        /* let { document, initialDocument, validation, documentItemsValidation } = this.state;
        if (!document.date ||
            !document.documentType ||
            !document.type ||
            document.documentItems.length === 0 ||
            documentItemsValidation.some((div) => Object.values(div).some((error) => error === true)) ||
            JSON.stringify(document) === JSON.stringify(initialDocument) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        } */
        return false;
    }

    render() {
        console.log("documentItems", this.state.document.documentItems)
        console.log("documentItemsValidation", this.state.documentItemsValidation)
        let { mainLoading, initialDocument, document, selectedInfirmary, selectedUser, validation, dialogOpen } = this.state
        const { infirmaryList } = this.props.infirmaryReducer
        const { userList } = this.props.userReducer

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialDocument) !== JSON.stringify(document)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_document") : this.props.t("new_document")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/documents`)}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageDocument} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("reference_no")}
                                                    </InputLabel>
                                                    <TextField
                                                        id="referenceNo"
                                                        name="referenceNo"
                                                        value={document.referenceNo || ""}
                                                        onChange={this.handleChange}
                                                        placeholder={this.props.t("enter", { text: this.props.t("reference_no").toLowerCase() })}
                                                        error={validation.referenceNoError}
                                                        helperText={validation.referenceNoErrorText}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("date")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <WrappedDatePicker
                                                        id="date"
                                                        name="date"
                                                        required
                                                        onChange={this.handleChangeDate}
                                                        disablePast
                                                        value={document.date ? Date.parse(document.date) : null}
                                                        error={(document.date === "Invalid date" || document.date === null) ? true : false}
                                                        helperText={(document.date === "Invalid date" || document.date === null) && this.props.t("required_field_message")}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("document_type")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <FormControl error={validation.documentTypeError}>
                                                        <Select
                                                            displayEmpty
                                                            labelId="select-label"
                                                            id="documentType"
                                                            name="documentType"
                                                            value={document.documentType || ""}
                                                            onChange={this.handleChange}
                                                            renderValue={(selected) => {
                                                                return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                                            }}>
                                                            {[Enum.DocumentTypes.INVENTORY, Enum.DocumentTypes.MANUAL].map(data => { return <MenuItem key={data} value={data}>{this.props.t(data)}</MenuItem> })}
                                                        </Select>
                                                        {validation.documentTypeError && <FormHelperText>{validation.documentTypeErrorText}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("type")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <FormControl error={validation.typeError}>
                                                        <Select
                                                            displayEmpty
                                                            labelId="type"
                                                            id="type"
                                                            name="type"
                                                            value={document.type || ""}
                                                            disabled={document.documentType === Enum.DocumentTypes.INVENTORY ? true : false}
                                                            onChange={this.handleChange}
                                                            renderValue={(selected) => {
                                                                return selected ? `${this.props.t(selected)}` : <span style={{ color: "#999999" }}>{this.props.t("select")}</span>;
                                                            }}>
                                                            {[Enum.Types.IN, Enum.Types.OUT].map(data => { return <MenuItem key={data} value={data}>{this.props.t(data)}</MenuItem> })}
                                                        </Select>
                                                        {validation.typeError && <FormHelperText>{validation.typeErrorText}</FormHelperText>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {document.documentType === Enum.DocumentTypes.MANUAL && document.type === Enum.Types.OUT && <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("infirmary")}
                                                    </InputLabel>
                                                    <WrappedAutocomplete
                                                        id="selectedInfirmary"
                                                        name="selectedInfirmary"
                                                        value={selectedInfirmary}
                                                        getOptionLabel={(option) => `${option.name}`}
                                                        options={infirmaryList}
                                                        onChange={(event, infirmary) => {
                                                            this.handleChangInfirmary(event, infirmary)
                                                        }}
                                                        placeholder={this.props.t("select")}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("user")}
                                                    </InputLabel>
                                                    <WrappedAutocomplete
                                                        id="selectedUser"
                                                        name="selectedUser"
                                                        value={selectedUser}
                                                        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                                                        options={userList}
                                                        onChange={(event, user) => {
                                                            this.handleChangeUser(event, user)
                                                        }}
                                                        placeholder={this.props.t("select")}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3}>
                                            <InputLabel className="form-label">
                                                {this.props.t("description")}
                                            </InputLabel>
                                            <TextField
                                                id="description"
                                                name="description"
                                                value={document.description}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("description").toLowerCase() })}
                                                multiline
                                                rows={5} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            {document.documentType && document.type && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <DocumentItems
                                        documentType={document.documentType}
                                        type={document.type}
                                        data={document.documentItems}
                                        onChange={(documentItems) => this.setState({ document: { ...this.state.document, documentItems: documentItems } })}
                                        onValidationChange={(validation) => this.setState({ documentItemsValidation: validation })}
                                        onItemsDialogOpen={() => this.setState({ dialogOpen: true })}
                                        isAddItemDialogOpen={dialogOpen}
                                        selectedItems={document.documentItems} />
                                </Box>
                                {document.documentType === Enum.DocumentTypes.MANUAL && document.type === Enum.Types.OUT &&
                                    <AddItemDialog
                                        isAddItemDialogOpen={dialogOpen}
                                        selectedItems={document.documentItems}
                                        onClose={() => this.setState({ dialogOpen: false })}
                                        addSelectedItems={(items) => this.setState({ document: { ...this.state.document, documentItems: this.state.document.documentItems.concat(items) }, dialogOpen: false })}
                                    />}
                            </Grid>}
                        </Grid>
                    </Box >
                </Grid >
            </Grid >
        )
    }
}
const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer,
    infirmaryReducer: state.infirmaryReducer,
    userReducer: state.userReducer
})

const mapActionsToProps = { getListOfItems, createDocument, updateDocument, getDocumentById, getInfirmaryList, getUserList, getSuppliersByWarehouseId }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageDocument)))