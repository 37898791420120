import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    TableCell,
    TableRow
} from '@mui/material';
import { getItemStates, setItemStatesPage, setItemStatesSize, setItemStatesParams, getListOfCategories } from '../../../redux/warehouse/actions'
import Base58Table from '../../../components/common/Base58Table';
import types from '../../../redux/warehouse/types';
import store from '../../../redux/store';

class WarehouseState extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.getItemStates(this.props.match.params.warehouseId);
        this.props.getListOfCategories(this.props.match.params.warehouseId)
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.RESET_ITEM_STATES_DATA
        })
    }

    render() {
        const { itemStates, itemStatesLoading, itemStatesTotalElements, itemStatesParams } = this.props.warehouseReducer

        let itemStatesList;
        if (itemStates && itemStates.length > 0) {
            itemStatesList = itemStates.map(item => {
                return <TableRow key={item.id}>
                    <TableCell>
                        {item.name}
                    </TableCell>
                    <TableCell>
                        {item.quantity}
                    </TableCell>
                    <TableCell>
                        {item.category}
                    </TableCell>
                </TableRow>
            })
        }

        let columns = [
            {
                name: "item",
                dbName: 'name',
                width: '50%',
                sort: true,
                filter: true
            },

            {
                name: "quantity",
                dbName: 'quantity',
                width: '20%',
                sort: true,
                filter: true
            },
            {
                name: "category",
                dbName: 'category',
                width: '30%',
                sort: true,
                type: "auto-complete",
                filter: true,
                items: this.props.warehouseReducer.categoriesList.map(category => { return category.name }),
            }
        ]

        return (
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Base58Table
                    isLoading={itemStatesLoading}
                    columns={columns}
                    data={itemStatesList}
                    rowsPerPageOptions={[10, 20, 30, 40]}
                    count={itemStatesTotalElements}
                    rowsPerPage={itemStatesParams.size}
                    page={itemStatesParams.page}
                    onPageChange={(e, page) => this.props.setItemStatesPage(this.props.match.params.warehouseId, page)}
                    onRowsPerPageChange={(e) => this.props.setItemStatesSize(this.props.match.params.warehouseId, e.target.value)}
                    onFilterChange={(params) => this.props.setItemStatesParams(this.props.match.params.warehouseId, { ...params, page: 0 })}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

const mapActionsToProps = { getItemStates, setItemStatesPage, setItemStatesSize, setItemStatesParams, getListOfCategories }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(WarehouseState)))