import types from './types';

const initialState = {
    warehousesLoading: true,
    warehouses: [],
    warehousesTotalElements: 0,
    warehousesParams: {
        page: 0,
        size: 10,
        sort: "id,desc"
    },

    categoriesLoading: true,
    categories: [],
    categoriesList: [],
    categoriesTotalElements: 0,
    categoriesParams: {
        page: 0,
        size: 10,
        sort: 'id,desc'
    },

    measurementsLoading: true,
    measurements: [],
    measurementList: [],
    measurementsTotalElements: 0,
    measurementsParams: {
        page: 0,
        size: 10,
        sort: 'id,desc'
    },

    itemsLoading: true,
    items: [],
    itemList: [],
    itemsTotalElements: 0,
    itemsParams: {
        page: 0,
        size: 10
    },

    documentsLoading: true,
    documents: [],
    documentsTotalElements: 0,
    documentsParams: {
        page: 0,
        size: 10
    },
    documentItems: [],
    documentItemsTotalElements: 0,
    documentItemsParams: {
        page: 0,
        size: 20
    },
    hasMoreDocumentItems: true,

    itemStatesLoading: true,
    itemStates: [],
    itemStatesTotalElements: 0,
    itemStatesParams: {
        page: 0,
        size: 20
    },

    documentItemsStatesLoading: true,
    documentItemsStates: [],
    documentItemsStatesTotalElements: 0,
    documentItemsStatesParams: {
        page: 0,
        size: 20
    },

}

export default function warehouse(state = initialState, action) {
    switch (action.type) {
        case types.SET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.payload.content,
                warehousesTotalElements: action.payload ? action.payload.totalElements : 0,
                warehousesLoading: false
            }
        case types.SET_WAREHOUSES_PAGE:
            return {
                ...state, warehousesParams: { ...state.warehousesParams, page: action.payload }
            }
        case types.SET_WAREHOUSES_SIZE:
            return {
                ...state, warehousesParams: { ...state.warehousesParams, size: action.payload, page: 0 }
            }
        case types.SET_WAREHOUSES_PARAMS:
            return {
                ...state,
                warehousesParams: action.payload
            }
        case types.RESET_WAREHOUSES_DATA:
            return {
                ...state,
                warehousesParams: {
                    size: 10,
                    page: 0
                }
            }


        case types.SET_ITEM_CATEGORIES:
            return {
                ...state,
                categories: action.payload.content,
                categoriesTotalElements: action.payload ? action.payload.totalElements : 0,
                categoriesLoading: false
            }
        case types.SET_LIST_OF_CATEGORIES:
            return {
                ...state,
                categoriesList: action.payload
            }
        case types.SET_CATEGORIES_PAGE:
            return {
                ...state, categoriesParams: { ...state.categoriesParams, page: action.payload }
            }
        case types.SET_CATEGORIES_SIZE:
            return {
                ...state, categoriesParams: { ...state.categoriesParams, size: action.payload, page: 0 }
            }
        case types.SET_CATEGORIES_PARAMS:
            return {
                ...state,
                categoriesParams: action.payload
            }
        case types.RESET_CATEGORIES_DATA:
            return {
                ...state,
                categoriesParams: {
                    size: 10,
                    page: 0,
                    sort: 'id,desc'
                }
            }

        case types.SET_MEASUREMENTS:
            return {
                ...state,
                measurements: action.payload.content,
                measurementsTotalElements: action.payload ? action.payload.totalElements : 0,
                measurementsLoading: false
            }
        case types.SET_LIST_OF_MEASUREMENT:
            return {
                ...state,
                measurementList: action.payload
            }
        case types.SET_MEASUREMENTS_PAGE:
            return {
                ...state, measurementsParams: { ...state.measurementsParams, page: action.payload }
            }
        case types.SET_MEASUREMENTS_SIZE:
            return {
                ...state, measurementsParams: { ...state.measurementsParams, size: action.payload, page: 0 }
            }
        case types.SET_MEASUREMENTS_PARAMS:
            return {
                ...state,
                measurementsParams: action.payload
            }
        case types.RESET_MEASUREMENTS_DATA:
            return {
                ...state,
                measurementsParams: {
                    size: 10,
                    page: 0,
                    sort: 'id,desc'
                }
            }

        case types.SET_ITEMS:
            return {
                ...state,
                items: action.payload.content,
                itemsTotalElements: action.payload ? action.payload.totalElements : 0,
                itemsLoading: false
            }
        case types.SET_LIST_OF_ITEM:
            return {
                ...state,
                itemList: action.payload
            }
        case types.SET_ITEMS_PAGE:
            return {
                ...state, itemsParams: { ...state.itemsParams, page: action.payload }
            }
        case types.SET_ITEMS_SIZE:
            return {
                ...state, itemsParams: { ...state.itemsParams, size: action.payload, page: 0 }
            }
        case types.SET_ITEMS_PARAMS:
            return {
                ...state,
                itemsParams: action.payload
            }
        case types.RESET_ITEMS_DATA:
            return {
                ...state,
                itemsParams: {
                    size: 10,
                    page: 0
                }
            }

        case types.SET_DOCUMENTS:
            return {
                ...state,
                documents: action.payload.content,
                documentsTotalElements: action.payload ? action.payload.totalElements : 0,
                documentsLoading: false
            }
        case types.SET_DOCUMENTS_PAGE:
            return {
                ...state, documentsParams: { ...state.documentsParams, page: action.payload }
            }
        case types.SET_DOCUMENTS_SIZE:
            return {
                ...state, documentsParams: { ...state.documentsParams, size: action.payload, page: 0 }
            }
        case types.SET_DOCUMENTS_PARAMS:
            return {
                ...state,
                documentsParams: action.payload
            }
        case types.RESET_DOCUMENTS_DATA:
            return {
                ...state,
                documentsParams: {
                    size: 10,
                    page: 0
                }
            }

        case types.SET_DOCUMENT_ITEMS:
            return {
                ...state,
                documentItems: action.payload.content,
                documentItemsTotalElements: action.payload ? action.payload.totalElements : 0
            }
        case types.SET_DOCUMENT_ITEMS_PAGE:
            return {
                ...state, documentItemsParams: { ...state.documentItemsParams, page: action.payload }
            }
        case types.SET_DOCUMENT_ITEMS_SIZE:
            return {
                ...state, documentItemsParams: { ...state.documentItemsParams, size: action.payload, page: 0 }
            }
        case types.SET_DOCUMENT_ITEMS_PARAMS:
            return {
                ...state,
                documentItemsParams: action.payload
            }
        case types.RESET_DOCUMENT_ITEMS_DATA:
            return {
                ...state,
                documentItems: [],
                documentItemsParams: {
                    size: 20,
                    page: 0
                },
                hasMoreDocumentItems: true
            }
        case types.SET_HAS_MORE_DOCUMENT_ITEMS:
            return {
                ...state,
                hasMoreDocumentItems: action.payload
            }

        case types.SET_ITEM_STATES:
            return {
                ...state,
                itemStates: action.payload.content,
                itemStatesTotalElements: action.payload ? action.payload.totalElements : 0,
                itemStatesLoading: false
            }
        case types.SET_ITEM_STATES_PAGE:
            return {
                ...state, itemStatesParams: { ...state.itemStatesParams, page: action.payload }
            }
        case types.SET_ITEM_STATES_SIZE:
            return {
                ...state, itemStatesParams: { ...state.itemStatesParams, size: action.payload, page: 0 }
            }
        case types.SET_ITEM_STATES_PARAMS:
            return {
                ...state,
                itemStatesParams: action.payload
            }
        case types.RESET_ITEM_STATES_DATA:
            return {
                ...state,
                itemStatesParams: {
                    size: 20,
                    page: 0
                }
            }

        case types.SET_DOCUMENT_ITEMS_STATES:
            return {
                ...state,
                documentItemsStates: action.payload.content,
                documentItemsStatesTotalElements: action.payload ? action.payload.totalElements : 0,
                documentItemsStatesLoading: false
            }
        case types.SET_DOCUMENT_ITEMS_STATES_PAGE:
            return {
                ...state, documentItemsStatesParams: { ...state.documentItemsStatesParams, page: action.payload }
            }
        case types.SET_DOCUMENT_ITEMS_STATES_SIZE:
            return {
                ...state, documentItemsStatesParams: { ...state.documentItemsStatesParams, size: action.payload, page: 0 }
            }
        case types.SET_DOCUMENT_ITEMS_STATES_PARAMS:
            return {
                ...state,
                documentItemsStatesParams: action.payload
            }
        case types.RESET_DOCUMENT_ITEMS_STATES_DATA:
            return {
                ...state,
                documentItemsStatesParams: {
                    size: 20,
                    page: 0
                }
            }
        default:
            return state;
    }
}