import { createTheme } from '@mui/material/styles';

import '@fontsource/asap/100.css';
import '@fontsource/asap/200.css';
import '@fontsource/asap/300.css';
import '@fontsource/asap/400.css';
import '@fontsource/asap/500.css';
import '@fontsource/asap/700.css';
import '@fontsource/asap/800.css';
import '@fontsource/asap/900.css';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Asap'
        ].join(',')
    },
    palette: {
        primary: {
            main: '#24A36C',
            light: '#53D99F',
            contrastText: '#fff'
        },
        secondary: {
            main: '#a8a8a8',
            light: '#FB6340',
            contrastText: '#fff'
        }
    },
    props: {
        MuiInput: { inputProps: { autoComplete: 'off', spellCheck: false } }
    }
});

theme = createTheme(theme, {
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                size: 'small'
            },
            styleOverrides: {
                colorInherit: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: "#a6deca",
                        color: theme.palette.primary.contrastText,
                    }
                },
                root: {
                    "&.default-button": {
                        textTransform: 'none',
                        backgroundColor: '#1B7E53'
                    },
                    "&.default-button-cancel": {
                        textTransform: 'none',
                        backgroundColor: '#F9F9F9',
                        color: '#0F0F0F'
                    },
                    '&.login-google-button': {
                        height: '40px',
                        backgroundColor: '#F9F9F9',
                        border: '1px solid var(--Greyscale-200, #D6D6D6)'
                    },
                    '&.login-google-button:hover': {
                        backgroundColor: '#F9F9F9',
                    },
                    "&.default-button-edit": {
                        backgroundColor: theme.palette.primary.light,
                        textTransform: 'none',
                        padding: '4px 12px 4px 12px',
                        fontStyle: 'italic',
                        fontWeight: '900',
                        borderRadius: '4px'

                    },
                    "&.default-button-delete": {
                        backgroundColor: theme.palette.secondary.light,
                        textTransform: 'none',
                        padding: '4px 12px 4px 12px',
                        fontStyle: 'italic',
                        fontWeight: '900',
                        borderRadius: '4px'
                    },
                    "&.default-button-examination": {
                        backgroundColor: '#D6AB00',
                        textTransform: 'none',
                        padding: '4px 12px 4px 12px',
                        fontStyle: 'italic',
                        fontWeight: '900',
                        borderRadius: '4px'
                    },
                    "&.cancel-btn": {
                        width: "100px",
                        height: "40px",
                        backgroundColor: '#F9F9F9',
                        textTransform: 'none',
                        padding: '4px 24px 4px 24px',
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: "18px",
                        color: "#0F0F0F",
                        border: "1px solid #D6D6D6",
                        boxShadow: "none"
                    },
                    "&.create-btn": {
                        minWidth: "100px",
                        height: "40px",
                        backgroundColor: '#1B7E53',
                        textTransform: 'none',
                        padding: '4px 24px 4px 24px',
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: "18px",
                        color: "#FFFFFF"
                    },
                    "&.create-btn:disabled": {
                        color: "#F1F1F1",
                        backgroundColor: "#D6D6D6"
                    },
                    "&.default-button-edit:disabled": {
                        color: "#F1F1F1",
                        backgroundColor: "#D6D6D6"
                    }

                }
            }
        },

        MuiIconButton: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    "&.default-button-edit": {
                        backgroundColor: theme.palette.primary.light,
                        textTransform: 'none',
                        padding: '4px 12px 4px 12px',
                        borderRadius: '4px',
                        opacity: '0px',
                        fontStyle: 'italic',
                        fontWeight: 'bold'

                    },
                    "&.default-button-delete": {
                        backgroundColor: theme.palette.secondary.light,
                        textTransform: 'none',
                        padding: '4px 12px 4px 12px',
                        borderRadius: '4px',
                        opacity: '0px',
                        fontStyle: 'italic',
                        fontWeight: 'bold'
                    }
                }
            }
        },
        MuiFilledInput: {
            defaultProps: {
                margin: 'dense',
            }
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
                fullWidth: true
            }
        },
        MuiFormHelperText: {
            defaultProps: {
                margin: 'dense'
            },
            styleOverrides: {
                root: {
                    /* color: "#24A36C", */
                    margin: "4px 0px 0px 0px"
                }
            }
        },
        MuiInputBase: {
            defaultProps: {
                margin: 'dense',
                spellCheck: false,
                autoComplete: 'off'
            }
        },
        MuiInputLabel: {
            defaultProps: {
                margin: 'dense'
            },
            styleOverrides: {
                root: {
                    color: '#000000',
                    "&.form-label": {
                        color: "#0F0F0F",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "19px"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            defaultProps: {
                size: 'small',
                margin: 'dense',
                variant: 'outlined'
            },
            styleOverrides: {
                root: {
                    background: "#FFFFFF",
                    input: {
                        background: "#FFFFFF",
                        "&::placeholder": {
                            color: "#999999",
                            opacity: 1
                        }
                    }
                }
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
                variant: 'extended'
            },
            styleOverrides: {
                colorInherit: {
                    backgroundColor: theme.palette.error.main,
                    color: theme.palette.primary.contrastText,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    },
                    root: {
                        "&.table-fab": {
                            maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'
                        },
                        "&.calendar-fab": {
                            maxWidth: '15px', maxHeight: '15px', minWidth: '15px', minHeight: '15px'
                        }
                    }

                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '35px'
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.table-fab-icon": {
                        maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'
                    },
                    "&.calendar-fab-icon": {
                        maxWidth: '15px', maxHeight: '15px', minWidth: '15px', minHeight: '15px'
                    }
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    /* textTransform: "uppercase", */
                    backgroundColor: 'none',
                    border: 'none',
                    boxShadow: 'none'
                }
            }
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            }
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                variant: 'outlined',
                size: 'small',
                fullWidth: true
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    "&.table-cell-light": {
                        backgroundColor: theme.palette.primary.light
                    },
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h4: {
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "19px",
                    letterSpacing: "-0.28px"
                },
                h5: {
                    fontSize: "24px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "30px",
                    letterSpacing: "-0.48px"
                },
                subtitle1: {
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px"
                },
                subtitle2: {
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "17.5px",
                    letterSpacing: '-0.28px'
                },
                overline: {
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "19px",
                    letterSpacing: '-0.24px',
                    textTransform: 'none'
                }/* ,
                caption: {
                    fontSize: "32px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineheight: "36px"
                } */
            }
        },
        /* MuiCard: {
            styleOverrides: {
                root: {
                    "&.custom-card": {
                        padding: '24px',
                        boxShadow: '0px 3.5px 5.5px 0px #00000005',
                        borderRadius: '8px',
                        marginTop: '20px'
                    }
                }
            }
        }, */
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F9F9F9',
                    fontWeight: 500,
                    fontSize: "20px",
                    lineHeight: "22.92px",
                    color: "#0F0F0F",
                    padding: "12px 24px 12px 24px"
                },
                subheader: {
                    color: theme.palette.primary.main
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '15px 25px 25px 25px',
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: "48px",
                    fontSize: "16px",
                    fontWeight: 500,
                    borderRadius: "4px",
                    marginRight: "24px",
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: "#F9F9F9",
                        color: "#000000"
                    },
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                    }
                }
            }
        }
    },
});

export default theme;
