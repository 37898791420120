import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Grid,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
import Base58Table from '../../../components/common/Base58Table';
import { getCategories, setCategoriesSize, setCategoriesParams, setCategoriesPage, deleteCategory } from '../../../redux/warehouse/actions'
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import store from '../../../redux/store';
import types from '../../../redux/warehouse/types';

class ItemCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDeleteDialog: false,
            categoryToDelete: null
        };
    }

    componentDidMount() {
        this.props.getCategories(this.props.match.params.warehouseId);
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.RESET_CATEGORIES_DATA
        })
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, categoryToDelete: null });
    }

    deleteCategory = () => {
        this.props.deleteCategory(this.state.categoryToDelete.id).then((response) => {
            if (response.status === 200) {
                this.setState({ openDeleteDialog: false }, () => this.props.setCategoriesPage(this.props.match.params.warehouseId, 0));
            }
        })
    }

    render() {
        const { categoryToDelete } = this.state
        const { categoriesLoading, categories, categoriesParams, categoriesTotalElements } = this.props.warehouseReducer;

        let categoryList;
        if (categories && categories.length > 0) {
            categoryList = categories && categories.map((category, index) => {
                return <TableRow key={index}>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>{category.description}</TableCell>
                    <TableCell className='row-cell' align='center'>{
                        <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                            <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/categories/${category.id}`)}>
                                {this.props.t("edit")}
                            </Button>
                            <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ categoryToDelete: category, openDeleteDialog: true })}>
                                {this.props.t("delete")}
                            </Button>
                        </Stack>
                    }</TableCell>
                </TableRow>
            })
        } else {
            categoryList = <TableRow>
                <TableCell align="center" colSpan={9}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "name",
                dbName: 'name',
                width: '40%',
                sort: true
            },
            {
                name: "description",
                dbName: 'description',
                width: '40%',
                sort: true
            },
            {
                name: "",
                width: '20%'
            }
        ]

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            className="create-btn"
                            sx={{ marginLeft: 'auto' }}
                            startIcon={<AddIcon />}
                            onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/categories/new`)}
                        >
                            {this.props.t("new_category")}
                        </Button>
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={categoriesLoading}
                        columns={columns}
                        data={categoryList}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        count={categoriesTotalElements}
                        rowsPerPage={categoriesParams.size}
                        page={categoriesParams.page}
                        onPageChange={(e, page) => this.props.setCategoriesPage(this.props.match.params.warehouseId, page)}
                        onRowsPerPageChange={(e) => this.props.setCategoriesSize(this.props.match.params.warehouseId, e.target.value)}
                        onFilterChange={(params) => this.props.setCategoriesParams(this.props.match.params.warehouseId, params)}
                    />
                </Grid>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_category")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_category_message", { name: categoryToDelete && categoryToDelete.name }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.deleteCategory()}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

const mapActionsToProps = { getCategories, setCategoriesSize, setCategoriesParams, setCategoriesPage, deleteCategory }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ItemCategories)))