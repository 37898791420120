import React, { Component } from 'react';
import { Box, Button, DialogContent, Grid, Stack, Typography } from '@mui/material';
import { BootstrapDialog, Transition } from '../../../components/common/Dialog';
import JsonView from 'react18-json-view';
import { withTranslation } from 'react-i18next';

class JSONViewerDialog extends Component {

    constructor(props) {
        super(props);
    }

    closeDialog = () => {
        this.props.onClose();
    }

    render() {
        return (<BootstrapDialog
            onClose={this.closeDialog}
            open={this.props.isOpen}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="lg"
        >
            <DialogContent sx={{ padding: "0px !important" }}>
                <Box className="custom-box">
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                    {this.props.t("configuration_version", { version: this.props.data.configVersion })}
                                </Typography>
                                <Button className="cancel-btn" onClick={this.closeDialog}>
                                    {this.props.t("close")}
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <JsonView src={this.props.data.json} />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </BootstrapDialog>
        );
    }
}

export default (withTranslation()(JSONViewerDialog));