import React, { Component } from 'react';
import './Login.css';
import { GOOGLE_AUTH_URL } from '../../config';
import { Redirect } from 'react-router-dom'
import googleLogo from '../../assets/icon/google-logo.png';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Container,
    Typography,
    IconButton,
    Button,
    Box
} from "@mui/material";
import { ReactComponent as Logo } from '../../assets/icon/logo_login.svg';
import { FlagImage } from 'react-international-phone';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                this.props.enqueueSnackbar(this.props.location.state.error, { variant: 'error' });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    render() {

        if (this.props.user) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: this.props.location }
                }} />;
        }

        return (
            <Container maxWidth={'xl'} className="loading-position-login">
                <Grid container direction="row" sx={{ boxShadow: '0px 4px 23.5px 0px #00000014', height: '665px', borderRadius: "16px" }}>
                    <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6} sx={{ backgroundColor: '#f5f5f5 !important' }}>
                        <Logo className='logo-login' />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6} justifyContent="center" alignContent="center">
                        <Box sx={{ maxWidth: "500px" }}>
                            <Grid container direction="row" spacing={5}>
                                <Grid item container>
                                    <Typography sx={{ fontSize: '32px', fontWeight: '700', flexGrow: 1 }}> {this.props.t("welcome")}</Typography>
                                    {["en", "hr"].map(language => {
                                        return <IconButton key={language} onClick={() => this.props.i18n.changeLanguage(language)}>
                                            <FlagImage
                                                iso2={language === "en" ? "gb" : language}
                                                alt="flag"
                                                title={this.props.t(language)}
                                                style={{ display: 'flex' }}
                                            />
                                        </IconButton>
                                    })}
                                </Grid>
                                <Grid item container>
                                    <Button
                                        fullWidth
                                        className='login-google-button'
                                        color="primary"
                                        startIcon={<img src={googleLogo} width="24px" height="24px" alt="google-icon" />}
                                        onClick={() => window.open(GOOGLE_AUTH_URL, "_self")}>
                                        <Typography variant="overline" fontWeight={500} color={'#000000'}>{this.props.t("login_with_google")}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container >
        );
    }
}

export default withTranslation()(Login);
