import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Stack,
    Box,
    Divider
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Base58Table from "../../components/common/Base58Table";
import { getDiagnoses, importDiagnoses, setDiagnosesPage, setDiagnosesSize, setDiagnosesParams, deleteDiagnosis } from '../../redux/diagnosis/actions'
import ImportDiagnosesModal from './ImportDiagnosesModal';
import { hasAnyRole, UserRole } from '../../utils/Security';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import store from '../../redux/store';
import types from '../../redux/diagnosis/types';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';

class Diagnoses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileName: null,
            fileData: null,
            importDialog: false,
            openDeleteDialog: false,
            diagnosisToDelete: null
        };
    }

    componentDidMount() {
        this.props.getDiagnoses()
    }

    componentWillUnmount() {
        this.props.setDiagnosesSize(20, true)
        store.dispatch({
            type: types.RESET_DIAGNOSES_DATA
        })
    }

    closeImportDialog = () => {
        this.setState({ importDialog: false, fileName: null, fileData: null });
    }

    handleChangeFile = (e) => {
        let file = e.target.files[0];
        if (!file) {
            return;
        }
        const data = new FormData()
        data.append('file', file)
        this.setState({ fileName: file.name, fileData: data })
    }

    importFile = () => {
        this.props.importDiagnoses(this.state.fileData).then((response) => {
            if (response.status !== 400) {
                this.setState({ importDialog: false })
                this.props.getDiagnoses()
            }
        });
    }

    render() {
        const { importDialog, diagnosisToDelete } = this.state;
        const { diagnosesLoading, diagnoses, diagnosesParams, diagnosesTotalElements } = this.props.diagnosisReducer;

        let diagnosesList = diagnoses && diagnoses.map((diagnosis, index) => {
            return <TableRow key={index}>
                <TableCell>{diagnosis.code}</TableCell>
                <TableCell>{diagnosis.name}</TableCell>
                <TableCell>{diagnosis.latinName}</TableCell>
                {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.DOCTOR]) && <TableCell className='row-cell' align='center'>{
                    <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                        <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/diagnoses/${diagnosis.id}`)}>
                            {this.props.t("edit")}
                        </Button>
                        <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ diagnosisToDelete: diagnosis, openDeleteDialog: true })}>
                            {this.props.t("delete")}
                        </Button>
                    </Stack>
                }</TableCell>}
            </TableRow>
        })

        let columns = [
            {
                name: "code",
                dbName: 'code',
                width: '15%',
                sort: true,
                filter: true
            },
            {
                name: "name_diagnosis",
                dbName: 'name',
                width: '35%',
                sort: true,
                filter: true
            },
            {
                name: "latin_name",
                dbName: 'latinName',
                width: '30%',
                filter: true
            },
            {
                name: "",
                width: '20%'
            }
        ]

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                {this.props.t("diagnoses")}
                            </Typography>
                            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.DOCTOR]) &&
                                <Button
                                    className="create-btn"
                                    startIcon={<AddIcon />}
                                    onClick={() => this.props.history.push("/new-diagnosis")}>
                                    {this.props.t("new_diagnosis")}
                                </Button>}
                            {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Button
                                className="create-btn"
                                startIcon={<FileUploadIcon />}
                                onClick={() => this.setState({ importDialog: true })}>
                                {this.props.t("import_diagnoses")}
                            </Button>}
                        </Stack>
                        <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Base58Table
                            isLoading={diagnosesLoading}
                            columns={columns}
                            data={diagnosesList}
                            rowsPerPageOptions={[10, 20, 30, 40]}
                            count={diagnosesTotalElements}
                            rowsPerPage={diagnosesParams.size}
                            page={diagnosesParams.page}
                            onPageChange={(e, page) => this.props.setDiagnosesPage(page)}
                            onRowsPerPageChange={(e) => this.props.setDiagnosesSize(e.target.value)}
                            onFilterChange={(params) => this.props.setDiagnosesParams(params)}
                        />
                    </Grid>
                </Grid>
                <ImportDiagnosesModal
                    isOpen={importDialog}
                    onClose={this.closeImportDialog}
                    fileName={this.state.fileName}
                    importFile={this.importFile}
                    handleChangeFile={this.handleChangeFile}
                />
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_diagnosis")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_diagnosis_message", { name: diagnosisToDelete && diagnosisToDelete.name }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.props.deleteDiagnosis(diagnosisToDelete.id).then(() => {
                        this.setState({ openDeleteDialog: false }, () => this.props.getDiagnoses(), this.props.setDiagnosesPage(0));
                    })}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    diagnosisReducer: state.diagnosisReducer
})

const mapActionsToProps = { getDiagnoses, importDiagnoses, setDiagnosesPage, setDiagnosesSize, setDiagnosesParams, deleteDiagnosis }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Diagnoses))
