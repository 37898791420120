import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Stack,
    Box,
    Divider,
    InputLabel,
    FormControl
} from '@mui/material';
import Base58Table from "../../components/common/Base58Table";
import { deleteWarehouse, getWarehouses, setWarehousesSize, setWarehousesPage, setWarehousesParams, getUserWarehouses } from '../../redux/warehouse/actions';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import store from '../../redux/store';
import types from '../../redux/warehouse/types';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import WarehouseOptions from './WarehouseOptions';

class Warehouses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedWarehouse: null,
            openDeleteDialog: false,
            allowedWarehouses: []
        };
    }

    componentDidMount() {
        if (!this.props.match.params.warehouseId) {
            this.props.getWarehouses();
            this.props.getUserWarehouses().then(response => {
                this.setState({ allowedWarehouses: response });
            });
        }
    }

    componentWillUnmount() {
        this.props.setWarehousesSize(10, true)
        store.dispatch({
            type: types.RESET_WAREHOUSES_DATA
        })
    }

    render() {

        const { selectedWarehouse, allowedWarehouses } = this.state;
        const { isLoading, warehouses, warehousesTotalElements, warehousesParams } = this.props.warehouseReducer;
        let warehousesList;
        if (warehouses && warehouses.length > 0) {
            warehousesList = warehouses.map(warehouse => {
                return <TableRow key={warehouse.id}>
                    <TableCell>
                        {warehouse.name}
                    </TableCell>
                    <TableCell>
                        {warehouse.users.map(u => {
                            return <div key={u.id}>{`${u.firstName} ${u.lastName} (${u.phone})`}</div>
                        })}
                    </TableCell>
                    <TableCell>
                        {warehouse.description}
                    </TableCell>
                    <TableCell className='row-cell' align='center'>
                        <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                            <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push("/warehouses/" + warehouse.id)}>
                                {this.props.t("edit")}
                            </Button>
                            <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ warehouseToDelete: warehouse, openDeleteDialog: true })}>
                                {this.props.t("delete")}
                            </Button>
                        </Stack>
                    </TableCell>
                </TableRow>
            })
        }

        let columns = [
            {
                name: "name",
                dbName: 'name',
                width: '25%',
                sort: true
            },
            {
                name: "users",
                dbName: "users",
                width: '25%'
            },
            {
                name: "description",
                dbName: "description",
                width: '25%'
            },
            {
                name: "",
                width: '25%'
            }
        ];

        return (
            <Grid container direction="row" spacing={2}>
                {!selectedWarehouse && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className='custom-box'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row">
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                        {this.props.t("warehouses")}
                                    </Typography>
                                    <Button
                                        className="create-btn"
                                        startIcon={<AddIcon />}
                                        style={{ marginLeft: 'auto' }}
                                        onClick={() => this.props.history.push("warehouses/new")}>
                                        {this.props.t("new_warehouse")}
                                    </Button>
                                </Stack>
                                <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Base58Table
                                    isLoading={isLoading}
                                    columns={columns}
                                    data={warehousesList}
                                    count={warehousesTotalElements}
                                    rowsPerPage={warehousesParams.size}
                                    page={warehousesParams.page}
                                    onPageChange={(e, page) => this.props.setWarehousesPage(page)}
                                    onRowsPerPageChange={(e) => this.props.setWarehousesSize(e.target.value)}
                                    onFilterChange={(params) => this.props.setWarehousesParams({ ...params, page: 0 })}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>}
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className='custom-box'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputLabel className="form-label">
                                    {this.props.t("warehouse")} <span style={{ color: "#D32F2F" }}>*</span>
                                </InputLabel>
                                <FormControl>
                                    <WrappedAutocomplete
                                        id="selectedWarehouse"
                                        name="selectedWarehouse"
                                        value={selectedWarehouse}
                                        getOptionLabel={(option) => `${option.name}`}
                                        options={allowedWarehouses}
                                        onChange={(event, value) => this.setState({ selectedWarehouse: value }, () => this.props.history.push(`/warehouses/${value.id}/categories`))}
                                        placeholder={this.props.t("select")}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {selectedWarehouse && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <WarehouseOptions />
                </Grid>}
                {this.state.openDeleteDialog && this.state.warehouseToDelete && <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_warehouse")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_warehouse_message", { name: this.state.warehouseToDelete.name }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.props.deleteWarehouse(this.state.warehouseToDelete.id).then(() => this.setState({ openDeleteDialog: false }))}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

const mapActionsToProps = { getWarehouses, deleteWarehouse, setWarehousesPage, setWarehousesSize, setWarehousesParams, getUserWarehouses }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(Warehouses)))
