import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import './Statistics.css'
import { getAllGoogleForms, setGoogleFormsPage, setGoogleFormsSize, setGoogleFormsParams } from '../../redux/setting/actions'
import { getPatientList } from '../../redux/patient/actions'
import { Check, Clear } from '@mui/icons-material';
import Base58Table from '../../components/common/Base58Table';
import moment from 'moment';
import types from '../../redux/setting/types';
import store from '../../redux/store';
import JsonView from 'react18-json-view';

class DashboardStatisticsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.getAllGoogleForms()
        this.props.getPatientList()
    }

    componentWillUnmount() {
        store.dispatch({
            type: types.SET_GOOGLE_FORMS_PARAMS,
            payload: { page: 0, size: 10 }
        });
    }

    getParamDetails = (index) => {
        const { googleForms } = this.props.settingReducer;
        let selectedParams = googleForms[index].googleFormAnswer;
        if (this.isJsonString(selectedParams.rawResponse)) {
            let rawResponse = JSON.parse(selectedParams.rawResponse);
            selectedParams.rawResponse = rawResponse;
        }
        return selectedParams;
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {

        const { googleForms, googleFormLoading, googleFormsTotalElements, googleFormsParams } = this.props.settingReducer
        const { patientList } = this.props.patientReducer

        let formData = googleForms && googleForms.map((gF, index) => {
            let patient = patientList.find(p => p.id === gF.patientId)
            return <TableRow key={index}>
                <TableCell>{patient && `${patient.firstName} ${patient.lastName} ${patient.dateOfBirth}, ${patient.phone}, ${patient.email}${patient.city === null ? "" : ", " + patient.city}`}</TableCell>
                <TableCell>{moment.unix(gF.createdDate).format("DD.MM.YYYY. HH:mm:ss")}</TableCell>
                <TableCell align='center'>{gF.responseCollected ? <Check style={{ color: '#22AE73' }} /> : <Clear style={{ color: '#F44812' }} />}</TableCell>
                <TableCell align='center'>{gF.responseCollected && <JsonView collapsed={true} src={this.getParamDetails(index)} />}</TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "patient",
                width: '50%'
            },
            {
                name: "created_date",
                dbName: "createdDate",
                width: '20%',
                sort: true
            },
            {
                name: "response_collected",
                dbName: 'responseCollected',
                width: '15%',
                filter: true,
                type: 'boolean-select'
            },
            {
                name: "googleFormAnswer",
                width: '15%'
            }
        ]

        return (
            <Grid container direction="row" spacing={3}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ color: "#5C5C5C", fontSize: "16px", fontWeight: 500 }}>{this.props.t("patient_satisfaction_questionnaire")}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={googleFormLoading}
                        columns={columns}
                        data={formData}
                        count={googleFormsTotalElements}
                        rowsPerPage={googleFormsParams.size}
                        page={googleFormsParams.page}
                        onPageChange={(e, page) => this.props.setGoogleFormsPage(page)}
                        onRowsPerPageChange={(e) => this.props.setGoogleFormsSize(e.target.value)}
                        onFilterChange={(params) => this.props.setGoogleFormsParams(params)}
                    />
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer,
    patientReducer: state.patientReducer
})

export default connect(mapStateToProps, { getAllGoogleForms, getPatientList, setGoogleFormsPage, setGoogleFormsSize, setGoogleFormsParams })(withTranslation()(DashboardStatisticsForm))