import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    FormControl,
    Grid,
    Typography,
    Button,
    TableContainer,
    Paper,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Table,
    InputLabel,
    Box,
    Stack
} from '@mui/material';
import moment from 'moment';
import { getServicesByDoctorId } from '../../redux/service/actions'
import { getExaminationStatistics, exportStatisticsToExcel, exportStatisticsToPdf } from '../../redux/examination/actions'
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import { NumericFormat } from 'react-number-format';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import './Statistics.css'
import StatisticsIcon from '../../assets/icons/statistics.svg';
import ExportXLSIcon from '../../assets/icons/exportXLS.svg';
import ExportPDFIcon from '../../assets/icons/exportPDF.svg';
import { optionsPieChart, randomColor } from '../../utils/ChartUtil';

ChartJS.register(
    CategoryScale,
    PieController,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

class DashboardStatisticsDoctor extends Component {

    emptyData = {
        userId: null,
        serviceIds: null,
        serviceGroupIds: null,
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedDoctor: null,
            selectedServices: [],
            selectedServiceGroups: [],
            selectedStartDate: null,
            selectedEndDate: null,
            data: JSON.parse(JSON.stringify(this.emptyData))
        };
    }

    componentWillUnmount() {
        this.props.examinationReducer.examinationStats = null
    }

    handleChangeStartDate = (date) => {
        let { data } = this.state
        data.startDate = moment(date)
        this.props.examinationReducer.examinationStats = null
        this.setState({ data: data, selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        data.endDate = moment(date).add(24, 'hours')
        this.props.examinationReducer.examinationStats = null
        this.setState({ data: data, selectedEndDate: date });
    }

    handleChangeDoctor = (e, doctor) => {
        let { selectedDoctor, data } = this.state;
        if (doctor !== null) {
            data.userId = doctor.id
            selectedDoctor = doctor
            this.props.examinationReducer.examinationStats = null
        } else {
            data.userId = null
            selectedDoctor = null
            this.props.examinationReducer.examinationStats = null
        }
        this.setState({ selectedDoctor: selectedDoctor, data: data, selectedServiceGroups: [], selectedServices: [] })
    }

    handleChangeServiceGroups = (e, serviceGroup) => {
        let { data, selectedServiceGroups, selectedServices } = this.state
        if (serviceGroup.length === 0) {
            data.serviceGroupIds = null
            this.props.examinationReducer.examinationStats = null
            this.setState({ data: data, selectedServiceGroups: [], selectedServices: [] });
        } else {
            selectedServices = []
            data.serviceIds = null
            selectedServiceGroups = serviceGroup;
            data.serviceGroupIds = []
            selectedServiceGroups.forEach(d => {
                data.serviceGroupIds.push(d.id)
            })
            this.props.examinationReducer.examinationStats = null
            this.setState({ selectedServiceGroups: selectedServiceGroups, data: data, selectedServices: selectedServices });
        }
    }

    handleChangeServices = (e, service) => {
        let { data } = this.state
        let selectedServices = { ...this.state.selectedServices }
        if (service.length === 0) {
            data.serviceIds = null
            this.props.examinationReducer.examinationStats = null
            this.setState({ data: data, selectedServices: [] });
        } else {
            selectedServices = service;
            data.serviceIds = []
            selectedServices.forEach(d => {
                data.serviceIds.push(d.id)
            })
            this.props.examinationReducer.examinationStats = null
            this.setState({ selectedServices: selectedServices, data: data });
        }
    }

    getStatistics = () => {
        this.props.getExaminationStatistics(this.state.data)
    }

    render() {
        const { selectedDoctor, selectedServices, selectedServiceGroups, selectedStartDate, selectedEndDate } = this.state
        const { doctors } = this.props.userReducer
        const { serviceList } = this.props.serviceReducer
        const { examinationStats } = this.props.examinationReducer
        const { serviceGroupsList } = this.props.serviceGroupReducer

        let pieChartData = []
        examinationStats && examinationStats.groupItemList.map(d => {
            return d.services.map(d1 => {
                return pieChartData.push(d1)
            })
        })

        return (<Grid container direction="row" spacing={3}>
            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={{ color: "#5C5C5C", fontSize: "16px", fontWeight: 500 }}>{this.props.t("statistics_for_doctors")}</Typography>
            </Grid>
            <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container direction="row" spacing={3}>
                    <Grid item container direction="column" xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Box boxShadow={3} className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel className="form-label">
                                        {this.props.t("doctor")} <span style={{ color: "#D32F2F" }}>*</span>
                                    </InputLabel>
                                    <FormControl>
                                        <WrappedAutocomplete
                                            id="selectedDoctor"
                                            name="selectedDoctor"
                                            value={selectedDoctor}
                                            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.phone})`}
                                            options={doctors}
                                            onChange={(event, value) => {
                                                this.handleChangeDoctor(event, value)
                                            }}
                                            placeholder={this.props.t("select")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel className="form-label">
                                        {this.props.t("service_group")}
                                    </InputLabel>
                                    <FormControl>
                                        <WrappedAutocomplete
                                            disabled={!selectedDoctor}
                                            id="selectedServiceGroups"
                                            name="selectedServiceGroups"
                                            multiple={true}
                                            value={selectedServiceGroups}
                                            getOptionLabel={(option) => option ? option.name : ""}
                                            options={serviceGroupsList}
                                            onChange={(event, value) => {
                                                this.handleChangeServiceGroups(event, value)
                                            }}
                                            placeholder={this.props.t("select")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel className="form-label">
                                        {this.props.t("service")}
                                    </InputLabel>
                                    <FormControl>
                                        <WrappedAutocomplete
                                            disabled={selectedServiceGroups.length > 0 ? false : true}
                                            id="selectedServices"
                                            name="selectedServices"
                                            multiple={true}
                                            value={selectedServices}
                                            getOptionLabel={(option) => option ? option.code + " - " + option.name : ""}
                                            options={serviceList.filter(sl => selectedServiceGroups.some(ssg => ssg.name === sl.serviceGroup))}
                                            onChange={(event, value) => {
                                                this.handleChangeServices(event, value)
                                            }}
                                            placeholder={this.props.t("select")}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Box boxShadow={3} className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container>
                                    <InputLabel className="form-label">
                                        {this.props.t("start_date")}
                                    </InputLabel>
                                    <FormControl>
                                        <WrappedDatePicker
                                            id="startDate"
                                            name="startDate"
                                            maxDate={moment(selectedEndDate).add(-1, 'day')}
                                            onChange={this.handleChangeStartDate}
                                            value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container>
                                    <InputLabel className="form-label">
                                        {this.props.t("end_date")} <span style={{ color: "#D32F2F" }}>*</span>
                                    </InputLabel>
                                    <FormControl>
                                        <WrappedDatePicker
                                            id="endDate"
                                            name="endDate"
                                            minDate={moment(selectedStartDate).add(1, 'day')}
                                            maxDate={moment(new Date()).add(1, 'year')}
                                            onChange={this.handleChangeEndDate}
                                            value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item container mb={4.4}>
                                    <Button
                                        className="create-btn"
                                        style={{ width: '100%' }}
                                        startIcon={<img src={StatisticsIcon} width={24} height={24} alt="statistics-icon" />}
                                        title={this.props.t("get_statistics")}
                                        onClick={() => this.getStatistics()}
                                        disabled={!selectedEndDate || !selectedDoctor}>
                                        {this.props.t("statistics")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {examinationStats && examinationStats.groupItemList.length > 0 && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box boxShadow={3} className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("group")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("service")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align="center">{this.props.t("date")}</TableCell>
                                                    <TableCell style={{ fontWeight: 'bold' }} align="right">{this.props.t("total")}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {examinationStats.groupItemList.map((data, index) => {
                                                    return <React.Fragment key={index}>
                                                        <TableRow>
                                                            <TableCell className="table-cell" style={{ verticalAlign: 'top' }}>
                                                                {data.name}
                                                            </TableCell>
                                                            <TableCell style={{ padding: '0px' }}>
                                                                {data.services.map((item, index) => {
                                                                    return <Fragment key={index}>
                                                                        <p style={{ height: item.valueItems.length > 1 ? 20 * item.valueItems.length : 20 }}>
                                                                            {item.name}
                                                                        </p>
                                                                        <p className="border-top-total">
                                                                            &nbsp;
                                                                        </p>
                                                                    </Fragment>
                                                                })}
                                                            </TableCell>
                                                            <TableCell align='center' style={{ padding: '0px' }}>
                                                                {data.services.map(item => {
                                                                    return item.valueItems.map((item1, index1) => {
                                                                        return <Fragment key={index1}>
                                                                            <p style={{ height: '20px' }}>
                                                                                {moment.unix(item1.date).format("MM.YYYY.")}
                                                                            </p>
                                                                            {index1 + 1 === item.valueItems.length && <p className="border-top-total" style={{ textAlign: 'left', backgroundColor: '#E7E8E7' }}>
                                                                                {this.props.t("Total")}
                                                                            </p>}
                                                                        </Fragment>
                                                                    })
                                                                })}
                                                            </TableCell>
                                                            <TableCell align='right' style={{ padding: '0px' }}>
                                                                {data.services.map(item => {
                                                                    return item.valueItems.map((item1, index1) => {
                                                                        return <Fragment key={index1}>
                                                                            <p style={{ height: '20px', paddingRight: '10px' }}>
                                                                                <NumericFormat value={item1.amount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                                            </p>
                                                                            {index1 + 1 === item.valueItems.length && <p className="border-top-total" style={{ paddingRight: '10px', backgroundColor: '#E7E8E7' }}>
                                                                                <NumericFormat value={item.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                                            </p>}
                                                                        </Fragment>
                                                                    })
                                                                })}
                                                            </TableCell>
                                                        </TableRow>
                                                        {selectedServiceGroups.length !== 1 && <TableRow>
                                                            <TableCell colSpan={2} />
                                                            <TableCell align="left" style={{ fontWeight: 'bold', padding: '0px', backgroundColor: 'lightgrey' }}>{this.props.t("Total") + " - " + data.name}</TableCell>
                                                            <TableCell className="total-cell" style={{ backgroundColor: 'lightgrey' }} align="right"><NumericFormat value={data.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                        </TableRow>}
                                                    </React.Fragment>
                                                })}
                                                <TableRow style={{ borderTop: '1px solid black', backgroundColor: 'lightgrey' }}>
                                                    <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("Total")}</TableCell>
                                                    <TableCell colSpan={2} />
                                                    <TableCell className="total-cell" align="right"><NumericFormat value={examinationStats.total} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item container>
                                    <Stack direction="row" spacing={1.5} sx={{ marginLeft: "auto" }}>
                                        <Button
                                            sx={{ height: "42px", width: "100px" }}
                                            title={this.props.t("export_to_xls")}
                                            color="primary"
                                            onClick={() => this.props.exportStatisticsToExcel(this.state.data)}
                                            startIcon={<img src={ExportXLSIcon} alt="export-icon" />}
                                        >
                                            {this.props.t("export_to_xls")}
                                        </Button>
                                        <Button
                                            sx={{ height: "42px", width: "100px" }}
                                            title={this.props.t("export_to_pdf")}
                                            color="error"
                                            onClick={() => this.props.exportStatisticsToPdf(this.state.data)}
                                            startIcon={<img src={ExportPDFIcon} alt="export-icon" />}
                                        >
                                            {this.props.t("export_to_pdf")}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>}
                    {examinationStats && examinationStats.groupItemList.length === 0 && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography sx={{ fontSize: "18px", fontWeight: 600 }} color='primary'>{this.props.t("no_data_found")}</Typography>
                    </Grid>}
                </Grid>
            </Grid>
            {examinationStats && examinationStats.groupItemList.length > 0 && <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box boxShadow={3} className="custom-box" sx={{ width: "100%" }} justifyItems="center">
                    {selectedServiceGroups.length !== 1 && examinationStats.groupItemList.length > 1 && <div style={{ width: "700px", height: "700px" }}><Pie
                        data={{
                            labels: examinationStats.groupItemList.map(data => { return data.name }),
                            datasets: [{
                                data: examinationStats.groupItemList.map(data => { return data.total }),
                                backgroundColor: examinationStats.groupItemList.map(data => { return randomColor() }),
                                hoverOffset: 4
                            }]
                        }}
                        options={optionsPieChart}
                        plugins={[ChartDataLabels]}
                    /></div>}
                    {selectedServices.length !== 1 && <div style={{ width: "700px", height: "700px" }}> <Pie
                        data={{
                            labels: pieChartData.map(d => { return d.name }),
                            datasets: [{
                                data: pieChartData.map(data => { return data.total }),
                                backgroundColor: pieChartData.map(data => { return randomColor() }),
                                hoverOffset: 4
                            }]
                        }}
                        options={optionsPieChart}
                        plugins={[ChartDataLabels]}
                    /> </div>}
                </Box>
            </Grid>}
        </Grid >
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    serviceReducer: state.serviceReducer,
    examinationReducer: state.examinationReducer,
    serviceGroupReducer: state.serviceGroupReducer
})

export default connect(mapStateToProps, { getServicesByDoctorId, getExaminationStatistics, exportStatisticsToExcel, exportStatisticsToPdf })(withTranslation()(DashboardStatisticsDoctor))
