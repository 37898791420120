import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Box,
    Button,
    Collapse,
    FormHelperText,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { ReactComponent as AddIcon } from '../../../assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete_icon.svg';
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down.svg';
import {
    updateDocument
} from '../../../redux/warehouse/actions';
import Base58Table from '../../../components/common/Base58Table';
import { getDocuments, setDocumentsSize, setDocumentsParams, setDocumentsPage, deleteDocument, getDocumentItemsbyDocumentId, setDocumentItemsPage, getSuppliersByWarehouseId } from '../../../redux/warehouse/actions'
import ConfirmationDialog from '../../../components/common/ConfirmationDialog';
import store from '../../../redux/store';
import types, { SET_DOCUMENT_ITEMS } from '../../../redux/warehouse/types';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import Enum from './DocumentEnums';
import WrappedDatePicker from '../../../components/common/WrappedDatePicker';
import InfiniteScroll from 'react-infinite-scroll-component';
import WrappedAutocomplete from '../../../components/common/WrappedAutocomplete';

class Documents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            documentToDelete: null,
            openDeleteDialog: false,
            collapseIndex: -1,
            selectedDocumentIndex: -1,
            selectedEditIndex: -1,
            documents: [],
            initialDocumentItems: [],
            isLoading: false,
            suppliers: []
        };
    }

    componentDidMount() {
        this.props.getDocuments(this.props.match.params.warehouseId)
        this.props.getSuppliersByWarehouseId(this.props.match.params.warehouseId).then(response => {
            this.setState({ suppliers: response.data });
        });
    }

    componentWillUnmount() {
        this.props.setDocumentsSize(this.props.match.params.warehouseId, 10, true)
        store.dispatch({ type: types.RESET_DOCUMENTS_DATA })
        store.dispatch({ type: types.RESET_DOCUMENT_ITEMS_DATA })
    }

    closeDeleteDialog = () => {
        this.setState({ openDeleteDialog: false, documentToDelete: null });
    }

    deleteDocument = () => {
        this.props.deleteDocument(this.state.documentToDelete.id).then((response) => {
            if (response.status !== 400) {
                this.setState({ openDeleteDialog: false, collapseIndex: -1 }, () => this.props.setDocumentsPage(this.props.match.params.warehouseId, 0));
            }
        })
    }

    handleChangeSupplier = (name, value, itemId) => {
        let documentItems = [...this.props.warehouseReducer.documentItems];
        const findIndex = documentItems.findIndex((di) => di.id === itemId);
        documentItems[findIndex][name] = value;
        this.setState({ documents: documentItems });
    }

    handleChangeNumber = (values, sourceInfo, itemId) => {
        let documentItems = [...this.props.warehouseReducer.documentItems];
        const findIndex = documentItems.findIndex((di) => di.id === itemId);
        if (sourceInfo.event) {
            const { floatValue } = values;
            documentItems[findIndex][sourceInfo.event.target.name] = floatValue;
            this.setState({ documents: documentItems });
        }
    }

    handleChangeExpiryDate = (date, itemId) => {
        let documentItems = [...this.props.warehouseReducer.documentItems];
        const findIndex = documentItems.findIndex((di) => di.id === itemId);
        if (date !== null && date._isValid) {
            documentItems[findIndex].expiryDate = date;
            this.setState({ documents: documentItems });
        }
    }

    handleChangeItemField = (e, itemId) => {
        let documentItems = [...this.props.warehouseReducer.documentItems];
        const findIndex = documentItems.findIndex((di) => di.id === itemId);
        documentItems[findIndex][e.target.name] = e.target.value;
        this.setState({ documents: documentItems });
    }

    onSave = () => {
        const { selectedDocumentIndex, documents } = this.state
        let document = this.props.warehouseReducer.documents.find(d => d.id === selectedDocumentIndex);
        let requestBody = {
            ...document,
            documentItems: documents
        }

        this.props.updateDocument(selectedDocumentIndex, requestBody).then(response => {
            if (response.status === 200) {
                this.setState({
                    selectedDocumentIndex: -1,
                    selectedEditIndex: -1,
                    documents: []
                })
            }
        })
    }

    onCancel = () => {
        console.log("this.state.initialDocumentItems", this.state.initialDocumentItems)
        store.dispatch({
            type: SET_DOCUMENT_ITEMS,
            payload: JSON.parse(JSON.stringify(this.state.initialDocumentItems))
        })
        this.setState({ selectedEditIndex: -1 })

    }

    getDocumentItemsbyDocumentId = (documentId) => {
        this.setState({ isLoading: true })
        store.dispatch({ type: types.RESET_DOCUMENT_ITEMS_DATA })
        this.props.getDocumentItemsbyDocumentId(documentId).then((response) => {
            this.setState({ isLoading: false, initialDocumentItems: JSON.parse(JSON.stringify(response)) })
        })
    }

    render() {
        const { t } = this.props
        const { collapseIndex, documentToDelete, selectedEditIndex, isLoading, suppliers } = this.state
        const { documentsLoading, documents, documentsParams, documentsTotalElements, documentItems, documentItemsParams, hasMoreDocumentItems } = this.props.warehouseReducer;
        console.log("documentItems", documentItems)
        let documentList = documents && documents.map((document, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell align='center'>
                        <IconButton size="small"
                            onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1, selectedDocumentIndex: document.id, selectedEditIndex: -1 },
                                () => collapseIndex !== index ? this.getDocumentItemsbyDocumentId(document.id) : store.dispatch({ type: types.RESET_DOCUMENT_ITEMS_DATA }))}>
                            {index !== collapseIndex ? <ArrowDown /> : <ArrowUp />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{document.referenceNo}</TableCell>
                    <TableCell>{t(document.type)}</TableCell>
                    <TableCell>{t(document.documentType)}</TableCell>
                    <TableCell>{document.description}</TableCell>
                    <TableCell>{moment(document.date).format('DD.MM.YYYY.')}</TableCell>
                    <TableCell className='row-cell' align='center'>{
                        <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                            <Button title={t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/documents/${document.id}`)}>
                                {t("edit")}
                            </Button>
                            <Button title={t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ documentToDelete: document, openDeleteDialog: true })}>
                                {t("delete")}
                            </Button>
                        </Stack>
                    }</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ border: "none", paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={7}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit>
                            {isLoading ? <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box> :
                                <Box margin={1}>
                                    <InfiniteScroll
                                        dataLength={documentItems.length}
                                        next={() => this.props.setDocumentItemsPage(document.id, documentItemsParams.page)}
                                        hasMore={hasMoreDocumentItems}
                                        loader={<></>}
                                        scrollableTarget="tableContainer"
                                    >
                                        <TableContainer component={Paper} style={{ maxHeight: 500 }} id="tableContainer">
                                            <Table stickyHeader>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className="table-cell-light" width='3%'>#</TableCell>
                                                        <TableCell className="table-cell-light" width='10%'>{t("item")}</TableCell>
                                                        <TableCell className="table-cell-light" width='10%'>{t("supplier")}</TableCell>
                                                        <TableCell className="table-cell-light" width='4%' align='center'>{t("quantity")}</TableCell>
                                                        <TableCell className="table-cell-light" width='6%' align='right'>{t("price")}</TableCell>
                                                        <TableCell className="table-cell-light" width='9%'>{t("expiry_date")}</TableCell>
                                                        <TableCell className="table-cell-light" width='7%'>{t("serial_no")}</TableCell>
                                                        <TableCell className="table-cell-light" width='14%'>{t("comment")}</TableCell>
                                                        <TableCell className="table-cell-light" width='14%'>{t("documentation_comment")}</TableCell>
                                                        <TableCell className="table-cell-light" width='14%'>{t("service_comment")}</TableCell>
                                                        <TableCell className="table-cell-light" width='9%'>{t("actions")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {documentItems.map((item, index) => {
                                                        return <TableRow key={document.id + "-" + item.id + "-" + item.itemId + "-" + index}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{item.itemName}</TableCell>
                                                            <TableCell>
                                                                {item.id === selectedEditIndex ?
                                                                    <WrappedAutocomplete
                                                                        freeSolo
                                                                        id="supplier"
                                                                        name="supplier"
                                                                        value={item.supplier || ""}
                                                                        getOptionLabel={(option) => `${option}`}
                                                                        options={suppliers ? suppliers.filter(s => s !== null) : []}
                                                                        onChange={(name, value) => this.handleChangeSupplier(name, value, item.id)}
                                                                        placeholder={this.props.t("enter_or_select")}
                                                                        isOptionEqualToValue={(option, value) => option === value}
                                                                        renderOption={(props, option) => (
                                                                            <Box component="li" {...props} key={option}>
                                                                                {option}
                                                                            </Box>
                                                                        )}
                                                                    /> : item.supplier}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {item.id === selectedEditIndex ?
                                                                    <NumericFormat
                                                                        id={"quantity-" + item.id}
                                                                        name={"quantity"}
                                                                        customInput={TextField}
                                                                        decimalScale={2}
                                                                        value={item.quantity || ''}
                                                                        onValueChange={(values, sourceInfo) => this.handleChangeNumber(values, sourceInfo, item.id)}
                                                                        allowNegative={false}
                                                                        isAllowed={(values) => {
                                                                            const { floatValue } = values;
                                                                            if (floatValue) {
                                                                                return floatValue <= item.maxAllowedQuantity;
                                                                            }
                                                                            if (floatValue === 0) return false;
                                                                            return true;
                                                                        }}
                                                                        placeholder={this.state.initialDocumentItems[index] && this.state.initialDocumentItems[index].quantity ? String(this.state.initialDocumentItems[index].quantity) : this.props.t("enter", { text: "" })}
                                                                    /> : item.quantity}
                                                                {!item.quantity && item.maxAllowedQuantity && <FormHelperText sx={{ color: "#d32f2f" }}>{this.props.t("max", { text: item.maxAllowedQuantity })} </FormHelperText>}
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {item.id === selectedEditIndex ?
                                                                    <NumericFormat
                                                                        id={"price-" + item.id}
                                                                        name="price"
                                                                        customInput={TextField}
                                                                        decimalScale={2}
                                                                        suffix=" EUR"
                                                                        decimalSeparator=','
                                                                        value={item.price || ''}
                                                                        onValueChange={(values, sourceInfo) => this.handleChangeNumber(values, sourceInfo, item.id)}
                                                                        placeholder={this.props.t("enter", { text: "" })}
                                                                        allowNegative={false}
                                                                    /> : <NumericFormat displayType="text" decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} value={item.price} suffix=" EUR" />}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.id === selectedEditIndex ?
                                                                    <WrappedDatePicker
                                                                        id={"expiryDate-" + item.id}
                                                                        name="expiryDate"
                                                                        required
                                                                        maxDate={moment().add(2, 'year')}
                                                                        onChange={(date) => this.handleChangeExpiryDate(date, item.id)}
                                                                        value={item.expiryDate}
                                                                        error={item.expiryDate === "Invalid date"}
                                                                        helperText={item.expiryDate === "Invalid date" && this.props.t("required_field_message")}
                                                                    /> : item.expiryDate !== null ? moment(item.expiryDate).format('DD.MM.YYYY.') : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.id === selectedEditIndex ?
                                                                    <TextField
                                                                        id={"serialNo-" + item.id}
                                                                        name="serialNo"
                                                                        value={item.serialNo || ''}
                                                                        onChange={(e) => this.handleChangeItemField(e, item.id)}
                                                                        placeholder={this.props.t("enter", { text: "" })}
                                                                        disabled={item.itemId === null}
                                                                        error={item.serialNo && item.serialNo.length > 255 ? true : false}
                                                                        helperText={item.serialNo && item.serialNo.length > 255 && this.props.t("number_regex_message", { max: 255 })} />
                                                                    : item.serialNo ? item.serialNo : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.id === selectedEditIndex ?
                                                                    <TextField
                                                                        id={"comment-" + item.id}
                                                                        name="comment"
                                                                        value={item.comment || ''}
                                                                        onChange={(e) => this.handleChangeItemField(e, item.id)}
                                                                        placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                                                                        disabled={item.itemId === null}
                                                                        error={item.comment && item.comment.length > 255 ? true : false}
                                                                        helperText={item.comment && item.comment.length > 255 && this.props.t("number_regex_message", { max: 255 })} />
                                                                    : item.comment ? item.comment : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.id === selectedEditIndex ?
                                                                    <TextField
                                                                        id={"documentation-comment-" + item.id}
                                                                        name="documentationComment"
                                                                        value={item.documentationComment || ''}
                                                                        onChange={(e) => this.handleChangeItemField(e, item.id)}
                                                                        placeholder={this.props.t("enter", { text: this.props.t("documentation_comment").toLowerCase() })}
                                                                        disabled={item.itemId === null}
                                                                        error={item.documentationComment && item.documentationComment.length > 255 ? true : false}
                                                                        helperText={item.documentationComment && item.documentationComment.length > 255 && this.props.t("number_regex_message", { max: 255 })} />
                                                                    : item.documentationComment ? item.documentationComment : '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.id === selectedEditIndex ?
                                                                    <TextField
                                                                        id={"service-comment-" + item.id}
                                                                        name="serviceComment"
                                                                        value={item.serviceComment || ''}
                                                                        onChange={(e) => this.handleChangeItemField(e, item.id)}
                                                                        placeholder={this.props.t("enter", { text: this.props.t("service_comment").toLowerCase() })}
                                                                        disabled={item.itemId === null}
                                                                        error={item.serviceComment && item.serviceComment.length > 255 ? true : false}
                                                                        helperText={item.serviceComment && item.serviceComment.length > 255 && this.props.t("number_regex_message", { max: 255 })} />
                                                                    : item.serviceComment ? item.serviceComment : '-'}
                                                            </TableCell>
                                                            <TableCell className='row-cell' align='center'>{
                                                                <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                                                                    {item.id === selectedEditIndex ?
                                                                        <>
                                                                            <Button title={t("save")} className="default-button-edit" sx={{ backgroundColor: '#1B7E53 !important' }} onClick={() => this.onSave()}>
                                                                                {t("save")}
                                                                            </Button>
                                                                            <Button title={t("cancel")} className="default-button-delete" onClick={() => this.onCancel()}>
                                                                                {t("cancel")}
                                                                            </Button>
                                                                        </>
                                                                        :
                                                                        <Button title={t("edit")} className="default-button-edit" disabled={selectedEditIndex > -1 && item.id !== selectedEditIndex} endIcon={<EditIcon />} onClick={() => this.setState({ selectedEditIndex: item.id })}>
                                                                            {t("edit")}
                                                                        </Button>}
                                                                </Stack>
                                                            }</TableCell>
                                                        </TableRow>
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </InfiniteScroll>
                                </Box>}
                        </Collapse>
                    </TableCell>
                </TableRow>

            </React.Fragment>
        })

        let columns = [
            {
                name: "",
                dbName: '',
                width: '5%'
            },
            {
                name: "reference_no",
                dbName: 'referenceNo',
                width: '15%',
                sort: true
            },
            {
                name: "type",
                dbName: 'type',
                width: '11%',
                sort: true,
                type: "auto-complete",
                filter: true,
                items: [Enum.Types.IN, Enum.Types.OUT],
                multiple: false
            },
            {
                name: "document_type",
                dbName: 'documentType',
                width: '16%',
                sort: true,
                type: "auto-complete",
                filter: true,
                items: [Enum.DocumentTypes.INVENTORY, Enum.DocumentTypes.MANUAL],
                multiple: false
            },
            {
                name: "description",
                dbName: 'description',
                width: '22%'
            },
            {
                name: "date",
                dbName: 'date',
                width: '16%'
            },
            {
                name: "",
                width: '15%'
            }
        ]

        return (
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5" style={{ alignItems: 'center', display: 'flex' }}>
                        <Button
                            className="create-btn"
                            sx={{ marginLeft: 'auto' }}
                            startIcon={<AddIcon />}
                            onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/documents/new`)}
                        >
                            {t("new_document")}
                        </Button>
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Base58Table
                        isLoading={documentsLoading}
                        columns={columns}
                        data={documentList}
                        rowsPerPageOptions={[10, 20, 30, 40]}
                        count={documentsTotalElements}
                        rowsPerPage={documentsParams.size}
                        page={documentsParams.page}
                        onPageChange={(e, page) => { this.props.setDocumentsPage(this.props.match.params.warehouseId, page); this.setState({ collapseIndex: -1 }) }}
                        onRowsPerPageChange={(e) => { this.props.setDocumentsSize(this.props.match.params.warehouseId, e.target.value); this.setState({ collapseIndex: -1 }) }}
                        onFilterChange={(params) => { this.props.setDocumentsParams(this.props.match.params.warehouseId, params); this.setState({ collapseIndex: -1 }) }}
                    />
                </Grid>
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={t("delete_document")}
                    message={<span dangerouslySetInnerHTML={{ __html: t("delete_document_message", { number: documentToDelete && documentToDelete.referenceNo }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.deleteDocument()}
                    closeButtonTitle={t("close")}
                    confirmButtonTitle={t("delete")}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

export default connect(mapStateToProps, { getDocuments, setDocumentsSize, setDocumentsParams, setDocumentsPage, deleteDocument, updateDocument, getDocumentItemsbyDocumentId, setDocumentItemsPage, getSuppliersByWarehouseId })(withRouter(withTranslation()(Documents)))