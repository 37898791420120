import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    DialogContent,
    Grid,
    TextField,
    FormControl,
    Box,
    Stack,
    Typography,
    InputLabel
} from '@mui/material';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { getPatientList, getGenders, getSources, getCounties } from '../../redux/patient/actions'
import { createCalendarEvent, updateCalendarEvent, getCalendarEntryById, updateCalendarEventStatus, deleteCalendarEntry, changePatientInfirmary } from '../../redux/calendar/actions'
import ManagePatientDialog from '../patient/ManagePatientDialog';
import WrappedDateTimePicker from '../../components/common/WrappedDateTimePicker';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { NumericFormat } from 'react-number-format';
import { BootstrapDialog, Transition } from '../../components/common/Dialog';
import AddIcon from '../../assets/icons/add_icon.svg';
import EditIcon from '../../assets/icons/edit_icon.svg';
import DeleteIcon from '../../assets/icons/delete_icon.svg';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

class ManageEvent extends Component {

    emptyCalendarEntry = {
        startTime: null,
        endTime: null,
        patientId: null,
        infirmaryId: null,
        comment: '',
        priceEstimate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            newPatientDialog: false,
            promptDialogOpen: false,
            selectPatient: null,
            startTime: null,
            endTime: null,
            calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            selectedStartTime: null,
            selectedEndTime: null,
            patientToEdit: null,
            selectedInfirmaryToChange: null,
            infirmarySelected: null
        };
    }

    componentDidMount() {
        this.getPatientList()
        this.props.getGenders()
        this.props.getSources()
        this.props.getCounties()
    }

    componentDidUpdate(prevProps) {
        if (this.props.calendarEventToEdit && prevProps.calendarEventToEdit !== this.props.calendarEventToEdit) {
            this.props.getCalendarEntryById(this.props.calendarEventToEdit.id.toString()).then(response => {
                this.setState({
                    calendaryEntry: JSON.parse(JSON.stringify(response.data)),
                    initialCalendarEntry: JSON.parse(JSON.stringify(response.data)),
                    selectPatient: this.props.patientReducer.patientList.find(p => p.id === response.data.patientId),
                    selectedStartTime: moment(response.data.startTime),
                    selectedEndTime: moment(response.data.endTime),
                    infirmarySelected: response.data.infirmaryId
                })
            })
        }
    }

    getPatientList = (patientId) => {
        this.props.getPatientList().then(() => {
            this.setState({ selectPatient: patientId ? this.props.patientReducer.patientList.find(e => e.id === patientId) : null })
        })
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            selectPatient: null,
            selectedStartTime: null,
            selectedEndTime: null,
            initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            selectedInfirmaryToChange: null
        })
    }

    closeDialogPatient = () => {
        this.setState({ newPatientDialog: false, patientToEdit: null })
    }

    handleChangePatient = (e, patient) => {
        let { calendaryEntry } = this.state
        if (patient !== null) {
            calendaryEntry.patientId = patient.id
            this.setState({ selectPatient: patient, calendaryEntry: calendaryEntry })
        } else {
            calendaryEntry.patientId = null
            this.setState({ selectPatient: null, calendaryEntry: calendaryEntry })
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { calendaryEntry } = this.state;
        calendaryEntry[target.name] = target.value.trimStart()
        this.setState({ calendaryEntry: calendaryEntry })
    }

    handleChangeStartTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedStartTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.startTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: new Date() })
            } else {
                calendaryEntry.startTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: date });
            }
        } else {
            calendaryEntry.startTime = "Invalid date"
            this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: date });
        }
    }

    handleChangeEndTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedEndTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.endTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: new Date() })
            } else {
                calendaryEntry.endTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: date });
            }
        } else {
            calendaryEntry.endTime = "Invalid date"
            this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: date });
        }
    }

    manageEvent = () => {

        let { calendaryEntry, selectPatient } = this.state
        let { infirmary, selectedSlot, calendarEventToEdit } = this.props
        let body = {
            ...calendaryEntry,
            infirmaryId: calendarEventToEdit ? calendarEventToEdit.infirmary.id : infirmary[0].id,
            startTime: calendarEventToEdit ? calendaryEntry.startTime : selectedSlot.start,
            endTime: calendarEventToEdit ? calendaryEntry.endTime : selectedSlot.end,
            patientId: selectPatient.id
        }
        const action = this.props.calendarEventToEdit ?
            this.props.updateCalendarEvent(this.props.calendarEventToEdit.id, body) :
            this.props.createCalendarEvent(body);
        action.then((response) => {
            this.props.calendarEventToEdit && this.state.selectedInfirmaryToChange !== null && this.changePatientInfirmary()
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
                    calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
                    selectPatient: null,
                    selectedInfirmaryToChange: null
                }, () => this.props.onSave());
            }
        })
    }

    deleteCalendarEntry = () => {
        this.props.deleteCalendarEntry(this.props.calendarEventToEdit.id).then((response) => {
            if (response.status === 200) {
                this.setState({
                    initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
                    calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
                    selectPatient: null,
                    selectedInfirmaryToChange: null
                }, () => this.props.onSave());
            }
        })
    }

    validationForm = () => {
        let { calendaryEntry, initialCalendarEntry, selectPatient, selectedInfirmaryToChange } = this.state;
        const { calendarEventToEdit } = this.props
        let valid = true;
        if (!calendaryEntry.comment || !calendaryEntry.comment.length > 500 || selectPatient === null) {
            valid = false
        }
        if (JSON.stringify(calendaryEntry) === JSON.stringify(initialCalendarEntry)) {
            if (calendarEventToEdit !== null && selectedInfirmaryToChange === null) {
                valid = false
            }
        }
        if (calendarEventToEdit) {
            if (!calendaryEntry.startTime || !calendaryEntry.endTime || calendaryEntry.startTime === "Invalid date" || calendaryEntry.endTime === "Invalid date") {
                valid = false
            }
        }
        return valid;
    }

    getCommentHelperText() {
        let { calendaryEntry } = this.state;
        if (!calendaryEntry.comment.trim()) {
            return this.props.t("required_field_message");
        }
        if (calendaryEntry.comment.length > 0 && calendaryEntry.comment.length > 500) {
            return this.props.t("name_regex_message", { min: 1, max: 500 });
        }
    }

    handleChangeInfirmary = (e, infirmary) => {
        if (infirmary !== null) {
            this.setState({ selectedInfirmaryToChange: infirmary })
        } else {
            this.setState({ selectedInfirmaryToChange: null })
        }
    }

    changePatientInfirmary = () => {
        let params = {}
        params.infirmaryId = this.state.selectedInfirmaryToChange.id
        this.props.changePatientInfirmary(this.props.calendarEventToEdit.id, { params: params }).then((response) => {
            if (response.status === 200) {
                this.setState({
                    initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
                    calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
                    selectPatient: null,
                    selectedInfirmaryToChange: null
                }, () => this.props.onSave());
            }
        })
    }

    handleChangePrice = (values) => {
        const { floatValue } = values;
        let { calendaryEntry } = this.state
        calendaryEntry.priceEstimate = floatValue;
        this.setState({ calendaryEntry: calendaryEntry })
    }



    render() {
        const { selectPatient, initialCalendarEntry, calendaryEntry, selectedStartTime, selectedEndTime, selectedInfirmaryToChange, promptDialogOpen, infirmarySelected } = this.state
        const { calendarEventToEdit, infirmary, } = this.props
        const { patientList } = this.props.patientReducer
        const { infirmaryList } = this.props.infirmaryReducer

        let infirmaryColor = calendarEventToEdit ? calendarEventToEdit.infirmary.calendarHexColor : infirmary.length > 0 && infirmary[0].calendarHexColor;
        let infirmaryName = calendarEventToEdit ? calendarEventToEdit.infirmary.name : infirmary.length > 0 && infirmary[0].name;

        return (
            <>
                <BootstrapDialog
                    onClose={() => JSON.stringify(initialCalendarEntry) !== JSON.stringify(calendaryEntry) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}
                    open={this.props.isOpen}
                    fullWidth
                    maxWidth={"md"}
                    TransitionComponent={Transition}>
                    <DialogContent sx={{ padding: "0px !important" }}>
                        <Box className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                            {calendarEventToEdit ? this.props.t("edit_event") : this.props.t("new_event")}
                                        </Typography>
                                        <Button className="cancel-btn" onClick={() => JSON.stringify(initialCalendarEntry) !== JSON.stringify(calendaryEntry) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}>
                                            {this.props.t("cancel")}
                                        </Button>
                                        <Button className="create-btn" onClick={this.manageEvent} disabled={!this.validationForm()}>
                                            {calendarEventToEdit ? this.props.t("update") : this.props.t("create")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item container>
                                                <Typography sx={{ color: "#0F0F0F", backgroundColor: infirmaryColor, fontWeight: 400, fontSize: "14px", lineHeight: "16px", padding: "8px 12px 8px 12px", borderRadius: "4px" }}>
                                                    {infirmaryName}
                                                </Typography>
                                            </Grid>
                                            {calendarEventToEdit && <>
                                                <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("start_time")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <WrappedDateTimePicker
                                                        id="startTime"
                                                        name="startTime"
                                                        minutesStep={15}
                                                        onChange={this.handleChangeStartTime}
                                                        disablePast
                                                        value={selectedStartTime ? Date.parse(selectedStartTime) : null}
                                                        error={calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null}
                                                        helperText={(calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null) && this.props.t("required_field_message")}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("end_time")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <WrappedDateTimePicker
                                                        id="endTime"
                                                        name="endTime"
                                                        disablePast
                                                        minutesStep={15}
                                                        onChange={this.handleChangeEndTime}
                                                        value={selectedEndTime ? Date.parse(selectedEndTime) : null}
                                                        error={calendaryEntry.endTime === "Invalid date" || calendaryEntry.endTime === null}
                                                        helperText={(calendaryEntry.endTime === "Invalid date" || calendaryEntry.endTime === null) && this.props.t("required_field_message")}
                                                    />
                                                </Grid>
                                            </>}
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid container direction="row" spacing={3}>
                                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <InputLabel className="form-label">
                                                            {this.props.t("patient")} <span style={{ color: "#D32F2F" }}>*</span>
                                                        </InputLabel>
                                                        <Stack direction="row" spacing={3}>
                                                            <FormControl>
                                                                <WrappedAutocomplete
                                                                    placeholder={this.props.t("select_patient")}
                                                                    name="selectPatient"
                                                                    value={selectPatient}
                                                                    getOptionLabel={(option) => option.firstName + " " + option.lastName}
                                                                    isOptionEqualToValue={(option, value) => value && option.id === value.id}
                                                                    options={patientList}
                                                                    onChange={(event, patient) => {
                                                                        this.handleChangePatient(event, patient)
                                                                    }}
                                                                    disabled={calendarEventToEdit ? true : false}
                                                                    error={!selectPatient}
                                                                    helperText={!selectPatient && this.props.t("required_field_message")}
                                                                    renderOption={(props, option) => (
                                                                        <li {...props} key={option.id}>
                                                                            <div>
                                                                                {option.firstName + " " + option.lastName}
                                                                                <div>
                                                                                    <small>{this.props.t("phone")}: {option.phone}</small>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                />
                                                            </FormControl>
                                                            <Button
                                                                sx={{ minWidth: "170px !important", height: "40px", marginTop: "7px !important" }}
                                                                color="primary"
                                                                startIcon={selectPatient !== null ? <img src={EditIcon} alt="edit-icon" /> : <img src={AddIcon} alt="add-icon" />}
                                                                onClick={() => this.setState({ newPatientDialog: true, patientToEdit: selectPatient !== null ? selectPatient.id : null })}
                                                            >
                                                                {selectPatient !== null ? this.props.t("edit_patient") : this.props.t("new_patient")}
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item container>
                                                        <InputLabel className="form-label">
                                                            {this.props.t("comment")} <span style={{ color: "#D32F2F" }}>*</span>
                                                        </InputLabel>
                                                        <TextField
                                                            id="comment"
                                                            name="comment"
                                                            value={calendaryEntry.comment || ''}
                                                            multiline
                                                            rows={5}
                                                            onChange={this.handleChange}
                                                            placeholder={this.props.t("enter", { text: this.props.t("comment").toLowerCase() })}
                                                            error={calendaryEntry.comment.length > 500 || !calendaryEntry.comment.trim() ? true : false}
                                                            helperText={this.getCommentHelperText()} />
                                                    </Grid>
                                                    <Grid item container>
                                                        <InputLabel className="form-label">
                                                            {this.props.t("price_estimate")}
                                                        </InputLabel>
                                                        <NumericFormat
                                                            id="priceEstimate"
                                                            name="priceEstimate"
                                                            customInput={TextField}
                                                            decimalScale={2}
                                                            thousandSeparator={true}
                                                            fixedDecimalScale={true}
                                                            value={calendaryEntry.priceEstimate}
                                                            suffix=' €'
                                                            onValueChange={(values, sourceInfo) => this.handleChangePrice(values, sourceInfo)}
                                                            placeholder={this.props.t("enter", { text: this.props.t("price_estimate").toLowerCase() })}
                                                            allowNegative={false}
                                                        />
                                                    </Grid>
                                                    {calendarEventToEdit && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <InputLabel className="form-label">
                                                            {this.props.t("change_to_another_infirmary")}
                                                        </InputLabel>
                                                        <WrappedAutocomplete
                                                            id="selectedInfirmaryToChange"
                                                            name="selectedInfirmaryToChange"
                                                            value={selectedInfirmaryToChange}
                                                            getOptionLabel={(option) => option ? option.name : ""}
                                                            getOptionDisabled={option => infirmarySelected === option.id}
                                                            options={infirmaryList}
                                                            onChange={(event, infirmary) => {
                                                                this.handleChangeInfirmary(event, infirmary)
                                                            }}
                                                            placeholder={this.props.t("select")}
                                                        />
                                                    </Grid>}
                                                    {calendarEventToEdit && <Grid item container>
                                                        <Button
                                                            sx={{ height: "40px !important", marginLeft: "auto" }}
                                                            title={this.props.t("delete")}
                                                            className="default-button-delete"
                                                            endIcon={<img src={DeleteIcon} alt="delete-icon" />}
                                                            onClick={() => this.deleteCalendarEntry()}>
                                                            {this.props.t("delete")}
                                                        </Button>
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
                <ManagePatientDialog
                    isOpen={this.state.newPatientDialog}
                    onClose={this.closeDialogPatient}
                    patientId={this.state.patientToEdit}
                    createFlag={true}
                    onSave={(patientId) => this.setState({ newPatientDialog: false, patientToEdit: null }, () => this.getPatientList(patientId))}
                />
                <ConfirmationDialog
                    isOpen={promptDialogOpen}
                    title={this.props.t("prompt_title")}
                    message={this.props.t("prompt_message_2")}
                    onClose={() => this.setState({ promptDialogOpen: false })}
                    onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.closeDialog())}
                    closeButtonTitle={this.props.t("continue")}
                    confirmButtonTitle={this.props.t("discard")}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer,
    infirmaryReducer: state.infirmaryReducer,
})

const mapActionsToProps = { getPatientList, getGenders, getSources, getCounties, createCalendarEvent, updateCalendarEvent, getCalendarEntryById, updateCalendarEventStatus, deleteCalendarEntry, changePatientInfirmary }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageEvent)))
