import React from 'react';
import { connect } from 'react-redux'
import { withRouter, Prompt } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    InputLabel,
    Box,
    Stack,
    Typography,
    CircularProgress,
    FormControl
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import {
    createItem, updateItem, getItemById, getListOfCategories, getListOfMeasurements,
    getManufacturersByWarehouseId
} from '../../../redux/warehouse/actions';
import { NAME_REGEX } from '../../../components/common/regex';
import WrappedAutocomplete from '../../../components/common/WrappedAutocomplete';
import { NumericFormat } from 'react-number-format';

class ManageItem extends React.Component {

    emptyItem = {
        warehouseId: Number(this.props.match.params.warehouseId),
        categoryId: null,
        unitOfMeasureId: null,
        name: '',
        description: '',
        reorderPoint: null,
        code: '',
        barcode: '',
        productNo: '',
        manufacturer: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            mainLoading: false,
            initialItem: JSON.parse(JSON.stringify(this.emptyItem)),
            item: JSON.parse(JSON.stringify(this.emptyItem)),
            selectedUnit: null,
            selectedCategory: null,
            manufacturers: [],
            validation: {
                nameError: false,
                nameErrorText: "",
                codeError: false,
                codeErrorText: "",
                descriptionError: false,
                descriptionErrorText: "",
                categoryError: false,
                categoryErrorText: "",
                unitOfMeasurementError: false,
                unitOfMeasurementErrorText: "",
                barcodeError: false,
                barcodeErrorText: "",
                productNoError: false,
                productNoErrorText: "",
                manufacturerError: false,
                manufacturerErrorText: "",
                reorderPointError: false,
                reorderPointErrorText: "",
            }
        };
    }

    componentDidMount() {
        this.props.getListOfCategories(this.props.match.params.warehouseId)
        this.props.getListOfMeasurements(this.props.match.params.warehouseId)
        this.props.getManufacturersByWarehouseId(this.props.match.params.warehouseId).then(response => {
            this.setState({ manufacturers: response.data });
        })
        if (this.props.match.params.id) {
            this.props.getItemById(this.props.match.params.id).then(response => {
                this.setState({
                    item: JSON.parse(JSON.stringify(response.data)),
                    initialItem: JSON.parse(JSON.stringify(response.data)),
                    selectedCategory: this.props.warehouseReducer.categoriesList.find(c => c.id === response.data.categoryId),
                    selectedUnit: this.props.warehouseReducer.measurementList.find(m => m.id === response.data.unitOfMeasureId)
                }, () => {
                    this.validate("category", this.state.item.categoryId);
                    this.validate("unitOfMeasurement", this.state.item.unitOfMeasureId);
                    this.validate("name", this.state.item.name);
                    this.validate("reorderPoint", this.state.item.reorderPoint);
                    this.setState({ mainLoading: false });
                });
            })
        } else {
            this.validate("category", this.state.item.categoryId);
            this.validate("unitOfMeasurement", this.state.item.unitOfMeasureId);
            this.validate("name", this.state.item.name);
            this.validate("reorderPoint", this.state.item.reorderPoint);
            this.setState({ mainLoading: false });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        let { item } = this.state;
        item[target.name] = target.value.trimStart()
        this.setState({ item: item })
        this.validate(target.name, target.value);
    };

    handleChangeManufacturer = (name, value) => {
        let { item } = this.state;
        item[name] = value || "";
        this.setState({ item: item });
    }

    validate = (field, value) => {
        let { validation } = this.state;
        switch (field) {
            case "name":
                if (value.length === 0) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("required_field_message");
                } else if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.nameError = true;
                    validation.nameErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.nameError = false;
                    validation.nameErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "description":
                if (value.length > 0 && !NAME_REGEX.test(value)) {
                    validation.descriptionError = true;
                    validation.descriptionErrorText = this.props.t("name_regex_message", { min: 1, max: 255 });
                } else {
                    validation.descriptionError = false;
                    validation.descriptionErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "category":
                if (value === null) {
                    validation.categoryError = true;
                    validation.categoryErrorText = this.props.t("required_field_message");
                } else {
                    validation.categoryError = false;
                    validation.categoryErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "unitOfMeasurement":
                if (value === null) {
                    validation.unitOfMeasurementError = true;
                    validation.unitOfMeasurementErrorText = this.props.t("required_field_message");
                } else {
                    validation.unitOfMeasurementError = false;
                    validation.unitOfMeasurementErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            case "reorderPoint":
                if (value === undefined || value === null) {
                    validation.reorderPointError = true;
                    validation.reorderPointErrorText = this.props.t("required_field_message");
                } else {
                    validation.reorderPointError = false;
                    validation.reorderPointErrorText = "";
                }
                this.setState({ validation: validation });
                break;
            default:
                return;
        }
    }

    manageItem = () => {
        const { item } = this.state;
        const action = this.props.match.params.id ?
            this.props.updateItem(this.props.match.params.id, item) :
            this.props.createItem(item);
        action.then(response => {
            if (response.status === 200 || response.status === 201) {
                this.setState({
                    initialItem: JSON.parse(JSON.stringify(this.emptyItem)),
                    item: JSON.parse(JSON.stringify(this.emptyItem))
                }, () => this.props.history.push(this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/items`)));
            }
        })
    };

    handleChangeCategory = (e, category) => {
        let { item } = this.state;
        if (category !== null) {
            item.categoryId = category.id
            this.setState({ selectedCategory: category, item: item })
        } else {
            item.categoryId = null
            this.setState({ selectedCategory: null, item: item })
        }
        this.validate("category", category);
    }

    handleChangeUnit = (e, unit) => {
        let { item } = this.state;
        if (unit !== null) {
            item.unitOfMeasureId = unit.id
            this.setState({ selectedUnit: unit, item: item })
        } else {
            item.unitOfMeasureId = null
            this.setState({ selectedUnit: null, item: item })
        }
        this.validate("unitOfMeasurement", unit);
    }

    handleChangeNumber = (values) => {
        const { floatValue } = values;
        let { item } = this.state
        item.reorderPoint = floatValue;
        this.setState({ item: item })
        this.validate("reorderPoint", floatValue);
    }

    disable() {
        let { validation, item, initialItem } = this.state;
        if (!item.name || !item.categoryId || !item.unitOfMeasureId || item.reorderPoint === null ||
            JSON.stringify(item) === JSON.stringify(initialItem) ||
            Object.values(validation).some((error) => error === true)) {
            return true;
        }
        return false;
    }

    render() {
        let { mainLoading, initialItem, item, validation, selectedCategory, selectedUnit, manufacturers } = this.state
        const { categoriesList, measurementList } = this.props.warehouseReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (
            <Grid container direction="row">
                <Prompt when={JSON.stringify(initialItem) !== JSON.stringify(item)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className="custom-box">
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                        {this.props.match.params.id ? this.props.t("edit_item") : this.props.t("new_item")}
                                    </Typography>
                                    <Button className="cancel-btn" onClick={() => this.props.history.push(`/warehouses/${this.props.match.params.warehouseId}/items`)}>
                                        {this.props.t("cancel")}
                                    </Button>
                                    <Button className="create-btn" onClick={this.manageItem} disabled={this.disable()}>
                                        {this.props.match.params.id ? this.props.t("update") : this.props.t("create")}
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <Grid container direction="row" spacing={2}>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("name")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <TextField
                                                id="name"
                                                name="name"
                                                value={item.name || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("name").toLowerCase() })}
                                                error={validation.nameError}
                                                helperText={validation.nameErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("code")}
                                            </InputLabel>
                                            <TextField
                                                id="code"
                                                name="code"
                                                value={item.code || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("code").toLowerCase() })}
                                                error={validation.codeError}
                                                helperText={validation.codeErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("select_category")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <FormControl>
                                                <WrappedAutocomplete
                                                    id="selectedCategory"
                                                    name="selectedCategory"
                                                    value={selectedCategory || null}
                                                    placeholder={this.props.t("select")}
                                                    getOptionLabel={(option) => option ? option.name : ""}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={categoriesList}
                                                    onChange={(event, category) => {
                                                        this.handleChangeCategory(event, category)
                                                    }}
                                                    error={validation.categoryError}
                                                    helperText={validation.categoryErrorText}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("select_unit")} <span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <FormControl>
                                                <WrappedAutocomplete
                                                    id="selectedUnit"
                                                    name="selectedUnit"
                                                    value={selectedUnit || null}
                                                    placeholder={this.props.t("select")}
                                                    getOptionLabel={(option) => option ? option.unitOfMeasurement : ""}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    options={measurementList}
                                                    onChange={(event, unit) => {
                                                        this.handleChangeUnit(event, unit)
                                                    }}
                                                    error={validation.unitOfMeasurementError}
                                                    helperText={validation.unitOfMeasurementErrorText}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("description")}
                                            </InputLabel>
                                            <TextField
                                                id="description"
                                                name="description"
                                                value={item.description || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("description").toLowerCase() })}
                                                multiline
                                                rows={3}
                                                error={validation.descriptionError}
                                                helperText={validation.descriptionErrorText}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item container direction="column" xs={6} sm={6} md={6} lg={6} xl={6}>
                                <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("barcode")}
                                            </InputLabel>
                                            <TextField
                                                id="barcode"
                                                name="barcode"
                                                value={item.barcode || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("barcode").toLowerCase() })}
                                                error={validation.barcodeError}
                                                helperText={validation.barcodeErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("product_no")}
                                            </InputLabel>
                                            <TextField
                                                id="productNo"
                                                name="productNo"
                                                value={item.productNo || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("product_no").toLowerCase() })}
                                                error={validation.productNoError}
                                                helperText={validation.productNoErrorText}
                                            />
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("manufacturer")}
                                            </InputLabel>

                                            <WrappedAutocomplete
                                                freeSolo
                                                id="manufacturer"
                                                name="manufacturer"
                                                value={item.manufacturer || ""}
                                                getOptionLabel={(option) => `${option}`}
                                                options={manufacturers ? manufacturers.filter(s => s !== null) : []}
                                                onChange={(name, value) => this.handleChangeManufacturer(name, value)}
                                                placeholder={this.props.t("enter_or_select")}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props} key={option}>
                                                        {option}
                                                    </Box>
                                                )}
                                            />

                                            {/* 
                                            <TextField
                                                id="manufacturer"
                                                name="manufacturer"
                                                value={item.manufacturer || ''}
                                                onChange={this.handleChange}
                                                placeholder={this.props.t("enter", { text: this.props.t("manufacturer").toLowerCase() })}
                                                error={validation.manufacturerError}
                                                helperText={validation.manufacturerErrorText}
                                            /> */}
                                        </Grid>
                                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <InputLabel className="form-label">
                                                {this.props.t("reorder_point")}<span style={{ color: "#D32F2F" }}>*</span>
                                            </InputLabel>
                                            <NumericFormat
                                                customInput={TextField}
                                                decimalScale={0}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                value={item.reorderPoint}
                                                onValueChange={(values) => this.handleChangeNumber(values)}
                                                allowNegative={false}
                                                error={validation.reorderPointError}
                                                helperText={validation.reorderPointErrorText}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    warehouseReducer: state.warehouseReducer
})

const mapActionsToProps = { createItem, updateItem, getItemById, getListOfCategories, getListOfMeasurements, getManufacturersByWarehouseId }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageItem)))