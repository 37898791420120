import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    DialogContent,
    Grid,
    FormControl,
    Box,
    Stack,
    Typography,
    InputLabel
} from '@mui/material';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { createSchedule, updateSchedule, getScheduleEventById, deleteScheduleEvent } from '../../redux/schedule/actions'
import WrappedDateTimePicker from '../../components/common/WrappedDateTimePicker';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { BootstrapDialog, Transition } from '../../components/common/Dialog';
import DeleteIcon from '../../assets/icons/delete_icon.svg';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

class ManageScheduleEvent extends Component {

    emptyCalendarEntry = {
        startTime: null,
        endTime: null,
        userId: null,
        infirmaryId: null
    }

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            startTime: null,
            endTime: null,
            calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            initialCalendarEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)),
            selectedStartTime: null,
            selectedEndTime: null,
            selectedDoctor: null,
            selectedInfirmary: null,
            promptDialogOpen: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.calendarEventToEdit && prevProps.calendarEventToEdit !== this.props.calendarEventToEdit) {
            this.props.getScheduleEventById(this.props.calendarEventToEdit.id.toString()).then(response => {
                this.setState({
                    calendaryEntry: JSON.parse(JSON.stringify(response.data)),
                    initialCalendarEntry: JSON.parse(JSON.stringify(response.data)),
                    selectedDoctor: this.props.userReducer.doctors.find(d => d.id === response.data.user.id),
                    selectedInfirmary: this.props.infirmaryReducer.infirmaryList.find(i => i.id === response.data.infirmary.id),
                    selectedStartTime: moment(response.data.startTime),
                    selectedEndTime: moment(response.data.endTime)
                })
            })
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({ selectedDoctor: null, selectedInfirmary: null, selectedStartTime: null, selectedEndTime: null, calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)) })
    }

    handleChangeDoctor = (e, doctor) => {
        let { calendaryEntry } = this.state
        if (doctor !== null) {
            calendaryEntry.userId = doctor.id
            this.setState({ selectedDoctor: doctor, calendaryEntry: calendaryEntry })
        } else {
            calendaryEntry.userId = null
            this.setState({ selectedDoctor: null, calendaryEntry: calendaryEntry })
        }
    }

    handleChangeInfirmary = (e, infirmary) => {
        let { calendaryEntry } = this.state
        if (infirmary !== null) {
            calendaryEntry.infirmaryId = infirmary.id
            this.setState({ selectedInfirmary: infirmary, calendaryEntry: calendaryEntry })
        } else {
            calendaryEntry.infirmaryId = null
            this.setState({ selectedInfirmary: null, calendaryEntry: calendaryEntry })
        }
    }

    handleChangeStartTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedStartTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.startTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: new Date() })
            } else {
                calendaryEntry.startTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedStartTime: date });
            }
        }
    }

    handleChangeEndTime = (date) => {
        let { calendaryEntry } = this.state
        if (date !== null && date._isValid) {
            if (date === null && this.state.selectedEndTime !== null) {
                return
            }
            if (moment(date).isBefore(new Date())) {
                calendaryEntry.endTime = new Date()
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: new Date() })
            } else {
                calendaryEntry.endTime = moment(date)
                this.setState({ calendaryEntry: calendaryEntry, selectedEndTime: date });
            }
        }
    }

    manageEvent = () => {
        let { calendaryEntry, selectedDoctor, selectedInfirmary } = this.state
        let { selectedSlot, calendarEventToEdit } = this.props
        let body = {
            userId: selectedDoctor.id,
            infirmaryId: selectedInfirmary.id,
            startTime: calendarEventToEdit ? moment(calendaryEntry.startTime) : selectedSlot.start,
            endTime: calendarEventToEdit ? moment(calendaryEntry.endTime) : selectedSlot.end
        }
        const action = this.props.calendarEventToEdit ?
            this.props.updateSchedule(this.props.calendarEventToEdit.id, body) :
            this.props.createSchedule(body);
        action.then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.props.onSave()
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectedDoctor: null, selectedInfirmary: null });
            }
        })
    }

    deleteScheduleEvent = () => {
        this.props.deleteScheduleEvent(this.props.calendarEventToEdit.id).then((response) => {
            if (response.status === 200) {
                this.setState({ calendaryEntry: JSON.parse(JSON.stringify(this.emptyCalendarEntry)), selectedDoctor: null, selectedInfirmary: null });
                this.props.onSave();
            }
        })
    }

    validation = () => {
        let { initialCalendarEntry, calendaryEntry, selectedDoctor, selectedInfirmary } = this.state;
        const { calendarEventToEdit } = this.props
        let valid = true;
        if (JSON.stringify(calendaryEntry) === JSON.stringify(initialCalendarEntry)) {
            valid = false
        }
        if (selectedDoctor === null) {
            valid = false
        }
        if (selectedInfirmary === null) {
            valid = false
        }
        if (calendarEventToEdit) {
            if (!calendaryEntry.startTime || !calendaryEntry.endTime || calendaryEntry.startTime === "Invalid date" || calendaryEntry.endTime === "Invalid date") {
                valid = false
            }
        }
        return valid;
    }

    render() {
        const { selectedDoctor, selectedInfirmary, initialCalendarEntry, calendaryEntry, selectedStartTime, selectedEndTime, promptDialogOpen } = this.state
        const { calendarEventToEdit } = this.props
        const { doctors } = this.props.userReducer
        const { infirmaryList } = this.props.infirmaryReducer

        return (
            <>
                <BootstrapDialog
                    onClose={() => JSON.stringify(initialCalendarEntry) !== JSON.stringify(calendaryEntry) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}
                    open={this.props.isOpen}
                    fullWidth
                    maxWidth={"md"}
                    TransitionComponent={Transition}>
                    <DialogContent sx={{ padding: "0px !important" }}>
                        <Box className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                            {calendarEventToEdit ? this.props.t("edit_event") : this.props.t("new_event")}
                                        </Typography>
                                        <Button className="cancel-btn" onClick={() => JSON.stringify(initialCalendarEntry) !== JSON.stringify(calendaryEntry) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}>
                                            {this.props.t("cancel")}
                                        </Button>
                                        <Button className="create-btn" onClick={this.manageEvent} disabled={!this.validation()}>
                                            {calendarEventToEdit ? this.props.t("update") : this.props.t("create")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                        <Grid container direction="row" spacing={3}>
                                            {calendarEventToEdit && <>
                                                <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("start_time")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <WrappedDateTimePicker
                                                        id="startTime"
                                                        name="startTime"
                                                        minutesStep={15}
                                                        onChange={this.handleChangeStartTime}
                                                        disablePast
                                                        value={selectedStartTime ? Date.parse(selectedStartTime) : null}
                                                        error={calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null}
                                                        helperText={(calendaryEntry.startTime === "Invalid date" || calendaryEntry.startTime === null) && this.props.t("required_field_message")}
                                                    />
                                                </Grid>
                                                <Grid item container direction="column" xs={12} sm={6} md={6} lg={6} xl={6}>
                                                    <InputLabel className="form-label">
                                                        {this.props.t("end_time")} <span style={{ color: "#D32F2F" }}>*</span>
                                                    </InputLabel>
                                                    <WrappedDateTimePicker
                                                        id="endTime"
                                                        name="endTime"
                                                        disablePast
                                                        minutesStep={15}
                                                        onChange={this.handleChangeEndTime}
                                                        value={selectedEndTime ? Date.parse(selectedEndTime) : null}
                                                        error={calendaryEntry.endTime === "Invalid date" || calendaryEntry.endTime === null}
                                                        helperText={(calendaryEntry.endTime === "Invalid date" || calendaryEntry.endTime === null) && this.props.t("required_field_message")}
                                                    />
                                                </Grid>
                                            </>}
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("doctor")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <FormControl>
                                                    <WrappedAutocomplete
                                                        id="selectedDoctor"
                                                        name="selectedDoctor"
                                                        value={selectedDoctor}
                                                        getOptionLabel={(option) => option.firstName + " " + option.lastName + " (" + option.phone + ")"}
                                                        options={doctors}
                                                        onChange={(event, doctor) => {
                                                            this.handleChangeDoctor(event, doctor)
                                                        }}
                                                        placeholder={this.props.t("select_doctor")}
                                                        error={!selectedDoctor}
                                                        helperText={!selectedDoctor && this.props.t("required_field_message")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel className="form-label">
                                                    {this.props.t("infirmary")} <span style={{ color: "#D32F2F" }}>*</span>
                                                </InputLabel>
                                                <FormControl>
                                                    <WrappedAutocomplete
                                                        id="selectedInfirmary"
                                                        name="selectedInfirmary"
                                                        value={selectedInfirmary}
                                                        getOptionLabel={(option) => option.name}
                                                        options={infirmaryList}
                                                        onChange={(event, infirmary) => {
                                                            this.handleChangeInfirmary(event, infirmary)
                                                        }}
                                                        placeholder={this.props.t("select_infirmary")}
                                                        error={!selectedInfirmary}
                                                        helperText={!selectedInfirmary && this.props.t("required_field_message")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            {calendarEventToEdit && <Grid item container>
                                                <Button
                                                    sx={{ height: "40px !important", marginLeft: "auto" }}
                                                    title={this.props.t("delete")}
                                                    className="default-button-delete"
                                                    endIcon={<img src={DeleteIcon} alt="delete-icon" />}
                                                    onClick={() => this.deleteScheduleEvent()}>
                                                    {this.props.t("delete")}
                                                </Button>
                                            </Grid>}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
                <ConfirmationDialog
                    isOpen={promptDialogOpen}
                    title={this.props.t("prompt_title")}
                    message={this.props.t("prompt_message_2")}
                    onClose={() => this.setState({ promptDialogOpen: false })}
                    onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.closeDialog())}
                    closeButtonTitle={this.props.t("continue")}
                    confirmButtonTitle={this.props.t("discard")}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer,
    infirmaryReducer: state.infirmaryReducer
})

const mapActionsToProps = { createSchedule, updateSchedule, getScheduleEventById, deleteScheduleEvent }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(ManageScheduleEvent)))
