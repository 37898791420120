import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import Languages from './languages/Languages';
import SmsProvider from './sms/SmsProvider';
import Credentials from './credentials/Credentials';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';
import { ReactComponent as SmsActiveIcon } from '../../assets/icons/settings/sms_active.svg';
import { ReactComponent as SmsInactiveIcon } from '../../assets/icons/settings/sms_inactive.svg';
import { ReactComponent as GoogleActiveIcon } from '../../assets/icons/settings/questionnaire_active.svg';
import { ReactComponent as GoogleInactiveIcon } from '../../assets/icons/settings/questionnaire_inactive.svg';
import { ReactComponent as LanguagesActiveIcon } from '../../assets/icons/settings/languages_active.svg';
import { ReactComponent as LanguagesInactiveIcon } from '../../assets/icons/settings/languages_inactive.svg';
import { ReactComponent as CredentialsActiveIcon } from '../../assets/icons/settings/credentials_active.svg';
import { ReactComponent as CredentialsInactiveIcon } from '../../assets/icons/settings/credentials_inactive.svg';
import GoogleQuestionnaire from './google-questionnaire/GoogleQuestionnaire';

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    render() {
        const { selectedTab } = this.state

        return (
            <Grid container direction="row" spacing={1}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className='custom-box'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                                    {this.props.t("settings")}
                                </Typography>
                                <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ borderBottom: 1, border: 'none' }}>
                                    <Tabs value={selectedTab} onChange={this.handleChangeTab}
                                        TabIndicatorProps={{
                                            sx: { display: 'none' }
                                        }}>
                                        <Tab icon={selectedTab === 0 ? <SmsInactiveIcon /> : <SmsActiveIcon />} iconPosition="start" label={this.props.t("sms")} {...a11yProps(0)} wrapped />
                                        <Tab icon={selectedTab === 1 ? <GoogleInactiveIcon /> : <GoogleActiveIcon />} iconPosition="start" label={this.props.t("google_questionnaire")} {...a11yProps(1)} wrapped />
                                        <Tab icon={selectedTab === 2 ? <LanguagesInactiveIcon /> : <LanguagesActiveIcon />} iconPosition="start" label={this.props.t("languages")} {...a11yProps(2)} wrapped />
                                        <Tab icon={selectedTab === 3 ? <CredentialsInactiveIcon /> : <CredentialsActiveIcon />} iconPosition="start" label={this.props.t("credentials")} {...a11yProps(3)} wrapped />
                                    </Tabs>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CustomTabPanel value={selectedTab} index={0} sx={{ width: "100%" }}>
                        <SmsProvider />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={1} sx={{ width: "100%" }}>
                        <GoogleQuestionnaire />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={2} sx={{ width: "100%" }}>
                        <Languages />
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedTab} index={3} sx={{ width: "100%" }}>
                        <Credentials />
                    </CustomTabPanel>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(withTranslation()(Settings))
