import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    Card,
    CardContent,
    CardHeader,
    Button,
    Box,
    Typography,
    CardActions,
    Collapse,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    Tabs,
    Tab,
    Zoom,
    Tooltip,
    Select,
    MenuItem,
    IconButton,
    FormControl,
    Divider
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { UserRole, hasAnyRole, hasRole } from '../../utils/Security';
import { getTodayEntries, getCalendarEntryStatuses, updateCalendarEventStatus } from '../../redux/calendar/actions';
import { getTodayExaminations, exportExaminationByIdToPdf } from '../../redux/examination/actions';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { FaRegUser } from 'react-icons/fa';
import { BsJournalMedical } from 'react-icons/bs';
import moment from 'moment'
import Base58Table from '../../components/common/Base58Table';
import { NumericFormat } from 'react-number-format';
import CreateOrUpdateInvoice from '../invoice/CreateOrUpdateInvoice';
import { getInvoiceTypes, exportInvoiceToPdf } from '../../redux/invoice/actions'
import { getServiceList } from '../../redux/service/actions'
import { getDoctors } from '../../redux/user/actions'
import { getServiceGroupsList } from '../../redux/serviceGroup/actions'
import ExaminationServicesModal from '../examination/ExaminationServicesModal';
import DashboardStatistics from './DashboardStatistics';
import { CustomTabPanel, a11yProps } from '../../components/common/tabs';
import { ReactComponent as TodayScheduleActive } from '../../assets/icons/today_schedule_active.svg';
import { ReactComponent as TodayScheduleInactive } from '../../assets/icons/today_schedule_inactive.svg';
import { ReactComponent as CompletedExaminationActive } from '../../assets/icons/complete_examination_active.svg';
import { ReactComponent as CompletedExaminationInactive } from '../../assets/icons/complete_examination_inactive.svg';
import { ReactComponent as UnprocessedActive } from '../../assets/icons/unprocessed_active.svg';
import { ReactComponent as UnprocessedInactive } from '../../assets/icons/unprocessed_inactive.svg';
import { ReactComponent as ProcessedActive } from '../../assets/icons/processed_active.svg';
import { ReactComponent as ProcessedInactive } from '../../assets/icons/processed_inactive.svg';

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseIndex: -1,
            invoiceDialog: false,
            examinationId: null,
            eId: null,
            selectedTab: 0,
            selectedTabReceptionist: 0,
            examinationServiceModalOpen: false,
            statusFilter: 'ALL'
        };
    }

    componentDidMount() {
        hasRole(UserRole.DOCTOR) && this.props.getTodayEntries()
        if (hasRole(UserRole.RECEPTIONIST)) {
            this.props.getCalendarEntryStatuses()
            this.props.getTodayEntries()
            this.props.getTodayExaminations()
            this.props.getInvoiceTypes()
            this.props.getServiceList()
        }
        if (hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM])) {
            this.props.getDoctors()
            this.props.getInvoiceTypes()
            this.props.getServiceList()
            this.props.getServiceGroupsList()
        }
    }

    getTotal = (services) => {
        let total = 0;
        services.forEach(e => {
            total += Number(e.price * e.quantity);
        });
        return total;
    }

    getTotalDiscount = (services) => {
        let total = 0;
        services.forEach(e => {
            total += Number(e.discountAmount);
        });
        return total;
    }

    closeDialog = () => {
        this.setState({ invoiceDialog: false });
    }

    closeDialogExaminationServiceModal = () => {
        this.setState({ examinationServiceModalOpen: false, eId: null });
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
    }

    handleChangeTabReceptionist = (event, value) => {
        this.setState({ selectedTabReceptionist: value });
    }

    status(status) {
        switch (status) {
            case 'PATIENT_NOT_PRESENT':
                return 'red'
            case 'PATIENT_ARRIVED':
                return 'green'
            default:
                return 'grey';
        }
    }

    handleChangeStatus = (e, id) => {
        let params = {}
        params.status = e.target.value
        this.props.updateCalendarEventStatus(id, { params: params }).then(() => {
            this.props.getTodayEntries()
        })
    }

    handleChangeStatusFilter = (e) => {
        this.setState({ statusFilter: e.target.value })
    }

    displayData = (event) => {
        return <TableRow>
            <TableCell>
                {event.patient.firstName && event.patient.firstName.length > 15 ? <Tooltip
                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={event.patient.firstName} style={{ cursor: 'pointer' }}>
                    <span>{event.patient.firstName && event.patient.firstName.length > 15 ? event.patient.firstName.substring(0, 15) + "..." : event.patient.firstName}</span>
                </Tooltip> :
                    event.patient.firstName}</TableCell>
            <TableCell>{event.patient.lastName && event.patient.lastName.length > 15 ? <Tooltip
                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={event.patient.lastName} style={{ cursor: 'pointer' }}>
                <span>{event.patient.lastName && event.patient.lastName.length > 15 ? event.patient.lastName.substring(0, 15) + "..." : event.patient.lastName}</span>
            </Tooltip> :
                event.patient.lastName}</TableCell>
            <TableCell>{event.patient.personalIdentificationNumber}</TableCell>
            <TableCell>{moment(event.startTime).format("HH:mm") + " - " + moment(event.endTime).format("HH:mm")}</TableCell>
            <TableCell>{event.infirmary.name && event.infirmary.name.length > 20 ? <Tooltip
                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={event.infirmary.name} style={{ cursor: 'pointer' }}>
                <span>{event.infirmary.name && event.infirmary.name.length > 20 ? event.infirmary.name.substring(0, 20) + "..." : event.infirmary.name}</span>
            </Tooltip> :
                event.infirmary.name} {event.infirmary.currentOccupant && <span style={{ color: 'salmon' }}>{"(" + event.infirmary.currentOccupant.firstName + " " + event.infirmary.currentOccupant.lastName + ")"}</span>}</TableCell>
            <TableCell>
                <Select
                    style={{ width: '240px', color: this.status(event.calendarEntryStatus) }}
                    name="calendarEntryStatus"
                    value={event.calendarEntryStatus}
                    onChange={(e) => this.handleChangeStatus(e, event.id)}>
                    {this.props.calendarReducer.calendarEntryStatuses && this.props.calendarReducer.calendarEntryStatuses.map(status => {
                        return <MenuItem key={status} value={status}>{this.props.t(status)}</MenuItem>
                    })}
                </Select>
            </TableCell>
        </TableRow>
    }

    render() {
        const { collapseIndex, selectedTab, selectedTabReceptionist, statusFilter } = this.state;
        const { todayEvents, calendarEntryStatuses } = this.props.calendarReducer
        const { examinationsToday } = this.props.examinationReducer

        let allStatuses = []
        allStatuses.push("ALL")
        calendarEntryStatuses.map(d => {
            return allStatuses.push(d)
        })

        let examinationList = examinationsToday && examinationsToday.map((examination, index) => {
            return <React.Fragment key={index}>
                <TableRow>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ collapseIndex: collapseIndex !== index ? index : -1 })}>
                            {index === collapseIndex ? <ArrowUpward /> : <ArrowDownward />}
                        </IconButton>
                    </TableCell>
                    <TableCell style={{ fontWeight: index === collapseIndex ? 'bold' : 'unset' }} className="table-cell">{examination.patient.firstName && examination.patient.firstName.length > 35 ? <Tooltip
                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={examination.patient.firstName} style={{ cursor: 'pointer' }}>
                        <span>{examination.patient.firstName && examination.patient.firstName.length > 35 ? examination.patient.firstName.substring(0, 35) + "..." : examination.patient.firstName}</span>
                    </Tooltip> :
                        examination.patient.firstName}</TableCell>
                    <TableCell style={{ fontWeight: index === collapseIndex ? 'bold' : 'unset' }} className="table-cell">{examination.patient.lastName && examination.patient.lastName.length > 35 ? <Tooltip
                        disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={examination.patient.lastName} style={{ cursor: 'pointer' }}>
                        <span>{examination.patient.lastName && examination.patient.lastName.length > 35 ? examination.patient.lastName.substring(0, 35) + "..." : examination.patient.lastName}</span>
                    </Tooltip> :
                        examination.patient.lastName}</TableCell>
                    <TableCell style={{ fontWeight: index === collapseIndex ? 'bold' : 'unset' }}>{examination.patient.phone}</TableCell>
                    <TableCell style={{ fontWeight: index === collapseIndex ? 'bold' : 'unset' }}>{moment(examination.createdDate).format("DD.MM.YYYY. HH:mm")}</TableCell>
                    <TableCell style={{ fontWeight: index === collapseIndex ? 'bold' : 'unset' }}>{examination.createdBy}</TableCell>
                    <TableCell>
                        <Button
                            id={"examination-inovice-" + index}
                            className="default-button"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.setState({ invoiceDialog: true, examinationId: examination.id })}>
                            {this.props.t("create_offer")}
                        </Button>
                        <Button
                            id={"examination-inovice-" + index}
                            className="default-button"
                            style={{ marginRight: '5px' }}
                            onClick={() => this.setState({ examinationServiceModalOpen: true, eId: examination.id })}>
                            {this.props.t("edit_examination")}
                        </Button>
                        <Button
                            id={"examination-inovice-" + index}
                            className="default-button"
                            onClick={() => this.props.exportExaminationByIdToPdf(examination.id)}>
                            {this.props.t("export_examination")}
                        </Button>
                    </TableCell>
                </TableRow>
                <TableRow key={index}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={1} />
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={5}>
                        <Collapse in={index === collapseIndex} timeout="auto" unmountOnExit style={{ paddingTop: 10, paddingBottom: 20 }}>
                            <Box margin={1}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" width="5%">{this.props.t("code")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" width="40%">{this.props.t("name_column")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" width="5%">{this.props.t("quantity")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" align="right" width="20%">{this.props.t("price")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" align="right" width="10%">{this.props.t("discount_amount")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} component="th" scope="row" align="right" width="15%">{this.props.t("amount")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {examination.examinationServices.map((item, index) => {
                                                return <TableRow key={index}>
                                                    <TableCell className="table-cell">{item.service && item.service.code}</TableCell>
                                                    <TableCell className="table-cell">{item.service && item.service.name}</TableCell>
                                                    <TableCell className="table-cell" align="center">{item.quantity}</TableCell>
                                                    <TableCell className="table-cell" align="right"><NumericFormat value={item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                    <TableCell className="table-cell" align="right"><NumericFormat value={item.discountAmount} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                    <TableCell className="table-cell" align="right"><NumericFormat value={item.quantity * item.price} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                                </TableRow>
                                            })}
                                            <TableRow style={{ borderTop: '2px solid black' }}>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("Total")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} className="table-cell" align="right"><NumericFormat value={this.getTotal(examination.examinationServices)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("discount")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} className="table-cell" align="right"><NumericFormat value={this.getTotalDiscount(examination.examinationServices)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell colSpan={4} />
                                                <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("to_pay")}</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }} className="table-cell" align="right">
                                                    <NumericFormat value={this.getTotal(examination.examinationServices) - this.getTotalDiscount(examination.examinationServices)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Collapse>
                    </TableCell>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#ebebeb' }} colSpan={1} />
                </TableRow>
            </React.Fragment>
        })

        let columns = [
            {
                name: '',
                width: '3%'
            },
            {
                name: "first_name",
                width: '20%'
            },
            {
                name: "last_name",
                width: '20%'
            },
            {
                name: "phone",
                width: '12%',
            },
            {
                name: "created_date",
                width: '11%'
            },
            {
                name: "created_by",
                width: '10%',
            },
            {
                name: "",
                width: '25%'
            }
        ]

        let todayPatientsList = todayEvents && todayEvents.map((event, index) => {
            if (event.calendarEntryStatus === statusFilter) {
                return <React.Fragment key={index}>
                    {this.displayData(event)}
                </React.Fragment>
            }
            if (statusFilter === "ALL") {
                return <React.Fragment key={index}>
                    {this.displayData(event)}
                </React.Fragment>
            }
            return null
        })

        let columnsTodayPatients = [
            {
                name: "first_name",
                width: '15%'
            },
            {
                name: "last_name",
                width: '15%'
            },
            {
                name: "oib_short",
                width: '10%'
            },
            {
                name: "appointment",
                width: '12%'
            },
            {
                name: "infirmary",
                width: '28%',
            },
            {
                name: "status",
                width: '20%'
            }
        ]

        return (
            <>
                {hasRole(UserRole.RECEPTIONIST) &&
                    <Box className='custom-box'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h5" sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", alignItems: 'center', display: 'flex' }} color="primary">
                                    {this.props.t("dashboard")}
                                </Typography>
                                <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid item container>
                                    <Tabs value={selectedTabReceptionist} variant="fullWidth" textColor="primary" indicatorColor="primary" onChange={this.handleChangeTabReceptionist}>
                                        <Tab icon={selectedTabReceptionist === 0 ? <TodayScheduleActive /> : <TodayScheduleInactive />} iconPosition="start" style={{ width: '300px' }} label={this.props.t("today_schedule")} {...a11yProps(0)} wrapped />
                                        <Tab icon={selectedTabReceptionist === 1 ? <CompletedExaminationActive /> : <CompletedExaminationInactive />} iconPosition="start" style={{ width: '300px' }} label={this.props.t("completed_examinations")} {...a11yProps(1)} wrapped />
                                    </Tabs>
                                </Grid>
                                <CustomTabPanel value={selectedTabReceptionist} index={0}>
                                    <FormControl>
                                        <Select
                                            style={{ width: '340px', color: this.status(statusFilter) }}
                                            name="filterStatuses"
                                            value={statusFilter}
                                            onChange={(e) => this.handleChangeStatusFilter(e)}>
                                            {allStatuses && allStatuses.map(status => {
                                                return <MenuItem style={{ color: this.status(status) }} key={status} value={status}>{this.props.t(status)}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <Grid item container>
                                        <Grid container direction="row" spacing={2} >
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Base58Table
                                                    columns={columnsTodayPatients}
                                                    data={todayPatientsList}
                                                    pagination={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={selectedTabReceptionist} index={1} style={{ width: "100%" }}>
                                    <Grid item container>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Base58Table
                                                    columns={columns}
                                                    data={examinationList}
                                                    pagination={false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                            </Grid>
                        </Grid>
                    </Box>
                }
                <ExaminationServicesModal
                    isOpen={this.state.examinationServiceModalOpen}
                    onClose={this.closeDialogExaminationServiceModal}
                    examinationId={this.state.eId}
                    onSave={() => this.setState({ examinationServiceModalOpen: false, eId: null }, () => this.props.getTodayExaminations())}
                />
                {hasRole(UserRole.DOCTOR) &&
                    <Box className='custom-box'>
                        <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant="h5" sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", alignItems: 'center', display: 'flex' }} color="primary">
                                    {this.props.t("today_schedule")}
                                </Typography>
                                <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                            </Grid>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{ borderBottom: 1, border: 'none' }}>
                                    <Tabs value={selectedTab} onChange={this.handleChangeTab} TabIndicatorProps={{
                                        sx: { display: 'none' }
                                    }}>
                                        <Tab icon={selectedTab === 0 ? <UnprocessedActive /> : <UnprocessedInactive />} iconPosition="start" style={{ width: '300px' }} label={this.props.t("unprocessed")} {...a11yProps(0)} wrapped />
                                        <Tab icon={selectedTab === 1 ? <ProcessedActive /> : <ProcessedInactive />} iconPosition="start" style={{ width: '300px' }} label={this.props.t("processed")} {...a11yProps(1)} wrapped />
                                    </Tabs>
                                </Box>
                                <Grid item container className="MuiBox-rmp">
                                    <CustomTabPanel value={selectedTab} index={0} style={{ width: "100%" }}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid item container>
                                                    {todayEvents.map((data, index) => {
                                                        return data.calendarEntryStatus !== "PATIENT_PROCESSED" &&
                                                            <React.Fragment key={index}>
                                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3} style={{ textAlign: 'center' }}>
                                                                    <Grid item>
                                                                        <Card style={{ paddingTop: 5, marginRight: '15px', borderRadius: '5%', boxShadow: data.calendarEntryStatus === "PATIENT_NOT_PRESENT" ? '3px 5px 5px #ed6666' : data.calendarEntryStatus === "PATIENT_ARRIVED" ? '3px 5px 5px #75c26b' : '3px 5px 5px #ed6666', marginTop: '10px', marginBottom: '10px' }} >
                                                                            <CardHeader
                                                                                style={{ height: '40px', backgroundColor: '#FFFFFF' }}
                                                                                title={moment(data.startTime).format("HH:mm") + " - " + moment(data.endTime).format("HH:mm")}
                                                                            />
                                                                            <CardContent>
                                                                                <Grid container direction="row" spacing={2}>
                                                                                    <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                                                                                        <FaRegUser style={{ fontSize: '80px', color: '#24A36C', marginLeft: '10%' }} />
                                                                                    </Grid>
                                                                                    <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'left' }}>
                                                                                        <Typography variant="body2" gutterBottom>
                                                                                            <strong>{this.props.t("first_name")}</strong>: {data.patient.firstName && data.patient.firstName.length > 15 ? <Tooltip
                                                                                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={data.patient.firstName} style={{ cursor: 'pointer' }}>
                                                                                                <span>{data.patient.firstName && data.patient.firstName.length > 15 ? data.patient.firstName.substring(0, 15) + "..." : data.patient.firstName}</span>
                                                                                            </Tooltip> :
                                                                                                data.patient.firstName}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" gutterBottom>
                                                                                            <strong>{this.props.t("last_name")}</strong>:  {data.patient.lastName && data.patient.lastName.length > 15 ? <Tooltip
                                                                                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={data.patient.lastName} style={{ cursor: 'pointer' }}>
                                                                                                <span>{data.patient.lastName && data.patient.lastName.length > 15 ? data.patient.lastName.substring(0, 15) + "..." : data.patient.lastName}</span>
                                                                                            </Tooltip> :
                                                                                                data.patient.lastName}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" gutterBottom>
                                                                                            <strong>{this.props.t("date_of_birth")}</strong>: {data.patient.dateOfBirth}
                                                                                        </Typography>
                                                                                        <Typography variant="body2" gutterBottom>
                                                                                            <strong>{this.props.t("phone")}</strong>: {data.patient.phone}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center', height: '50px' }}>
                                                                                        <PerfectScrollbar style={{ height: 'auto', maxHeight: '5vh' }}>
                                                                                            <Typography variant="h7" gutterBottom>
                                                                                                <strong>{data.comment && data.comment.length > 40 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={data.comment} style={{ cursor: 'pointer', fontSize: '18px', fontWeight: 'bold' }}>
                                                                                                    <span>{data.comment && data.comment.length > 40 ? data.comment.substring(0, 40) + "..." : data.comment}</span>
                                                                                                </Tooltip> :
                                                                                                    data.comment}</strong>
                                                                                            </Typography>
                                                                                        </PerfectScrollbar>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                            <CardActions disableSpacing style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                                <Typography variant="body2" gutterBottom style={{ marginRight: 'auto', color: data.calendarEntryStatus === "PATIENT_NOT_PRESENT" ? '#ed6666' : data.calendarEntryStatus === "PATIENT_ARRIVED" ? '#75c26b' : '#ed6666' }}>
                                                                                    <strong>{this.props.t(data.calendarEntryStatus)}</strong>
                                                                                </Typography>
                                                                                <Button color="secondary" className="button-with-border-radius" style={{ textTransform: 'none' }} onClick={() => this.props.history.push(`/examination/patients/${data.patient.id}/calendar-entries/${data.id}`)}>
                                                                                    <BsJournalMedical style={{ fontSize: '22px', marginRight: '5px' }} />{this.props.t("examination")}
                                                                                </Button>
                                                                            </CardActions>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </React.Fragment>
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel value={selectedTab} index={1} style={{ width: "100%" }}>
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Grid item container>
                                                    {todayEvents.map((data, index) => {
                                                        return data.calendarEntryStatus === "PATIENT_PROCESSED" && <React.Fragment key={index}>
                                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={3} xl={3} style={{ textAlign: 'center' }}>
                                                                <Grid item >
                                                                    <Card style={{ paddingTop: 20, marginRight: '15px', borderRadius: '5%', marginTop: '10px', marginBottom: '10px', boxShadow: '3px 5px 5px grey' }} >
                                                                        <CardHeader
                                                                            style={{ height: '40px', backgroundColor: '#FFFFFF' }}
                                                                            title={moment(data.startTime).format("HH:mm") + " - " + moment(data.endTime).format("HH:mm")}
                                                                        />
                                                                        <CardContent>
                                                                            <Grid container direction="row" spacing={2}>
                                                                                <Grid item container direction="column" xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: 'center' }}>
                                                                                    <FaRegUser style={{ fontSize: '80px', color: '#24A36C', marginLeft: '10%' }} />
                                                                                </Grid>
                                                                                <Grid item container direction="column" xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: 'left' }}>
                                                                                    <Typography variant="body2" gutterBottom>
                                                                                        <strong>{this.props.t("first_name")}</strong>: {data.patient.firstName}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" gutterBottom>
                                                                                        <strong>{this.props.t("last_name")}</strong>: {data.patient.lastName}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" gutterBottom>
                                                                                        <strong>{this.props.t("date_of_birth")}</strong>: {data.patient.dateOfBirth}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" gutterBottom>
                                                                                        <strong>{this.props.t("phone")}</strong>: {data.patient.phone}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center', height: '50px' }}>
                                                                                    <PerfectScrollbar style={{ height: 'auto', maxHeight: '5vh' }}>
                                                                                        <Typography variant="h7" gutterBottom>
                                                                                            <strong>{data.comment && data.comment.length > 40 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={data.comment} style={{ cursor: 'pointer', fontSize: '18px', fontWeight: 'bold' }}>
                                                                                                <span>{data.comment && data.comment.length > 40 ? data.comment.substring(0, 40) + "..." : data.comment}</span>
                                                                                            </Tooltip> :
                                                                                                data.comment}</strong>
                                                                                        </Typography>
                                                                                    </PerfectScrollbar>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                        <CardActions disableSpacing style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                            <Typography variant="body2" gutterBottom style={{ marginRight: 'auto' }}>
                                                                                <strong>{this.props.t(data.calendarEntryStatus)}</strong>
                                                                            </Typography>
                                                                            <Button color="secondary" className="button-with-border-radius" style={{ textTransform: 'none' }} onClick={() => this.props.history.push("/examination/patients/" + data.patient.id)}>
                                                                                <BsJournalMedical style={{ fontSize: '22px', marginRight: '5px' }} />{this.props.t("examination")}
                                                                            </Button>
                                                                        </CardActions>
                                                                    </Card>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {
                    hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <DashboardStatistics />
                        </Grid>
                    </Grid>
                }

                <CreateOrUpdateInvoice
                    isOpen={this.state.invoiceDialog}
                    onClose={this.closeDialog}
                    examinationId={this.state.examinationId}
                    onSave={() => this.setState({ invoiceDialog: false, examinationId: null, collapseIndex: -1 }, () => this.props.getTodayExaminations())}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    calendarReducer: state.calendarReducer,
    examinationReducer: state.examinationReducer,
    patientReducer: state.patientReducer
})

const mapActionsToProps = { getTodayEntries, getTodayExaminations, getInvoiceTypes, exportInvoiceToPdf, getServiceList, getDoctors, getCalendarEntryStatuses, updateCalendarEventStatus, getServiceGroupsList, exportExaminationByIdToPdf }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Dashboard))
