import types from './types';

const initialState = {
    auditLogsLoading: true,
    auditLogs: [],
    auditLogsTotalElements: 0,
    auditLogsParams: {
        page: 0,
        size: 20
    },
    auditLogsActions: []
}

export default function audit(state = initialState, action) {
    switch (action.type) {
        case types.SET_AUDIT_LOGS:
            return {
                ...state,
                auditLogs: action.payload.content,
                auditLogsTotalElements: action.payload ? action.payload.totalElements : 0,
                auditLogsLoading: false
            }
        case types.SET_ADUIT_LOGS_PAGE:
            return {
                ...state, auditLogsParams: { ...state.auditLogsParams, page: action.payload }
            }
        case types.SET_ADUIT_LOGS_SIZE:
            return {
                ...state, auditLogsParams: { ...state.auditLogsParams, size: action.payload, page: 0 }
            }
        case types.SET_ADUIT_LOGS_PARAMS:
            return {
                ...state,
                auditLogsParams: action.payload
            }
        case types.SET_AUDIT_LOGS_ACTIONS:
            return {
                ...state,
                auditLogsActions: action.payload
            }
        case types.RESET_AUDIT_LOGS_DATA:
            return {
                ...initialState,
                auditLogsParams: {
                    page: 0,
                    size: 20
                }
            }
        default:
            return state;
    }
}