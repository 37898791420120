import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    TableCell,
    TableRow,
    Typography,
    Button,
    Zoom,
    Tooltip,
    Chip,
    Stack,
    IconButton,
    Box,
    Divider
} from '@mui/material';
import { Help } from '@mui/icons-material';
import Base58Table from "../../components/common/Base58Table";
import { getUsers, deleteUser, setUsersPage, setUsersSize, setUsersParams } from '../../redux/user/actions'
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import UploadSignatureModal from './UploadSignatureModal';
import { ReactComponent as AddIcon } from '../../assets/icons/add_icon.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_icon.svg';
import { ReactComponent as SignatureIcon } from '../../assets/icons/signature_icon.svg';
import { UserRole } from '../../utils/Security';

class Users extends React.Component {

    emptyUser = {
        id: null,
        firstName: '',
        lastName: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            userToDelete: this.emptyUser,
            userToEdit: null,
            openDeleteDialog: false,
            openSignatureDialog: false,
            userId: null
        };
    }

    componentDidMount() {
        this.props.getUsers()
    }

    componentWillUnmount() {
        this.props.setUsersSize(10, true)
    }

    closeDialog = () => {
        this.setState({ openFormDialog: false, userToEdit: null });
    }

    closeSignatureDialog = () => {
        this.setState({ openSignatureDialog: false, userId: null }, () => this.props.getUsers());
    }

    getColor = (role) => {
        switch (role) {
            case 'SYSTEM':
                return '#D5F6E8'
            case 'ADMIN':
                return '#FFF9E0'
            case 'DOCTOR':
                return '#FEDED7'
            case 'RECEPTIONIST':
                return '#FDD8E0'
            case 'SIGNER':
                return '#CFF5FC'
            default:
                return null;
        }
    }

    render() {
        const { openSignatureDialog } = this.state;
        const { loadingUsers, users, usersParams, usersTotalElements } = this.props.userReducer;

        let usersList = users && users.map((user, index) => {
            return <TableRow key={index}>
                <TableCell>{user.firstName && user.firstName.length > 30 ? <Tooltip
                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={user.firstName} style={{ cursor: 'pointer' }}>
                    <span>{user.firstName && user.firstName.length > 30 ? user.firstName.substring(0, 30) + "..." : user.firstName}</span>
                </Tooltip> :
                    user.firstName}</TableCell>
                <TableCell>{user.lastName && user.lastName.length > 30 ? <Tooltip
                    disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={user.lastName} style={{ cursor: 'pointer' }}>
                    <span>{user.lastName && user.lastName.length > 30 ? user.lastName.substring(0, 30) + "..." : user.lastName}</span>
                </Tooltip> :
                    user.lastName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={1} sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                            style={{ backgroundColor: this.getColor(user.role), cursor: 'pointer' }}
                            key={this.props.t(user.role)}
                            tabIndex={-1}
                            label={<Typography variant="overline" sx={{ fontStyle: 'italic', fontWeight: '900', color: '#5C5C5C' }}>{this.props.t(user.role).toUpperCase()}</Typography>}
                            variant="filled"
                        />
                        {user.role && user.role === UserRole.DOCTOR ?
                            <Tooltip
                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<span>
                                    <p>{this.props.t("primary_title") + " : " + (user.primaryTitle === null ? "" : user.primaryTitle)} </p>
                                    <p>{this.props.t("secondary_title") + " : " + (user.secondaryTitle === null ? "" : user.secondaryTitle)} </p>
                                </span>}>
                                <Help style={{ fontSize: '18px', cursor: 'pointer' }} />
                            </Tooltip>
                            : null}
                    </Stack>
                </TableCell>
                <TableCell align='center'>
                    {user.role && user.role === UserRole.DOCTOR ?
                        <IconButton
                            title="Add"
                            aria-label="add"
                            onClick={() => this.setState({ openSignatureDialog: true, userId: user.id })}
                            sx={{
                                "& svg path": {
                                    stroke: user.hasSignature ? '#24A36C' : '#F53105'
                                }
                            }}
                        >
                            <SignatureIcon />
                        </IconButton>
                        : null}
                </TableCell>
                <TableCell className='row-cell' align='center'>{
                    <Stack direction={'row'} sx={{ justifyContent: 'center' }} spacing={1}>
                        <Button title={this.props.t("edit")} className="default-button-edit" endIcon={<EditIcon />} onClick={() => this.props.history.push(`/users/${user.id}`)}>
                            {this.props.t("edit")}
                        </Button>
                        <Button title={this.props.t("delete")} className="default-button-delete" endIcon={<DeleteIcon />} onClick={() => this.setState({ userToDelete: user, openDeleteDialog: true })}>
                            {this.props.t("delete")}
                        </Button>
                    </Stack>
                }</TableCell>
            </TableRow >
        })

        let columns = [
            {
                name: "first_name",
                dbName: 'first_name',
                width: '17%',
                sort: true
            },
            {
                name: "last_name",
                dbName: 'last_name',
                width: '17%',
                sort: true
            },
            {
                name: "email",
                dbName: 'email',
                width: '23%',
                sort: true
            },
            {
                name: "phone",
                width: '13%'
            },
            {
                name: "role",
                width: '10%',
            },
            {
                name: "signature",
                width: '5%',
            },
            {
                name: "",
                width: '15%',
            }
        ]

        return (
            <Box className='custom-box'>
                <Grid container direction="row" spacing={2}>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", flexGrow: 1, alignItems: 'center', display: 'flex' }} color="primary">
                            {this.props.t("users")}
                            <Button
                                className="create-btn"
                                startIcon={<AddIcon />}
                                style={{ marginLeft: 'auto' }}
                                onClick={() => this.props.history.push("/new-user")}>
                                {this.props.t("new_user")}
                            </Button>
                        </Typography>
                        <Divider sx={{ border: "1px solid #F1F1F1", marginTop: "12px" }} />
                    </Grid>
                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Base58Table
                            isLoading={loadingUsers}
                            columns={columns}
                            data={usersList}
                            count={usersTotalElements}
                            rowsPerPage={usersParams.size}
                            page={usersParams.page}
                            onPageChange={(e, page) => this.props.setUsersPage(page)}
                            onRowsPerPageChange={(e) => this.props.setUsersSize(e.target.value)}
                            onFilterChange={(params) => this.props.setUsersParams(params)}
                        />
                    </Grid>
                </Grid>
                <UploadSignatureModal
                    isOpen={openSignatureDialog}
                    onClose={this.closeSignatureDialog}
                    userId={this.state.userId}
                />
                <ConfirmationDialog
                    isOpen={this.state.openDeleteDialog}
                    title={this.props.t("delete_user")}
                    message={<span dangerouslySetInnerHTML={{ __html: this.props.t("delete_user_message", { firstName: this.state.userToDelete.firstName, lastName: this.state.userToDelete.lastName }) }} />}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirm={() => this.props.deleteUser(this.state.userToDelete.id).then(() => {
                        this.setState({ openDeleteDialog: false }, () => this.props.getUsers(), this.props.setUsersPage(0));
                    })}
                    closeButtonTitle={this.props.t("close")}
                    confirmButtonTitle={this.props.t("delete")}
                />
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    userReducer: state.userReducer
})

const mapActionsToProps = { getUsers, deleteUser, setUsersPage, setUsersSize, setUsersParams }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Users))
