import axios from '../axiosConfig';

/* wAREHOUSES */
export function getWarehouses(config = {}) {
    return axios.get(`/v1/warehouse/warehouses`, config);
}

export function getWarehouseById(id, config = {}) {
    return axios.get(`/v1/warehouse/warehouse/${id}`, config);
}

export function createWarehouse(data, config = {}) {
    return axios.post(`/v1/warehouse/warehouse`, data, config);
}

export function updateWarehouse(id, data) {
    return axios.patch(`/v1/warehouse/warehouse/${id}`, data);
}

export function deleteWarehouse(id) {
    return axios.delete(`/v1/warehouse/warehouse/${id}`);
}

export function addUsersToWarehouse(warehouseId, data, config = {}) {
    return axios.post(`/v1/warehouse/add-users/${warehouseId}`, data, config);
}

export function removeUsersFromWarehouse(warehouseId, data, config = {}) {
    return axios.post(`/v1/warehouse/remove-users/${warehouseId}`, data, config);
}

export function getWarehouseUsers(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/users/${warehouseId}`, config);
}

/* ITEM CATEGORY */
export function getCategories(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/categories/${warehouseId}`, config);
}

export function getListOfCategories(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/category_list/${warehouseId}`, config);
}

export function getCategoryById(id, config = {}) {
    return axios.get(`/v1/warehouse/category/${id}`, config);
}

export function createCategory(data, config = {}) {
    return axios.post(`/v1/warehouse/category`, data, config);
}

export function updateCategory(id, data) {
    return axios.patch(`/v1/warehouse/category/${id}`, data);
}

export function deleteCategory(id) {
    return axios.delete(`/v1/warehouse/category/${id}`);
}

/* UNIT OF MEASUREMENT */
export function getUnitOfMeasurements(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/uoms/${warehouseId}`, config);
}

export function getListOfMeasurements(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/uom_list/${warehouseId}`, config);
}

export function getMeasurementById(id, config = {}) {
    return axios.get(`/v1/warehouse/uom/${id}`, config);
}

export function createMeasurement(data, config = {}) {
    return axios.post(`/v1/warehouse/uom`, data, config);
}

export function updateMeasurement(id, data) {
    return axios.patch(`/v1/warehouse/uom/${id}`, data);
}

export function deleteMeasurement(id) {
    return axios.delete(`/v1/warehouse/uom/${id}`);
}

/* ITEMS */

export function getItems(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/items/${warehouseId}`, config);
}

export function getListOfItems(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/item_list/${warehouseId}`, config);
}

export function getItemById(id, config = {}) {
    return axios.get(`/v1/warehouse/item/${id}`, config);
}

export function createItem(data, config = {}) {
    return axios.post(`/v1/warehouse/item`, data, config);
}

export function updateItem(id, data) {
    return axios.patch(`/v1/warehouse/item/${id}`, data);
}

export function deleteItem(id) {
    return axios.delete(`/v1/warehouse/item/${id}`);
}

/* DOCUMENTS */

export function getDocuments(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/documents/${warehouseId}`, config);
}

export function getDocumentById(id, config = {}) {
    return axios.get(`/v1/warehouse/document/${id}`, config);
}

export function createDocument(data, config = {}) {
    return axios.post(`/v1/warehouse/document`, data, config);
}

export function updateDocument(id, data) {
    return axios.patch(`/v1/warehouse/document/${id}`, data);
}

export function deleteDocument(id) {
    return axios.delete(`/v1/warehouse/document/${id}`);
}

export function getDocumentItemsbyDocumentId(id, config = {}) {
    return axios.get(`/v1/warehouse/document-items/${id}`, config);
}

export function getItemStates(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/items-state/${warehouseId}`, config);
}

export function getItemStateById(id, config = {}) {
    return axios.get(`/v1/warehouse/item-state/${id}`, config);
}

export function getItemsDocumentItemsState(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/items-document-items-state/${warehouseId}`, config);
}

export function getItemDocumentItemsStateById(id, config = {}) {
    return axios.get(`/v1/warehouse/item-document-items-state/${id}`, config);
}

export function getItemsDocumentItemsStateAll(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/items-document-items-state-all/${warehouseId}`, config);
}

export function getSuppliersByWarehouseId(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/suppliers/${warehouseId}`, config);
}

export function getManufacturersByWarehouseId(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/manufacturers/${warehouseId}`, config);
}

export function getUserWarehouses(config = {}) {
    return axios.get(`/v1/warehouse/user-warehouses`, config);
}

export function getWarehouseCurrentState(warehouseId, config = {}) {
    return axios.get(`/v1/warehouse/items-document-items-state-all/${warehouseId}`, config);
}

const actions = {
    getWarehouses,
    getWarehouseById,
    createWarehouse,
    addUsersToWarehouse,
    removeUsersFromWarehouse,
    getWarehouseUsers,
    updateWarehouse,
    deleteWarehouse,
    getCategories,
    getListOfCategories,
    getCategoryById,
    createCategory,
    updateCategory,
    deleteCategory,
    getUnitOfMeasurements,
    getListOfMeasurements,
    getMeasurementById,
    createMeasurement,
    updateMeasurement,
    deleteMeasurement,
    getItems,
    getListOfItems,
    getItemById,
    createItem,
    updateItem,
    deleteItem,
    getDocuments,
    getDocumentById,
    createDocument,
    updateDocument,
    deleteDocument,
    getDocumentItemsbyDocumentId,
    getItemStates,
    getItemStateById,
    getItemsDocumentItemsState,
    getItemDocumentItemsStateById,
    getItemsDocumentItemsStateAll,
    getSuppliersByWarehouseId,
    getManufacturersByWarehouseId,
    getUserWarehouses,
    getWarehouseCurrentState
}

export default actions;