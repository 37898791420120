import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    Button,
    DialogContent,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    CircularProgress,
    Stack,
    Typography,
    Box,
    TextField
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { createInvoice, updateInvoice, getInvoiceById, getReferenceNumber } from '../../redux/invoice/actions';
import { INVOICE_REF_NUMBER_REGEX } from '../../components/common/regex';
import { BootstrapDialog, Transition } from '../../components/common/Dialog';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';

class CreateOrUpdateInvoice extends Component {

    emptyInvoice = {
        invoiceReference: '',
        invoiceType: null,
        examinationId: null
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            initialInvoice: JSON.parse(JSON.stringify(this.emptyInvoice)),
            invoice: JSON.parse(JSON.stringify(this.emptyInvoice)),
            invoiceReferenceNumber: '',
            promptDialogOpen: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.invoiceToEdit && prevProps.invoiceToEdit !== this.props.invoiceToEdit) {
            this.props.getInvoiceById(this.props.invoiceToEdit).then(response => {
                this.setState({
                    invoice: JSON.parse(JSON.stringify(response.data)),
                    initialInvoice: JSON.parse(JSON.stringify(response.data)),
                });
            })
        }
        if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
            if (!this.props.invoiceToEdit && prevProps.invoiceToEdit === this.props.invoiceToEdit) {
                this.getReferenceNumber()
            }
        }
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({
            invoice: JSON.parse(JSON.stringify(this.emptyInvoice))
        });
    }

    handleChange = (event) => {
        const target = event.target;
        let { invoice } = this.state;
        invoice[target.name] = target.value.trimStart();
        this.setState({ invoice: invoice })
    };

    createOrUpdateInvoice = (e) => {
        e.preventDefault();
        if (!this.validateForm()) {
            return;
        }
        const { invoice } = this.state;
        invoice.examinationId = this.props.examinationId
        const action = this.props.invoiceToEdit ?
            this.props.updateInvoice(this.props.invoiceToEdit, invoice) :
            this.props.createInvoice(invoice);
        action.then((response) => {
            if (response.status === 200 || response.status === 201) {
                this.props.onSave();
                this.setState({ invoice: JSON.parse(JSON.stringify(this.emptyInvoice)) });
            }
        })
    };

    validateForm() {
        let { invoice, initialInvoice } = this.state;
        let valid = true;
        if (!INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference)) {
            valid = false;
        }
        if (JSON.stringify(invoice) === JSON.stringify(initialInvoice)) {
            valid = false
        }
        if (!invoice.invoiceReference || !invoice.invoiceType
        ) {
            valid = false;
        }
        return valid;
    }

    getNameHelperTextRefNumber() {
        let { invoice } = this.state;
        if (!invoice.invoiceReference.trim()) {
            return this.props.t("required_field_message");
        }
        if (invoice.invoiceReference.length > 0 && !INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference)) {
            return this.props.t("name_regex_message", { min: 1, max: 30 });
        }
    }

    getReferenceNumber = () => {
        this.props.getReferenceNumber().then(response => {
            let { invoice } = this.state
            invoice.invoiceReference = response.data
            this.setState({ invoice: invoice });
        })
    }

    handleResetReferenceNumber = () => {
        let { invoice } = this.state;
        !this.props.invoiceToEdit ? this.getReferenceNumber() : invoice.invoiceReference = this.state.invoiceReferenceNumber
        this.setState({ invoice: invoice })
    }

    render() {
        let { initialInvoice, invoice, promptDialogOpen } = this.state
        let { invoiceTypes } = this.props.invoiceReducer

        return (
            <BootstrapDialog
                onClose={() => JSON.stringify(initialInvoice) !== JSON.stringify(invoice) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth
                maxWidth={"sm"}
            >
                <form onSubmit={this.createOrUpdateInvoice} autoComplete="off" spellCheck="false">
                    <DialogContent>
                        <Box className="custom-box">
                            <Grid container direction="row" spacing={3}>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                                        <Typography sx={{ fontWeight: 500, fontSize: "28px", lineHeight: "32px", color: "#24A36C", flexGrow: 1 }}>
                                            {this.props.invoiceToEdit ? this.props.t("edit_offer") : this.props.t("new_offer")}
                                        </Typography>
                                        <Button className="cancel-btn" onClick={() => JSON.stringify(initialInvoice) !== JSON.stringify(invoice) ? this.setState({ promptDialogOpen: true }) : this.closeDialog()}>
                                            {this.props.t("cancel")}
                                        </Button>
                                        <Button className="create-btn" color="primary" disabled={!this.validateForm()} type='submit'>
                                            {this.props.invoiceReducer.loadingCreate ? <CircularProgress
                                                size={20}
                                                color="secondary"
                                            /> : this.props.invoiceToEdit ? this.props.t("update") : this.props.t("create")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="custom-box" sx={{ backgroundColor: "#F9F9F9 !important" }}>
                                        <Grid container direction="row" spacing={3}>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel margin="dense">{this.props.t("offer_reference")}</InputLabel>
                                                <TextField
                                                    id="invoiceReference"
                                                    name="invoiceReference"
                                                    value={invoice.invoiceReference}
                                                    onChange={this.handleChange}
                                                    required
                                                    disabled={this.props.invoiceToEdit ? true : false}
                                                    error={!INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference) || !invoice.invoiceReference.trim()}
                                                />
                                            </Grid>
                                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <InputLabel id="invoiceType">{this.props.t("select_payment_method")}</InputLabel>
                                                <FormControl required error={!invoice.invoiceType}>
                                                    <Select
                                                        id="invoiceType"
                                                        name="invoiceType"
                                                        defaultValue=""
                                                        value={invoice.invoiceType || ""}
                                                        onChange={this.handleChange}
                                                    >
                                                        {invoiceTypes && invoiceTypes.map((type, index) => {
                                                            return <MenuItem key={index} value={type}>{this.props.t(type)}</MenuItem>
                                                        })}
                                                    </Select>
                                                    <FormHelperText>{!invoice.invoiceType && this.props.t("required_field_message")}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* <Grid container direction="row" spacing={2}>
                            <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item container>
                                        <FormControl fullWidth>
                                            <InputLabel margin="dense">{this.props.t("offer_reference")}</InputLabel>
                                            <OutlinedInput
                                                name="invoiceReference"
                                                label={this.props.t("offer_reference")}
                                                id="invoiceReference"
                                                value={invoice.invoiceReference}
                                                onChange={this.handleChange}
                                                required
                                                disabled={this.props.invoiceToEdit ? true : false}
                                                error={!INVOICE_REF_NUMBER_REGEX.test(invoice.invoiceReference) || !invoice.invoiceReference.trim()}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container>
                                        <FormControl required error={!invoice.invoiceType}>
                                            <InputLabel id="invoiceType">{this.props.t("select_payment_method")}</InputLabel>
                                            <Select
                                                id="invoiceType"
                                                name="invoiceType"
                                                defaultValue=""
                                                value={invoice.invoiceType || ""}
                                                onChange={this.handleChange}
                                                label={this.props.t("select_payment_method")}>
                                                {invoiceTypes && invoiceTypes.map((type, index) => {
                                                    return <MenuItem key={index} value={type}>{this.props.t(type)}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{!invoice.invoiceType && this.props.t("required_field_message")}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </DialogContent>
                </form>
                <ConfirmationDialog
                    isOpen={promptDialogOpen}
                    title={this.props.t("prompt_title")}
                    message={this.props.t("prompt_message_2")}
                    onClose={() => this.setState({ promptDialogOpen: false })}
                    onConfirm={() => this.setState({ promptDialogOpen: false }, () => this.closeDialog())}
                    closeButtonTitle={this.props.t("continue")}
                    confirmButtonTitle={this.props.t("discard")}
                />
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    invoiceReducer: state.invoiceReducer
})

const mapActionsToProps = { createInvoice, updateInvoice, getInvoiceById, getReferenceNumber }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(CreateOrUpdateInvoice)))
