import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import {
    Grid,
    CircularProgress,
    FormControlLabel,
    Radio,
    Box,
    Typography,
    Divider
} from '@mui/material'
import { getDocumentLanguages, getSettings, updateSettings } from '../../../redux/setting/actions';
import { FlagImage } from 'react-international-phone';

class Languages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: null,
            mainLoading: true,
            selectedIndex: null
        }
    }

    componentDidMount() {
        Promise.all([
            this.props.getDocumentLanguages(),
            this.props.getSettings().then((response) => {
                this.setState({ settings: JSON.parse(JSON.stringify(response.data)), selectedIndex: this.props.settingReducer.documentLanguages.findIndex(t => t === response.data.documentLanguage) })
            })])
            .then(() => {
                this.setState({ mainLoading: false });
            })
            .catch(error => {
                this.setState({ mainLoading: false });
            })
    }

    handleChangeFlag = (data) => {
        let requestObject = {
            currency: this.state.settings.currency,
            documentLanguage: data
        }
        this.props.updateSettings(requestObject).then((response) => {
            this.setState({ settings: JSON.parse(JSON.stringify(response.data)), selectedIndex: this.props.settingReducer.documentLanguages.findIndex(t => t === response.data.documentLanguage) })
        })
    }

    render() {
        const { mainLoading, selectedIndex } = this.state;
        const { documentLanguages } = this.props.settingReducer;

        if (mainLoading) {
            return (
                <div className="loading-position">
                    <CircularProgress className="spinner" color="primary" />
                </div>
            )
        }

        return (<Box className='custom-box'>
            <Grid container direction="row" spacing={2}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ fontWeight: 500, fontSize: "24px" }}>
                        {this.props.t("document_language")}
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider />
                </Grid>
                {documentLanguages.map((language, index) => {
                    return <Grid key={index} item container direction="column" xs={12} sm={12} md={1} lg={1} xl={1}>
                        <FormControlLabel
                            key={index}
                            sx={{ border: selectedIndex === index && '1px solid #24A36C', borderRadius: selectedIndex === index && '6px', padding: '12px' }}
                            control={
                                <Radio
                                    key={index}
                                    checked={selectedIndex === index ? true : false}
                                    onClick={() => this.handleChangeFlag(language)}
                                    color="primary"
                                />
                            }
                            label={
                                <FlagImage
                                    size={48}
                                    iso2={language.toLowerCase() === "en" ? "gb" : language.toLowerCase()}
                                    alt="flag"
                                    title={this.props.t(language.toLowerCase())}
                                    style={{ display: 'flex' }}
                                />} />
                    </Grid>
                })}
            </Grid>
        </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

const mapActionsToProps = { getDocumentLanguages, getSettings, updateSettings }

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(Languages))