import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter, Prompt } from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    Stack,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Zoom,
} from '@mui/material';
import { getConfigurations, createConfiguration, setConfigurationsPage, setConfigurationsSize, setConfigurationsParams } from '../../../redux/setting/actions'
import WrappedAutocomplete from '../../../components/common/WrappedAutocomplete';
import Base58Table from '../../../components/common/Base58Table';
import { Check, Clear, Help } from '@mui/icons-material';
import moment from 'moment';

class ConfigurationMessageTab extends Component {

    emptyConfiguration = {
        notificationType: '',
        message: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            initialConfiguration: JSON.parse(JSON.stringify(this.emptyConfiguration)),
            configuration: JSON.parse(JSON.stringify(this.emptyConfiguration)),
            selectedType: null
        };
    }

    componentDidMount() {
        this.props.getConfigurations()
    }

    handleChange = (event) => {
        const target = event.target;
        let { configuration } = this.state;
        configuration[target.name] = target.value.trimStart()
        this.setState({ configuration: configuration })
    }


    handleChangeType = (e, type) => {
        let { configuration } = this.state
        if (type !== null) {
            configuration.notificationType = type
            this.setState({ selectedType: type, configuration: configuration })
        } else {
            configuration.notificationType = ''
            this.setState({ selectedType: null, configuration: configuration })
        }
    }

    clearData = () => {
        this.setState({
            initialConfiguration: JSON.parse(JSON.stringify(this.emptyConfiguration)),
            configuration: JSON.parse(JSON.stringify(this.emptyConfiguration)),
            selectedType: null
        })
    }

    createConfiguration = () => {
        let { configuration } = this.state
        this.props.createConfiguration(configuration).then(response => {
            if (response.status === 200) {
                this.props.getConfigurations()
                this.clearData()
            }
        })
    }

    render() {
        const { selectedType, initialConfiguration, configuration } = this.state
        const { notificationTypes, configurations, configurationsLoading, configurationsTotalElements, configurationsParams } = this.props.settingReducer;

        let configurationList;
        if (configurations && configurations.length > 0) {
            configurationList = configurations.map((conf, index) => {
                return <React.Fragment key={index}>
                    <TableRow>
                        <TableCell>{this.props.t(conf.notificationType)}</TableCell>
                        <TableCell>{conf.message}</TableCell>
                        <TableCell>{conf.active ? <Check style={{ color: 'green', fontSize: '26px' }} /> : <Clear style={{ color: 'red', fontSize: '26px' }} />}</TableCell>
                        <TableCell>
                            <Tooltip
                                disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={<span>
                                    <p>{this.props.t("created_by") + ": " + conf.createdBy} </p>
                                    <p>{this.props.t("created_date") + ": " + moment.unix(conf.createdDate).format("DD.MM.YYYY. HH:mm")} </p>
                                </span>}>
                                <Help style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            })
        } else {
            configurationList = <TableRow>
                <TableCell align="center" colSpan={2}>
                    {this.props.t("records_not_found")}
                </TableCell>
            </TableRow>
        }

        let columns = [
            {
                name: "type",
                dbName: "type",
                width: '25%',
                type: "auto-complete",
                items: notificationTypes.map(n => { return this.props.t(n) }),
                filter: true,
            },
            {
                name: "message",
                dbName: "message",
                width: '62%'
            },
            {
                name: "active",
                dbName: "active",
                width: '8%',
                type: 'boolean-select',
                filter: true
            },
            {
                name: "info",
                dbName: "info",
                width: '5%'
            }
        ]

        return (
            <Grid item container direction="row" spacing={3}>
                <Prompt when={JSON.stringify(initialConfiguration) !== JSON.stringify(configuration)} message={this.props.t("prompt_message")} />
                <Grid item container direction="column" xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container>
                            <InputLabel className="form-label">
                                {this.props.t("type")} <span style={{ color: "#D32F2F" }}>*</span>
                            </InputLabel>
                            <FormControl>
                                <WrappedAutocomplete
                                    id="selectedType"
                                    name="selectedType"
                                    value={selectedType}
                                    getOptionLabel={(option) => option ? this.props.t(option) : ""}
                                    options={notificationTypes}
                                    onChange={(event, type) => {
                                        this.handleChangeType(event, type)
                                    }}
                                    placeholder={this.props.t("select_type")}
                                    error={!selectedType ? true : false}
                                    helperText={!selectedType && this.props.t("required_field_message")}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item container>
                            <InputLabel className="form-label" sx={{ flexGrow: 1 }}>
                                {this.props.t("message")} <span style={{ color: "#D32F2F" }}>*</span>
                                <Typography sx={{ fontSize: "14px", float: "right" }}><strong>{this.props.t("number_of_character")}</strong>: {configuration.message.length}</Typography>
                            </InputLabel>
                            <TextField
                                id="message"
                                name="message"
                                value={configuration.message}
                                multiline
                                rows={7}
                                onChange={this.handleChange}
                                placeholder={this.props.t("enter", { text: this.props.t("message").toLowerCase() })}
                                error={!configuration.message || !configuration.message.includes("{datum}") || !configuration.message.includes("{vrijeme}") ? true : false}
                                helperText={!configuration.message && this.props.t("required_field_message")}
                            />
                        </Grid>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Stack direction="row" spacing={2}>
                                {configuration.notificationType === "CALENDER_ENTRY_REMINDER" ? <Typography variant="body2" gutterBottom>
                                    <strong>{this.props.t("info_message")}</strong>
                                </Typography> : null}
                                <Box sx={{ flexGrow: 1 }} />
                                <Button
                                    sx={{ minWidth: "200px !important" }}
                                    className="create-btn"
                                    title={this.props.t("create_configuration")}
                                    color="primary"
                                    disabled={!selectedType || !configuration.message || !configuration.notificationType || !configuration.message.includes("{datum}") || !configuration.message.includes("{vrijeme}")}
                                    onClick={() => this.createConfiguration()}>
                                    {this.props.t("create_configuration")}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={8} lg={8} xl={8}>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item container>
                            <InputLabel className="form-label">
                                {this.props.t("list_of_configurations")}
                            </InputLabel>
                        </Grid>
                        <Grid item container>
                            <Base58Table
                                isLoading={configurationsLoading}
                                columns={columns}
                                data={configurationList}
                                count={configurationsTotalElements}
                                rowsPerPage={configurationsParams.size}
                                page={configurationsParams.page}
                                onPageChange={(e, page) => this.props.setConfigurationsPage(page)}
                                onRowsPerPageChange={(e) => this.props.setConfigurationsSize(e.target.value)}
                                onFilterChange={(params) => this.props.setConfigurationsParams(params)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    settingReducer: state.settingReducer
})

export default connect(mapStateToProps, { getConfigurations, createConfiguration, setConfigurationsPage, setConfigurationsSize, setConfigurationsParams })(withRouter(withTranslation()(ConfigurationMessageTab)))