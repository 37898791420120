import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import {
    DialogContent,
    Grid,
    FormControl,
    TableCell,
    TableRow,
    Tooltip,
    Zoom
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { getPatientCalendarEntries, setPatientCalendarEntriesPage, setPatientCalendarEntriesSize, setPatientCalendarEntriesParams } from '../../redux/calendar/actions';
import Base58Table from '../../components/common/Base58Table';
import moment from 'moment';
import { BootstrapDialog, BootstrapDialogTitle, Transition } from '../../components/common/Dialog';

class SearchPatientCalendarEntryModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectPatient: null,
            params: {}
        };
    }

    componentDidUpdate(prevProps) {
    }

    closeDialog = () => {
        this.props.onClose();
        this.setState({ selectPatient: null })
        this.props.calendarReducer.patientHistoryDataParams = { page: 0, size: 10 }
    }

    handleChangePatient = (e, patient) => {
        if (patient !== null) {
            this.setState({ selectPatient: patient }, () => this.getPatientCalendarEntries(patient.id))
        } else {
            this.setState({ selectPatient: null })
            this.props.calendarReducer.patientHistoryDataParams = { page: 0, size: 10 }
        }
    }

    getPatientCalendarEntries = (id) => {
        this.props.getPatientCalendarEntries(id)
    }

    render() {
        const { selectPatient } = this.state
        const { patientList } = this.props.patientReducer
        const { patientHistoryData, patientHistoryDataParams, patientHistoryDataTotalElements, patientHistoryDataLoading } = this.props.calendarReducer

        let patientHistoryList = patientHistoryData && patientHistoryData.map((item, index) => {
            return <TableRow key={index} style={{ cursor: 'pointer', backgroundColor: "#F9F9F9" }} className={'table-row-change-color'} onClick={() => this.props.goToDay(item.startTime)}>
                <TableCell style={{ color: moment(item.startTime).isBefore(moment(new Date())) ? "red" : "green" }} >{moment(item.startTime).format("DD.MM.YYYY.")}</TableCell>
                <TableCell>{moment(item.startTime).format("HH:mm") + " - " + moment(item.endTime).format("HH:mm")}</TableCell>
                <TableCell >{item.infirmary && item.infirmary.name.length > 21 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={item.infirmary.name} style={{ cursor: 'pointer' }}>
                    <span>{item.infirmary && item.infirmary.name.length > 21 ? item.infirmary.name.substring(0, 21) + "..." : item.infirmary.name}</span>
                </Tooltip> :
                    <span style={{ backgroundColor: item.infirmary && item.infirmary.calendarHexColor, padding: "4px", borderRadius: "4px" }}>{item.infirmary && item.infirmary.name}</span>}</TableCell>
                <TableCell>{this.props.t(item.calendarEntryStatus)}</TableCell>
                <TableCell>{item.comment && item.comment.length > 72 ? <Tooltip disableFocusListener disableTouchListener transitioncomponent={Zoom} arrow placement="top-end" title={item.comment} style={{ cursor: 'pointer' }}>
                    <span>{item.comment && item.comment.length > 72 ? item.comment.substring(0, 72) + "..." : item.comment}</span>
                </Tooltip> :
                    item.comment}</TableCell>
            </TableRow>
        })

        let columns = [
            {
                name: "date",
                width: '14%'
            },
            {
                name: "time",
                width: '14%'
            },
            {
                name: "infirmary",
                width: '18%'
            },
            {
                name: "status",
                width: '20%'
            },
            {
                name: "comment",
                width: '35%'
            }
        ]


        return (
            <BootstrapDialog
                onClose={this.closeDialog}
                open={this.props.isOpen}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
            >
                <BootstrapDialogTitle onClose={this.closeDialog}>
                    {this.props.t("patient_arrival_search")}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Grid container direction="row" spacing={2}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl>
                                <WrappedAutocomplete
                                    placeholder={this.props.t("select_patient")}
                                    name="selectPatient"
                                    value={selectPatient}
                                    getOptionLabel={(option) => option.firstName + " " + option.lastName + ", " + this.props.t("phone") + ": " + option.phone + ", " + this.props.t("date_of_birth") + ": " + moment(option.dateOfBirth).format("DD.MM.YYYY.")}
                                    options={patientList}
                                    onChange={(event, patient) => {
                                        this.handleChangePatient(event, patient)
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.stopPropagation();
                                        }
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            <div>
                                                {option.firstName + " " + option.lastName}
                                                <div>
                                                    <small>{this.props.t("phone")}: {option.phone}</small>{", "}
                                                    <small>{this.props.t("date_of_birth")}: {moment(option.dateOfBirth).format("DD.MM.YYYY.")}</small>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {selectPatient && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Base58Table
                                patientHistoryDataLoading={patientHistoryDataLoading}
                                columns={columns}
                                data={patientHistoryList}
                                count={patientHistoryDataTotalElements}
                                rowsPerPage={patientHistoryDataParams.size}
                                page={patientHistoryDataParams.page}
                                onPageChange={(e, page) => this.props.setPatientCalendarEntriesPage(selectPatient.id, page)}
                                onRowsPerPageChange={(e) => this.props.setPatientCalendarEntriesSize(selectPatient.id, e.target.value)}
                                onFilterChange={(params) => this.props.setPatientCalendarEntriesParams(selectPatient.id, params)}
                            />
                        </Grid>}
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
        );
    }
}

const mapStateToProps = (state) => ({
    patientReducer: state.patientReducer,
    calendarReducer: state.calendarReducer
})

const mapActionsToProps = { getPatientCalendarEntries, setPatientCalendarEntriesPage, setPatientCalendarEntriesSize, setPatientCalendarEntriesParams }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(SearchPatientCalendarEntryModal)))
