import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

class WrappedDateTimePicker extends Component {

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localStorage.getItem('i18nextLng')}>
                <DateTimePicker
                    disablePast={this.props.disablePast}
                    label={this.props.t(this.props.label)}
                    disabled={this.props.disabled}
                    id={this.props.name}
                    name={this.props.name}
                    onChange={(e) => this.props.onChange(e)}
                    value={this.props.value}
                    inputFormat="DD.MM.YYYY HH:mm"
                    mask="__.__.____ __:__"
                    renderInput={(params) =>
                        <TextField {...params}
                            required={this.props.required}
                            error={this.props.error}
                            helperText={this.props.helperText}
                        />}
                    localeText={{
                        previousMonth: this.props.t("previous_month"),
                        nextMonth: this.props.t("next_month")
                    }}
                />
            </LocalizationProvider>
        );
    }
}

export default withTranslation()(WrappedDateTimePicker)