import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import '../../components/sidebar/Sidebar.css';
import { UserRole, hasAnyRole, hasRole } from '../../utils/Security';
import Calendar from '../../views/calendar/Calendar';
import Dashboard from '../../views/dashboard/Dashboard';
import Users from '../../views/user/Users';
import Patients from '../../views/patient/Patients';
import Services from '../../views/service/Services';
import ServiceGroups from '../../views/serviceGroup/ServiceGroups';
import Diagnoses from '../../views/diagnosis/Diagnoses';
import Infirmaries from '../../views/infirmary/Infirmaries';
import InfirmaryForDoctors from '../../views/infirmary/InfirmaryForDoctors';
import Examination from '../../views/examination/Examination';
import Invoices from '../../views/invoice/Invoices';
import Settings from '../../views/settings/Settings';
import Schedule from '../../views/schedule/Schedule';
import AuditLogs from '../../views/auditLog/AuditLogs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { establishSseConnection } from '../../redux/auth/actions'
import WarehouseOptions from '../../views/warehouse/WarehouseOptions';
import ManagePatient from '../../views/patient/ManagePatient';
import ManageInfirmary from '../../views/infirmary/ManageInfirmary';
import ManageUser from '../../views/user/ManageUser';
import ManageServiceGroup from '../../views/serviceGroup/ManageServiceGroup';
import ManageDiagnosis from '../../views/diagnosis/ManageDiagnosis';
import ManageCategory from '../../views/warehouse/category/ManageCategory';
import ManageService from '../../views/service/ManageService';
import ManageNormative from '../../views/warehouse/normative/ManageNormative';
import ManageUnitOfMeasurement from '../../views/warehouse/unit-of-measurement/ManageUnitOfMeasurement';
import ManageItem from '../../views/warehouse/item/ManageItem';
import ManageDocument from '../../views/warehouse/document/ManageDocument';
import Warehouses from '../../views/warehouse/Warehouses';
import ManageWarehouse from '../../views/warehouse/ManageWarehouse';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.props.establishSseConnection();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return (
            <PerfectScrollbar style={{ height: 'calc(100vh-200px)', width: '100%' }}>
                <main className="content">
                    <Switch>
                        {hasRole(UserRole.DOCTOR) && this.props.user.currentInfirmary === null && <Route exact path='/choose-infirmary' render={(props) => <InfirmaryForDoctors {...props} />} />}
                        {hasRole(UserRole.DOCTOR) && this.props.user.currentInfirmary === null && <Redirect to="/choose-infirmary" />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.DOCTOR]) &&
                            <Route exact path={['/examination/patients/:id', '/examination/patients/:id/calendar-entries/:calendarEntryId']}
                                render={(props) => <Examination {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/dashboard' render={(props) => <Dashboard {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST, UserRole.SIGNER]) && <Route exact path='/patients' render={(props) => <Patients {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/patients/:id' render={(props) => <ManagePatient {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/new-patient' render={(props) => <ManagePatient {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/infirmaries' render={(props) => <Infirmaries {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/infirmaries/:id' render={(props) => <ManageInfirmary {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/new-infirmary' render={(props) => <ManageInfirmary {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/users' render={(props) => <Users {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/users/:id' render={(props) => <ManageUser {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/new-user' render={(props) => <ManageUser {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/service-groups' render={(props) => <ServiceGroups {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/service-groups/:id' render={(props) => <ManageServiceGroup {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/new-service-group' render={(props) => <ManageServiceGroup {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/services' render={(props) => <Services {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/services/:id' render={(props) => <ManageService {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/new-service' render={(props) => <ManageService {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/calendar' render={(props) => <Calendar {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM]) && <Route exact path='/diagnoses' render={(props) => <Diagnoses {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM]) && <Route exact path='/diagnoses/:id' render={(props) => <ManageDiagnosis {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.DOCTOR, UserRole.SYSTEM]) && <Route exact path='/new-diagnosis' render={(props) => <ManageDiagnosis {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.RECEPTIONIST, UserRole.SYSTEM]) && <Route exact path='/offers' render={(props) => <Invoices {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.RECEPTIONIST]) && <Route exact path='/schedule' render={(props) => <Schedule {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/settings' render={(props) => <Settings {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses' render={(props) => <Warehouses {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/new' render={(props) => <ManageWarehouse {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:id' render={(props) => <ManageWarehouse {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/:option' render={(props) => <WarehouseOptions {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/categories/new' render={(props) => <ManageCategory {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/categories/:id' render={(props) => <ManageCategory {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/measurement-units/new' render={(props) => <ManageUnitOfMeasurement {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/measurement-units/:id' render={(props) => <ManageUnitOfMeasurement {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/items/new' render={(props) => <ManageItem {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/items/:id' render={(props) => <ManageItem {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/documents/new' render={(props) => <ManageDocument {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/documents/:id' render={(props) => <ManageDocument {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/normatives/new' render={(props) => <ManageNormative {...props} />} />}
                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM]) && <Route exact path='/warehouses/:warehouseId/normatives/:id' render={(props) => <ManageNormative {...props} />} />}

                        {hasRole(UserRole.SUPERADMIN) && <Route exact path='/change-logs' render={(props) => <AuditLogs {...props} />} />}

                        {hasAnyRole([UserRole.SUPERADMIN, UserRole.ADMIN, UserRole.SYSTEM, UserRole.DOCTOR, UserRole.RECEPTIONIST]) && <Redirect to="/dashboard" />}
                        {hasRole(UserRole.SIGNER) && <Redirect to="/patients" />}
                    </Switch>
                </main>
            </PerfectScrollbar>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.authReducer.user
});

const mapActionsToProps = { establishSseConnection }

export default connect(mapStateToProps, mapActionsToProps)(withRouter(withTranslation()(Content)));
