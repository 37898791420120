import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Grid,
    Button,
    FormControl,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TableBody,
    Typography,
    Box,
    InputLabel,
    Stack
} from '@mui/material';
import './Statistics.css'
import { getServicesStatistics, exportStatisticsPerServiceToPdf, exportStatisticsPerServiceToExcel } from '../../redux/examination/actions'
import WrappedDatePicker from '../../components/common/WrappedDatePicker';
import moment from 'moment';
import WrappedAutocomplete from '../../components/common/WrappedAutocomplete';
import { NumericFormat } from 'react-number-format';
import StatisticsIcon from '../../assets/icons/statistics.svg';
import ExportXLSIcon from '../../assets/icons/exportXLS.svg';
import ExportPDFIcon from '../../assets/icons/exportPDF.svg';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    PieController,
    ArcElement,
    LineController,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { optionsPieChart, randomColor } from '../../utils/ChartUtil';

ChartJS.register(
    CategoryScale,
    PieController,
    LineController,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

class DashboardStatisticsProfitPerServices extends Component {

    emptyData = {
        serviceIds: [],
        startDate: null,
        endDate: null
    }

    constructor(props) {
        super(props);
        this.state = {
            selectedServices: [],
            selectedStartDate: null,
            selectedEndDate: null,
            data: this.emptyData
        };
    }

    componentWillUnmount() {
        this.props.examinationReducer.examinationStatsPerServices = null
    }

    handleChangeService = (e, types) => {
        let { data } = this.state
        let selectedServices = { ...this.state.selectedServices }
        if (types.length === 0) {
            data.serviceIds = null
            this.props.examinationReducer.examinationStatsPerServices = null
            this.setState({ data: data, selectedServices: [] });
        } else {
            selectedServices = types;
            data.serviceIds = []
            selectedServices.forEach(s => {
                data.serviceIds.push(s.id)
            })
            this.props.examinationReducer.examinationStatsPerServices = null
            this.setState({ selectedServices: selectedServices, data: data });
        }
    }

    handleChangeStartDate = (date) => {
        let { data } = this.state
        data.startDate = moment(date)
        this.props.examinationReducer.examinationStatsPerServices = null
        this.setState({ data: data, selectedStartDate: date });
    }

    handleChangeEndDate = (date) => {
        let { data } = this.state
        data.endDate = moment(date).add(24, 'hours')
        this.props.examinationReducer.examinationStatsPerServices = null
        this.setState({ data: data, selectedEndDate: date });
    }

    getStatistics = () => {
        this.props.getServicesStatistics(this.state.data)
    }

    render() {
        const { selectedServices, selectedStartDate, selectedEndDate } = this.state
        const { serviceList } = this.props.serviceReducer
        const { examinationStatsPerServices } = this.props.examinationReducer

        return (
            <Grid container direction="row" spacing={3}>
                <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography sx={{ color: "#5C5C5C", fontSize: "16px", fontWeight: 500 }}>{this.props.t("statistics_for_services")}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box boxShadow={3} className="custom-box">
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <InputLabel className="form-label">
                                            {this.props.t("service")}
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedAutocomplete
                                                id="selectedServices"
                                                name="selectedServices"
                                                value={selectedServices}
                                                getOptionLabel={(option) => option ? option.name : ""}
                                                options={serviceList}
                                                multiple={true}
                                                onChange={(event, value) => {
                                                    this.handleChangeService(event, value)
                                                }}
                                                placeholder={this.props.t("select")}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <InputLabel className="form-label">
                                            {this.props.t("start_date")}
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedDatePicker
                                                id="startDate"
                                                name="startDate"
                                                maxDate={moment(selectedEndDate).add(-1, 'day')}
                                                onChange={this.handleChangeStartDate}
                                                value={selectedStartDate ? Date.parse(selectedStartDate) : null}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={5} lg={5} xl={5}>
                                        <InputLabel className="form-label">
                                            {this.props.t("end_date")} <span style={{ color: "#D32F2F" }}>*</span>
                                        </InputLabel>
                                        <FormControl>
                                            <WrappedDatePicker
                                                id="endDate"
                                                name="endDate"
                                                minDate={moment(selectedStartDate).add(1, 'day')}
                                                maxDate={moment(new Date()).add(1, 'year')}
                                                onChange={this.handleChangeEndDate}
                                                value={selectedEndDate ? Date.parse(selectedEndDate) : null}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item container direction="column" xs={12} sm={12} md={2} lg={2} xl={2} mt={4}>
                                        <Button
                                            className="create-btn"
                                            style={{ width: '100%' }}
                                            startIcon={<img src={StatisticsIcon} width={24} height={24} alt="statistics-icon" />}
                                            title={this.props.t("get_statistics")}
                                            onClick={() => this.getStatistics()}
                                            disabled={!selectedEndDate}>
                                            {this.props.t("statistics")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {examinationStatsPerServices && examinationStatsPerServices.serviceCostList.length > 0 && <Grid item container direction="column" xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box boxShadow={3} className="custom-box">
                                <Grid container direction="row" spacing={3}>
                                    <Grid item container>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell style={{ fontWeight: 'bold' }} width='70%'>{this.props.t("service")}</TableCell>
                                                        <TableCell style={{ fontWeight: 'bold' }} width='30%' align="right">{this.props.t("total")}</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {examinationStatsPerServices.serviceCostList.map((data, index) => {
                                                        return <TableRow key={index}>
                                                            <TableCell>
                                                                {data.serviceName}
                                                            </TableCell>
                                                            <TableCell align='right' style={{ fontWeight: 'bold' }}>
                                                                <NumericFormat value={data.serviceCost} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                            </TableCell>
                                                        </TableRow>
                                                    })}
                                                    <TableRow style={{ backgroundColor: 'lightgray', borderTop: '2px solid black' }}>
                                                        <TableCell style={{ fontWeight: 'bold' }}>{this.props.t("total")}</TableCell>
                                                        <TableCell align='right' style={{ fontWeight: 'bold' }}>
                                                            <NumericFormat value={examinationStatsPerServices.serviceTotal} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} fixedDecimalScale={true} suffix=" EUR" />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                    <Grid item container>
                                        <Stack direction="row" spacing={1.5} sx={{ marginLeft: "auto" }}>
                                            <Button
                                                sx={{ height: "42px", width: "100px" }}
                                                title={this.props.t("export_to_xls")}
                                                color="primary"
                                                onClick={() => this.props.exportStatisticsToExcel(this.state.data)}
                                                startIcon={<img src={ExportXLSIcon} alt="export-icon" />}
                                            >
                                                {this.props.t("export_to_xls")}
                                            </Button>
                                            <Button
                                                sx={{ height: "42px", width: "100px" }}
                                                title={this.props.t("export_to_pdf")}
                                                color="error"
                                                onClick={() => this.props.exportStatisticsToPdf(this.state.data)}
                                                startIcon={<img src={ExportPDFIcon} alt="export-icon" />}
                                            >
                                                {this.props.t("export_to_pdf")}
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>}
                    </Grid>
                </Grid>
                {examinationStatsPerServices && examinationStatsPerServices.serviceCostList.length > 1 && <Grid item container direction="column" xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Box boxShadow={3} className="custom-box" sx={{ width: "100%" }} justifyItems="center">
                        <div style={{ width: "700px", height: "700px" }}>
                            <Pie
                                data={{
                                    labels: examinationStatsPerServices.serviceCostList.map(d => { return d.serviceName }),
                                    datasets: [{
                                        data: examinationStatsPerServices.serviceCostList.map(data => { return data.serviceCost }),
                                        backgroundColor: examinationStatsPerServices.serviceCostList.map(data => { return randomColor() }),
                                        hoverOffset: 4
                                    }]
                                }}
                                options={optionsPieChart}
                            />
                        </div>
                    </Box>
                </Grid>}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    serviceReducer: state.serviceReducer,
    examinationReducer: state.examinationReducer
})

export default connect(mapStateToProps, { getServicesStatistics, exportStatisticsPerServiceToExcel, exportStatisticsPerServiceToPdf })(withTranslation()(DashboardStatisticsProfitPerServices))